import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useState } from "react";

import {
  LightModeOutlined,
  DarkModeOutlined,
  SettingsOutlined,
  PersonOutlineOutlined,
  LogoutOutlined,
  MenuOutlined,
} from "@mui/icons-material";
import { ColorModeContext, tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { logout, useAuthSlice } from "../../redux/slice/authSlice";
import styled from "@emotion/styled";
import { useProSidebar } from "react-pro-sidebar";
import CustomTooltip from "./CustomTooltip";
import OptionsPopup from "./OptionsPopup";

interface ColorModeContextProps {
  toggleColorMode: () => void;
}

const CustomToolbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo } = useAuthSlice();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const [showInfoPopup, setShowInfoPopup]= useState<{message:string, show: boolean}>({message: "", show: false });

  const onClickLink = (linkName: string) => {
    navigate("/setting");
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const signUpTwoAuthentication = () => {
    navigate("/signup2fa");
  }; 
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(logout());
    localStorage.setItem("logout", Date.now().toString());
    navigate("/");
  };
  const handleSettingsClick = () => {
    navigate('/accountProfile?tab=3');
  };
  const handleProfile = () => {
    navigate("/accountProfile");
  };

  const spin = `@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }`;

  const SpinningSettingsIcon = styled(SettingsOutlined)`
    ${spin}
    animation: spin 3s linear infinite;
  `;


  const onClosePopup = () => {
    setShowInfoPopup({ message: "", show: false })
  }
  const onShowPopup = () => {
    setShowInfoPopup({ message: "You are about to sign out. Do you want to continue?", show: true })
  }

  return (
    <>
      <AppBar
        position="sticky"
        color="transparent"
        sx={{ boxShadow: 0, borderColor: colors.grey[700] }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={1}
          sx={{ backgroundColor: colors.grey[800] }}
        >
          <Box display="flex">
            {broken && !rtl && (
              <MenuItem
                onClick={() => toggleSidebar()}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                    color:
                      theme.palette.mode === "dark"
                        ? colors.primary[200] + "!important"
                        : colors.primary[300] + "!important",
                  },
                }}
              >
                <MenuOutlined />
              </MenuItem>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: "2px 5px 0px 5px",
              }}
            >
              <Typography variant="caption">
                <b>{userInfo.userName}</b>
              </Typography>
              <Typography variant="caption">
                {"[" + userInfo.roleDisplayValue + "]"}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === "dark" ? (
                  <LightModeOutlined />
                ) : (
                  <DarkModeOutlined />
                )}
              </IconButton>
              {/* <IconButton>
                <NotificationsOutlined />
              </IconButton> */}
              {/* <IconButton
              onClick={handleSettingsClick}
                >
                <SpinningSettingsIcon />
              </IconButton> */}
              <CustomTooltip title="Account" arrow placement="top-end">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>
                    <PersonOutlineOutlined />
                  </Avatar>
                </IconButton>
              </CustomTooltip>
            </Box>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleProfile}>
              <Avatar /> Account Profile
            </MenuItem>
            <Divider />
            <MenuItem onClick={onShowPopup}>
              <ListItemIcon>
                <LogoutOutlined fontSize="small" />
              </ListItemIcon>
              Sign Out
            </MenuItem>
          </Menu>
        </Box>
      </AppBar>
      <OptionsPopup
        open={showInfoPopup.show}
        onClose={onClosePopup}
        variant="custom"
        message={showInfoPopup.message}
        title="Sign Out Confirmation"
        buttons={[
          { name: "Continue", color: "primary", onClick: handleLogout },
          { name: "Cancel", color: "secondary", onClick: onClosePopup }
        ]}
      />
    </>
  );
};

export default CustomToolbar;
