import * as React from "react";
import { FormControl } from "@mui/base/FormControl";
import { TextField, TextFieldProps, useTheme } from "@mui/material";
import { tokens } from "../../theme";

export type InputFieldProps = {
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium";
  label?: string | "";
  rules?: Object;
} & TextFieldProps;

const TextFieldGroup: React.FC<InputFieldProps> = ({
  size,
  color,
  label,
  rules,
  ...props
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isRequired = () => {
    if (rules && typeof rules === "object" && "required" in rules && label) {
      return true;
    }
    return false;
  };

  const getLabel = () => {
    if (isRequired()) {
      return (
        <span>
          {label}{" "}
          <span style={{ color: colors.success[200], fontSize: "18px" }}>
            *
          </span>
        </span>
      );
    }
    return label;
  };

  return (
    <FormControl>
      {label ? <label>{label}{getLabel()}</label> : ""}
      <TextField
        style={{ paddingTop: "5px" }}
        fullWidth
        type="text"
        size={size || "small"}
        color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
        variant="outlined"
        {...props}
      />
    </FormControl>
  );
};
export default TextFieldGroup;
