import Cookies from 'js-cookie';

export const setDataToCookies = (cookieName: string, cookieValue: string, options?: Object) => {
  Cookies.set(cookieName, cookieValue, options);
};

export const getDataFromCookies = (cookieName: string): string => {
  return Cookies.get(cookieName) || "";
};

export const removeDataFromCookies= (cookieName: string) => {
  Cookies.remove(cookieName);
};

export const setDataToLocalStorage = (key: string, value: string) =>{
    localStorage.setItem(key, value);
}
  
export const getDataFromLocalStorage = (key: string) =>{
    return localStorage.getItem(key);
}

export const clearLocalStorage = () =>{
    localStorage.clear();
}

export const removeItemFromLocalStorage = (key: string) =>{
    return localStorage.removeItem(key);
}

export const setDataToSessionStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
}

export const removeItemFromSessionStorage = (key: string) => {
  return sessionStorage.removeItem(key);
}

export const getDataFromSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
}

export const setSearchCriteriaToSessionStorage = (listPage: string, criteriaObj: any) => {
  let searchCriterias = getDataFromSessionStorage("searchCriterias");
  let criterias = {};
  if (searchCriterias && searchCriterias.trim() !== "") {
    criterias = JSON.parse(searchCriterias);
    // @ts-ignore
    criterias[listPage] = criteriaObj;
    setDataToSessionStorage("searchCriterias", JSON.stringify(criterias));
  } else {
    criterias = {};
    // @ts-ignore
    criterias[listPage] = criteriaObj;
    setDataToSessionStorage("searchCriterias", JSON.stringify(criterias));
  }
}

export const getSearchCriteriaFromSessionStorage = (listPage: string) => {
  let searchCriterias = getDataFromSessionStorage("searchCriterias");
  if (searchCriterias && searchCriterias.trim() !== "") {
    let criterias = {};
    criterias = JSON.parse(searchCriterias);
    // @ts-ignore
    return criterias[listPage];
  }
  return undefined;
}