import * as React from "react";
import { Stack, Stepper, Step, StepButton, StepLabel, StepContent, useTheme, Box, Typography, Button as MuiButton, useMediaQuery, Paper } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { tokens } from "../../theme";

interface FormStepperProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  children: React.ReactNode;
  steps: string[];
}

const FormStepper: React.FC<FormStepperProps> = ({ activeStep, setActiveStep, children, steps }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const totalSteps = (): number => steps.length;

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep < totalSteps() - 1 ? prevActiveStep + 1 : prevActiveStep
    );
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep
    );
  };

  const handleStep = (step: number) => (): void => {
    setActiveStep(step);
  };

  const stepperSx = {
    ".MuiSvgIcon-root.Mui-active": {
      color: "#1976d2",
      padding: "3px",
      borderRadius: "50%",
      border: 1,
      marginY: "-3px",
    },
    ".MuiStepIcon-text": {
      fontWeight: 600,
      fontSize: 15,
    },
    ".MuiSvgIcon-root.Mui-completed": {
      color: colors.success[300],
    },
  };

  return (
    <Stack sx={{ width: "100%" }}>
      {isMobile ? (
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} orientation="vertical" sx={stepperSx}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  {label}
                </StepLabel>
                <StepContent>
                  <Typography>{children}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Stepper nonLinear activeStep={activeStep} sx={stepperSx}>
            {steps.map((label, index) => (
              <Step key={label} completed={false}>
                <StepButton onClick={handleStep(index)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>{children}</React.Fragment>
        </Box>
      )}
    </Stack>
  );
};

export default FormStepper;
