import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import { Container, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import {HomeOutlined } from "@mui/icons-material";

export default function Page404() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      <Container
        component="main"
        maxWidth="md"
        style={{
          paddingTop: "40px",
          paddingBottom: "50px",
          textAlign: "center",
        }}
      >
        <Typography variant="h3" fontWeight="bold" sx={{ m: "0 0 5px 0" }}>
          Sorry, Page Not Found!
        </Typography>
        <Box color={colors.grey[500]}>
          Sorry, we couldn't find the page you’re looking for. Perhaps you've
          mistyped the URL? Be sure to check your spelling.
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p="20px 0px"
        >
          <img
            alt="logo"
            width="300px"
            height="300px"
            src={require("../../assets/404image.png")}
          />
        </Box>
        <Button
          color="error"
          startIcon={<HomeOutlined />}
          onClick={() => navigate("/")}
        >
          Go to Home
        </Button>
      </Container>
      <Box
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, borderTop: 5 }}
      >
        <Footer />
      </Box>
    </Grid>
  );
}
