import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "./redux-hooks";
import { GeneralV3Response, InterceptorOption, LoadingType } from "../../types/CommonTypes";
import { getAllCodeTableValue, getAllCodeTableValueByTableName } from "../../service/CodetableService";

export type CodeTable = {
    tableName?: string;
    keyName: string;
    displayValue: string;
};

type PracticeRegistrationState = {
    allCodeTablesValuesMap?: Map<string, CodeTable>;
    codeTableValuesForATableName?: CodeTable[];
    allCodeTableValueFetchStatus?: LoadingType;
    allCodeTableValuesByTableNameFetchStatus?: LoadingType;
};

const initialState: PracticeRegistrationState = {
    allCodeTablesValuesMap: undefined,
    codeTableValuesForATableName: undefined,
    allCodeTableValueFetchStatus: "idle",
    allCodeTableValuesByTableNameFetchStatus: "idle",
};

export const getCodeTableValuesByTableName = (allCodeTablesValuesMap: Map<string, CodeTable>, tableName: string) => {
    let codeTables: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
        // @ts-ignore
        codeTables = allCodeTablesValuesMap[tableName];
    }
    return codeTables;
};

export const fetchAllCodeTablesValues = createAsyncThunk("fetchAllCodeTablesValues", async (interceptorOption?: InterceptorOption) => {
    const response = await getAllCodeTableValue(interceptorOption);
    return response.data;
});

export const fetchAllCodeTableValuesByTableName = createAsyncThunk("fetchAllCodeTableValuesByTableName", async (tableName: string) => {
    const response = await getAllCodeTableValueByTableName(tableName);
    return response.data;
});

const codetableSlice = createSlice({
    name: "codetables",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCodeTablesValues.pending, (state) => {
                state.allCodeTableValueFetchStatus = "loading";
            })
            .addCase(fetchAllCodeTablesValues.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.allCodeTableValueFetchStatus = "success";
                state.allCodeTablesValuesMap = action.payload.data;
            })
            .addCase(fetchAllCodeTablesValues.rejected, (state, action) => {
                state.allCodeTableValueFetchStatus = 'error';
            })
            .addCase(fetchAllCodeTableValuesByTableName.pending, (state) => {
                state.allCodeTableValuesByTableNameFetchStatus = "loading";
            })
            .addCase(fetchAllCodeTableValuesByTableName.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.allCodeTableValuesByTableNameFetchStatus = "success";
                if (action.payload.data && Object.keys(action.payload.data).length > 0) {
                    state.codeTableValuesForATableName = action.payload.data[Object.keys(action.payload.data)[0]];
                }
            })
            .addCase(fetchAllCodeTableValuesByTableName.rejected, (state, action) => {
                state.allCodeTableValuesByTableNameFetchStatus = 'error';
            });
    },
});

export const useCodetablesSlice = () => useAppSelector((state) => state.codetableSlice);
export default codetableSlice.reducer;