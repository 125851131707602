import { CreateSearchAgency, ProductCommissionAgency, SearchAgency } from "../redux/slice/agency/AgencySlice";
import { ADD_AGENCY_PRODUCT_COMMISSION_URL, EDIT_AGENCY_PRODUCT_COMMISSION_URL, EDIT_AGENCY_URL, GET_AGENCY_BY_ID_URL, GET_AGENCY_BY_USER_URL, GET_AGENCY_LIST_URL, GET_ASSOCIATE_AGENCY_LIST_URL, GET_IS_V3_AGENCY_EXISTS_URL, GET_PRODUCT_COMMISSIONS_BY_AGENCY_ID_URL, SAVE_AGENCY_URL } from "./URL";
import { http } from "./http";

export const saveAgencyData = async (agency: CreateSearchAgency) => {
    try{
        const response = await http.post(SAVE_AGENCY_URL, agency);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const editAgencyData = async (agency: CreateSearchAgency) => {
    try{
        const response = await http.post(EDIT_AGENCY_URL, agency);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const searchAgencyData = async (agency: SearchAgency) => {
    try{
        const response = await http.post(GET_AGENCY_LIST_URL, agency);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const getAgencyDataById = async (agencyId: number) => {
    try{
        const response = await http.get(GET_AGENCY_BY_ID_URL, {
            params: {
                agencyId
            }
        });
        return response;
    }
    catch(error){
        throw error;
    }
};

export const getAssociateAgencyList = async () => {
    try{
        const response = await http.get(GET_ASSOCIATE_AGENCY_LIST_URL);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const getAgencyByUser = async () => {
    try{
        const response = await http.get(GET_AGENCY_BY_USER_URL);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const getProductCommissionsByAgencyId = async (agencyId: number, currentPage: number, itemsPerPage: number) => {
    try{
        const response = await http.get(GET_PRODUCT_COMMISSIONS_BY_AGENCY_ID_URL, {
            params: {
                agencyId, currentPage, itemsPerPage
            }
        });
        return response;
    }
    catch(error){
        throw error;
    }
};

export const addAgencyProductCommissionData = async (agency: ProductCommissionAgency) => {
    try{
        const response = await http.post(ADD_AGENCY_PRODUCT_COMMISSION_URL, agency);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const editAgencyProductCommissionData = async (agency: ProductCommissionAgency) => {
    try{
        const response = await http.post(EDIT_AGENCY_PRODUCT_COMMISSION_URL, agency);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const getIsV3AgencyExists = async () => {
    try{
        const response = await http.get(GET_IS_V3_AGENCY_EXISTS_URL);
        return response;
    }
    catch(error){
        throw error;
    }
};