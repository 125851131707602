import React, { useCallback, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../common/Button";
import { Divider, FormControl, Grid, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import CustomTextField from "../../common/CustomTextField";
import Header from "../../common/Header";
import CustomNametField from "../../common/CustomNametField";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import CustomCheckbox from "../../common/CustomCheckBox";
import CustomRadioButton from "../../common/CustomRadioButton";
import CustomAddressTextField from "../../common/CustomAddressTextField";
import {
  CodeTable,
  fetchAllCodeTablesValues,
  getCodeTableValuesByTableName,
  useCodetablesSlice,
} from "../../../redux/slice/codetableSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { usePracticeEditSlice } from "../../../redux/slice/practice/practiceEditSlice";
import {
  PracticeInfo,
  fetchProducts,
  usePracticeRegistrationSlice,
} from "../../../redux/slice/practice/practiceRegistrationSlice";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Cancel } from "@mui/icons-material";
import Table from '../../common/CustomTable';
import { tokens } from '../../../theme';
import { Column } from "../../../redux/slice/commonSlice";
import { AuthorizedSignerInfo } from "./AuthorizedSignerPopup";

interface ApprovePracticeProps {
  open: boolean;
  onClose: () => void;
  practiceInfo?: PracticeInfo;
}

const signerColumns: Column[] = [
  { id: "fullName", label: "Full Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PracticeApprovePopup: React.FC<ApprovePracticeProps> = ({
  open,
  onClose,
}) => {
  const { control, reset, getValues } = useForm<PracticeInfo>();
  const { practiceEditInfo } = usePracticeEditSlice();
  const dispatch = useAppDispatch();
  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } =
    useCodetablesSlice();
  const [woundCareOptions, setWoundCareOptions] = React.useState<CodeTable[]>(
    []
  );
  const [placeOfService, setPlaceOfService] = React.useState<CodeTable[]>([]);
  const [placesOfServiceCheck, setPlacesOfServiceCheck] = React.useState<string[]>([]);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const betweenSmAndMd = useMediaQuery(
    `(min-width:${theme.breakpoints.values.sm}px) and (max-width:${theme.breakpoints.values.md}px)`
  );
  const colors = tokens(theme.palette.mode);
  const [signersRows, setSignersRows] = React.useState<{ fullName: string, email: string }[]>([]);

  const defaultValues = useCallback(() => {
    if (practiceEditInfo) {
      reset(practiceEditInfo);
    }
  }, [practiceEditInfo, reset]);

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  useEffect(() => {
    dispatch(fetchAllCodeTablesValues());
    console.log(allCodeTablesValuesMap);
    setSigners(practiceEditInfo);
  }, [practiceEditInfo]);
  React.useEffect(() => {
    if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
      console.log(woundCareOptions);
      setWoundCareOptions(
        getCodeTableValuesByTableName(allCodeTablesValuesMap, "WoundCare")
      );
      setPlaceOfService(
        getCodeTableValuesByTableName(allCodeTablesValuesMap, "PlaceOfService")
      );
    }
  }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  useEffect(()=>{
    if(practiceEditInfo){
      setPlacesOfServiceCheck(getValues("practiceRequestInfo.placesOfService"));
      console.log(getValues("practiceRequestInfo.placesOfService"));
    }
  },[practiceEditInfo, allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  const setSigners = (practiceEditInfo: any) => {
    const signersArr: { fullName: string, email: string}[] = [];
    practiceEditInfo && practiceEditInfo?.practiceRequestInfo?.authorizedSigners?.forEach((signer: AuthorizedSignerInfo) => {
      signersArr.push({
        fullName: signer.fullName,
        email: signer.email
      });
    });
    setSignersRows(signersArr);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      // PaperProps={{
      //   style: {
      //     maxWidth: isXsScreen ? '65%' : '1400px', width: '65%',
      //     marginLeft: isXsScreen ? '40%' : '20%',
      //   },
      // }}
      maxWidth="lg"
      onClick={(event) => {
        event.stopPropagation();
      }}
      onClose={onClose}
    >
      <DialogContent>
        <Header
          title="Practice Information"
          subtitle=""
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomTextField
              control={control}
              controllerName="practiceName"
              label="Practice Name"
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              control={control}
              controllerName="taxId"
              label="Practice Tax ID"
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              control={control}
              controllerName="npi"
              label="Practice NPI"
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              control={control}
              controllerName="ptan"
              label="Practice PTAN"
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={12}>
            <CustomNametField
              control={control}
              context="Contact"
              xs={12}
              md={4}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              control={control}
              controllerName="contactEmail"
              label="Contact Email"
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomPatternTextField
              control={control}
              label="Contact Phone"
              name="contactPhone"
              value={practiceEditInfo?.contactPhone || ""}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomPatternTextField
              control={control}
              label="Contact Fax"
              name="contactFax"
              value={practiceEditInfo?.contactFax || ""}
              isDisabled={true}
            />
          </Grid>
        </Grid>
        <div style={{ height: "30px" }}></div>
        <Header
          title="Account Payable Information"
          subtitle=""
        />
        <Grid container spacing={2}>
          <Grid item md={12}>
            <CustomNametField
              control={control}
              context="ap"
              label="AP Contact"
              xs={12}
              md={4}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomPatternTextField
              control={control}
              label="AP Contact Phone"
              name="apPhone"
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              control={control}
              controllerName="apEmail"
              label="AP Contact Email"
              isDisabled={true}
            />
          </Grid>
        </Grid>
        <div style={{ height: "30px" }}></div>
        <Header title="Biller Information" subtitle="" />
        <Grid container spacing={2}>
          {/* <Grid item md={12}>
            <CustomCheckbox
              id={"12"}
              control={control}
              controllerName={`practiceRequestInfo.${"billerNotApplicable"}`}
              label="Not Applicable"
              checked={billerNotApplicableCheck}
              isDisabled={true}
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <CustomNametField
              control={control}
              context="Biller"
              name="practiceRequestInfo"
              xs={12}
              md={4}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomPatternTextField
              control={control}
              label="Biller Contact Phone"
              name={`practiceRequestInfo.${"billerContactPhone"}`}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              control={control}
              controllerName={`practiceRequestInfo.${"billerContactEmail"}`}
              label="Biller Contact Email"
              isDisabled={true}
            />
          </Grid>
          {/* <Grid item md={12}>
            <CustomAddressTextField
              control={control}
              context="Biller"
              name="practiceRequestInfo"
              isDisabled={true}
            />
          </Grid> */}
        </Grid>
        <div style={{ height: "30px" }}></div>
        <Header
          title="Authorized Signer(s) Information"
          subtitle=""
        />
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box sx={{ backgroundColor: "#898989", borderRadius: 1, border: '3px solid #898989' }} >
              <Table
                columns={signerColumns}
                rows={signersRows}
                disablePagination={true}
                disabled={true} />
            </Box>
          </Grid>
        </Grid>
        <div style={{ height: "30px" }}></div>
        <Header
          title="Wound Care Information"
          subtitle=""
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div style={{ fontSize: "medium" }}>Wound Care Focus</div>
            <FormControl>
              <CustomRadioButton
                control={control}
                controllerName={`practiceRequestInfo.${"woundCareFocus"}`}
                options={
                  woundCareOptions
                    ? woundCareOptions.map((option) => ({
                        value: option.keyName,
                        label: option.displayValue,
                      }))
                    : []
                }
                isDisabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              control={control}
              controllerName={`practiceRequestInfo.${"averageWoundsTreatedPerMonth"}`}
              label="Average Wounds Treated Per Month"
              isDisabled={true}
            />
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item xs={12} md={4}>
            <div style={{ fontSize: "medium" }}>Place(s) of Service</div>
            <FormControl>
              <Grid
                container
                spacing={1}
                style={{ position: "relative", left: "10px" }}
              >
                {(placeOfService || []).map((option, index) => (
                  <Grid item xs={6} key={index}>
                    <CustomCheckbox
                      id={`${index}`}
                      control={control}
                      controllerName={`practiceRequestInfo.${"placeOfService"}`}
                      label={option.displayValue}
                      checked={
                        placesOfServiceCheck &&
                        placesOfServiceCheck.includes(option.keyName)
                      }
                      isDisabled={true}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormControl>
          </Grid>
        </Grid>

        <div style={{ height: "30px" }}></div>
        <Grid container spacing={2}></Grid>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Divider
              orientation="horizontal"
              variant="middle"
              flexItem
              sx={{ padding: 0, margin: "20px 0" }}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item md={6} xs={12}>
              <CustomTextField
                control={control}
                controllerName={`practiceRequestInfo.${"repOrDistributorName"}`}
                label="Please provide the name of the Rep or Distributor that brought you here"
                multiline
                rows={4}
                maxRows={4} 
                disabled={true}
                />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextField
                control={control}
                controllerName={`practiceRequestInfo.${"anyQuestions"}`}
                label="Please List Any Questions You Have"
                multiline
                rows={4}
                maxRows={4}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
{/* 
        <div style={{ height: "30px" }}></div>
        <Header title="Insurance Payer Mix" subtitle="" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CustomTextField
              control={control}
              controllerName={`practiceRequestInfo.${"insurancePayer1"}`}
              label="#1 Insurance Payer"
              isDisabled={true}
            />
            <p style={{ fontSize: "small" }}>As a percentage of volume.</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              control={control}
              controllerName={`practiceRequestInfo.${"insurancePayer2"}`}
              label="#2 Insurance Payer"
              isDisabled={true}
            />
            <p style={{ fontSize: "small" }}>As a percentage of volume.</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField
              control={control}
              controllerName={`practiceRequestInfo.${"insurancePayer3"}`}
              label="#3 Insurance Payer"
              isDisabled={true}
            />
            <p style={{ fontSize: "small" }}>As a percentage of volume.</p>
          </Grid>
        </Grid> */}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose} startIcon={<Cancel />}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PracticeApprovePopup;
