import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { generate2faOTPs, getAll2faOptions, inactivate2FAoptionById, save2faCommunications, validate2faOTPs, getUser2FList } from "../../../service/2FAService";
import { useAppSelector } from "../redux-hooks";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { resetFetchStatus } from "../userSlice";
 
export type Generate2faRequest = {
  methodValue: string,
  methodType: "EMAIL" | "MOBILE" | null,
  isPrimary: boolean,
};
 
export type TwoFactorSaveRequest = {
  userId: number | null,
  communicationValue: string,
  communicationType: "EMAIL" | "MOBILE" | null,
}
 
export type twoFaSignUp = {
  mobile: string | undefined;
  email: number | undefined;
};
 
export type TwoFactorUserDetailDTO = {
  user2faId: number,
  userId: number,
  methodType: "EMAIL" | "MOBILE" | null,
  methodValue: string,
  inactiveDate: string,
}

export type User2faDTO = {
  user2faId: number;
  userId: number;
  methodType: "EMAIL" | "MOBILE";
  methodValue: string;
  inactiveDate: Date;
  isPrimary: boolean;
};
 
export type TwoFactorDTO = {
  communicationDetails: TwoFactorUserDetailDTO[],
  isValid: boolean,
  getAllOptionListStatus: LoadingType,
  inactivateOptionStatus: LoadingType,
  generate2faStatus: LoadingType,
  validate2faStatus: LoadingType,
  save2faOptionStatus: LoadingType,
  User2FaListInfo?: User2faDTO[] | null,
  User2FaListFetchStatus?: LoadingType,
}
 
const initialState: TwoFactorDTO = {
  communicationDetails: [],
  isValid: false,
  getAllOptionListStatus: "idle",
  inactivateOptionStatus: "idle",
  generate2faStatus: "idle",
  validate2faStatus: "idle",
  save2faOptionStatus: "idle",
}
 
export const getAll2faOptionList = createAsyncThunk("getAll2faOptionList", async () => {
  const response = await getAll2faOptions();
  return response.data;
});
 
export const inactivate2FAoption = createAsyncThunk("inactivate2FAoption", async (user2faId: number) => {
  const response = await inactivate2FAoptionById(user2faId);
  return response.data;
});
 
export const generate2faOTP = createAsyncThunk<
any,
{ request: TwoFactorSaveRequest },
{ state: RootState; rejectValue: string }>
("generate2faOTP", async ({request}) => {
  const response = await generate2faOTPs(request);
  return response.data;
});
 
export const validate2faOTP = createAsyncThunk<
any,
{ otp: string},
{ state: RootState; rejectValue: string }>
("validate2faOTP", async ({otp}) => {
  const response = await validate2faOTPs(otp);
  return response.data;
});
 
export const save2faCommunicationData = createAsyncThunk("save2faCommunicationData", async (obj: Generate2faRequest) => {
  const response = await save2faCommunications(obj);
  return response.data;
});

export const fetchAllUserTwoFactorList = createAsyncThunk("fetch2FList", async (activeUser2faOnly : boolean) => {
    const response = await getUser2FList(activeUser2faOnly);
    return response;
  }
);
 
const twoFactorSlice = createSlice({
  name: "twoFactor",
  initialState,
  reducers: {
    reset2FAFetchStatus: (state)=>{
      state.getAllOptionListStatus = "idle";
      state.inactivateOptionStatus = "idle";
      state.generate2faStatus = "idle";
      state.validate2faStatus = "idle";
      state.save2faOptionStatus = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAll2faOptionList.pending, (state) => {
      state.getAllOptionListStatus = "loading";
    })
    .addCase(getAll2faOptionList.fulfilled, (state, action) => {
      state.getAllOptionListStatus = "success";
      state.communicationDetails = action.payload;
    })
    .addCase(getAll2faOptionList.rejected, (state, action) => {
      state.getAllOptionListStatus = "error";
    })
    .addCase(inactivate2FAoption.pending, (state) => {
      state.inactivateOptionStatus = "loading";
    })
    .addCase(inactivate2FAoption.fulfilled, (state, action) => {
      state.inactivateOptionStatus = "success";
    })
    .addCase(inactivate2FAoption.rejected, (state, action) => {
      state.inactivateOptionStatus = "error";
    })
    .addCase(generate2faOTP.pending, (state) => {
      state.generate2faStatus = "loading";
    })
    .addCase(generate2faOTP.fulfilled, (state, action) => {
      state.generate2faStatus = "success";
    })
    .addCase(generate2faOTP.rejected, (state, action) => {
      state.generate2faStatus = "error";
    })
    .addCase(validate2faOTP.pending, (state) => {
      state.validate2faStatus = "loading";
    })
    .addCase(validate2faOTP.fulfilled, (state, action) => {
      state.validate2faStatus = "success";
    })
    .addCase(validate2faOTP.rejected, (state, action) => {
      state.validate2faStatus = "error";
    })
    .addCase(save2faCommunicationData.pending, (state) => {
      state.save2faOptionStatus = "loading";
    })
    .addCase(save2faCommunicationData.fulfilled, (state, action) => {
      state.save2faOptionStatus = "success";
    })
    .addCase(save2faCommunicationData.rejected, (state, action) => {
      state.save2faOptionStatus = "error";
    })
    .addCase(fetchAllUserTwoFactorList.pending, (state) => {
      state.User2FaListFetchStatus = "loading";
    })
    .addCase(
      fetchAllUserTwoFactorList.fulfilled, (state, action) => {
        state.User2FaListFetchStatus = "success";
        state.User2FaListInfo = action.payload.user2falist;
      }
    )
    .addCase(fetchAllUserTwoFactorList.rejected, (state, action) => {
      state.User2FaListFetchStatus = "error";
    });
  },
});

export const { reset2FAFetchStatus } = twoFactorSlice.actions;
export const useTwoFactorReducer = () => useAppSelector((state) => state.twoFactorSlice);
export default twoFactorSlice.reducer;