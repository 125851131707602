import { useAuthSlice } from "../redux/slice/authSlice";

export default function useToken() {
  const { userInfo } = useAuthSlice();
  
  // Check if userInfo exists and has canAccessApplicationValue
  if (userInfo.canAccessApplicationValue) {
    const canAccessApplicationValue = userInfo.canAccessApplicationValue;
    
    return (token?: string | string[]) => {
      if (canAccessApplicationValue) {
        const items = canAccessApplicationValue.split(";");
        if (token instanceof Array) {
          return token.find((item) => items.includes(item));
        } else if (token) {
          return items.includes(token);
        } else {
          return true;
        }
      }
      return false;
    };
  } else {
    // Return a function that always returns false if userInfo doesn't have canAccessApplicationValue
    return () => false;
  }
}
