import {
    FETCH_BILLERS_URL, FETCH_MACS_URL, FETCH_PRODUCTS_PRICE_BY_MAC, FETCH_PRODUCT_WITH_PRICE_URL, GET_ALL_AGENCIES_URL,
    GET_MANUFACTURERS_URL, SEARCH_PRACTICES_URL
} from "./URL";
import { http } from "./http";

export const getMacsList = async () => {
    const response = await http.get(FETCH_MACS_URL);
    return response;
};

export const getManufacturersList = async () =>{
    const response = await http.get(GET_MANUFACTURERS_URL);
    return response;
};

export const getProductList = async () => {
    const response = http.get(FETCH_PRODUCT_WITH_PRICE_URL);
    return response;
};

export const getPracticeList = async (data:any) => {
    const response = await http.post(SEARCH_PRACTICES_URL, data)
    return response;
};

export const getAllAgenciesList = async () =>{
    const response = await http.get(GET_ALL_AGENCIES_URL);
    return response;
};

export const findAllBillers = async (activeBillersOnly?: boolean) => {
    try {
        const response = await http.get(`${FETCH_BILLERS_URL}?activeBillersOnly=${activeBillersOnly}`);
        return response;
    } catch (error) {
        throw error;
    }
}