import { Box, useTheme } from '@mui/material';
import * as React from 'react';
import moment from "moment";
import { tokens } from '../../theme';
import packageJson from '../../../package.json';

const Footer:React.FC= () => {
    let thisYear = moment().format('YYYY');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign : 'center',
          }}>
        <Box className="footer fixed-bottom" p={1} style={{backgroundColor : colors.grey[900]}}>
            <div className="footer-text">
                { "Copyright © " + thisYear+ " V3 BioMedical "+packageJson.version+" - Powered by Conscisys"}
            </div>
        </Box> 
    </Box>
    );
  }
  
  export default Footer;