import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import { CHANGE_PASSWORD_RESET_URL, CHECK_AUTHENTICATION_URL, FORGOT_PASSWORD_RESET_URL, LOGIN_URL, LOGOUT_URL, RE_ACCESS_TOKEN_URL } from "../../service/URL";
import { useAppSelector } from "./redux-hooks";
import { getDataFromCookies, removeDataFromCookies, setDataToCookies } from "../../utils/StorageUtils";
import { RootState } from "../store";
import { GeneralV3Response, InterceptorOption } from "../../types/CommonTypes";
import { updateTokenToCookie } from "../../utils/Utility";
import { decrypt, encrypt } from "../../utils/EncryptorUtils";
import { SHIFT_KEY } from "../../constants/applicationConstants";

export type UserInfo = {
  accessTokenRenewTime: number,
  canAccessApplicationValue: string,
  firstName: string,
  inactivityTimeExceeded: boolean,
  lastName: string,
  middleName: string,
  mobileNumber: string,
  refreshToken: string,
  refreshTokenExpired: boolean,
  token: string,
  userId: number,
  userName: string,
  userRole: string,
  roleDisplayValue: string,
  securityTokens: {
    [key: string]: string[];
  },
}

export type LoginInfo = {
  userName: string;
  password: string;
};


export type ResetPasswordInfo = {
  newPassword: string;
  confirmPassword: string;
  code: string;
}

type AuthApiState = {
  userInfo: UserInfo
  loginInfo?: LoginInfo | null;
  status: "idle" | "loading" | "failed" | "success";
  renewStatus: "idle" | "loading" | "failed" | "success";
  reLoginStatus: "idle" | "loading" | "failed" | "success";
  error: string | null;
  isValid: true | false;
  firstTimeLogin: true | false;
  selectedModule:string;
};

const initialState: AuthApiState = {
  userInfo: {
    accessTokenRenewTime: 0,
    canAccessApplicationValue: "",
    firstName: "",
    inactivityTimeExceeded: false,
    lastName: "",
    middleName: "",
    mobileNumber: "",
    refreshToken: "",
    refreshTokenExpired: false,
    token: "",
    userId: 0,
    userName: "",
    userRole: "",
    roleDisplayValue: "",
    securityTokens: {},
  },
  loginInfo: undefined,
  status: "idle",
  renewStatus: "idle",
  reLoginStatus: "idle",
  error: null,
  isValid: false,
  firstTimeLogin: false,
  selectedModule: "",
};

export const loginInfoInitialValues = {
  userName: "",
  password: "",
}

export const login = createAsyncThunk<
  any,
  { loginInfo: LoginInfo; interceptorOption?: InterceptorOption },
  { state: RootState; rejectValue: string }
>("login", async (data, { rejectWithValue }) => {
  const { loginInfo, interceptorOption } = data;
  try {
    const response = await http.post(LOGIN_URL, loginInfo, undefined, interceptorOption);
    const resData = response.data;
    return resData.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});

export const checkAuthentication = createAsyncThunk("checkAuthentication", async (interceptorOption?: InterceptorOption) => {
  try {
    const response = await http.get(CHECK_AUTHENTICATION_URL, undefined, interceptorOption);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const logout = createAsyncThunk("logout", async () => {
  const response = await http.post(LOGOUT_URL, undefined, undefined, {skipErrorPopup: true});
  return response.data;
});

export const reNewAccessToken = createAsyncThunk("reNewAccessToken", async (data: string, { rejectWithValue }) => {
  try {
    const response = await http.post(RE_ACCESS_TOKEN_URL, { refreshToken: data }, undefined, {skipErrorPopup: true, hideLoadingPopup: true});
    const resData = response.data;
    return resData.data;
  }
  catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const relogin = createAsyncThunk("relogin", async (data: LoginInfo, { rejectWithValue }) => {
  try {
    const response = await http.post(LOGIN_URL, data, undefined);
    const resData = response.data;
    return resData.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const forgotPasswordReset = createAsyncThunk("forgotPassword", async (username: string, { rejectWithValue }) => {
  try {
    const response = await http.post(FORGOT_PASSWORD_RESET_URL, username, undefined);
    const resData = response.data;
    return resData;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
})

export const ChangeForgotPassword = createAsyncThunk("ChangeForgotPassword", async (data: ResetPasswordInfo, { rejectWithValue }) => {
  try {
    const response = await http.post(CHANGE_PASSWORD_RESET_URL, data, undefined);
    const resData = response.data;
    return resData;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
})


const authSlice = createSlice({
  name: "auth",
  initialState,
    reducers: {
      resetReloginFetchStatus: (state) =>{
        state.reLoginStatus= "idle";
    },
    setSelectedModule: (state: AuthApiState, action) => {
      state.selectedModule = action.payload;
    },
    setFirstTimeLogin: (state: AuthApiState, action) => {
      state.firstTimeLogin = action.payload;
    },
    resetAuthState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<UserInfo>) => {
        state.status = "success";
        state.isValid = true;
        state.firstTimeLogin = true;
        state.userInfo = action.payload;
        updateTokenToCookie(action.payload.token);
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.isValid = false;
        state.error = action.error.message || "Login failed";
      })
      .addCase(checkAuthentication.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(checkAuthentication.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.status = "success";
        state.isValid = true;
        state.userInfo = action.payload.data;
        updateTokenToCookie(action.payload.data.token);
      })
      .addCase(checkAuthentication.rejected, (state, action) => {
        state.status = "failed";
        state.isValid = false;
        state.error = action.error.message || "Login failed";
      })
      .addCase(logout.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        removeDataFromCookies("token");
        const version = getDataFromCookies("version");
        console.log(decrypt(version, SHIFT_KEY))
        if (version && decrypt(version, SHIFT_KEY) !== "private"){
          removeDataFromCookies(encrypt(`2FA${state.userInfo.userId}CHECK`, SHIFT_KEY))
          removeDataFromCookies("version");
        }
        return {
          ...state,
          ...initialState,
          status: "success",
        };
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Logout failed";
      })
      .addCase(reNewAccessToken.pending, (state) => {
        state.renewStatus = "loading";
      })
      .addCase(reNewAccessToken.fulfilled, (state, action) => {
        state.renewStatus = "success";
        state.userInfo.token = action.payload.token;
        state.userInfo.refreshTokenExpired = action.payload.refreshTokenExpired;
        if (action.payload.token) updateTokenToCookie(action.payload.token);
        else {
          removeDataFromCookies("token");
          const version = getDataFromCookies("version");
          if (version && decrypt(version, SHIFT_KEY) !== "private"){
            removeDataFromCookies(encrypt(`2FA${state.userInfo.userId}CHECK`, SHIFT_KEY))
            removeDataFromCookies("version");
          }
        }
      })
      .addCase(reNewAccessToken.rejected, (state, action) => {
        state.renewStatus = "failed";
      })
      .addCase(relogin.pending, (state) => {
        state.reLoginStatus = "loading";
        state.error = null;
      })
      .addCase(relogin.fulfilled, (state, action: PayloadAction<UserInfo>) => {
        state.reLoginStatus = "success";
        state.isValid = true;
        state.userInfo = action.payload;
        updateTokenToCookie(action.payload.token);
      })
      .addCase(relogin.rejected, (state, action) => {
        state.reLoginStatus = "failed";
        state.isValid = false;
        state.error = action.error.message || "Login failed";
      });
  },
});

export const selectIsTokenAvailable = (tokenName: string) => (state: RootState) => {
  const userInfo = state.authSlice.userInfo;
  return !!(userInfo?.securityTokens?.[tokenName]);
};

export const selectIsValueFoundInToken = (tokenName: string, value: string) => (state: RootState) => {
  const userInfo = state.authSlice.userInfo;
  return !!userInfo && userInfo.securityTokens && !!userInfo.securityTokens[tokenName] && userInfo.securityTokens[tokenName].includes(value);
};

export const selectTokenValues = (tokenName: string) => (state: RootState) => {
  const userInfo = state.authSlice.userInfo;
  return userInfo?.securityTokens?.[tokenName] || '';
};

export const { resetReloginFetchStatus, setSelectedModule, setFirstTimeLogin, resetAuthState } = authSlice.actions;

export const useAuthSlice = () => useAppSelector((state) => state.authSlice);
export default authSlice.reducer;
