import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { fetchPracticeLocationsById, savePracticeLocation, inactivePracticeLocations, getLocationsByLocationId, fetchActivePracticeLocationsById } from "../../../service/PracticeService";
import { useAppSelector } from "./../redux-hooks";

export type PracticeLocationInfo = {
    locationId: number;
    locationType: string;
    streetAddress: string;
    unitNumber: string;
    city: string;
    state: string;
    zipCode: string;
    isBillingAddress: string;
    inactiveDate: string;
  }

type PracticeLocationState = {
    practiceLocationsInfo?: PracticeLocationInfo[];
    practiceLocationsDataFetchStatus?: LoadingType
    practiceLocationsSaveStatus?: LoadingType
    practiceLocationsInactiveStatus?: LoadingType
  };

  const initialState: PracticeLocationState = {
    practiceLocationsInfo: undefined,
    practiceLocationsDataFetchStatus: "idle",
    practiceLocationsInactiveStatus: "idle",
  };

  export const fetchPracticeLocationsData = createAsyncThunk("fetchPracticeLocationsData", async (practiceId: string) => {
    const response = await fetchPracticeLocationsById(practiceId);
    return response.data;
  });

  export const fetchActivePracticeLocationsData = createAsyncThunk("fetchActivePracticeLocationsData", async (practiceId: string) => {
    const response = await fetchActivePracticeLocationsById(practiceId);
    return response.data;
  });

  export const savePracticeLocations = createAsyncThunk("savePracticeLocation", async (data: PracticeLocationInfo, { rejectWithValue }) => {
    try{
    const response = await savePracticeLocation(data);
    return response.data;
    }catch(error){
      //@ts-ignore
      return rejectWithValue(error?.response?.data);
    }
  });

  export const inactivePracticeLocation = createAsyncThunk("inactivePracticeLocation", async (data: any, { rejectWithValue }) => {
    try{
    const response = await inactivePracticeLocations(data.locationId, data.inactiveDate);
    return response.data;
    }catch(error){
      //@ts-ignore
      return rejectWithValue(error?.response?.data);
    }
  });

  export const fetchLocationsByLocationId = createAsyncThunk("fetchLocationsByLocationId", async (locationId: number) => {
    const response = await getLocationsByLocationId(locationId);
    const v3Response = response.data;
    return v3Response.data;
  });

  const practiceLocationsSlice = createSlice({
    name: "practiceLocations",
    initialState,
    reducers: {
      updatePracticeLocationsInfoObject: (state: PracticeLocationState, action) => {
        state.practiceLocationsInfo = action.payload;
      },
      resetPracticeLocationsInfo : (state) =>{
        state.practiceLocationsInfo = [];
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchPracticeLocationsData.pending, (state) => {
          state.practiceLocationsDataFetchStatus = "loading";
        })
        .addCase(fetchPracticeLocationsData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceLocationsDataFetchStatus = "success";
          state.practiceLocationsInfo = action.payload.data;
        })
        .addCase(fetchPracticeLocationsData.rejected, (state, action) => {
          state.practiceLocationsDataFetchStatus = 'error';
        })
        .addCase(fetchActivePracticeLocationsData.pending, (state) => {
          state.practiceLocationsDataFetchStatus = "loading";
        })
        .addCase(fetchActivePracticeLocationsData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceLocationsDataFetchStatus = "success";
          state.practiceLocationsInfo = action.payload.data;
        })
        .addCase(fetchActivePracticeLocationsData.rejected, (state, action) => {
          state.practiceLocationsDataFetchStatus = 'error';
        })
        .addCase(savePracticeLocations.pending, (state) => {
          state.practiceLocationsSaveStatus = "loading";
        })
        .addCase(savePracticeLocations.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceLocationsSaveStatus = "success";
          // state.practiceLocationsInfo = action.payload.data;
        })
        .addCase(savePracticeLocations.rejected, (state, action) => {
          state.practiceLocationsSaveStatus = 'error';
        })
        .addCase(inactivePracticeLocation.pending, (state) => {
          state.practiceLocationsInactiveStatus = "loading";
        })
        .addCase(inactivePracticeLocation.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceLocationsInactiveStatus = "success";
          // state.practiceLocationsInfo = action.payload.data;
        })
        .addCase(inactivePracticeLocation.rejected, (state, action) => {
          state.practiceLocationsInactiveStatus = 'error';
        })
        .addCase(fetchLocationsByLocationId.pending, (state) => {
          state.practiceLocationsInactiveStatus = "loading";
        })
        .addCase(fetchLocationsByLocationId.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceLocationsInactiveStatus = "success";
        })
        .addCase(fetchLocationsByLocationId.rejected, (state, action) => {
          state.practiceLocationsInactiveStatus = 'error';
        });
    },
  });

export const { updatePracticeLocationsInfoObject, resetPracticeLocationsInfo } = practiceLocationsSlice.actions;
export const usePracticeLocationsSlice = () => useAppSelector((state) => state.practiceLocationSlice);
export default practiceLocationsSlice.reducer;