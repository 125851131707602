import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { http } from "../../../service/http";
import { CREATE_PATIENT_URL, DELETE_PATIENT_URL, FETCH_PATIENT_LIST_BY_PRACTICE_ID, FETCH_PATIENTLIST_URL, FETCH_PATIENT_URL } from "../../../service/URL";
import { useAppSelector } from "../redux-hooks";
import { LoadingType } from "../../../types/CommonTypes";
import { PracticeInfo } from "../practiceSlice";
import { fetchPatientsByUserRole } from "../../../service/PatientService";

type PatientInfoResponse = {
  code: Number,
  message: string,
  data: PatientInfo[],
};

export type SearchInfo = {
  name: string,
  address: string,
  unitNumber: string,
  city: string,
  state: string,
  primaryInsurance: string,
  secondaryInsurance: string,
  practiceName: string,
};

export type NameInfoState = {
  firstName : string,
  middleName : string,
  lastName : string,
}

export type PatientInfo = {
    patientId: number,
    name: NameInfoState,
    address: string,
    unitNumber: string,
    city: string,
    state: string,
    zip: string,
    primaryInsurance: string,
    secondaryInsurance: boolean,
    practiceName: string,
    firstName: string,
    middleName: string,
    lastName: string,
    nameList: any[],
    birthDate: string,
    gender: string
};

type PatientInfoState = {
    patientInfoObject : PatientInfo,
    patientList : PatientInfo[], 
    practiceList : PracticeInfo[];
    patientsByUserRole: PatientInfo[];
    patientFetchStatus : LoadingType,
    patientListFetchStatus : LoadingType,
    patientListByUserRoleStatus: LoadingType
}

const initialState = {
    patientInfoObject:{
        patientId: 0,
        name: {
          firstName: "",
          middleName: "",
          lastName: "",
        },
        address: "",
        unitNumber: "",
        city: "",
        state: "",
        zip: "",
        primaryInsurance: "",
        secondaryInsurance: false,
        practiceName: "",
        firstName: "",
        middleName: "",
        lastName: "",
        nameList:[],
        birthDate: "",
        gender: "",
    },
    patientList: [],
    practiceList : [],
    patientsByUserRole: [],
    patientListFetchStatus: "idle",
    patientFetchStatus: "idle",
    patientListByUserRoleStatus: 'idle'
} as PatientInfoState;


export const createPatient = createAsyncThunk<
any,
{patientObj: PatientInfo, navigate: any},
{ rejectValue: string }
>("createPatient", async (data, {getState, rejectWithValue, dispatch}) => {
const {patientObj, navigate} = data;
try {
  const response = await http.post(CREATE_PATIENT_URL, patientObj);
  let resp = response.data;
  navigate('/patients')
  return resp;
}
catch {
  return rejectWithValue("Error while creating the patient")
}
});

export const getPatientList = createAsyncThunk("getPatientList", async (undefined,{ rejectWithValue}) => {
    try {
        const response = await http.post(FETCH_PATIENTLIST_URL);
        let resp = response.data;
        return resp;
    }
    catch {
        return rejectWithValue("Error while creating the patient")
    }
});

export const getPatient = createAsyncThunk<any, {patientId: string}>("getPatient", async (data,{ rejectWithValue}) => {
    let queryParam = "";
    queryParam += `?patientId=${data.patientId}&`;
    try {
        const response = await http.get(FETCH_PATIENT_URL + queryParam);
        let resp = response.data;
        return resp;
    }
    catch {
        return rejectWithValue("Error while creating the patient")
    }
});

export const deletePatient = createAsyncThunk<any, {patientId: string}>("deletePatient", async (data,{ rejectWithValue}) => {
    let queryParam = "";
    queryParam += `?patientId=${data.patientId}&`;
    try {
        const response = await http.delete(DELETE_PATIENT_URL + queryParam);
        let resp = response.data;
        return resp;
    }
    catch {
        return rejectWithValue("Error while creating the patient")
    }
});

export const getPatientListByPracticeId = createAsyncThunk<
  any,
  { practiceId: string }
>("getPatientListByPracticeId", async (data, { rejectWithValue }) => {
  let queryParam = "";
  queryParam += `?practiceId=${data.practiceId}&`;
  try {
    const response = await http.get(
      FETCH_PATIENT_LIST_BY_PRACTICE_ID + queryParam
    );
    let resp = response.data;
    return resp;
  } catch {
    return rejectWithValue("Error while fetching patients");
  }
});

export const getPatientListByUserRole = createAsyncThunk("getPatientListByUserRole", async () => {
    const response = await fetchPatientsByUserRole();
    const v3Response = response.data;
    return v3Response.data;
});

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    updatePatientObjectReducer: (state:PatientInfoState, action) => {
      state.patientInfoObject = action.payload
    },
    resetPatientsByUserRole: (state:PatientInfoState) => {
      state.patientsByUserRole = []
      state.patientListByUserRoleStatus = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPatient.pending, (state) => {
        state.patientListFetchStatus = "loading";
      })
      .addCase(createPatient.fulfilled, (state, action: PayloadAction<PatientInfoResponse>) => {
        state.patientListFetchStatus = "success";
        state.patientList = action.payload.data;
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.patientListFetchStatus = 'error';
      })
      .addCase(getPatientList.pending, (state) => {
        state.patientListFetchStatus = "loading";
      })
      .addCase(getPatientList.fulfilled, (state, action: PayloadAction<PatientInfoResponse>) => {
        state.patientListFetchStatus = "success";
        state.patientList = action.payload.data;
      })
      .addCase(getPatientList.rejected, (state, action) => {
        state.patientListFetchStatus = 'error';
      })
      .addCase(getPatient.pending, (state) => {
        state.patientFetchStatus = "loading";
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.patientFetchStatus = "success";
        state.patientInfoObject = action.payload.data;
      })
      .addCase(getPatient.rejected, (state, action) => {
        state.patientFetchStatus = 'error';
      })
      .addCase(deletePatient.pending, (state) => {
        state.patientListFetchStatus = "loading";
      })
      .addCase(deletePatient.fulfilled, (state, action: PayloadAction<PatientInfoResponse>) => {
        state.patientListFetchStatus = "success";
        state.patientList = action.payload.data;
      })
      .addCase(deletePatient.rejected, (state, action) => {
        state.patientListFetchStatus = 'error';
      })
      .addCase(getPatientListByPracticeId.pending, (state) => {
        state.patientListFetchStatus = "loading";
      })
      .addCase(getPatientListByPracticeId.fulfilled, (state, action: PayloadAction<PatientInfoResponse>) => {
        state.patientListFetchStatus = "success";
        state.patientList = action.payload.data;
      })
      .addCase(getPatientListByPracticeId.rejected, (state, action) => {
        state.patientListFetchStatus = 'error';
      })
      .addCase(getPatientListByUserRole.pending, (state) => {
        state.patientListFetchStatus = "loading";
      })
      .addCase(getPatientListByUserRole.fulfilled, (state, action) => {
        state.patientListByUserRoleStatus = "success";
        state.patientsByUserRole = action.payload;
      })
      .addCase(getPatientListByUserRole.rejected, (state, action) => {
        state.patientListFetchStatus = 'error';
      });
  },
});

export const { updatePatientObjectReducer, resetPatientsByUserRole } = patientSlice.actions;

export const usePatientReducer = () => useAppSelector((state) => state.patientSlice);
export default patientSlice.reducer;
