import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useAuthSlice } from "../../redux/slice/authSlice";
import { useNavigate } from "react-router-dom";
import userSlice, { fetchUserProfileById, useUserReducer } from "../../redux/slice/userSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { EditOutlined } from "@mui/icons-material";
import Button from "../common/Button";
import { useEffect } from "react";

const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userInfo } = useAuthSlice();
  const {userObject} =  useUserReducer();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUserProfileById(parseInt(String(userInfo.userId))));
  }, [])

  const handleActionEdit = () => {
    const { userName, userId, firstName, lastName } = userInfo;
    navigate("/user_create", {
      state: { user: { userName, userId, firstName, lastName } },
    });
  };
  
  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: "16px",
        backgroundColor: colors.grey[900],
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", marginBottom: 2 }}
        color={colors.info[300]}
      >
        Personal Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" sx={{ color: "gray" }}>
            Full Name
          </Typography>
          <Typography variant="body1"> {`${userObject?.firstName || ''} ${userObject?.middleName ? ` ${userObject.middleName} ` : ''}${userObject?.lastName || ''}`}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" sx={{ color: "gray" }}>
            Phone
          </Typography>
          <Typography variant="body1">{userObject?.phone}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" sx={{ color: "gray" }}>
            Email
          </Typography>
          <Typography variant="body1">{userObject?.email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container justifyContent="flex-end" spacing={1} sx={{ flexWrap: 'wrap' }}>
            <Grid item xs={12} sm="auto">
              <Button startIcon={<EditOutlined />} variant="contained" fullWidth onClick={() => handleActionEdit()}>
                Edit Profile
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
