import * as React from "react";
import { TextField as MUITextField, TextFieldProps, useTheme } from "@mui/material";

export type InputFieldProps = {
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium";
  label?: any | "";
  variant?: "filled" | "outlined" | "standard" | undefined;
} & TextFieldProps;

const TextField: React.FC<InputFieldProps> = ({
  size,
  color,
  label,
  variant,
  ...props
}) => {
  const theme = useTheme();
  return (
    <MUITextField
      label={label || ""}
      fullWidth
      type="text"
      size={size || "medium"}
      color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
      variant={variant || "outlined"}
      {...props}
    />
  );
};
export default TextField;
