import React, { useState } from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Grid } from "@mui/material";
import TextField from "../../common/TextField";
import Button from "../../common/Button";
import DatePicker from "../../common/DatePicker";
import moment from "moment";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useNavigate } from "react-router-dom";
import { AddCircleOutlineOutlined, Cancel } from "@mui/icons-material";



const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CreateProductAgreement = () => {

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const { value } = event.target as HTMLInputElement;
    setter(value);
  };
  const navigate = useNavigate();

  const [effectiveDate, setEffectiveDate] = useState("");
  const [inactiveDate, setInactiveDate] = useState("");

  const [product, setProduct] = useState("");
  const [practice, setPractice] = useState("");
  const [pricingType, setPricingType] = useState("");
  const [discount, setDiscount] = useState("");
  const [mac, setMac] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");

 

  const handleDateChangeEffective = (date: Date | null) => {
    setEffectiveDate(moment(date).format("MM/DD/YYYY"));
  };
  const handleDateChangeInactive = (date: Date | null) => {
    setInactiveDate(moment(date).format("MM/DD/YYYY"));
  };

  return (
    <>
      <Box m="20px">
        <Header title="Add Product Item" subtitle="Product Management" />
        <Card>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <TextField
                label="Product"
                onChange={(e) => handleChange(e, setProduct)}
                value={product}
                name="product"
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                label="Practice"
                onChange={(e) => handleChange(e, setPractice)}
                value={practice}
                name="practice"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <DatePicker
                label="Effective Date"
                onChange={handleDateChangeEffective}
                openTo="day"
                name="effectiveDate"
              />
            </Grid>
            <Grid item md={4}>
              <DatePicker
                label="Inactive Date"
                onChange={handleDateChangeInactive}
                openTo="day"
                name="inactiveDate"
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                label="Pricing Type"
                onChange={(e) => handleChange(e, setPricingType)}
                value={pricingType}
                name="pricingType"
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                label="Discount"
                onChange={(e) => handleChange(e, setDiscount)}
                value={discount}
                name="discount"
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                label="MAC (Optional)"
                onChange={(e) => handleChange(e, setMac)}
                value={mac}
                name="mac"
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                label="Discount Price"
                onChange={(e) => handleChange(e, setDiscountPrice)}
                value={discountPrice}
                name="discountPrice"
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" spacing={1} mt={1}>
            <Grid item>
              <Button onClick={()=>navigate("/purchase_agreement")} startIcon={<AddCircleOutlineOutlined />}>
                Create Agreement
              </Button>
            </Grid>
            <Grid item>
            <Button color="error" onClick={()=>navigate("/")}  startIcon={<Cancel />}>
              Cancel
            </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default CreateProductAgreement;