import { getAuthSliceData } from "../index";
import { SHIFT_KEY } from "../constants/applicationConstants";
import { decrypt, encrypt } from "./EncryptorUtils";
import { getDataFromCookies, removeDataFromCookies, removeItemFromSessionStorage, setDataToCookies } from "./StorageUtils";
import {  getWindowCountFromCookie, updateWindowCountToCookie } from "./Utility";

type TokenString = {
    token: string,
    count: number,
}

export function setupWindowCloseHandler() {

    incrementInstanceCount();

    window.addEventListener('beforeunload', function (event) {
        const [navigationEntry] = performance.getEntriesByType('navigation');
    
        if (navigationEntry && (navigationEntry as PerformanceNavigationTiming).type === 'reload') {
            return;
        }
        const windowFrames = getWindowCountFromCookie();
        const version = getDataFromCookies("version");
        if (windowFrames === 1 && version && decrypt(version, SHIFT_KEY) !== "private"){
            removeDataFromCookies(encrypt(`2FA${getAuthSliceData().userInfo.userId}CHECK`, SHIFT_KEY))
            removeDataFromCookies("token");
            removeDataFromCookies("version");
        }
        decrementInstanceCount();

        removeItemFromSessionStorage("searchCriterias");
    });

}

export const incrementInstanceCount = () => {;
    let data = getWindowCountFromCookie();
    updateWindowCountToCookie(data + 1);
};

export const decrementInstanceCount = () => {
    let data = getWindowCountFromCookie();
    updateWindowCountToCookie(data>0? data- 1: 0);
};
