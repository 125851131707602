import {
  Box,
  Grid,
  LinearProgress,
  linearProgressClasses,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getInvoiceById,
  InvoiceDetailDTO,
  invoiceInfo,
  SaveInvoiceInfo,
} from "../../../redux/slice/invoice/InvoiceSlice";
import { tokens } from "../../../theme";
import styled from "styled-components";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { SUCCESS_CODE } from "../../../service/ServiceConstants";
import { CancelOutlined } from "@mui/icons-material";
import Button from "../../common/Button";

const BillingDetail = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceDetailDTO>();

  useEffect(() => {
    const invoiceId = location.state?.invoiceId;
    if(invoiceId) {
      setInvoiceDetails(invoiceId);
    };
  }, []);

  const setInvoiceDetails = async (invoiceId: number) => {
    const response = await dispatch(getInvoiceById(invoiceId));
    if(response?.payload?.code === SUCCESS_CODE && response?.payload?.data) {
      setSelectedInvoice(response.payload.data);
    }
  };

  const paymentListData = [
    { date: "04/07/2024", amount: "$600.00" },
    { date: "21/07/2024", amount: "$400.00" },
  ];

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
  }));

  return (
    <>
      <Box m="20px">
        <Header title="Billing Detail" subtitle="Invoice Management" />
        <Box>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 4,
                    p: 2,
                    borderColor: colors.grey[700],
                  }}
                >
                  <Typography variant="h4" fontWeight="bold">
                    Invoice Details
                  </Typography>
                  <Typography variant="body1" mt={1}>
                    Invoice# {selectedInvoice?.mfgInvoiceNumber}
                  </Typography>
                  <Typography variant="body1" mt={1}>
                    Invoice Amount : {selectedInvoice?.invoiceAmount}
                  </Typography>
                  <Typography variant="body1" mt={1}>
                    MFG : <strong>{selectedInvoice?.mfgName}</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 4,
                    p: 2,
                    borderColor: colors.grey[700],
                  }}
                >
                  <Typography variant="h4" fontWeight="bold">
                    Other Details
                  </Typography>

                  <Typography variant="body1" mt={1}>
                    Practice : <strong>{selectedInvoice?.practiceName}</strong>
                  </Typography>

                  <Typography variant="body1" mt={1}>
                    Product: <strong>{selectedInvoice?.productName}</strong>
                  </Typography>

                  <Typography variant="body1" mt={1}>
                    Agency :<strong>{selectedInvoice?.agencyName}</strong>
                  </Typography>

                  <Typography variant="body1" mt={1}>
                    Order # : <strong>{selectedInvoice?.orderNumber}</strong>
                  </Typography>

                  <Typography variant="body1" mt={1}>
                    Agent : <strong>{selectedInvoice?.agentName}</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>

          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Card>
                  <Typography variant="h5" fontWeight="bold">
                    Total Payment
                  </Typography>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">04/07/2024</Typography>
                    <Typography variant="body1">$500</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">05/07/2024</Typography>
                    <Typography variant="body1">$2000</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="h5" fontWeight="bold">
                      Total Amount
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      $2500
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">Remaining Amount</Typography>
                    <Typography variant="body1">$1250</Typography>
                  </Box>
                  <Box mt={1}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={50}
                      color="warning"
                    />
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={3}>
                <Card>
                  <Typography variant="h5" fontWeight="bold">
                    V3 Commission (50%)
                  </Typography>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">04/07/2024</Typography>
                    <Typography variant="body1">$250</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">05/07/2024</Typography>
                    <Typography variant="body1">$1000</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="h5" fontWeight="bold">
                      Total Amount
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      $1250
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">Remaining Amount</Typography>
                    <Typography variant="body1">$100</Typography>
                  </Box>
                  <Box mt={1}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={80}
                      color="info"
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card>
                  <Typography variant="h5" fontWeight="bold">
                  Agency Commission Payouts (1%)
                  </Typography>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">04/07/2024</Typography>
                    <Typography variant="body1">$5</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">05/07/2024</Typography>
                    <Typography variant="body1">$20</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="h5" fontWeight="bold">
                      Total Amount
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      $25
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">Remaining Amount</Typography>
                    <Typography variant="body1">$5</Typography>
                  </Box>
                  <Box mt={1}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={25}
                      color="error"
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card>
                  <Typography variant="h5" fontWeight="bold">
                  OVR Agency Commission Payouts (1%)
                  </Typography>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">04/07/2024</Typography>
                    <Typography variant="body1">$5</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">05/07/2024</Typography>
                    <Typography variant="body1">$20</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="h5" fontWeight="bold">
                      Total Amount
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      $25
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body1">Remaining Amount</Typography>
                    <Typography variant="body1">$5</Typography>
                  </Box>
                  <Box mt={1}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={25}
                      color="success"
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt="40px">
              <Grid>
                <Button
                  color="error"
                  startIcon={<CancelOutlined />}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BillingDetail;
