import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme, Theme } from "@mui/material/styles";

// Define types for the mode
type Mode = "light" | "dark";

// Define type for colors
interface ColorPalette {
  [key: number]: string;
}

interface Colors {
  grey: ColorPalette;
  primary: ColorPalette;
  greenAccent: ColorPalette;
  redAccent: ColorPalette;
  blueAccent: ColorPalette;
}

// color design
export const tokens = (mode: Mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#F9FAFB",
          200: "#F4F6F8",
          300: "#DFE3E8",
          400: "#C4CDD5",
          500: "#919EAB",
          600: "#637381",
          700: "#454F5B",
          800: "#161C24",
          900: "#131920",
        },
        primary: {
          100: "#C8FAD6",
          200: "#5BE49B",
          300: "#00A76F",
          400: "#007867",
          500: "#004B50",
        },
        secondary: {
          100: "#EFD6FF",
          200: "#C684FF  ",
          300: "#8E33FF",
          400: "#5119B7",
          500: "#27097A",
        },
        info: {
          100: "#CAFDF5",
          200: "#61F3F3",
          300: "#00B8D9",
          400: "#006C9C",
          500: "#003768",
        },
        success: {
          100: "#D3FCD2",
          200: "#77ED8B",
          300: "#22C55E",
          400: "#118D57",
          500: "#065E49",
        },
        warning: {
          100: "#FFF5CC",
          200: "#FFD666",
          300: "#FFAB00",
          400: "#B76E00",
          500: "#7A4100",
        },
        error: {
          100: "#FFE9D5",
          200: "#FF5630",
          300: "#FF5630",
          400: "#B71D18",
          500: "#7A0916",
        },
      }
    : {
        grey: {
          100: "#161C24",
          200: "#212B36",
          300: "#454F5B",
          400: "#637381",
          500: "#919EAB",
          600: "#C4CDD5",
          700: "#DFE3E8",
          800: "#F4F6F8",
          900: "#F9FAFB",
        },
        primary: {
          100: "#004B50",
          200: "#007867",
          300: "#00A76F",
          400: "#5BE49B",
          500: "#C8FAD6",
        },
        secondary: {
          100: "#27097A",
          200: "#5119B7",
          300: "#8E33FF",
          400: "#C684FF  ",
          500: "#EFD6FF",
        },
        info: {
          100: "#003768",
          200: "#006C9C",
          300: "#00B8D9",
          400: "#61F3F3",
          500: "#CAFDF5",
        },
        success: {
          100: "#065E49",
          200: "#118D57",
          300: "#22C55E",
          400: "#77ED8B",
          500: "#D3FCD2",
        },
        warning: {
          100: "#7A4100",
          200: "#B76E00",
          300: "#FFAB00",
          400: "#FFD666",
          500: "#FFF5CC",
        },
        error: {
          100: "#7A0916",
          200: "#B71D18",
          300: "#FF5630",
          400: "#FF5630",
          500: "#FFE9D5",
        },
      }),
});

// Define type for theme settings
interface ThemeSettings {
  palette: {
    mode: Mode;
    primary: { main: string };
    secondary: { main: string };
    neutral: { dark: string; main: string; light: string };
    background: { default: string };
  };
  typography: {
    fontFamily: string;
    fontSize: number;
    h1: { fontFamily: string; fontSize: number };
    h2: { fontFamily: string; fontSize: number };
    h3: { fontFamily: string; fontSize: number };
    h4: { fontFamily: string; fontSize: number };
    h5: { fontFamily: string; fontSize: number };
    h6: { fontFamily: string; fontSize: number };
  };
}

//mui theme setting
export const themeSettings = (mode: Mode): ThemeSettings => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.grey[900],
            },
            secondary: {
              main: colors.primary[200],
            },
            neutral: {
              dark: colors.grey[900],
              main: colors.primary[200],
              light: colors.grey[100],
            },
            background: {
              default: colors.grey[900],
            },
          }
        : {
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.success[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// Context for color mode
interface ColorModeContextProps {
  toggleColorMode: () => void;
}

export const ColorModeContext = createContext<ColorModeContextProps>({
  toggleColorMode: () => {},
});

// Hook for managing color mode and theme
export const useMode = (): [Theme, { toggleColorMode: () => void }] => {
  
  let storageMode = localStorage.getItem("theme");
  const [mode, setMode] = useState<Mode>(storageMode ? storageMode === "light" ? "light" : "dark" : "dark");
  
  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
