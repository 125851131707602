import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { useAppSelector } from "../redux-hooks";
import { activateProductItems, createProducts, deleteProductPrice, getProductDocumentsByProductId, getProductItem, getProductItemsByProductId, getProductItemsHistory, getProductsPricingHistory, inactivateProductItems, inactivateProductPrices, saveProductItems, saveProductPriceItems, saveProductsLogo, updateProducts } from "../../../service/ProductService";

export type ProductDocuemtnListinfo = {
    entityId: string,
    type: string,
    entityName: string,
    docName: string
    description: string
}

export type ProductInfo = {
    saveMode: string,
    productId: number,
    productName: string,
    hcpcsCode: string,
    inactiveDate: any,
    manufacturerId: number,
    // agreementTemplate: string,
    productDescription: string,
    composition: string,
    insuranceCoverage: string,
    productLogo: string,
    isAgreementRequired: boolean,
    mues: string,
    documents: DocumentItemInfo[],
    productItems: ProductItemInfo[],
    productPrices: ProductItemPriceInfo[],
}

export type ProductItemInfo = {
    productItemId: number;
    productId: number;
    sku: string;
    size: string;
    units: string;
    inactiveDate: any;
}

export type ProductItemPriceInfo = {
    productPriceId: number;
    productId: number;
    effectiveDate: any;
    inactiveDate: any;
    allowableUnitPrice: number | "";
    macId: number;
    priceType: string;
    listPrice: number | "";
    customPrice?: number | "";
    practiceProducts?: PracticeProductInfo[],
    fakeValidationPurposeAttribute?: string
}

export type PracticeProductInfo = {
    practiceProductId: number;
    productId: number;
    productPriceId: number;
    productName: string;
    practiceName: string;
    providerName: string;
    agreementStatus: string;
    auditTimeStamp: string;
    fileType: string;
    effectiveDate: string;
    inactiveDate: string;
    discountType: string;
    extendedPrice: number;
}

export type DocumentItemInfo = {
    documentId: number;
    documentName: string;
    type: string;
    fileType: string;
    content: string;
}

export type ProductInfoState = {
    productObject: ProductInfo,
    manufacturerList: any,
    productPricingHistory: ProductItemPriceInfo[],
    productItemsHistory: ProductItemInfo[],
    productDocumentInfo: ProductDocuemtnListinfo[],
    createProductStatus: LoadingType,
    getProductStatus: LoadingType,
    saveProductItemStatus: LoadingType,
    saveProductPriceStatus: LoadingType,
    inactivateProductItemStatus: LoadingType,
    activateProductItemStatus: LoadingType,
    inactivateProductPriceStatus: LoadingType,
    deleteProductPriceStatus: LoadingType,
    fetchProductPriceHistoryStatus: LoadingType,
    fetchProductItemHistoryStatus: LoadingType,
    updateProductStatus: LoadingType,
    saveProductLogoStatus: LoadingType,
    fetchProductDocumentsStatus: LoadingType,
}

export const initialValuesOfProductItemPrice = {
    productPriceId: 0,
    effectiveDate: null,
    inactiveDate: null,
    allowableUnitPrice: "",
    macId: 0,
    priceType: "",
    listPrice: "",
    customPrice: "",
    fakeValidationPurposeAttribute: "",
} as ProductItemPriceInfo;

export const initialValuesOfProductItem = {
    productItemId: 0,
    sku: "",
    size: "",
    units: "",
    inactiveDate: null,
} as ProductItemInfo;

export const initialValuesOfDocumentItem = {
    documentId: 0,
    documentName: "",
    type: "",
    fileType: "",
    content: "",
} as DocumentItemInfo;

const initialState = {
    productObject: {
        saveMode: "",
        productId : 0,
        productName: "",
        hcpcsCode: "",
        inactiveDate: "",
        manufacturerId: 0,
        // agreementTemplate: "",
        productDescription: "",
        composition: "",
        insuranceCoverage: "",
        productLogo: "",
        isAgreementRequired: false,
        mues: "",
        documents: [],
        productItems: [],
        productPrices: [],
    },
    manufacturerList: [],
    productPricingHistory: [],
    productItemsHistory: [],
    productDocumentInfo: [],
    createProductStatus: "idle" ,
    getProductStatus: "idle",
    saveProductItemStatus: "idle",
    saveProductPriceStatus: "idle",
    inactivateProductItemStatus: "idle",
    activateProductItemStatus: "idle",
    inactivateProductPriceStatus: "idle",
    deleteProductPriceStatus: "idle",
    fetchProductPriceHistoryStatus: "idle",
    fetchProductItemHistoryStatus: "idle",
    updateProductStatus: "idle",
    saveProductLogoStatus: 'idle',
    fetchProductDocumentsStatus: 'idle',
} as ProductInfoState;

export const createProduct = createAsyncThunk<
    any,
    { product: ProductInfo, navigate: any },
    { rejectValue: string }
>("createProduct", async (data, { rejectWithValue }) => {
    const { product, navigate } = data;
    try {
        const response = await createProducts(product);
        let resp = response.data;
        navigate("/product_list")
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const getProduct = createAsyncThunk<
    any,
    { productId: number },
    { rejectValue: string }
>("getProduct", async (data, { rejectWithValue }) => {
    const { productId } = data;
    try {
        const response = await getProductItem(productId);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const saveProductItem = createAsyncThunk<
    any,
    { productItem: ProductItemInfo },
    { rejectValue: string }
>("saveProductItem", async (data, { rejectWithValue }) => {
    const { productItem } = data;
    try {
        const response = await saveProductItems(productItem);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const saveProductPriceItem = createAsyncThunk<
    any,
    { productPriceItem: ProductItemPriceInfo },
    { rejectValue: string }
>("saveProductPriceItem", async (data, { rejectWithValue }) => {
    const { productPriceItem } = data;
    try {
        const response = await saveProductPriceItems(productPriceItem);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const inactivateProductItem = createAsyncThunk<
    any,
    { productItemId: number, date: string },
    { rejectValue: string }
>("inactivateProductItem", async (data, { rejectWithValue }) => {
    const { productItemId, date } = data;
    try {
        const response = await inactivateProductItems(productItemId, date);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const activateProductItem = createAsyncThunk<
    any,
    { productItemId: number},
    { rejectValue: string }
>("activateProductItem", async (data, { rejectWithValue }) => {
    const { productItemId } = data;
    try {
        const response = await activateProductItems(productItemId);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const inactivateProductPrice = createAsyncThunk<
    any,
    { productPriceId: number, date: string },
    { rejectValue: string }
>("inactivateProductPrice", async (data, { rejectWithValue }) => {
    const { productPriceId, date } = data;
    try {
        const response = await inactivateProductPrices(productPriceId, date);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const deleteProductPriceObj = createAsyncThunk<
    any,
    { productPriceId: number },
    { rejectValue: string }
>("deleteProductPriceObj", async (data, { rejectWithValue }) => {
    const { productPriceId } = data;
    try {
        const response = await deleteProductPrice(productPriceId);
        let resp = response.data;
        return resp;
    } catch (error) {
        console.log("deleteProductPriceObj==slice====eeeeee");
        console.log(error);
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const getProductPricingHistory = createAsyncThunk<
    any,
    { productId: number },
    { rejectValue: string }
>("getProductPricingHistory", async (data, { rejectWithValue }) => {
    const { productId } = data;
    try {
        const response = await getProductsPricingHistory(productId);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const getProductItemHistory = createAsyncThunk<
    any,
    { productId: number },
    { rejectValue: string }
>("getProductItemHistory", async (data, { rejectWithValue }) => {
    const { productId } = data;
    try {
        const response = await getProductItemsHistory(productId);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const saveProductLogo = createAsyncThunk<
    any,
    { logoData: Object },
    { rejectValue: string }
>("saveProductLogo", async (data, { rejectWithValue }) => {
    const { logoData } = data;
    try {
        const response = await saveProductsLogo(logoData);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const updateProductDetails = createAsyncThunk<
    any,
    { product: Object },
    { rejectValue: string }
>("updateProductDetails", async (data, { rejectWithValue }) => {
    const { product } = data;
    try {
        const response = await updateProducts(product);
        let resp = response.data;
        return resp;
    }
    catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const fetchProductDocumentsByProductId = createAsyncThunk("getProductDocumentsList", async (productId: number) => {
    const response = await getProductDocumentsByProductId(productId);
    let resp = response.data;
    return resp;
});

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        updateProductObjectValues: (state: ProductInfoState, action) => {
            state.productObject = action.payload;
        },
        resetFetchStatus: (state) =>{
            state.createProductStatus= "idle";
            state.getProductStatus= "idle";
            state.saveProductItemStatus= "idle";
            state.saveProductPriceStatus= "idle";
            state.inactivateProductItemStatus= "idle";
            state.activateProductItemStatus= "idle";
            state.inactivateProductPriceStatus= "idle";
            state.deleteProductPriceStatus= "idle";
            state.fetchProductPriceHistoryStatus= "idle";
            state.fetchProductItemHistoryStatus= "idle";
            state.updateProductStatus= "idle";
            state.saveProductLogoStatus= 'idle';
        },
        resetCreateProductSlice: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createProduct.pending, (state) => {
                state.createProductStatus = "loading";
            })
            .addCase(createProduct.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.createProductStatus = "success";
            })
            .addCase(createProduct.rejected, (state, action) => {
                state.createProductStatus = 'error';
            })
            .addCase(getProduct.pending, (state) => {
                state.getProductStatus = "loading";
            })
            .addCase(getProduct.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.getProductStatus = "success";
                state.productObject = action.payload?.data;
            })
            .addCase(getProduct.rejected, (state, action) => {
                state.getProductStatus = 'error';
            })
            .addCase(saveProductItem.pending, (state) => {
                state.saveProductItemStatus = "loading";
            })
            .addCase(saveProductItem.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.saveProductItemStatus = "success";
                state.productObject.productItems = action.payload?.data;

            })
            .addCase(saveProductItem.rejected, (state, action) => {
                state.saveProductItemStatus = 'error';
            })
            .addCase(inactivateProductItem.pending, (state) => {
                state.inactivateProductItemStatus = "loading";
            })
            .addCase(inactivateProductItem.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.inactivateProductItemStatus = "success";
                state.productObject.productItems = action.payload?.data;

            })
            .addCase(inactivateProductItem.rejected, (state, action) => {
                state.inactivateProductItemStatus = 'error';
            })
            .addCase(activateProductItem.pending, (state) => {
                state.activateProductItemStatus = "loading";
            })
            .addCase(activateProductItem.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.activateProductItemStatus = "success";
                state.productObject.productItems = action.payload?.data;
            })
            .addCase(activateProductItem.rejected, (state, action) => {
                state.activateProductItemStatus = 'error';
            })
            .addCase(saveProductPriceItem.pending, (state) => {
                state.saveProductPriceStatus = "loading";
            })
            .addCase(saveProductPriceItem.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.saveProductPriceStatus = "success";
                state.productObject.productPrices = action.payload?.data
            })
            .addCase(saveProductPriceItem.rejected, (state, action) => {
                state.saveProductPriceStatus = 'error';
            })
            .addCase(inactivateProductPrice.pending, (state) => {
                state.inactivateProductPriceStatus = "loading";
            })
            .addCase(inactivateProductPrice.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.inactivateProductPriceStatus = "success";
                state.productObject.productPrices = action.payload?.data
            })
            .addCase(inactivateProductPrice.rejected, (state, action) => {
                state.inactivateProductPriceStatus = 'error';
            })
            .addCase(deleteProductPriceObj.pending, (state) => {
                state.deleteProductPriceStatus = "loading";
            })
            .addCase(deleteProductPriceObj.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.deleteProductPriceStatus = "success";
                state.productObject.productPrices = action.payload?.data
            })
            .addCase(deleteProductPriceObj.rejected, (state, action) => {
                state.deleteProductPriceStatus = 'error';
            })
            .addCase(getProductPricingHistory.pending, (state) => {
                state.fetchProductPriceHistoryStatus = "loading";
            })
            .addCase(getProductPricingHistory.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.fetchProductPriceHistoryStatus = "success";
                state.productPricingHistory = action.payload?.data;
            })
            .addCase(getProductPricingHistory.rejected, (state, action) => {
                state.fetchProductPriceHistoryStatus = 'error';
            })
            .addCase(getProductItemHistory.pending, (state) => {
                state.fetchProductItemHistoryStatus = "loading";
            })
            .addCase(getProductItemHistory.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.fetchProductItemHistoryStatus = "success";
                state.productItemsHistory = action.payload?.data;
            })
            .addCase(getProductItemHistory.rejected, (state, action) => {
                state.fetchProductItemHistoryStatus = 'error';
            })
            .addCase(updateProductDetails.pending, (state) => {
                state.updateProductStatus = "loading";
            })
            .addCase(updateProductDetails.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.updateProductStatus = "success";
            })
            .addCase(updateProductDetails.rejected, (state, action) => {
                state.updateProductStatus = 'error';
            })
            .addCase(saveProductLogo.pending, (state) => {
                state.saveProductLogoStatus = "loading";
            })
            .addCase(saveProductLogo.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
                state.saveProductLogoStatus = "success";
            })
            .addCase(saveProductLogo.rejected, (state, action) => {
                state.saveProductLogoStatus = 'error';
            })
            .addCase(fetchProductDocumentsByProductId.pending, (state) => {
                state.fetchProductDocumentsStatus = "loading";
            })
            .addCase(fetchProductDocumentsByProductId.fulfilled, (state, action) => {
                state.fetchProductDocumentsStatus = "success";
                state.productDocumentInfo = action.payload.data;
            })
            .addCase(fetchProductDocumentsByProductId.rejected, (state) => {
                state.fetchProductDocumentsStatus = "error";
            });
    },
});

export const { updateProductObjectValues, resetCreateProductSlice, resetFetchStatus } = productSlice.actions;

export const useProductReducer = () => useAppSelector((state) => state.productSlice);
export default productSlice.reducer;