import { WoundCodes } from "../redux/slice/ivr/woundCodeSlice";
import { http } from "./http";
import { GET_ALL_WOUND_CODE } from "./URL";

export const getAllWoundCodes = async () => {
    try{
        const response = await http.get(GET_ALL_WOUND_CODE);
        return response;
    } catch(error) {
        throw error;
    }
    
};
