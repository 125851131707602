import { Control } from "react-hook-form";
import { TWO_FACTOR_VERIFICATION_CODE_LENGTH } from "../../constants/applicationConstants";
import CustomTextField from "../common/CustomTextField";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  ClipboardEvent as ReactClipboardEvent,
} from "react";
import { useTwoFactorReducer } from "../../redux/slice/2FA/twoFactorSlice";

type OTPVerificationProps = {
  control: Control<any, any>;
  sendOtp: () => void;
  submitCode: (value: string) => void;
  disable?: boolean;
};

const OTPVerification: React.FC<OTPVerificationProps> = ({
  control,
  sendOtp,
  submitCode,
  disable,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const inputFieldRefs = useRef<HTMLInputElement[]>([]);
  const [countdown, setCountdown] = useState(120);

  const [value, setValue] = useState(
    new Array(TWO_FACTOR_VERIFICATION_CODE_LENGTH).fill("")
  );

  const { generate2faStatus } = useTwoFactorReducer();

  let interval: any;

  useEffect(() => {
    if (generate2faStatus === "success") {
      interval = setInterval(() => {
        setCountdown((prevCounter) => {
          if (prevCounter <= 0) {
            clearInterval(interval);
            return prevCounter;
          }
          return prevCounter - 1;
        });
      }, 1000);
    } else if (generate2faStatus === "error") {
      clearInterval(interval);
      setCountdown(0);
    }
  }, [generate2faStatus]);

  useEffect(() => {
    submitCode(value.join(""));
  }, [value]);

  const handleValueChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number
    ) => {
      const inputValue = event.target.value;
      if (inputValue === value[index]) return;
      setValue((prev: string[]) => {
        const newValue = [...prev];
        newValue[index] = inputValue;
        return newValue;
      });
      if (inputValue && index < TWO_FACTOR_VERIFICATION_CODE_LENGTH - 1) {
        inputFieldRefs.current[index + 1]?.focus();
      } else if (!inputValue && index > 0) {
        inputFieldRefs.current[index - 1]?.focus();
      }
    },
    [value]
  );

  const handlePaste = (event: ReactClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData("text");
    if (pasteData.length === TWO_FACTOR_VERIFICATION_CODE_LENGTH) {
      pasteData.split("").forEach((char: string, index: number) => {
        if (inputFieldRefs.current[index]) {
          handleValueChange(
            { target: { value: char } } as React.ChangeEvent<HTMLInputElement>,
            index
          );
        }
      });
      event.preventDefault();
    }
  };
  return (
    <>
      <Box mb={2}>
        <Box display="flex" mb={2}>
          {Array(TWO_FACTOR_VERIFICATION_CODE_LENGTH)
            .fill(null)
            .map((_, index) => (
              <CustomTextField
                key={index}
                variant="outlined"
                ref={(el) =>
                  (inputFieldRefs.current[index] = el as HTMLInputElement)
                }
                control={control}
                controllerName={`${index}`}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center" },
                }}
                style={{ width: 50, marginRight: 8, minWidth :40 }}
                onChange={(e) => handleValueChange(e, index)}
                onPaste={handlePaste}
                value={value[index]}
                disabled={disable}
              />
            ))}
        </Box>
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          {!disable && (
            <Typography variant="body2" align="center" sx={{color : colors.grey[500]}}>
              Didn't get the OTP?
              {countdown < 1 ? (
                <Typography
                  variant="h5"
                  sx={{ color: colors.error[300] }}
                >
                  <b  onClick={() => {
                    sendOtp();
                    setCountdown(120);
                  }} style={{cursor: "pointer"}}>Resend</b>
                </Typography>
              ) : (
                <Typography align="center" variant="h5" sx={{color : colors.grey[100]}}>
                  Resend OTP again after <span style={{color : colors.info[300]}}><b>{countdown}s</b></span>
                </Typography>
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default OTPVerification;
