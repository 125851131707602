import React from "react";
import {
  Button as MaterialButton,
  ButtonProps,
  useTheme,
  Typography,
  Tooltip,
} from "@mui/material";
import { tokens } from "../../theme";

export type MaterialButtonProps = {
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium" | "large";
  variant?: "contained" | "outlined" | "text";
  tooltipPlacement?: "right" | "left" | "top" | "bottom" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined;
  tooltipMessage?: string;
} & ButtonProps;

const Button: React.FC<MaterialButtonProps> = ({ size, color,variant, tooltipPlacement, tooltipMessage, ...props }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Tooltip title={tooltipMessage} placement={tooltipPlacement || 'bottom'} arrow>
    <MaterialButton
      size={size || "large"}
      color={color || "success"}
      variant={variant || "contained"}
      {...props}
    >
      <Typography color={variant === "contained" ? colors.grey[900] : ""} fontWeight={600}>
        {props.children}
      </Typography>
    </MaterialButton>
    </Tooltip>
  );
};

export default Button;
