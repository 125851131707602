import React from "react";
import { Snackbar, SnackbarProps, Divider, Typography, useTheme } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert } from "@mui/material";
import { useCommonReducer, setErrorDetailExpand, hideMainErrorPopup, MainErrorInfo } from "../../redux/slice/commonSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { tokens } from "../../theme";

interface CustomSnackbarOrigin {
    vertical: "top" | "bottom";
    horizontal: "left" | "right" | "center";
}

interface CustomAlertProps {
    open: boolean;
    onClose?: () => void;
    severity: "error" | "success" | "warning" | "info";
    mainErrorObj?: MainErrorInfo | null;
    messageStream?: string;
    messageList?: string[];
    content?: React.ReactNode;
    variant?: "filled" | "outlined";
    snackbarProps?: SnackbarProps;
    alertTitle?: string;
    anchorOrigin?: CustomSnackbarOrigin;
}

const CustomErrorAlert: React.FC<CustomAlertProps> = ({
    open,
    onClose,
    severity,
    mainErrorObj,
    messageStream,
    messageList,
    content,
    snackbarProps,
    variant,
    alertTitle,
    anchorOrigin,
}) => {

    const dispatch = useAppDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { errorDetailExpand } = useCommonReducer();

    let alertTitleValue: string = "Error";

    if (mainErrorObj) {
        alertTitle = mainErrorObj.title;
        messageStream = mainErrorObj.errorMessageStream;
        messageList = mainErrorObj.errorMessageList;
    }

    if (alertTitle) {
        alertTitleValue = alertTitle;
    }

    const replaceNbsp = (str: string) => str.replace(/&nbsp;/g, '');

    if (content) {
        content = typeof content === "string" ? replaceNbsp(content) : content;
    } else if (messageStream) {
        content = <div>
            {replaceNbsp(messageStream).split('\n').map((line, index) => (
                <Typography key={index} variant="body1" paragraph>
                    {line}
                </Typography>
            ))}
        </div>;
    } else if (messageList) {
        content = <div>
            {messageList.map((line, index) => (
                <Typography key={index} variant="body1" paragraph>
                    {replaceNbsp(line)}
                </Typography>
            ))}
        </div>;
    }

    const handleExpanding = () => {
        dispatch(setErrorDetailExpand(!errorDetailExpand));
    }

    const onCloseErrorPopup = () => {
        dispatch(hideMainErrorPopup());
    }

    return (
        <Snackbar
            open={open}
            anchorOrigin={anchorOrigin || { vertical: "top", horizontal: "center" }}
            {...snackbarProps}
        >
            <Alert
                onClose={onClose ? onClose : onCloseErrorPopup}
                icon={false}
                severity={severity}
                sx={{ width: "100%" }}
                variant={variant || "filled"}
            >
                <Accordion expanded={errorDetailExpand}
                    sx={{
                        backgroundColor: colors.error[400],
                        padding: 1,
                        margin: 0,
                        boxShadow: "none",
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={handleExpanding} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                            backgroundColor: 'transparent',
                            width: '100%',
                           
                        }}
                    >
                        <Typography variant="h6" fontWeight="bold">{alertTitleValue}</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                        {content}
                    </AccordionDetails>
                </Accordion>
            </Alert>
        </Snackbar>
    );
};

export default CustomErrorAlert;
