import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { fetchPracticeExecutiveById, updatePracticeExecutive } from "../../../service/PracticeService";
import { useAppSelector } from "./../redux-hooks";
import { SUCCESS_CODE } from "../../../service/ServiceConstants";

export type PracticeExecutiveInfo = {
  practiceExecutiveId: number;
  agency: {
    agencyId: number;
    agencyName: string;
  };
  user: {
    userId: number;
    firstName: string;
    lastName: string;
    middleName: string;
  };
};

type PracticeExecutiveState = {
  practiceExecutiveInfo?: PracticeExecutiveInfo | null;
  status?: LoadingType;
  error?: string;
};

const initialState: PracticeExecutiveState = {
  practiceExecutiveInfo: undefined,
  status: "idle",
};

export const fetchPracticeExecutiveData = createAsyncThunk(
  "fetchPracticeExecutiveData",
  async (practiceId: string) => {
    const response = await fetchPracticeExecutiveById(practiceId);
    return response.data;
  }
);

export const updatePracticeExecutiveById = createAsyncThunk(
  "updatePracticeExecutive",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updatePracticeExecutive(data.practiceExecutiveId, data.agencyId, data.userId);

      if (response.data.code !== SUCCESS_CODE) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.data);
    }
  }
);

const practiceExecutiveSlice = createSlice({
  name: "practiceExecutive",
  initialState,
  reducers: {
    updatePracticeExecutiveInfoObject: (
      state: PracticeExecutiveState,
      action
    ) => {
      state.practiceExecutiveInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPracticeExecutiveData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchPracticeExecutiveData.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.status = "success";
          state.practiceExecutiveInfo = action.payload.data;
        }
      )
      .addCase(fetchPracticeExecutiveData.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(updatePracticeExecutiveById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        updatePracticeExecutiveById.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          if (action.payload.code === SUCCESS_CODE) {
            state.status = "success";
            state.practiceExecutiveInfo = action.payload.data;
          } else {
            state.status = "error";
            state.error = action.payload.message;
          }
        }
      )
      .addCase(updatePracticeExecutiveById.rejected, (state, action) => {
        state.status = "error";
        if (action.error.message) {
          state.error = action.error.message;
        } else {
          state.error = "An unknown error occurred.";
        }
      });
  },
});

export const { updatePracticeExecutiveInfoObject } =
  practiceExecutiveSlice.actions;
export const usePracticeExecutiveSlice = () =>
  useAppSelector((state) => state.practiceExecutiveSlice);
export default practiceExecutiveSlice.reducer;
