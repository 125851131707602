import React, { ChangeEvent } from 'react';
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox, useTheme } from '@mui/material';

interface CheckboxProps {
    color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
    id? : string;
    name?: string;
    label?: any;
    value?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {

    const { color, id, name, label, value, onChange } = props;
    const theme = useTheme();
    return (
        <FormControl>
            <FormControlLabel
                control={<MuiCheckbox
                    id={id}
                    name={name}
                    color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
                    checked={value}
                    size="medium" 
                    onChange={onChange}
                />}
                label={label}
            />
        </FormControl>
    );
}

export default Checkbox;
