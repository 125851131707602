import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { getAllWoundCodes } from "../../../service/WoundCodeService";
import { useAppSelector } from "../redux-hooks";

export type WoundCodes = {
  woundcodeId?: number;
  woundcodeType?: string;
  woundKey?: string;
  woundDisplayValue?: string;
  woundLocation?: string;
  inactiveDate?: string;
};

type WoundCodesState = {
  allWoundCodesValuesMap?: Map<string, WoundCodes[]>;
  woundCodeFetchStatus: LoadingType;
};

const initialState: WoundCodesState = {
  allWoundCodesValuesMap: undefined,
  woundCodeFetchStatus: "idle",
};

export const getWoundCodeValuesByWoundCodeType = (allWoundCodesValuesMap: Map<string, WoundCodes[]>, woundcodeType: string) => {
  let woundCodeType: WoundCodes[] = [];
  if (allWoundCodesValuesMap) {
    // @ts-ignore
    woundCodeType = allWoundCodesValuesMap[woundcodeType];
  }
  return woundCodeType;
};

export const fetchAllWoundCodes = createAsyncThunk("fetchAllWoundCodes",async () => {
  const response = await getAllWoundCodes();
    const v3Response = response?.data;
    return v3Response;
  }
);

const woundCodesSlice = createSlice({
  name: "woundCodes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllWoundCodes.pending, (state) => {
        state.woundCodeFetchStatus = "loading";
      })
      .addCase(
        fetchAllWoundCodes.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.woundCodeFetchStatus = "success";
          state.allWoundCodesValuesMap = action.payload.data;
        }
      )
      .addCase(fetchAllWoundCodes.rejected, (state, action) => {
        state.woundCodeFetchStatus = "error";
      });
  },
});

export const useWoundCodesSlice = () => useAppSelector((state) => state.woundCodesSlice);
export default woundCodesSlice.reducer;
