import { Box, Divider, Grid } from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getProductListWithLogo, useProductListReducer } from "../../../redux/slice/product/productListSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import ProductCard from "./ProductCard";
import { selectIsTokenAvailable } from "../../../redux/slice/authSlice";
import { useSelector } from "react-redux";
import { RootState } from '../../../redux/store';

const ProductList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { productList } = useProductListReducer();

  useEffect(() => {
    dispatch(getProductListWithLogo());
  }, [dispatch]);

  const onEditClick = (productId: number) => {
    navigate("/product?id=" + productId);
  };

  const canCreateProduct = useSelector((state: RootState) => selectIsTokenAvailable("canCreateProduct")(state));

  return (
    <Box m="20px">
      <Header title="Product List" subtitle="Products Management" />
      <Grid container justifyContent="flex-end">
        {canCreateProduct && (
          <Button sx={{ marginBottom: 2 }} color="info" startIcon={<AddCircleOutlineOutlined />} onClick={() => navigate("/product")} >
            Add Products
          </Button>
        )}
      </Grid>

      <Divider orientation="horizontal" variant="middle" flexItem sx={{ padding: 0, margin: 0 }} />

      <Box sx={{ flexGrow: 1, mt: 2, mb:5 }}>
        <Grid container spacing={3}>
          {productList.map((product) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={product.productId}>
              <ProductCard title={product.productName} subTitle={product.hcpcsCode}
                logo={
                  product.productLogo && product.productLogo.content
                    ? "data:image/png;base64," + product.productLogo.content : null
                }
                onClick={() => onEditClick(product.productId)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductList;
