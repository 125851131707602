import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import ProductCommissionPopup from "./ProductCommissionPopup";
import Card from "../../common/Card";
import Header from "../../common/Header";
import CustomTextField from "../../common/CustomTextField";
import { CreateSearchAgency, ProductCommissionAgency, saveAgency, Option, updateAgencyInfoObject, editAgency, addAgencyProductCommission, fetchProductCommissionsByAgencyId, useAgencyReducer, editAgencyProductCommission, fetchAgencyById, clearAgencyObject, clearAgencyProductCommissionList, fetchAssociateAgencyList, AgencyOption, fetchIsV3AgencyExist } from "../../../redux/slice/agency/AgencySlice";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { AddCircleOutlineOutlined, ModeOutlined } from "@mui/icons-material";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { MainErrorInfo, SingleAlertInfo, setMainErrorObj, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { ProductInfo, fetchProductsByUserRoleData, useProductListReducer } from "../../../redux/slice/product/productListSlice";
import { sortNames } from "../../../utils/Utility";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import moment from "moment";
import { OptionPopupObjectProps } from "../../common/OptionsPopup";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import CustomIconButton from "../../common/CustomIconButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectIsValueFoundInToken } from "../../../redux/slice/authSlice";
import { COMMISSION_STATUS } from "../../../constants/applicationConstants";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface Row {
  productCommissionId: number | undefined;
  productId: number | undefined;
  productName: string | undefined;
  associateAgencyId: number | undefined;
  commissionRate: number | string | undefined,
  commissionPayTerms: string | undefined;
  associateAgencyName: string | undefined;
  associateAgencyCommission: string | number | undefined;
  effectiveDate: string | undefined;
  inactiveDate: string | undefined;
  rowIndex: number | undefined;
}

const AgencyForm: React.FC = () => {
  const location = useLocation();
  const agencyData = location.state?.agency;
  const isEditing = Boolean(agencyData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isCommTypeOverride, setIsCommTypeOverride] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editCommission, setEditCommission] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError
  } = useForm<CreateSearchAgency>();
  const [totalPages, setTotalPages] = useState(0);

  const selectedCommissionType = watch("commissionType");

  const { agencyProductCommissionList, agency, associateAgencyList, isV3AgencyExists } = useAgencyReducer();
  const [commissionList, setCommissionList] = useState<ProductCommissionAgency[]>([]);

  const defaultValues = useCallback(() => {
    if (agency) {
      reset(agency);
    } else {
      reset();
    }
  }, [agency, reset]);

  //Mount
  useEffect(() => {
    dispatch(fetchIsV3AgencyExist());
    dispatch(fetchProductsByUserRoleData(false));
    dispatch(fetchAssociateAgencyList());
  }, [dispatch]);

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  const canAccessCommissionActionsAdd = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessCommissionActions", COMMISSION_STATUS.CREATE)(state));
  const canAccessCommissionActionsEdit = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessCommissionActions", COMMISSION_STATUS.EDIT)(state));

  const columns: Column[] = [
    { id: "productName", label: "Product", minWidth: 100 },
    { id: "effectiveDate", label: "Effective Date", minWidth: 100 },
    { id: "inactiveDate", label: "Inactive Date", minWidth: 100 },
    { id: "commissionRate", label: "Commission", minWidth: 100, align: "center" },
    ...(canAccessCommissionActionsEdit ? [{
      id: "action",
      label: "Action",
      minWidth: 100,
      align: "center" as const,
    }] : []),
  ];

  const { productsByUserRole } = useProductListReducer();

    const commTypeOptions = useMemo(() => {
    let options: Option[] = [];
    if (!isV3AgencyExists || selectedCommissionType==="V3") {
      options.push({ label: "V3", value: "V3" });
    };
    options.push({ label: "Agency", value: "Agency"});
    options.push({ label: "Override", value: "Override"});
    return options;
  }, [isV3AgencyExists, selectedCommissionType]);

  useEffect(() => {
    if (agencyData?.agencyId) {
      dispatch(fetchAgencyById(agencyData.agencyId));
      const param = {
        agencyId: agencyData.agencyId,
        itemsPerPage: 10,
        currentPage: currentPage
      }
      dispatch(fetchProductCommissionsByAgencyId(param));
    }
  }, [dispatch, agencyData]);

  useEffect(() => {
    if (agencyProductCommissionList && productsByUserRole) {
      setCommissionList(agencyProductCommissionList?.content);
    }
  }, [agencyProductCommissionList, productsByUserRole]);

  useEffect(() => {
    return () => {
      dispatch(clearAgencyObject());
      dispatch(clearAgencyProductCommissionList());
    };
  }, [dispatch]);

  useEffect(()=>{
    if(selectedCommissionType === "Override"){
      setIsCommTypeOverride(true);
    }else{
      setIsCommTypeOverride(false);
    }
  },[selectedCommissionType]);

  const [selectedCommission, setSelectedCommission] = useState<Row>({
    productCommissionId: 0,
    productId: 0,  
    productName: undefined,
    associateAgencyId: undefined,
    commissionRate: undefined,
    commissionPayTerms: undefined,
    associateAgencyName: undefined,
    associateAgencyCommission: undefined,
    effectiveDate: undefined,
    inactiveDate: undefined,
    rowIndex: 1
  });
  const [optionPopupShow, setOptionPopupShow] = useState<OptionPopupObjectProps>({ message: "", show: false });

  const handleClickOpen = () => {
    const initialCommission: Row = {
      productCommissionId: 0,
      productId: 0,
      productName: undefined,
      associateAgencyId: undefined,
      commissionRate: undefined,
      commissionPayTerms: undefined,
      associateAgencyName: undefined,
      associateAgencyCommission: undefined,
      effectiveDate: undefined,
      inactiveDate: undefined,
      rowIndex: 1
    }
    setSelectedCommission(initialCommission);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditCommission(false);
  };

  const productOptions = useMemo(() => {
    let options: Option[] = [];
    if (productsByUserRole) {
      const sortedProductList: ProductInfo[] = sortNames(productsByUserRole, 'productName');
      sortedProductList?.map((option) => {
        options.push({ label: option.productName, value: option.productId })
      });
    };
    return options;
  }, [productsByUserRole]);

  const associateAgencyOptions = useMemo(() => {
    let options: AgencyOption[] = [];
    if (associateAgencyList) {
      const sortedAgencyList: CreateSearchAgency[] = sortNames(associateAgencyList, 'agencyName');
      sortedAgencyList?.forEach((option) => {
        if (option.agencyName !== undefined && option.agencyId !== undefined) {
          if (isEditing && isCommTypeOverride && option.agencyId === agencyData.agencyId) {
            return; // This acts like 'continue' by skipping to the next iteration
          }
          options.push({ label: option.agencyName, value: option.agencyId });
        }
      });
    };
    return options;
  }, [associateAgencyList, isCommTypeOverride]);

  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } = useCodetablesSlice();
  const [stateList, setStateList] = React.useState<CodeTable[]>([]); 

  const stateListOptions = useMemo(() => {
    return stateList.map(item => ({
      label: item.displayValue,
      value: item.keyName
    }));
  }, [stateList]);

  useEffect(() => {
    if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
      const sortedStateList = [
        ...getCodeTableValuesByTableName(allCodeTablesValuesMap, "StateList"),
      ].sort((a, b) => a.displayValue.localeCompare(b.displayValue));
      setStateList(sortedStateList);
    }
  }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  const isDateEarlier = (dateStr: string, targetDateStr: string) : boolean => {
    const date = new Date(dateStr);
    const targetDate = new Date(targetDateStr);
    return date <= targetDate;
  }

  const isDateBetween = (startDateStr: string, endDateStr: string, targetDateStr: string): boolean => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const targetDate = new Date(targetDateStr);
  
    return targetDate >= startDate && targetDate <= endDate;
  };

  const isSameRecord = (selectedCommision: Row, commission: ProductCommissionAgency): boolean => {
    let effectiveDate = commission.effectiveDate? moment(commission.effectiveDate?.toString())?.format("MM/DD/YYYY") : "";
    let inactiveDate = commission.inactiveDate? moment(commission.inactiveDate?.toString())?.format("MM/DD/YYYY") : "";
    if (selectedCommision.productId == commission.productId && selectedCommision.commissionRate == commission.commissionRate && selectedCommision.effectiveDate == effectiveDate
      && selectedCommision.inactiveDate == inactiveDate && selectedCommision.associateAgencyId == commission.associateAgencyId && selectedCommision.productCommissionId == commission.productCommissionId) {
      return true;
    }
    return false;
  }

  const submitCommission = (commission: ProductCommissionAgency) => {

    let updatedCommissionList:ProductCommissionAgency[] = [];
    
    let notValidCommission = false;
    let errorMsg = "";

    let newEffectiveDate = commission.effectiveDate? moment(commission.effectiveDate?.toString())?.format("MM/DD/YYYY") : "";
    let newInativeDate = commission.inactiveDate? moment(commission.inactiveDate?.toString())?.format("MM/DD/YYYY") : "";

    if(newInativeDate === "Invalid date"){
      notValidCommission = true;
      errorMsg = "Inactive Date is Invalid";
    }

    if(newEffectiveDate === "Invalid date"){
      notValidCommission = true;
      errorMsg = "Effective Date is Invalid";
    }

    if(newInativeDate !== "" && newInativeDate !== "Invalid date" && newEffectiveDate !== "Invalid date" && new Date(newEffectiveDate) > new Date(newInativeDate) ){
      notValidCommission = true;
      errorMsg = "Effective Date should be less than Inactive Date";
    }

    if (!notValidCommission){
      for (let i = 0; i < commissionList.length; i++) {
        const existingCommission: ProductCommissionAgency = commissionList[i];
  
        if(existingCommission.productId !== commission.productId) {
          continue;
        }

        if(!!selectedCommission.productId && isSameRecord(selectedCommission, existingCommission)) {
          continue;
        }

        if(commission.associateAgencyId && existingCommission.productId == commission.productId && existingCommission.associateAgencyId !== commission.associateAgencyId) {
          continue;
        }
  
        let existingEffectiveDate = existingCommission.effectiveDate? moment(existingCommission.effectiveDate?.toString())?.format("MM/DD/YYYY") : "";
        let existingInactiveDate = existingCommission.inactiveDate? moment(existingCommission.inactiveDate?.toString())?.format("MM/DD/YYYY") : "";

        if (existingInactiveDate === "" && newInativeDate === "" || existingInactiveDate === "" && newInativeDate !== "" && isDateEarlier(existingEffectiveDate, newInativeDate)
          || existingInactiveDate !== "" && newInativeDate === "" && isDateEarlier(newEffectiveDate, existingInactiveDate)
          || isDateBetween(existingEffectiveDate, existingInactiveDate, newEffectiveDate) || isDateBetween(existingEffectiveDate, existingInactiveDate, newInativeDate)
          || isDateBetween(newEffectiveDate, newInativeDate, existingEffectiveDate)) {
          notValidCommission = true;
          errorMsg = "Valid existing commission is exists in the system";
          break;
        }
  
      }
    }
    
    if(notValidCommission){
      let errorObj: MainErrorInfo = {
        title: "",
      };

      errorObj.title = editCommission ? "Error updating agency product commission" : "Error creating agency product commission";
      errorObj.errorMessageStream = errorMsg;
      setOpen(false);
      dispatch(setMainErrorObj(errorObj));
      return;
    }

    if(!isEditing && !editCommission){
      updatedCommissionList = [...commissionList, commission];
      setCommissionList(updatedCommissionList);
    };

    if(!isEditing && editCommission){
      let changedCommission: ProductCommissionAgency = commissionList[commission.rowIndex];
      changedCommission.productId = commission.productId;
      changedCommission.commissionRate = commission.commissionRate;
      changedCommission.commissionPayTerms = commission.commissionPayTerms;
      changedCommission.associateAgencyId = commission.associateAgencyId;
      changedCommission.effectiveDate = commission.effectiveDate;
      changedCommission.inactiveDate = commission.inactiveDate;
      setCommissionList([...commissionList]);
    };

    if(isEditing){ 
      commission.agencyId = agencyData.agencyId;
      commission.inactiveDate = commission.inactiveDate? moment(commission.inactiveDate?.toString())?.format("MM/DD/YYYY") : "";
      commission.effectiveDate = commission.effectiveDate? moment(commission.effectiveDate?.toString())?.format("MM/DD/YYYY") : "";
      commission.commissionRate = commission.commissionRate ? Number(commission.commissionRate) : 0;
      if(!isCommTypeOverride){
        delete commission.associateAgencyId;
      }
      handleSaveAgencyProductCommission(commission);
    } 
    setOpen(false);
    setEditCommission(false);
  };

  const handleSaveAgencyProductCommission = async (data: ProductCommissionAgency) => {
    let errorObj: MainErrorInfo = {
      title: "",
    };
    
    try {
      let response;
      if (!editCommission) {
        response = await dispatch(addAgencyProductCommission(data));
      } else {
        response = await dispatch(editAgencyProductCommission(data));
      }
  
      if (response.payload && response.payload.message === 'SUCCESS') {
  
        let alertMessage = editCommission ? "Agency Product Commission Updated Successfully!" : "Agency Product Commission Created Successfully!";
  
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "success",
        };
        if (!editCommission) {
          if (isEditing) {
            const param = {
              agencyId: agencyData.agencyId,
              itemsPerPage: 10,
              currentPage: currentPage
            }
            dispatch(fetchProductCommissionsByAgencyId(param));
          } else {
            setCommissionList([...commissionList, data]);
          }

        } else {

          const updatedCommissionList: ProductCommissionAgency[] = [...commissionList];
          let changedCommission: ProductCommissionAgency = {
            ...updatedCommissionList[data.rowIndex],
            productId: data.productId,
            // productName: data.productName,
            commissionRate: data.commissionRate,
            commissionPayTerms: data.commissionPayTerms,
            associateAgencyId: data.associateAgencyId,
            effectiveDate: data.effectiveDate,
            inactiveDate: data.inactiveDate,
          }

          updatedCommissionList[data.rowIndex] = changedCommission;
          setCommissionList(updatedCommissionList);
        }

        dispatch(setSingleAlertObj(alertObj));
      } else {
        let errorSentence = '';
        if (response.payload.errors.length <= 0) {
          errorSentence = response.payload.message || "An unknown error occurred.";
        } else {
          response.payload.errors.forEach((error: any, index: any) => {
            if (error.field) {
              errorSentence += `${error.field}: ${error.errorMessage}\n`;
            } else {
              errorSentence += `${error.reason}\n`;
            }
          });
        }
  
        errorObj.title = editCommission ?  "Agency Product Commission Update Failed!" : "Agency Product Commission Creation Failed!";
        errorObj.errorMessageStream = errorSentence;
        dispatch(setMainErrorObj(errorObj));
      }
    } catch (error) {
      console.log("Error creating agency product commission :", error);
      errorObj.title = editCommission ? "Error updating agency product commission" : "Error creating agency product commission";
      errorObj.errorMessageStream = "" + error;
      dispatch(setMainErrorObj(errorObj));
    }
  };

  const findLabelByValue = (productId: number | undefined) => {
    const option = productOptions?.find(option => Number(option.value) == productId)
    return option ? option.label : "";
  }


  const findProductCommission = (commission: ProductCommissionAgency) => {
    if(associateAgencyList && associateAgencyList.length > 0){
      const associateAgency: CreateSearchAgency | undefined = associateAgencyList?.find((associateAgency: CreateSearchAgency) => commission.associateAgencyId==associateAgency.agencyId);
      if(associateAgency && associateAgency.agencyProductCommisions){
        const associateProdComm : ProductCommissionAgency | undefined = associateAgency.agencyProductCommisions?.find((prodComm: ProductCommissionAgency) => prodComm.productId === commission.productId);
        if(associateProdComm && associateProdComm.commissionRate){
          return associateProdComm.commissionRate;
        }
      }
    }
    return "";
  }

  let commissionRow: Row[] = useMemo(() => {
    let commission: Row[] = [];
    if(commissionList) {
      let index = 0;
      commission = commissionList.map((commission: ProductCommissionAgency) => {
        return {
          productCommissionId: commission.productCommissionId,
          productId: commission.productId,
          // productName: commission.productName  ,
          // productName: commission.productName ? commission.productName : findLabelByValue(commission.productId) ,
          productName: findLabelByValue(commission.productId) === "" ? commission.productName : findLabelByValue(commission.productId) ,
          associateAgencyId: commission.associateAgencyId,
          commissionRate: commission.commissionRate + " %",
          commissionPayTerms: commission.commissionPayTerms,
          associateAgencyName: commission.associateAgencyId ? associateAgencyList?.find((agency: CreateSearchAgency) => agency.agencyId == commission.associateAgencyId)?.agencyName : "",
          associateAgencyCommission: commission.associateAgencyId ? findProductCommission(commission) : "",
          effectiveDate: commission.effectiveDate? moment(commission.effectiveDate?.toString())?.format("MM/DD/YYYY") : "",
          inactiveDate: commission.inactiveDate? moment(commission.inactiveDate?.toString())?.format("MM/DD/YYYY") : "",
          rowIndex: index++
        }
      });
    }
    return commission;
  }, [commissionList,agencyProductCommissionList,associateAgencyList]);

  useEffect(()=> {
    handleTotalPages();
  },[commissionRow]);

  const onSubmit = async (data: CreateSearchAgency) => {
    data = finalizeSavingAgency(data);
    if(!isEditing && isCommTypeOverride && data.agencyProductCommisions){
      for(let i = 0; i < data.agencyProductCommisions?.length; i++){
        const existingCommission: ProductCommissionAgency = data.agencyProductCommisions[i];
        if(!existingCommission.associateAgencyId){
          let errorObj: MainErrorInfo = {
            title: "",
          };
    
          errorObj.title = "Error creating agency";
          errorObj.errorMessageStream = "Each product commission in the override agency should have an associate agency";
          dispatch(setMainErrorObj(errorObj));
          return;
        }
      }
    }
    if (isEditing) {
      data = { ...data, agencyId: agencyData.agencyId };
      dispatch(updateAgencyInfoObject(data));
    }
    handleSaveAgency(data); 
  };

  

  const finalizeSavingAgency = (agency: CreateSearchAgency) => {
    
    const updatedCommissionList = commissionList.map((commission) => {
      const updatedCommission = { ...commission };
      updatedCommission.agencyId = isEditing ? agencyData?.agencyId : 0;
      updatedCommission.inactiveDate = updatedCommission.inactiveDate? moment(updatedCommission.inactiveDate?.toString())?.format("MM/DD/YYYY") : "";
      updatedCommission.effectiveDate = updatedCommission.effectiveDate? moment(updatedCommission.effectiveDate?.toString())?.format("MM/DD/YYYY") : "";
      delete updatedCommission.rowIndex;
      if(agency.commissionType !== "Override") {
        delete updatedCommission.associateAgencyId;
      }
      return updatedCommission;
    });

    
    if(!isEditing) {
      agency.agencyProductCommisions = updatedCommissionList;
    }

    return agency;
  }

  const onClose = () => {
    navigate(-1);
    reset();
    if(location.state || isEditing){
      location.state.agency = null;
    }
    
  }

  const handleSaveAgency = async (data: CreateSearchAgency) => {
    let errorObj: MainErrorInfo = {
      title: "",
    };
    
    try {
      let response;
      if(isEditing){
        response = await dispatch(editAgency(data));   
      } else {
        response = await dispatch(saveAgency(data));
      }
  
      if (response.payload && response.payload.message === 'SUCCESS' || response.payload && response.payload.message==="Agency updated successfully") {
        navigate(-1);
        
        let alertMessage = isEditing ? 
          "Agency Updated Successfully!" : 
          "Agency Created Successfully!";
  
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "success",
        };
  
        dispatch(setSingleAlertObj(alertObj));   
      } else {
        let errorSentence = '';
        if (response.payload.errors.length <= 0) {
          errorSentence = response.payload.message || "An unknown error occurred.";
        } else {
          response.payload.errors.forEach((error: any, index: any) => {
            if (error.field) {
              errorSentence += `${error.field}: ${error.errorMessage}\n`;
              if (error.field === 'email') {
                setError('email', {
                  type: 'manual',
                  message: error.errorMessage,
                });
              }
            } else {
              errorSentence += `${error.reason}\n`;
            }
          });
        }
  
        errorObj.title = isEditing ? "Agency Update Failed!" :"Agency Creation Failed!";
        errorObj.errorMessageStream = errorSentence;
        dispatch(setMainErrorObj(errorObj));
      }
    } catch (error) {
      console.log(isEditing ? "Error updating agency:" : "Error creating agency:", error);
      errorObj.title = isEditing ? "Error updating agency" : "Error creating agency";
      errorObj.errorMessageStream = "" + error;
      dispatch(setMainErrorObj(errorObj));
    }
  };

  const pagination = (pageNumber: number) => {
    if(isEditing){
      const param = {
        agencyId: agencyData.agencyId,
        itemsPerPage: 10,
        currentPage: pageNumber + 1
      }
      dispatch(fetchProductCommissionsByAgencyId(param));
    }
    setCurrentPage(pageNumber);
  };

  const handleTotalPages = () => {
    setTotalPages(getTotalPages());
  }

  const getTotalPages = () => {
    if(isEditing){
      if(agencyProductCommissionList){
        return agencyProductCommissionList.totalPages;
      }
      return 1;
    } else {
      if (commissionRow.length > 10) {
        if (commissionRow.length % 10 === 0) {
          return commissionRow.length % 10;
        } else {
          return (commissionRow.length - commissionRow.length % 10) / 10 + 1;
        }
      }
      return 1;
    }
    
  }

  const handleActionEdit = (row: Row, rowIndex: number) => {

    setEditCommission(true);

    const selectedCommission: Row = {
      productCommissionId: row.productCommissionId,
      productId: row.productId,
      productName: row.productName,
      associateAgencyId: row.associateAgencyId,
      commissionRate: row.commissionRate ? Number(row.commissionRate.toString().split(" ")[0]) : "",
      commissionPayTerms: row.commissionPayTerms,
      associateAgencyName: row.associateAgencyName,
      associateAgencyCommission: row.associateAgencyCommission,
      effectiveDate: row.effectiveDate,
      inactiveDate: row.inactiveDate,
      rowIndex: row.rowIndex
    }
    setSelectedCommission(selectedCommission);
    setOpen(true);
  }

  const actionComponent = (row: Row, rowIndex: number): JSX.Element => {
    return <>
      <CustomIconButton
        color="info"
        size="small"
        variant="outlined"
        onClick={() => handleActionEdit(row, rowIndex)}
        tooltipMessage="Edit"
        tooltipPlacement='top'>
        <ModeOutlined fontSize='small'/>
      </CustomIconButton>
    </>
  }

  const confirmOptionPopup = () => {
    handleSubmit(onSubmit)()
    setOptionPopupShow({ message: "", show: false });
  }

  const cancelOptionPopup = () => {
    setOptionPopupShow({ message: "", show: false });
  }

  const updateAgencyValues = () =>{
    {setOptionPopupShow({message: "Do You Really want to update agency details? ", 
    show: true, 
    variant: "confirm", 
    buttons: [{name: "Confirm", color: 'primary', onClick:confirmOptionPopup },{name: "Cancel", color: 'secondary', onClick:cancelOptionPopup }]})}
  }

  const addAssociateAgencyColumns = (columns: Column[]): Column[] => {
    const modifiedColumns = [...columns];
    if (isCommTypeOverride) {
      modifiedColumns.splice(columns.length - 1, 0, { id: "associateAgencyCommission", label: "Associate Agency Comm.", minWidth: 100 , align: "center", 
      format: (value: number)=>{ return value + " %"} });
      modifiedColumns.splice(columns.length - 1, 0, { id: "associateAgencyName", label: "Associate Agency", minWidth: 100 });
    } else {
      if (columns.length == 7) {
        modifiedColumns.splice(columns.length - 1, 1);
        modifiedColumns.splice(columns.length - 1, 1);
      }
    }
    return modifiedColumns;
  }

  return (
    <Box m="20px">
      <Header title="Agency Management" subtitle={isEditing ? "Update Agency" : "Create Agency"} />
      <Card sx={{ p: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                control={control}
                controllerName="agencyName"
                label="Agency Name"
                rules={{ required: "Agency Name is required." }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomDropdownField
                name="commissionType"
                control={control}
                options={commTypeOptions}
                label="Comm Type"
                rules={{ required: "Commission type is required." }}
                disabled={isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                control={control}
                controllerName="email"
                label="Email"
                rules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                    message: "Invalid email.",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomPatternTextField
                control={control}
                name="phone"
                label="Phone"
                rules={{ required: "Phone is required." }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                control={control}
                controllerName="streetAddress"
                label="Street Address"
                rules={{ required: "Street Address is required." }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CustomTextField
                control={control}
                controllerName="unitNumber"
                label="Unit #"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CustomTextField
                control={control}
                controllerName="zipcode"
                label="ZipCode"
                rules={{ required: "ZipCode is required." }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                control={control}
                controllerName="city"
                label="City"
                rules={{ required: "City is required." }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CustomDropdownField
                name="state"
                control={control}
                options={stateListOptions}
                label="State"
                rules={{ required: "State is required." }}
              />
            </Grid>
          </Grid>
          <Box mt={4}>
            {isEditing &&<Grid container justifyContent="flex-end" mb={1}>
              <Button type="submit" onClick={updateAgencyValues} style={{ marginRight: 10 }}>
                {isEditing ? "Update Agency" : "Save Agency"}
              </Button>
            </Grid>}
            {canAccessCommissionActionsAdd ?
              <Grid container justifyContent="flex-end">
                <Button
                  onClick={handleClickOpen}
                  color="info"
                  startIcon={<AddCircleOutlineOutlined />}
                  sx={{ mb: 1 }}
                >
                  Add Product Commission
                </Button>
              </Grid>
              : ""}
            {/* <Table columns={columns} rows={[]} /> */}
            <Table
              columns={addAssociateAgencyColumns(columns)}
              rows={commissionRow || []}
              actionComponent={(row: any, rowIndex: number) =>
                actionComponent(row, rowIndex)
              }
              emptyText = "No Orders Found"
              // totalPages={listData?.totalPages}
              totalPages={ totalPages }
              pageNumber={(thisPage: number) => pagination(thisPage)}
            />
          </Box>
          <Box mt={2} mb={2}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xs={12} sm="auto">
                {!isEditing && <Button type="submit" style={{ marginRight: 10 }} fullWidth>
                  Save Agency
                </Button>}
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="error" onClick={() => onClose()} fullWidth>
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
        <ProductCommissionPopup
          open={open}
          productOptions={productOptions}
          associateAgencyOptions={associateAgencyOptions}
          isCommTypeOverride={isCommTypeOverride}
          editCommission = {editCommission}
          defaultValue = {selectedCommission}
          handleClose={handleClose}
          submitCommission={submitCommission}
        />
      </Card>
    </Box>
  );
};

export default AgencyForm;
