import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";

const SettingProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: "16px",
        backgroundColor: colors.grey[900],
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", marginBottom: 2 }}
        color={colors.info[300]}
      >
        Advanced Settings
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={9}>
          <Typography variant="subtitle1" sx={{ color: "gray" }}>
            Secure Browsing
          </Typography>
          <Typography variant="body2">
            Browsing Securely (https) when it's necessary
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={<Switch color="info" />}
            label=""
            sx={{ marginLeft: "auto" }}
          />
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="subtitle1" sx={{ color: "gray" }}>
            Login Notifications
          </Typography>
          <Typography variant="body2">
            Notify when login attempted from another place
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={<Switch color="info" />}
            label=""
            sx={{ marginLeft: "auto" }}
          />
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="subtitle1" sx={{ color: "gray" }}>
            Login Approvals
          </Typography>
          <Typography variant="body2">
            Approvals are not required when logging in from unrecognized
            devices.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={<Switch color="info" />}
            label=""
            sx={{ marginLeft: "auto" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingProfile;
