import React from "react";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../theme";

interface LegacyToolBarProps {
  items: Array<{
    icon: React.ReactElement;
    text: string;
  }>;
}

const LegacyToolBar: React.FC<LegacyToolBarProps> = ({ items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        p: "8px",
        backgroundColor: colors.grey[900],
        borderRadius: 1,
        boxShadow: 1,
        overflowX: "auto",
      }}
    >
      {isMobile ? (
        <Box p={1}>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={12}>
              <b>Key/Legand</b>
            </Grid>
            {items.map((item, index) => (
              <Grid
                item
                key={index}
                xs={isMobile ? 6 : "auto"}
                sx={{
                  display: "flex",
                  whiteSpace: "nowrap",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {item.icon}
                  <Typography
                    variant="caption"
                    sx={{ ml: 1, fontWeight: "bold" }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Grid container spacing={1} justifyContent="flex-start" wrap="wrap">
          {items.map((item, index) => (
            <Grid
              item
              key={index}
              xs={isMobile ? 6 : "auto"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                whiteSpace: "nowrap",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {item.icon}
                <Typography
                  variant="caption"
                  sx={{ ml: 1, fontWeight: "bold" }}
                >
                  {item.text}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default LegacyToolBar;
