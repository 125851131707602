import React, { useCallback, useEffect } from "react";
import Header from "../../common/Header";
import { Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  fetchActiveAgencyExecutives,
  usePracticeApproveSlice,
} from "../../../redux/slice/practice/practiceApproveSlice";
import { fullName } from "../../../utils/Utility";
import { usePracticeEditSlice } from "../../../redux/slice/practice/practiceEditSlice";
import {
  PracticeExecutiveInfo,
  fetchPracticeExecutiveData,
  updatePracticeExecutiveById,
  usePracticeExecutiveSlice,
} from "../../../redux/slice/practice/practiceExecutiveSlice";
import Button from "../../common/Button";
import { Save } from "@mui/icons-material";
import {
  MainErrorInfo,
  SingleAlertInfo,
  setMainErrorObj,
  setSingleAlertObj,
} from "../../../redux/slice/commonSlice";
import OptionsPopup from "../../common/OptionsPopup";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { MSG_NO_AGENCY_FOUND, MSG_NO_USER_FOUND, MSG_NOT_AN_ACTIVE_EXECUTIVE, MSG_PRACTICE_EXECUTIVE_NOT_FOUND } from "../../../constants/applicationConstants";

interface PracticeExecutiveInfoProps {
  contentChangedStatus: () => void;
}

const PracticeExecutiveInfoViewForm: React.FC<PracticeExecutiveInfoProps> = ({contentChangedStatus}) => {
  const { control, handleSubmit, reset } = useForm<PracticeExecutiveInfo>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const practiceExOps: any[] = [{ value: "", label: "Select" }];
  const [isPracticeExInfoVisible, setIsPracticeExInfoVisible] =
    React.useState(false);
  const { agencyExecutives } = usePracticeApproveSlice();
  const { practiceEditInfo } = usePracticeEditSlice();
  const { practiceExecutiveInfo } = usePracticeExecutiveSlice();
  const [optionPopupShow, setOptionPopupShow] = React.useState<{
    message: string;
    show: boolean;
  }>({ message: "", show: false });
  const defaultValues = useCallback(() => {
    if (practiceExecutiveInfo) {
      reset(practiceExecutiveInfo);
    }
  }, [practiceExecutiveInfo, reset]);
  const location = useLocation();
  const practiceApprovalStatus =
    location.state?.practiceEditInfo?.approvalStatus;

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  useEffect(() => {
    dispatch(fetchActiveAgencyExecutives(true));
    if (practiceEditInfo && practiceApprovalStatus !== "REJECTED") {
      dispatch(fetchPracticeExecutiveData(practiceEditInfo.practiceId));
    }
  }, [dispatch, practiceEditInfo, practiceApprovalStatus]);

  const confirmOptionPopup = () => {
    handleSubmit(handleUpdateExecutive)();
    setOptionPopupShow({ message: "", show: false });
  };

  const cancelOptionPopup = () => {
    setOptionPopupShow({ message: "", show: false });
  };

  const findAgencyIdByUser = (userId: number) => {
    const executive = agencyExecutives?.find(executive => executive.userId === userId);
    return executive ? executive.agencyId : undefined;
  }

  const handleUpdateExecutive = async (updateExecutiveData: any) => {
    let errorObj: MainErrorInfo = {
      title: "",
    };

    const data = {
      practiceExecutiveId: updateExecutiveData.practiceExecutiveId,
      agencyId: findAgencyIdByUser(updateExecutiveData.user.userId),
      userId: updateExecutiveData.user.userId,
    };
    try {
      const response = await dispatch(updatePracticeExecutiveById(data));
      if (response.payload && response.payload.message === "SUCCESS") {
        setIsPracticeExInfoVisible(false);
        let successObj: SingleAlertInfo = {
          message: "Account Representative Updated Successfully.",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(successObj));
        contentChangedStatus();
      } else {
        let errorSentence = "";
        if (response.payload && response.payload.errors.length > 0) {
          response.payload.errors.forEach((error: any, index: any) => {
            if (error.field) {
              errorSentence += `${error.field}: ${error.errorMessage}\n`;
            } else {
              errorSentence += `${error.reason}\n`;
            }
          });
        } else if (response.payload && response.payload.message) {
          switch (response.payload.message) {
            case MSG_NOT_AN_ACTIVE_EXECUTIVE:
              errorSentence = "This Practice Executive is not active. Please check their status and try again.";
              break;
            case MSG_NO_USER_FOUND:
              errorSentence = "No user was found with the provided details.";
              break;
            case MSG_NO_AGENCY_FOUND:
              errorSentence = "No agency was found with the provided details.";
              break;
            case MSG_PRACTICE_EXECUTIVE_NOT_FOUND:
              errorSentence = "The specified Practice Executive could not be found.";
              break;
            default:
              errorSentence = "An unknown error occurred.";
          }
        } else {
          errorSentence = "An unknown error occurred.";
        }

        errorObj.title = "Updating Account Representative Failed!";
        errorObj.errorMessageStream = errorSentence;
        dispatch(setMainErrorObj(errorObj));
        navigate("/practice_list");
      }
    } catch (error) {
      errorObj.title = "Error Updating Account Representative";
      errorObj.errorMessageStream = "" + error;
      dispatch(setMainErrorObj(errorObj));
    }
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    if (value !== "" && value !== undefined) {
      if (value === practiceExecutiveInfo?.user.userId) {
        setIsPracticeExInfoVisible(false);
      } else {
        setIsPracticeExInfoVisible(true);
      }
    } else {
      setIsPracticeExInfoVisible(false);
    }
  };

  agencyExecutives?.map((executive) => {
    practiceExOps.push({
      value: executive.userId,
      label:
        executive.agencyName +
        " - " +
        fullName(
          null,
          executive.firstName,
          executive.middleName,
          executive.lastName
        ),
    });
  });

  return (
    <>
      <div style={{ height: "10px" }}></div>
      <Header title="Account Representative Information" subtitle="" />
      <div style={{ height: "20px" }}></div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CustomDropdownField
            control={control}
            name={`user.${"userId"}`}
            options={practiceExOps}
            label="Account Representative"
            onChangeSelect={handleChange}
            disableSearch
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <form onSubmit={handleSubmit(handleUpdateExecutive)}>
            <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
              <Button
                onClick={() => {
                  setOptionPopupShow({
                    message:
                      "Do you really want to update Account Representative details? ",
                    show: true,
                  });
                }}
                disabled={!isPracticeExInfoVisible}
                startIcon={<Save />}
                fullWidth
              >
                Save
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
      <OptionsPopup
        open={optionPopupShow.show}
        onClose={() => setOptionPopupShow({ message: "", show: false })}
        variant="confirm"
        message={<>{optionPopupShow.message}</>}
        buttons={[
          { name: "Confirm", color: "primary", onClick: confirmOptionPopup },
          { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
        ]}
      />
    </>
  );
};

export default PracticeExecutiveInfoViewForm;
