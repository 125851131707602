import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone, DropzoneOptions, FileRejection } from "react-dropzone";
import { Box, Chip, Typography } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import OptionsPopup, { OptionPopupObjectProps } from "./OptionsPopup";

interface FileUploaderProps {
  name: string;
  label: string | JSX.Element;
  onFileChange: () => void;
  onFileChangeTest?: (uploadedFile: File, fieldName: any) => void;
  allowedFileTypes?: { [mimeType: string]: string[] };
  document?: any;
  optionPopupObject?: OptionPopupObjectProps;
  disableActions?: boolean;
}

const baseStyle: React.CSSProperties = {
  width: "150px",
  height: "150px",
  position: "relative",
};

const hoverTextStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  pointerEvents: "none",
  width: "150px",
};

const FileUploader: React.FC<FileUploaderProps> = ({
  name,
  label,
  onFileChange,
  onFileChangeTest,
  allowedFileTypes,
  document,
  optionPopupObject,
  disableActions,
}) => {
  const [file, setFile] = useState<any | null>(null);
  const [hovered, setHovered] = useState<boolean>(false);
  const [optionPopupShow, setOptionPopupShow] = useState<boolean>(false);
  const [uploadDocument, setUploadDocument] = useState<File[]>([]);

  const onDrop = useCallback (
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length !== 0) {
        if (optionPopupObject?.show) {
          setUploadDocument(acceptedFiles);
          setOptionPopupShow(true);
        } else {
          continueOnDrop(acceptedFiles);
        }
      }
    },
    [onFileChange, onFileChangeTest, label]
  );

  const continueOnDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const uploadedFile = acceptedFiles[0];
      setFile(uploadedFile);
      onFileChange();
      onFileChangeTest && onFileChangeTest(uploadedFile, name);
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFileTypes ? allowedFileTypes : undefined,
  } as DropzoneOptions);

  const style = useMemo(
    () => ({
      ...baseStyle,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
    }),
    [isDragActive]
  );

  const confirmOptionPopup = useCallback(() => {
    continueOnDrop(uploadDocument);
    setOptionPopupShow(false);
  }, [uploadDocument]);

  const cancelOptionPopup = useCallback(() => {
    setOptionPopupShow(false);
  }, []);

  const handleHoverEnter = () => {
    setHovered(true);
  }

  const handleHoverLeave = () => {
    setHovered(false);
  }

  useEffect(() => {
    return () => {
      if (file) {
        URL.revokeObjectURL(file.preview);
      }
    };
  }, [file]);

  useEffect(() => {
    if (document) {
      const raw = window.atob(document?.content);
      const rawLength = raw.length;
      const uint8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uint8Array[i] = raw.charCodeAt(i);
      }
      const blob = new Blob([uint8Array]);
      const fileObj = new File([blob], document.documentName);
      setFile(fileObj);
    } else {
      setFile(null);
    }
  }, [document]);

  if (disableActions) {
    return (
      <>
        <Box component={Box} textAlign="center"
          sx={{
            ...baseStyle,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
          }}
        >
          {file ?
            <img src={URL.createObjectURL(file)} alt={file?.name}
              style={{ width: "100%", height: "auto" }} />
            :
            <Typography sx={{ color: 'darkorange' }}>Logo is not found</Typography>
          }
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box
          {...getRootProps({ style })}
          component={Box}
          textAlign="center"
          sx={
            hovered
              ? {
                ...style,
                backgroundColor: "rgba(180, 180, 180, 0.1)",
              }
              : style
          }
          onMouseEnter={handleHoverEnter}
          onMouseLeave={handleHoverLeave}
        >
          <input {...getInputProps()} name={name} />

          {(hovered || !file) && (
            <Box style={hoverTextStyle} p={2} >
              <CloudUpload fontSize="large" />
              <Typography variant="body1" component="div">
                {label}
              </Typography>
            </Box>
          )}

          {file && (
            <Box>
              {!hovered && <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }} ><Chip label={<ModeEditOutlinedIcon fontSize="medium" />} /></Box>}
              <img src={URL.createObjectURL(file)} alt={file.name}
                style={
                  hovered
                    ? { width: "100%", height: "auto", opacity: "0.1" }
                    : { width: "100%", height: "auto" }
                }
              />
            </Box>
          )}
        </Box>
        <OptionsPopup open={optionPopupShow} onClose={() => setOptionPopupShow(false)} variant="confirm" message={<>{optionPopupObject?.message}</>}
          buttons={[{ name: "Confirm", color: 'primary', onClick: confirmOptionPopup }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]} />
      </>
    );
  }
};

export { FileUploader };
