import React from 'react';
import {
  Box,
  FormControlLabel,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Checkbox,
} from '@mui/material';

interface ColumnToggleProps {
  columns: { id: string, label: string | JSX.Element }[];
  hiddenColumns: string[];
  setHiddenColumns: (columns: string[]) => void;
}

const ColumnToggle: React.FC<ColumnToggleProps> = ({ columns, hiddenColumns, setHiddenColumns }) => {
  const handleToggle = (columnId: string) => {
    setHiddenColumns(
      hiddenColumns.includes(columnId)
        ? hiddenColumns.filter(id => id !== columnId)
        : [...hiddenColumns, columnId]
    );
  };

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Toggle Columns
      </Typography>
      <List>
        {columns.map((column, index) => (
          <React.Fragment key={column.id}>
            <ListItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!hiddenColumns.includes(column.id)}
                    onChange={() => handleToggle(column.id)}
                    color="success"
                  />
                }
                label={<ListItemText primary={column.label} />}
              />
            </ListItem>
            {index < columns.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default ColumnToggle;
