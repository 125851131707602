import { forwardRef } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../common/Button";
import { Cancel, CheckCircleOutlineOutlined } from "@mui/icons-material";
import {
  SingleAlertInfo,
  setSingleAlertObj,
} from "../../../redux/slice/commonSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { inactivateActivateUser, useUserReducer } from "../../../redux/slice/userSlice";

interface InactiveUserProps {
  open: boolean;
  onClose: (reloadList: boolean) => void;
  userId: number;
  userStatus: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const InactiveUserPopup: React.FC<InactiveUserProps> = ({
  open,
  onClose,
  userId,
  userStatus,
}) => {
  const { handleSubmit } = useForm();
  const dispatch = useAppDispatch();
  const { status } = useUserReducer();
  const isUserActive = userStatus === "Active";

  const handleAlert = (message: string, type: "success" | "error" | "info") => {
    const alertObj: SingleAlertInfo = {
      message,
      alertType: type,
    };
    dispatch(setSingleAlertObj(alertObj));
  };

  const inactivateUserProfile = async (userId: number) => {
    try {
      const response = await dispatch(inactivateActivateUser({userId: userId, action: isUserActive ? "Inactivate" : "Activate" }));
      if (status === "success") {
        handleAlert(`User profile ${isUserActive ? "inactivated" : "activated"} successfully.`, "success");
        return response;
      } else {
        throw new Error("Failed to inactivate user profile.");
      }
    } catch (error) {
      handleAlert("Failed to inactivate user profile.", "error");
      throw error;
    }
  };

  const onSubmit = async () => {
    await inactivateUserProfile(userId);
    onClose(true);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {isUserActive ? "Inactivate User Profile" : "Activate User Profile"}
        </DialogTitle>
        <DialogContent>
          <Box p={1}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                Are you sure you want to{" "}
                {isUserActive ? "inactivate" : "activate"} this user?
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" startIcon={<CheckCircleOutlineOutlined />}>
            Yes
          </Button>
          <Button color="error" startIcon={<Cancel />} onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InactiveUserPopup;
