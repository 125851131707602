import React, { useState, useRef } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import {
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import { InsertInvitation } from "@mui/icons-material";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import "react-multi-date-picker/styles/colors/green.css";
import "react-multi-date-picker/styles/layouts/mobile.css";

interface CustomDateRangeProps {
  label?: string;
  control: any;
  controllerName: string;
  month?: number;
  onChange?: (fromDate: DateObject | null, toDate: DateObject | null) => void; // Added onChange prop
}

const CustomDateRange: React.FC<CustomDateRangeProps> = ({
  label,
  control,
  controllerName,
  month,
  onChange
}) => {
  const theme = useTheme();
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [dateRange, setDateRange] = useState<DateObject[]>([]); // Default value set to empty array
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFocus = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const handleChange = (dates: DateObject[]) => {
    handleFocus();
    setDateRange(dates);

    if (onChange) {
      const [fromDate, toDate] = dates;
      onChange(fromDate || null, toDate || null);
    }
  };

 
  return (
    <>
      <DatePicker
        numberOfMonths={month || 2}
        value={dateRange}
        onChange={handleChange}
        range
        onOpen={() => handleFocus()}
        onClose={() => handleFocus()}
        className={
          isMobile
            ? theme.palette.mode === "dark"
              ? "rmdp-mobile green bg-dark"
              : "green"
            : theme.palette.mode === "dark"
            ? "green bg-dark"
            : "green"
        }
        style={{
          width: "100%",
        }}
        containerStyle={{
          width: "100%",
        }}
        mobileLabels={{
          OK: "APPLY",
          CANCEL: "CLOSE",
        }}
        render={(value, openCalendar) => (
          <CustomTextField
            control={control}
            controllerName={controllerName}
            value={value}
            onClick={() => {
              openCalendar();
              handleFocus();
            }}
            label={label}
            variant="outlined"
            fullWidth
            inputRef={textFieldRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      openCalendar();
                      handleFocus();
                    }}
                  >
                    <InsertInvitation />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default CustomDateRange;
