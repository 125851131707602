import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingDialogProps {
    open: boolean;
}

const LoadingDialog: React.FC<LoadingDialogProps> = ({
    open,
}) => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} >
            <CircularProgress size={60} variant="indeterminate" />
        </Backdrop>
    );
};

export default LoadingDialog;