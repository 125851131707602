import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { fetchPracticeProvidersById, savePracticeProvider, inactivePracticeProviders, fetchActivePracticeProvidersById } from "../../../service/PracticeService";
import { useAppSelector } from "./../redux-hooks";

export type PracticeProviderInfo = {
    providerId: number;
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
    npi: string;
    ptan: string;
    taxId: string;
    createdDate: Date;
    inactiveDate: Date;
    billingType?: string; 
  }

export type FetchActiveProvidersParams = {
  productId: string;
  onlyActiveRecords: boolean;
}

type PracticeProviderState = {
    practiceProvidersInfo?: PracticeProviderInfo[] | null;
    practiceProvidersDataFetchStatus?: LoadingType
    practiceProvidersSaveStatus?: LoadingType
    practiceProvidersInactiveStatus?: LoadingType
  };

  const initialState: PracticeProviderState = {
    practiceProvidersInfo: undefined,
    practiceProvidersDataFetchStatus: "idle",
    practiceProvidersSaveStatus: "idle",
    practiceProvidersInactiveStatus: "idle",
  };

  export const fetchPracticeProvidersData = createAsyncThunk("fetchPracticeProvidersData", async (practiceId: string) => {
    const response = await fetchPracticeProvidersById(practiceId);
    return response.data;
  });

  export const fetchActivePracticeProvidersData = createAsyncThunk("fetchActivePracticeProvidersData", async ({ productId, onlyActiveRecords }: FetchActiveProvidersParams) => {
    const response = await fetchActivePracticeProvidersById(productId, onlyActiveRecords);
    return response.data;
  });

  export const savePracticeProviders = createAsyncThunk("savePracticeProvider", async (data: PracticeProviderInfo, { rejectWithValue }) => {
    try{
    const response = await savePracticeProvider(data);
    return response.data;
    }catch(error){
      //@ts-ignore
      return rejectWithValue(error?.response?.data);
    }
  });

  export const inactivePracticeProvider = createAsyncThunk("inactivePracticeProvider", async (data: any, { rejectWithValue }) => {
    console.log(data);
    try{
    const response = await inactivePracticeProviders(data.providerId, data.inactiveDate);
    return response.data;
    }catch(error){
      //@ts-ignore
      return rejectWithValue(error?.response?.data);
    }
  });

  const practiceProvidersSlice = createSlice({
    name: "practiceProviders",
    initialState,
    reducers: {
      updatePracticeProvidersInfoObject: (state: PracticeProviderState, action) => {
        state.practiceProvidersInfo = action.payload;
      },
      resetPracticeProvidersInfo : (state) =>{
        state.practiceProvidersInfo = null;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchPracticeProvidersData.pending, (state) => {
          state.practiceProvidersDataFetchStatus = "loading";
        })
        .addCase(fetchPracticeProvidersData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersDataFetchStatus = "success";
          state.practiceProvidersInfo = action.payload.data;
        })
        .addCase(fetchPracticeProvidersData.rejected, (state, action) => {
          state.practiceProvidersDataFetchStatus = 'error';
        })
        .addCase(fetchActivePracticeProvidersData.pending, (state) => {
          state.practiceProvidersDataFetchStatus = "loading";
        })
        .addCase(fetchActivePracticeProvidersData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersDataFetchStatus = "success";
          state.practiceProvidersInfo = action.payload.data;
        })
        .addCase(fetchActivePracticeProvidersData.rejected, (state, action) => {
          state.practiceProvidersDataFetchStatus = 'error';
        })
        .addCase(savePracticeProviders.pending, (state) => {
          state.practiceProvidersSaveStatus = "loading";
        })
        .addCase(savePracticeProviders.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersSaveStatus = "success";
          // state.practiceLocationsInfo = action.payload.data;
        })
        .addCase(savePracticeProviders.rejected, (state, action) => {
          state.practiceProvidersSaveStatus = 'error';
        })
        .addCase(inactivePracticeProvider.pending, (state) => {
          state.practiceProvidersInactiveStatus = "loading";
        })
        .addCase(inactivePracticeProvider.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProvidersInactiveStatus = "success";
          // state.practiceLocationsInfo = action.payload.data;
        })
        .addCase(inactivePracticeProvider.rejected, (state, action) => {
          state.practiceProvidersInactiveStatus = 'error';
        });
    },
  });

export const { updatePracticeProvidersInfoObject, resetPracticeProvidersInfo } = practiceProvidersSlice.actions;
export const usePracticeProvidersSlice = () => useAppSelector((state) => state.practiceProvidersSlice);
export default practiceProvidersSlice.reducer;