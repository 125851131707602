import { createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import { useAppSelector } from "./redux-hooks";
import { getAllAgenciesList, getMacsList, getManufacturersList, getPracticeList, getProductList } from "../../service/CommonService";
import { PracticeInfo } from "./practiceSlice";
import { LoadingType } from "../../types/CommonTypes";

export type Column = {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

export type MainErrorInfo = {
  title: string;
  errorMessageStream?: string;
  errorMessageList?: string[];
}

export type SingleAlertInfo = {
  title?: string;
  message: string | JSX.Element;
  alertType: "error" | "success" | "warning" | "info";
}

export type MacInfo = {
    macId: number;
    macName: string;
}

export type ManufacturerInfo = {
    manufacturerId: number;
    manufacturerName: string;
    contactPhone: string;
    contactEmail: string;
}

export type AgencyInfo = {
  agencyId: string;
  agencyName: string;
  commissionType: string,
  phone: string;
  email: string;
  streetAddress: string;
  unitNumber: string,
  city: string,
  state: string,
  zipCode: string
}

type CommonState = {
  showLoadingDialog: boolean,
  loadingCount: number,
  showMainErrorPopup: boolean,
  errorDetailExpand: boolean,
  mainErrorObj: MainErrorInfo | null,
  showSingleAlert: boolean,
  singleAlertObj: SingleAlertInfo | null,
  macInfoList: MacInfo[],
  manufacturerInfoList: ManufacturerInfo[],
  agencyInfoList: AgencyInfo[],
  practiceInfo?: PracticeInfo[] | null,
  status: "idle" | "loading" | "failed" | "success";
  fetchAgencyListStatus: LoadingType;
  ferchManufactureListStatus: LoadingType;
  error: string | null;
  isValid: true | false;
};

const initialState = {
    showLoadingDialog: false,
    loadingCount: 0,
    showMainErrorPopup: false,
    errorDetailExpand: true,
    mainErrorObj: null,
    showSingleAlert: false,
    singleAlertObj: null,
    macInfoList: [],
    manufacturerInfoList: [],
    agencyInfoList: [],
    practiceInfo: [],
    status: "idle",
    fetchAgencyListStatus: "idle",
    ferchManufactureListStatus: "idle",
    error: null,
    isValid: true,
} as CommonState;

export const fetchMac = createAsyncThunk("fetchMac", async () => {
    const response = await getMacsList();
    const v3Response = response.data;
    return v3Response.data;
});

export const fetchManufacturersList = createAsyncThunk("fetchManufacturersList", async () => {
    const response = await getManufacturersList();
    const v3Response = response.data;
    return v3Response.data;
});

export const fetchAgenciesList = createAsyncThunk("fetchAgenciesList", async () => {
  const response = await getAllAgenciesList();
  const v3Response = response.data;
  return v3Response.data;
});

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    showHideLoadingDialog: (
      state: CommonState,
      action: PayloadAction<boolean>
    ) => {
      if (action.payload) {
        state.loadingCount += 1;
        state.showLoadingDialog = true;
      } else {
        if (state.loadingCount === 1) {
          state.loadingCount = 0;
          state.showLoadingDialog = false;
        } else if (state.loadingCount > 0){
          state.loadingCount = state.loadingCount - 1;
        }
      }
    },
    setMainErrorObj: (state: CommonState, action: PayloadAction<MainErrorInfo>) => {
      state.mainErrorObj = action.payload;
      state.showMainErrorPopup = true;
      state.errorDetailExpand = true;
    },
    hideMainErrorPopup: (state: CommonState) => {
      state.showMainErrorPopup = false;
      state.errorDetailExpand = true;
    },
    setErrorDetailExpand: (state: CommonState, action) => {
      state.errorDetailExpand = action.payload;
    },
    setSingleAlertObj: (state: CommonState, action: PayloadAction<SingleAlertInfo>) => {
      state.singleAlertObj = action.payload;
      state.showSingleAlert = true;
    },
    hideSingleAlert: (state: CommonState) => {
      state.showSingleAlert = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMac.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchMac.fulfilled, (state, action) => {
        state.status = "success";
        state.isValid = true;
        state.macInfoList = action.payload;
      })
      .addCase(fetchMac.rejected, (state, action) => {
        state.status = "failed";
        state.isValid = false;
        state.error = action.error.message || "Login failed";
      })
      .addCase(fetchManufacturersList.pending, (state) => {
        state.ferchManufactureListStatus = "loading";
        state.error = null;
      })
      .addCase(fetchManufacturersList.fulfilled, (state, action) => {
        state.ferchManufactureListStatus = "success";
        state.isValid = true;
        state.manufacturerInfoList = action.payload;
      })
      .addCase(fetchManufacturersList.rejected, (state, action) => {
        state.ferchManufactureListStatus = "error";
        state.isValid = false;
        state.error = action.error.message || "Login failed";
      })
      .addCase(fetchAgenciesList.pending, (state) => {
        state.fetchAgencyListStatus = "loading";
        state.error = null;
      })
      .addCase(fetchAgenciesList.fulfilled, (state, action) => {
        state.fetchAgencyListStatus = "success";
        state.isValid = true;
        state.agencyInfoList = action.payload;
      })
      .addCase(fetchAgenciesList.rejected, (state, action) => {
        state.fetchAgencyListStatus = "error";
        state.isValid = false;
        state.error = action.error.message || "Login failed";
      });
  },
});

export const {showHideLoadingDialog, setMainErrorObj, hideMainErrorPopup, setErrorDetailExpand, setSingleAlertObj, hideSingleAlert} = commonSlice.actions;
export const useCommonReducer = () => useAppSelector((state) => state.commonSlice);
export default commonSlice.reducer;
