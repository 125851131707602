import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../common/Button";
import { Box, FormHelperText, Grid } from "@mui/material";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import { Cancel, SaveAlt } from "@mui/icons-material";
import CustomAlert from "../../common/CustomAlert";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import {
  useCodetablesSlice,
  getCodeTableValuesByTableName,
  CodeTable,
} from "../../../redux/slice/codetableSlice";
import { sortNames } from "../../../utils/Utility";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { getWoundCodeValuesByWoundCodeType, fetchAllWoundCodes, useWoundCodesSlice } from "../../../redux/slice/ivr/woundCodeSlice"

interface WoundInfoProps {
  open: boolean;
  onClose: () => void;
  onAddWoundInfo: (woundData: any) => void;
  currentWoundInfo?: any;
}

export const woundInfoInitialState: any = {
  woundType: "",
  // noOfApplications: "",
  cpt: "",
  eCode: "",
  iCode: "",
  lCode: "",
  length: "",
  width: "",
  depth: "",
  totalSize: "",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const woundTypesOptions = [
  { value: "Diabetic Ulcer", label: "Diabetic Ulcer" },
  { value: "Venous Ulcer", label: "Venous Ulcer" },
  { value: "Pressure Ulcer", label: "Pressure Ulcer" },
  { value: "Chronic Wound", label: "Chronic Wound" },
  { value: "Other", label: "Other" },
];

const noOfAppOptions: { value: string | number; label: string }[] = [
  { value: "1-3", label: "1 - 3" },
  { value: "4-6", label: "4 - 6" },
  { value: "7-10", label: "7 - 10" },
];

const IVRFormWoundInfoPopup: React.FC<WoundInfoProps> = ({
  open,
  onClose,
  onAddWoundInfo,
  currentWoundInfo,
}) => {
  const { control, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: woundInfoInitialState,
  });
  const dispatch = useAppDispatch();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const length = watch("length");
  const width = watch("width");
  const woundType = watch("woundType");
  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } =
    useCodetablesSlice();
  const { allWoundCodesValuesMap, woundCodeFetchStatus } = useWoundCodesSlice();
  const [cptOptions, setCptOptions] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [iCodeOptions, setICodeOptions] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [lCodeOptions, setLCodeOptions] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [eCodeOptions, setECodeOptions] = useState<
    { value: string | number; label: string }[]
  >([]);

  // React.useEffect(() => {
  //   if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
  //     setCptOptions(
  //       getCodeTableValuesByTableName(
  //         allCodeTablesValuesMap,
  //         "WoundInfo_CPTCode"
  //       ).map((item) => ({
  //         value: item.keyName,
  //         label: item.displayValue,
  //       }))
  //     );
  //     setICodeOptions(
  //       getCodeTableValuesByTableName(
  //         allCodeTablesValuesMap,
  //         "WoundInfo_ICode"
  //       ).map((item) => ({
  //         value: item.keyName,
  //         label: item.displayValue,
  //       }))
  //     );

  //     const woundInfoLCode = getCodeTableValuesByTableName(allCodeTablesValuesMap, "WoundInfo_LCode")
  //     const sortedLCodeData = woundInfoLCode ? sortNames(woundInfoLCode, 'keyName') : [];
  //     setLCodeOptions(
  //       sortedLCodeData.map((item: any) => ({
  //         value: item.keyName,
  //         label: item.displayValue,
  //       }))
  //     );

  //     setECodeOptions(
  //       getCodeTableValuesByTableName(
  //         allCodeTablesValuesMap,
  //         "WoundInfo_ECode"
  //       ).map((item) => ({
  //         value: item.keyName,
  //         label: item.displayValue,
  //       }))
  //     );
  //   }
  // }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  React.useEffect(() => {
    dispatch(fetchAllWoundCodes());
  }, [dispatch]);

  React.useEffect(() => {
    if (woundCodeFetchStatus === "success" && allWoundCodesValuesMap) {

      const cptCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "CPTCODE")
      const sortedCPTCodes = cptCodes ? sortNames(cptCodes, 'woundKey') : [];
      setCptOptions(
        sortedCPTCodes.map((item: any) => ({
          value: item.woundKey,
          label: item.woundDisplayValue,
        }))
      );

      const eCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "ECODE")
      const sortedECodes = eCodes ? sortNames(eCodes, 'woundKey') : [];
      setECodeOptions(
        sortedECodes.map((item: any) => ({
          value: item.woundKey,
          label: item.woundDisplayValue,
        }))
      );

      const iCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "ICODE")
      const sortedICodes = iCodes ? sortNames(iCodes, 'woundKey') : [];
      setICodeOptions(
        sortedICodes.map((item: any) => ({
          value: item.woundKey,
          label: item.woundDisplayValue,
        }))
      );

      const lCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "LCODE")
      const sortedLCodes = lCodes ? sortNames(lCodes, 'woundKey') : [];
      setLCodeOptions(
        sortedLCodes.map((item: any) => ({
          value: item.woundKey,
          label: item.woundDisplayValue,
        }))
      );
    }
  }, [woundCodeFetchStatus, allWoundCodesValuesMap]);

  React.useEffect(() => {
    if (open && !currentWoundInfo) {
      reset(woundInfoInitialState);
    } else if (currentWoundInfo) {
      Object.keys(currentWoundInfo).forEach((key) => {
        setValue(key, currentWoundInfo[key], {
          shouldValidate: true,
        });
      });
    }
  }, [open, currentWoundInfo, reset, setValue]);

  React.useEffect(() => {
    if (length && width && !isNaN(length) && !isNaN(width)) {
      const totalSize = parseFloat(length) * parseFloat(width);
      setValue("totalSize", totalSize.toFixed(2), { shouldValidate: true });
    }
  }, [length, width, setValue]);

  const onSubmit = (data: any) => {
    const formattedData = {
      ...data,
      cpt: Array.isArray(data.cpt) ? data.cpt.join(', ') : data.cpt,
    };
    onAddWoundInfo(formattedData);
    // setShowErrorAlert(true);
    reset(woundInfoInitialState);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Add Wound Information</DialogTitle>
          <DialogContent>
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomDropdownField
                    name="woundType"
                    control={control}
                    options={woundTypesOptions}
                    label="Wound Type"
                    rules={{ required: "Type of wound is required." }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <CustomDropdownField
                    name="noOfApplications"
                    control={control}
                    options={noOfAppOptions}
                    label="# of Applications"
                    rules={{ required: "No of application is required." }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomMultipleDropDown
                    name="cpt"
                    control={control}
                    options={cptOptions}
                    label="CPT Code"
                    rules={{ required: "CPT Code is required." }}
                  />
                </Grid>

                {woundType === "Diabetic Ulcer" && (
                  <>
                    <Grid item xs={12}>
                      <CustomDropdownField
                        name="eCode"
                        control={control}
                        options={eCodeOptions}
                        label="E Code"
                        rules={{ required: "E Code is required." }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomDropdownField
                        name="lCode"
                        control={control}
                        options={lCodeOptions}
                        label="L Code"
                        rules={{ required: "L Code is required." }}
                      />
                    </Grid>
                  </>
                )}

                {woundType === "Venous Ulcer" && (
                  <>
                    <Grid item xs={12}>
                      <CustomDropdownField
                        name="iCode"
                        control={control}
                        options={iCodeOptions}
                        label="I Code"
                        rules={{ required: "I Code is required." }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomDropdownField
                        name="lCode"
                        control={control}
                        options={lCodeOptions}
                        label="L Code"
                        rules={{ required: "L Code is required." }}
                      />
                    </Grid>
                  </>
                )}

                {(woundType === "Pressure Ulcer" ||
                  woundType === "Chronic Wound") && (
                  <Grid item xs={12}>
                    <CustomDropdownField
                      name="lCode"
                      control={control}
                      options={lCodeOptions}
                      label="L Code"
                      rules={{ required: "L Code is required." }}
                    />
                  </Grid>
                )}

                {woundType === "Other" && (
                  <Grid item xs={12}>
                    <CustomTextField
                      controllerName="otherDxCodes"
                      control={control}
                      label="Specify Other Dx Code(s) / ICD-10"
                      rules={{ required: "Other is required." }}
                      multiline
                      rows={4}
                      maxRows={4}
                    />
                    <p>Separate codes with comma(,)</p>
                  </Grid>
                )}

                <Grid item xs={12} md={3}>
                  <CustomTextField
                    controllerName="length"
                    control={control}
                    label="Length"
                    rules={{ required: "Length is required." }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomTextField
                    controllerName="width"
                    control={control}
                    label="Width"
                    rules={{ required: "Width is required." }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomTextField
                    controllerName="depth"
                    control={control}
                    label="Depth"
                    rules={{ required: "Depth is required." }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomTextField
                    controllerName="totalSize"
                    control={control}
                    label="Total Size"
                    rules={{ required: "Total Size is required." }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              justifyContent="flex-end"
              sx={{flexWrap: "wrap" }}
              spacing={1}
              p={2}
            >
              <Grid item xs={12} sm="auto">
                <Button type="submit" startIcon={<SaveAlt />} fullWidth>
                  Add to IVR
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="error" startIcon={<Cancel />} onClick={onClose} fullWidth>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <CustomAlert
        open={showErrorAlert}
        onClose={() => setShowErrorAlert(false)}
        severity="info"
        message="Feature currently under development. Stay tuned for updates!"
      />
    </>
  );
};

export default IVRFormWoundInfoPopup;
