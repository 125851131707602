import { useForm } from "react-hook-form";
import {
  selectIsTokenAvailable,
  setSelectedModule,
  useAuthSlice,
} from "../../../redux/slice/authSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  DocumentItemInfo,
  getProduct,
  resetCreateProductSlice,
  useProductReducer,
} from "../../../redux/slice/product/createProductSlice";
import React, { useCallback, useEffect, useLayoutEffect } from "react";
import OptionsPopup, {
  OptionPopupObjectProps,
} from "../../common/OptionsPopup";
import moment from "moment";
import {
  SingleAlertInfo,
  setSingleAlertObj,
} from "../../../redux/slice/commonSlice";
import {
  PracticeProductAgreement,
  SearchPracticeProductAgreementRequest,
  fetchProductAgreementList,
  manufactureSignProductAgreement,
  practiceSignProductAgreement,
  previewAgreement,
  useCreateAgreementSlice,
} from "../../../redux/slice/productAgreement/createAgreementSlice";
import { Box, Dialog, Grid, Stack } from "@mui/material";
import Button from "../../common/Button";
import {
  Cancel,
  DriveFileRenameOutline,
  Visibility,
  VisibilityOutlined,
} from "@mui/icons-material";
import CustomNametField from "../../common/CustomNametField";
import Signature from "../../common/Signature";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomTextField from "../../common/CustomTextField";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { fullName } from "../../../utils/Utility";
import PDFViewer from "../../common/pdf/PDFViewer";
import { getPracticeProductById } from "../../../redux/slice/practiceSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import SignaturePad, {
  SignatureData,
} from "../../common/signature/SignaturePad";
import { AGREEMENT_STATUS } from "../../../constants/applicationConstants";

type AgreementContent = {
  practiceSideName: string;
  practiceSideSignature: string;
  manufacturerSideName: string;
  manufacturerSideSignature: string;
};

type SignProductAgreement = {
  product: string;
  practice: string;
  effectiveDate: string;
  extendedPrice: string;
  practiceProductId: number | null;
  agreementStatus: string;
  practiceSignedDate: string;
  manufacturerSignedDate: string;
  agreementContent: AgreementContent;
  documents: DocumentItemInfo[];
};

const initialSignProductAgreement: SignProductAgreement = {
  product: "",
  practice: "",
  effectiveDate: "",
  extendedPrice: "",
  practiceProductId: null,
  agreementStatus: "",
  practiceSignedDate: "",
  manufacturerSignedDate: "",
  agreementContent: {
    practiceSideName: "",
    practiceSideSignature: "",
    manufacturerSideName: "",
    manufacturerSideSignature: "",
  },
  documents: [],
};

interface ProductAgreementSignProps {}

const ProductAgreementSign: React.FC<ProductAgreementSignProps> = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useAuthSlice();
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    unregister,
  } = useForm<SignProductAgreement>({
    defaultValues: initialSignProductAgreement,
  });
  const [errorIdentifiers, setErrorIdentifiers] = React.useState<string[]>([]);
  const [showOptionPopup, setShowOptionsPopup] =
    React.useState<OptionPopupObjectProps>({ message: "", show: false });
  const [showPDF, setShowPDF] = React.useState(false);
  const [productAgreement, setProductAgreement] =
    React.useState<PracticeProductAgreement>();
  const { document } = useCreateAgreementSlice();
  const [isPreviewClicked, setIsPreviewClicked] =  React.useState(0);

  const canPracticeSignAgreement = useSelector((state: RootState) =>
    selectIsTokenAvailable("canPracticeSignAgreement")(state)
  );
  const canManufacturerSignAgreement = useSelector((state: RootState) =>
    selectIsTokenAvailable("canManufacturerSignAgreement")(state)
  );

  useEffect(() => {
    dispatch(setSelectedModule("Agreements"));
    if (!(canManufacturerSignAgreement || canPracticeSignAgreement)) {
      let errorObj: SingleAlertInfo = {
        message: "You don't have autherized to this action.",
        alertType: "error",
      };
      dispatch(setSingleAlertObj(errorObj));
      navigate("/");
    }

    const queryParams = new URLSearchParams(location.search);
    const practiceProductId = queryParams.get("practiceProductId");

    dispatch(getPracticeProductById(practiceProductId)).then((res) => {
      if (res.payload) {
        const productAgreementObject = res.payload;

        if (((canPracticeSignAgreement && productAgreementObject?.agreementStatus !== AGREEMENT_STATUS.INITIATED)
          || (canManufacturerSignAgreement && productAgreementObject?.agreementStatus !== AGREEMENT_STATUS.PRACTICE_SIGNED))) {
          let errorObj: SingleAlertInfo = {
            message: "You don't have autherized to this action.",
            alertType: "error",
          };
          dispatch(setSingleAlertObj(errorObj));
          navigate("/");
        }

        setProductAgreement(productAgreementObject);
        setValue("product", productAgreementObject.productName);
        setValue("practice", productAgreementObject.practiceName);
        setValue("effectiveDate", productAgreementObject.effectiveDate);
        setValue("extendedPrice", productAgreementObject.extendedPrice);
        setValue("practiceProductId", productAgreementObject.practiceProductId);
      }
    });
    const date = new Date();
    if (canManufacturerSignAgreement) {
      setValue("manufacturerSignedDate", moment(date).format("MM/DD/YYYY"));
    } else if (canPracticeSignAgreement) {
      setValue("practiceSignedDate", moment(date).format("MM/DD/YYYY"));
    }
  }, []);

  const handleDropzoneChange = async (uploadedFiles: File, fieldName: any) => {
    let base64File = "";
    let fileName: string = "";
    // if (uploadedFiles) {
    //   base64File = String(await convertBase64(uploadedFiles));
    //   fileName = uploadedFiles.name;
    // }
    // setValue(fieldName, base64File);

    let existingdocuments: DocumentItemInfo[] = getValues("documents");

    let docObj: DocumentItemInfo = {
      documentId: 0,
      documentName: "",
      type: "",
      fileType: "",
      content: "",
    };

    if (existingdocuments) {
      docObj = existingdocuments[0];
      if (fieldName === "productLogo" && docObj?.type === "PRODUCTLOGO") {
        docObj.documentName = fileName;
        docObj.fileType = "png";
      } else {
        docObj.type = "PRODUCTLOGO";
        docObj.documentName = fileName;
        docObj.fileType = "png";

        existingdocuments.push(docObj);
      }
    } else {
      existingdocuments = [];
      docObj.type = "PRODUCTLOGO";
      docObj.documentName = fileName;
      docObj.fileType = "png";

      existingdocuments.push(docObj);
    }

    setValue("documents", existingdocuments);
  };

  const onPreviewClick = async () => {
    if (productAgreement) {
      const actionResult = await dispatch(
        previewAgreement(productAgreement?.practiceProductId)
      );
      const documentData = actionResult.payload;
      if (documentData && documentData.content) {
        setShowPDF(true);
      }
    }
    setIsPreviewClicked(isPreviewClicked + 1);
  };

  const handleSignatureChange = (signatureData: SignatureData) => {
    if (canManufacturerSignAgreement) {
      setValue(
        "agreementContent.manufacturerSideSignature",
        signatureData.signature
      );
      setValue(
        "agreementContent.manufacturerSideName",
        signatureData.typedText
      );
    } else if (canPracticeSignAgreement) {
      setValue(
        "agreementContent.practiceSideSignature",
        signatureData.signature
      );
      setValue("agreementContent.practiceSideName", signatureData.typedText);
    }
    setErrorIdentifiers((prevValues) => {
      prevValues = prevValues.filter((value) => value !== "signature");
      return prevValues;
    });
  };

  const onSubmit = async (obj: any) => {
    let {
      product,
      practice,
      effectiveDate,
      extendedPrice,
      documents,
      ...sideAgreementObj
    } = obj;
    if (isPreviewClicked <= 0 ) {
      let previewAgreementAlertObj: SingleAlertInfo = {
        message: `Please "Preview" the agreement to continue`,
        alertType: "error",
      };
      dispatch(setSingleAlertObj(previewAgreementAlertObj));
    }
    else if (
      sideAgreementObj?.agreementContent?.practiceSideSignature === "" &&
      sideAgreementObj?.agreementContent?.manufacturerSideSignature === ""
    ) {
      setErrorIdentifiers((prevValues) => {
        const newValues = [...prevValues];
        newValues.push("signature");
        return newValues;
      });
    } else {
      setShowOptionsPopup({
        message: "Do you really want to sign the agreement? ",
        show: true,
        variant: "confirm",
        buttons: [
          {
            name: "Confirm",
            color: "primary",
            onClick: () => signSideAgreement(sideAgreementObj),
          },
          { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
        ],
      });
    }
  };

  const loadAgreementListAfterSign = () => {
    let successObj: SingleAlertInfo = {
      message: `Onboarding Agreement has been signed`,
      alertType: "success",
    };
    dispatch(setSingleAlertObj(successObj));
    navigate(-1);
  };

  const signSideAgreement = async (sideAgreementObj: any) => {
    if (canManufacturerSignAgreement) {
      sideAgreementObj.agreementStatus = "EXECUTED";
      const response = await dispatch(
        manufactureSignProductAgreement(sideAgreementObj)
      );
      if (response?.payload?.data === "SUCCESS") {
        loadAgreementListAfterSign();
      }
    } else if (canPracticeSignAgreement) {
      sideAgreementObj.agreementStatus = "INITIATED";
      const response = await dispatch(
        practiceSignProductAgreement(sideAgreementObj)
      );
      if (response?.payload?.data === "SUCCESS") {
        loadAgreementListAfterSign();
      }
    }
    cancelOptionPopup();
  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  useEffect(() => () => {}, []);

  let signatureFieldName;
  let name;
  if (canManufacturerSignAgreement) {
    signatureFieldName = "agreementContent.manufacturerSideSignature";
    name = "agreementContent.manufacturerSideName";
  } else if (canPracticeSignAgreement) {
    signatureFieldName = "agreementContent.practiceSideSignature";
    name = "agreementContent.practiceSideName";
  } else {
    signatureFieldName = "signature";
    name = "name";
  }

  const signaturePadValidations = {
    name: {
      required: "Name is required.",
    },
    signature: {
      required: "Signature is required.",
    },
  };

  return (
    <>
      {showOptionPopup.show && (
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      )}
      {showPDF && <PDFViewer
        isOpen={showPDF}
        onClose={() => setShowPDF(false)}
        title={productAgreement?.productName + " OnBoarding Agreement"}
        base64String={document?.content || ""}
        fileName={productAgreement?.productName + "_OnBoarding_Agreement"}
      />}
      <Box m="20px">
        <Header
          title="Manufacturer Onboarding Agreement"
          subtitle='Please "Preview" the agreement to view the full agreement before "Sign" the agreement'
        />
        <Box m="20px">
          <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    controllerName="product"
                    control={control}
                    label="Product"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    controllerName="practice"
                    control={control}
                    label="Practice"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}>
                  <CustomDatePicker
                    controllerName="effectiveDate"
                    control={control}
                    label="Effective Date"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomCurrencyField
                    controllerName="extendedPrice"
                    control={control}
                    label="Extended Price"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12}>
                  <div style={{ fontSize: "medium" }}>Signature:</div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <SignaturePad
                    isError={errorIdentifiers?.includes("signature")}
                    onSignatureChange={handleSignatureChange}
                    name={signatureFieldName}
                    textName={name}
                    control={control}
                    validationRules={signaturePadValidations}
                    setSignOutside={
                      userInfo &&
                      fullName(
                        null,
                        userInfo.firstName,
                        userInfo.middleName,
                        userInfo.lastName
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" spacing={2} mt="20px">
                <Grid item>
                  <Button
                    color="info"
                    onClick={onPreviewClick}
                    startIcon={<VisibilityOutlined />}
                    fullWidth
                  >
                    Preview
                  </Button>
                </Grid>
                { ((canPracticeSignAgreement && productAgreement?.agreementStatus === AGREEMENT_STATUS.INITIATED)
                    || (canManufacturerSignAgreement && productAgreement?.agreementStatus === AGREEMENT_STATUS.PRACTICE_SIGNED)) ?
                <Grid item>
                  <Button
                    style={{ marginLeft: "10px" }}
                    type="submit"
                    startIcon={<DriveFileRenameOutline />}
                    fullWidth
                  >
                    Sign
                  </Button>
                </Grid> : ""
                }
                <Grid item>
                  <Button
                    style={{ marginLeft: "10px" }}
                    color="error"
                    onClick={() => navigate(-1)}
                    startIcon={<Cancel />}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default ProductAgreementSign;
