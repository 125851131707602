import React, { useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "../common/Button";
import Card from "../common/Card";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext, tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import { LoginInfo, forgotPasswordReset } from "../../redux/slice/authSlice";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../redux/slice/redux-hooks";

const ForgotPassword: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorMessageContent, setErrorMessageContent] = useState("");
  const [open, setOpen] = useState(false);
  const [successopen, setSuccessOpen] = useState(false);
  const colorMode = React.useContext(ColorModeContext);
  const dispatch = useAppDispatch();
  const { control, handleSubmit, reset } = useForm<LoginInfo>();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  const handleEmailSend = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email: string = data.get("email") as string;

    // Check if the email is empty or only contains whitespace
    if (!email || email.trim() === "") {
      setErrorMessageContent("Email address cannot be empty.");
      setOpen(true);
      return;
    }

    // Validate the email format
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email)) {
      setErrorMessageContent("Invalid email address.");
      setOpen(true);
      return;
    }

    try {
      // Attempt to send the password reset request
      const response = await dispatch(forgotPasswordReset(email));
      // Check the response status and display the appropriate message
      if (response.payload.message === "SUCCESS") {
        setErrorMessageContent("The password reset email was sent successfully.");
        setSuccessOpen(true);
      } else {
        setErrorMessageContent(
          "Failed to send password reset email. Please try again."
        );
        setOpen(true);
      }
    } catch (error) {
      // Handle any unexpected errors
      setErrorMessageContent("An error occurred. Please try again later.");
      setOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          borderBottom: 1,
          borderColor:
            theme.palette.mode === "dark" ? "primary.dark" : "secondary.main",
        }}
      >
        <Grid
          container
          spacing={2}
          p={2}
          display="flex"
          style={{ backgroundColor: colors.grey[800] }}
        >
          <Grid item sm={4}></Grid>
          <Grid item sm={4} style={{ textAlign: "center" }}>
            <img
              alt="logo"
              src={require("../../assets/V3_logo-gradient.png")}
            />
          </Grid>
          <Grid item sm={4} style={{ textAlign: "end" }}>
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <LightModeOutlined />
              ) : (
                <DarkModeOutlined />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Container component="main" maxWidth="sm">
          <Card>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1 }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography variant="h3">
                <b>Forgot Password</b>
              </Typography>
              <Box sx={{ textAlign: "center", mt: 2, color: colors.grey[500] }}>
                <Typography variant="h6">
                  Please enter the email address associated with your account
                  and We will email you a link to reset your password.
                </Typography>
              </Box>
              <Box
                component="form"
                onSubmit={handleEmailSend}
                noValidate
                sx={{ mt: 1 }}
              >
                {errorMessageContent == null || errorMessageContent === "" ? (
                  ""
                ) : (
                  <>
                    <Box mb={2}>
                      {open && (
                        <Alert
                          variant="filled"
                          severity="error"
                          onClose={handleClose}
                        >
                          {errorMessageContent}
                        </Alert>
                      )}
                    </Box>
                    <Box mb={2}>
                      {successopen && (
                        <Alert
                          variant="filled"
                          severity="success"
                          onClose={handleSuccessClose}
                        >
                          {errorMessageContent}
                        </Alert>
                      )}
                    </Box>
                  </>
                )}
                <CustomTextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  controllerName="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  control={control}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  fullWidth
                  size="large"
                  style={{
                    borderRadius: 50,
                    padding: "15px",
                    marginTop: "20px",
                  }}
                >
                  Send Reset Link
                </Button>
                <Grid container>
                  <Grid item xs sx={{ textAlign: "center", mt: 2 }}>
                    <Typography
                      variant="h6"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/signIn")}
                    >
                      Back to Sign In
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Container>
      </Grid>
    </>
  );
};

export default ForgotPassword;
