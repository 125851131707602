import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import { Container, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import {
  CallOutlined,
  EmailOutlined,
  HomeOutlined,
  LanguageOutlined,
} from "@mui/icons-material";
import Card from "./Card";

export default function Success() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      <Container
        component="main"
        maxWidth="md"
        style={{
          paddingTop: "40px",
          paddingBottom: "50px",
          textAlign: "center",
        }}
      >
        <Typography variant="h3" fontWeight="bold" sx={{ m: "0 0 5px 0" }}>
          Successfully Submitted!
        </Typography>
        <Box color={colors.grey[500]}>
          Your Account Registration is currently being reviewed. Upon approval, you will receive your temporary login credentials by email.
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p="20px 0px"
        >
          <img
            alt="logo"
            width="100px"
            height="100px"
            src={require("../../assets/success.png")}
          />
        </Box>
        <Container maxWidth="sm">
          <Box justifyContent="center" alignItems="center">
            <Card>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12} sx={{ textAlign: "left" }}>
                  <Typography sx={{ fontSize: 28, fontWeight: "bold" }}>
                    Nicole Cochrane
                  </Typography>
                  <Typography sx={{ fontSize: 20 }}>
                    Onboarding Specialist
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <IconButton sx={{ background: colors.grey[700], mr: 1 }}>
                      <CallOutlined />
                    </IconButton>
                    <Typography sx={{ fontSize: 18 }}>
                      (520) 401 - 3600
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <IconButton sx={{ background: colors.grey[700], mr: 1 }}>
                      <LanguageOutlined />
                    </IconButton>
                    <Typography sx={{ fontSize: 18 }}>
                      www.v3biomedical.com
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <IconButton sx={{ background: colors.grey[700], mr: 1 }}>
                      <EmailOutlined />
                    </IconButton>
                    <Typography sx={{ fontSize: 18 }}>
                      nicole@v3biomedical.com
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <img
                      alt="Onboarding Specialist Contact Information"
                      src={require("../../assets/nicoleImage.png")}
                      style={{
                        maxWidth: "200px",
                        minWidth: "200px",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Container>
        <Box mt={2}>
          <Button
            color="error"
            startIcon={<HomeOutlined />}
            onClick={() => navigate("/")}
          >
            Go to Home
          </Button>
        </Box>
      </Container>
      <Box
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, borderTop: 5 }}
      >
        <Footer />
      </Box>
    </Grid>
  );
}
