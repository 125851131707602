import React from "react";
import { AlertTitle, Snackbar, SnackbarProps, Typography } from "@mui/material";
import { Alert, AlertProps } from "@mui/material";

interface CustomSnackbarOrigin {
  vertical: "top" | "bottom";
  horizontal: "left" | "right" | "center";
}

interface CustomAlertProps {
  open: boolean;
  onClose: () => void;
  severity: "error" | "success" | "warning" | "info";
  message: string;
  variant?: "filled" | "outlined";
  SnackbarProps?: SnackbarProps;
  autoHideDuration?: number;
  alertTitle?: string;
  anchorOrigin?: CustomSnackbarOrigin;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  open,
  onClose,
  severity,
  message,
  SnackbarProps,
  variant,
  autoHideDuration,
  alertTitle,
  anchorOrigin,
}) => {
  let alertTitleValue = alertTitle;
  if (alertTitle == null) {
    alertTitleValue = severity.charAt(0).toUpperCase() + severity.slice(1);
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration || 5000}
      onClose={onClose}
      anchorOrigin={anchorOrigin|| { vertical: "top", horizontal: "center" }}
      {...SnackbarProps}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: "100%", maxHeight : '300px'}}
        variant={variant || "filled"}
      >
        <AlertTitle>
          <Typography variant="subtitle1" fontWeight="bold">
            {alertTitleValue}
          </Typography>
        </AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
