import React, { useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Divider, Grid } from "@mui/material";
import Button from "../../common/Button";
import { Cancel } from "@mui/icons-material";
import CustomTable from "../../common/CustomTable";
import { Column, useCommonReducer } from "../../../redux/slice/commonSlice";
import { getProductPricingHistory, useProductReducer } from "../../../redux/slice/product/createProductSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomCurrencyField from "../../common/CustomCurrencyField";

interface ProductItemPriceHistoryProps {
    open: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ProductItemPriceHistoryPopup: React.FC<ProductItemPriceHistoryProps> = ({
    open,
    onClose
}) => {
    const dispatch = useAppDispatch();

    const { productPricingHistory, productObject } = useProductReducer();
    const { macInfoList } = useCommonReducer();

    const columnsPriceItem: Column[] = useMemo(() => {
        return [
            { id: "effectiveDate", label: "Effective Date", minWidth: 100 },
            { id: "inactiveDate", label: "Inactive Date", minWidth: 100 },
            { id: "priceType", label: "File Type", minWidth: 100 },
            { id: "listPrice", label: "List Price", minWidth: 100, align: "right" },
            { id: "allowableUnitPrice", label: "Allowable Price", minWidth: 100, align: "right" },
            { id: "customPrice", label: "Custom Price", minWidth: 100, align: "right" },
        ]
    }, [open]);

    useEffect(() => {
        if (open) {
            dispatch(getProductPricingHistory({productId: productObject.productId}))
        }
    }, [open]);

    const productPricingHistoryArry: any[] = useMemo(() => {
        let modifiedArray: any[] = [];
    //@ts-ignore
    productPricingHistory?.forEach(obj => {

        let modifiedObj = {
            ...obj,
            listPrice: <CustomCurrencyField externalValue={obj?.listPrice} displayType="text" />,
            customPrice: <CustomCurrencyField externalValue={obj?.customPrice} displayType="text" />,
            allowableUnitPrice: <CustomCurrencyField externalValue={obj?.allowableUnitPrice} displayType="text" />      
          }
      if (modifiedObj.priceType === "MAC") {
        let macName = macInfoList?.find(option => option.macId === obj.macId)?.macName;
        modifiedObj["priceType"] = "Local Fee Schedule" + (macName ? " - " + macName : "");
      } else if (modifiedObj.priceType === "NATIONAL") {
        modifiedObj["priceType"] = "National";
      }
      modifiedArray.push(modifiedObj);
    });
    return modifiedArray;
    }, [productPricingHistory]);

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                maxWidth={"lg"}
            >
                <DialogTitle>Pricing History</DialogTitle>
                <Divider orientation="horizontal" variant="fullWidth" flexItem />
                <DialogContent>
                    <Box p={1}>
                        <Grid container spacing={2}>
                            <CustomTable
                                columns={columnsPriceItem}
                                rows={productPricingHistoryArry}
                                disablePagination={true}
                                borderBox={true}
                            />
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={onClose} startIcon={<Cancel />}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProductItemPriceHistoryPopup;
