import { Navigate, useLocation } from "react-router-dom";
import { PropsWithChildren } from "react";
import { useAuthSlice } from "../../redux/slice/authSlice";
import { getDataFromCookies } from "../../utils/StorageUtils";

type Props = {
  fallback?: string;
};

const PublicRoute = ({ children, fallback }: PropsWithChildren<any>) => {
  const { status, isValid } = useAuthSlice();
  const location = useLocation();
  
  if ((status === "success" ) && isValid) {
    if (fallback) {
      return children;
    } else {
      return (
        <main className="content">
          <Navigate to= "/" replace state={{ from: location }} />
        </main>
      );
    }
  }

  return children;
};

export default PublicRoute;
