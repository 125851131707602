import React, { useCallback, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import { ProductCommissionAgency, Option, AgencyOption } from "../../../redux/slice/agency/AgencySlice";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { ProductInfo, fetchProductsByUserRoleData, useProductListReducer } from "../../../redux/slice/product/productListSlice";
import { sortNames } from "../../../utils/Utility";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { Cancel } from "@mui/icons-material";

interface Row {
  productName: string | undefined;
  effectiveDate: string | undefined;
  inactiveDate: string | undefined;
  commissionRate: number | string | undefined,
}

interface AddProductCommissionModalProps {
  open: boolean;
  productOptions: Option[];
  associateAgencyOptions: AgencyOption[];
  isCommTypeOverride: boolean;
  editCommission: boolean;
  defaultValue: Row,
  handleClose: () => void;
  submitCommission: (commission: ProductCommissionAgency) => void;
}

const AddProductCommissionModal: React.FC<AddProductCommissionModalProps> = ({
  open,
  productOptions,
  associateAgencyOptions,
  isCommTypeOverride,
  editCommission,
  defaultValue,
  handleClose,
  submitCommission
}) => {
  const { control, handleSubmit, reset } = useForm<ProductCommissionAgency>();
  const onCloseAction = ()=> {
    handleClose();
    reset();
  };
  const defaultValues = useCallback(() => {
    if (defaultValue) {
      console.log(defaultValue);
      reset(defaultValue);
    } else {
      // reset();
    }
  }, [defaultValue, reset]);

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  return (
    // <Dialog open={open} onClose={onCloseAction}>
    <Dialog open={open} >
      <DialogTitle>Add Agency Product Commission</DialogTitle>
      <form onSubmit={handleSubmit(submitCommission)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: '5px' }}>
              <CustomDropdownField
                name="productId"
                control={control}
                options={productOptions}
                label="Product"
                rules={{
                  required: "Product is required.",
                  validate: {
                    notZero: (value: number) =>
                      value !== 0 || "Product is required.",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                control={control}
                controllerName="commissionRate"
                label="Commission %"
                rules={{
                  required: "Commission Rate is required.",
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Invalid Commission Rate.",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                control={control}
                controllerName="commissionPayTerms"
                label="Commission Pay Terms "
                rules={{
                  required: "Commission Pay Terms is required.",
                }}
              />
            </Grid>
            {isCommTypeOverride && <Grid item xs={12} style={{ marginTop: '5px' }}>
              <CustomDropdownField
                name="associateAgencyId"
                control={control}
                options={associateAgencyOptions}
                label="Associate Agency"
                rules={{ required: "Associate Agency is required." }}
              />
            </Grid>}
            <Grid item xs={12}>
              <CustomDatePicker
                control={control}
                controllerName="effectiveDate"
                label="Effective Date"
                rules={{ required: "Effective Date is required." }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomDatePicker
                control={control}
                controllerName="inactiveDate"
                label="Inactive Date"
                // rules={{ required: "Inactive Date is required." }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit">{editCommission ? "Update Commission" :  "Save Commission"} </Button>
          <Button color="error" startIcon={<Cancel />} onClick={() => onCloseAction()}>
              Cancel
            </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddProductCommissionModal;
