import React, { useCallback, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../common/Button";
import { Box, Grid } from "@mui/material";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import { AddCircleOutline, Cancel } from "@mui/icons-material";

export type AuthorizedSignerInfo = {
  index?: number;
  fullName: string;
  email: string;
}

interface AuthorizedSignerProps {
  open: boolean;
  onClose: () => void;
  addAuthorizedSigner: (authorizedSigner: AuthorizedSignerInfo) => void;
  onChange?: (name: string, value: string) => void;
  authorizedSignerData?: AuthorizedSignerInfo;
}

export const authorizedSignerInitialState: any = {
  fullName: "",
  email: "",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AuthorizedSignerPopup: React.FC<AuthorizedSignerProps> = ({
  open,
  onClose,
  addAuthorizedSigner,
  onChange,
  authorizedSignerData,
}) => {

  const [authorizedSigner, setAuthorizedSigner] = React.useState({
    fullName: "",
    email: "",
  });
  const { control, handleSubmit, reset } = useForm<AuthorizedSignerInfo>();

  useEffect(()=>{
    if (authorizedSignerData)
      reset(authorizedSignerData);

    return () => {
      reset(authorizedSignerInitialState);
    };
  },[])



  const onSubmit = (data: AuthorizedSignerInfo) => {
    addAuthorizedSigner(data);
    reset(authorizedSignerInitialState);
    onClose();
  };

  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} keepMounted
        onClose={onClose} aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Authorized Signer</DialogTitle>
          <DialogContent>
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <CustomTextField control={control} controllerName="fullName" label="Full Name"
                    rules={{
                      required: "Full Name is required.",
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CustomTextField control={control} controllerName="email" label="Email"
                    rules={{
                      required: "Email is required.",
                      pattern: {
                        value: /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|n\/a|N\/A)$/i,
                        message: "Invalid email.",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="flex-end" sx={{ mt: 1, flexWrap: "wrap" }} spacing={1} >
              <Grid item xs={12} sm="auto">
                <Button type="submit" startIcon={<AddCircleOutline />} fullWidth >
                  {authorizedSignerData? "Update": "Add"} Authorized Signer
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="error" onClick={onClose} startIcon={<Cancel />} fullWidth >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default AuthorizedSignerPopup;