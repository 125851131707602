import React, { useCallback, useEffect } from "react";
import Header from "../../common/Header";
import { Grid, Stack } from "@mui/material";
import Button from "../../common/Button";
import { Save } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import {
  fetchActiveBillers,
  usePracticeApproveSlice,
} from "../../../redux/slice/practice/practiceApproveSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  PracticeBillerInfo,
  fetchPracticeBillerData,
  updatePracticeBillerById,
  usePracticeBillerSlice,
} from "../../../redux/slice/practice/practiceBillerSlice";
import { usePracticeEditSlice } from "../../../redux/slice/practice/practiceEditSlice";
import {
  MainErrorInfo,
  SingleAlertInfo,
  setMainErrorObj,
  setSingleAlertObj,
} from "../../../redux/slice/commonSlice";
import OptionsPopup from "../../common/OptionsPopup";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { MSG_BILLER_NOT_FOUND, MSG_NOT_AN_ACTIVE_BILLER, MSG_PRACTICE_BILLER_NOT_FOUND } from "../../../constants/applicationConstants";
import { useNavigate } from "react-router-dom";

interface BillerInfoProps {
  contentChangedStatus: () => void;
}

const BillerInfoEditViewForm: React.FC<BillerInfoProps> = ({ contentChangedStatus }) => {
  const { control, handleSubmit, reset } = useForm<PracticeBillerInfo>();
  const { billerList } = usePracticeApproveSlice();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { practiceBillerInfo } = usePracticeBillerSlice();
  const [isBillerInfoVisible, setIsBillerInfoVisible] = React.useState(false);
  const billerOptions: any[] = [{ value: "", label: "Select" }];
  const { practiceEditInfo } = usePracticeEditSlice();
  const [optionPopupShow, setOptionPopupShow] = React.useState<{
    message: string;
    show: boolean;
  }>({ message: "", show: false });

  const defaultValues = useCallback(() => {
    if (practiceBillerInfo) {
      reset(practiceBillerInfo);
    }
  }, [practiceBillerInfo, reset]);

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  useEffect(() => {
    dispatch(fetchActiveBillers());
    if (practiceEditInfo) {
      dispatch(fetchPracticeBillerData(practiceEditInfo.practiceId));
    }
  }, [dispatch, practiceEditInfo]);

  billerList?.map((biller) => {
    billerOptions.push({ value: biller.billerId, label: biller.billerName });
  });

  const confirmOptionPopup = () => {
    handleSubmit(handleUpdateBiller)();
    setOptionPopupShow({ message: "", show: false });
  };

  const cancelOptionPopup = () => {
    setOptionPopupShow({ message: "", show: false });
  };

  const handleUpdateBiller = async (practiceBillerData: any) => {
    let errorObj: MainErrorInfo = {
      title: "",
    };

    const data = {
      practiceBillerId: practiceBillerData.practiceBillerId,
      billerId: practiceBillerData.biller.billerId,
    };
    try {
      const response = await dispatch(updatePracticeBillerById(data));
      if (response.payload && response.payload.message === "SUCCESS") {
        setIsBillerInfoVisible(false);
        let successObj: SingleAlertInfo = {
          message: "Practice Biller Updated Successfully.",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(successObj));
        contentChangedStatus();
      } else {
        let errorSentence = "";
        if (response.payload && response.payload.errors.length > 0) {
          response.payload.errors.forEach((error: any, index: any) => {
            if (error.field) {
              errorSentence += `${error.field}: ${error.errorMessage}\n`;
            } else {
              errorSentence += `${error.reason}\n`;
            }
          });
        } else if (response.payload && response.payload.message) {
          switch (response.payload.message) {
            case MSG_PRACTICE_BILLER_NOT_FOUND:
              errorSentence = "No Practice Biller Found. Please check their status and try again.";
              break;
            case MSG_NOT_AN_ACTIVE_BILLER:
              errorSentence = "Given Practice Biller is not active. Please check their status and try again.";
              break;
            case MSG_BILLER_NOT_FOUND:
              errorSentence = "No Biller Found.";
              break;
            default:
              errorSentence = "An unknown error occurred.";
          }
        }
        else {
          errorSentence = "An unknown error occurred.";
        }

        errorObj.title = "Updating Practice Biller Failed!";
        errorObj.errorMessageStream = errorSentence;
        dispatch(setMainErrorObj(errorObj));
        navigate("/practice_list");
      }
    } catch (error) {
      errorObj.title = "Error Updating practice biller";
      errorObj.errorMessageStream = "" + error;
      dispatch(setMainErrorObj(errorObj));
    }
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    if (value !== "" && value !== undefined) {
      if (value === practiceBillerInfo?.biller?.billerId) {
        setIsBillerInfoVisible(false);
      } else {
        setIsBillerInfoVisible(true);
      }
    } else {
      setIsBillerInfoVisible(false);
    }
  };

  return (
    <>
      <div style={{ height: "10px" }}></div>
      <Header title="Biller Information" subtitle="" />
      <div style={{ height: "20px" }}></div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CustomDropdownField
            control={control}
            name={`biller.${"billerId"}`}
            options={billerOptions}
            label="Practice Biller"
            onChangeSelect={handleChange}
            disableSearch
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <form onSubmit={handleSubmit(handleUpdateBiller)}>
            <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
              <Button
                onClick={() => {
                  setOptionPopupShow({
                    message:
                      "Do you really want to update practice biller details? ",
                    show: true,
                  });
                }}
                fullWidth
                disabled={!isBillerInfoVisible}
                startIcon={<Save />}
              >
                Save
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
      <OptionsPopup
        open={optionPopupShow.show}
        onClose={() => setOptionPopupShow({ message: "", show: false })}
        variant="confirm"
        message={<>{optionPopupShow.message}</>}
        buttons={[
          { name: "Confirm", color: "primary", onClick: confirmOptionPopup },
          { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
        ]}
      />
    </>
  );
};

export default BillerInfoEditViewForm;
