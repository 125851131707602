import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { useAppSelector } from "../redux-hooks";
import {
  getIVRNoteById,
  removeIVRDocumentById,
  uploadIVRDocumentData,
  uploadIVRNotesData,
  getIVRDocuments,
  getIVRDocumentByDocumentId
} from "../../../service/IVRService";

export type IVRDocumentInfo = {
  ivrId: any;
  documentId?: number;
  documentName: string;
  content: string;
  documentType?: string;
  type?: string;
  fileType: string;
  auditTimestamp? : string;
};

export type IVRNotes = {
  noteType: string;
  content?: string;
  attachment: string;
  documentName: string;
  fileType: string;
  xrefTable: string;
  xrefId: string;
  auditUserName?: string,
  auditTimestamp? : string,
  comments?: string, 
};

export type IVRNoteDetail = {
  noteId: number;
  xrefTable: string;
  xrefId: number;
  noteType: string;
  content?: string;
  attachementId: number;
  documentName: string;
  fileType: string;  
  auditUserName?: string,
  auditTimestamp? : string,
  comments?: string, 
};

type IVRDocumentState = {
  ivrDocumentInfo?: IVRDocumentInfo | null;
  ivrDocumentInfoList?: IVRDocumentInfo[];
  IVRNotes?:IVRNotes | null;
  IVRNotesList?:IVRNoteDetail[];
  status?: LoadingType;
  documentUploadInfo?: any;
  documentRemoveInfo?: any;
};

const initialState: IVRDocumentState = {
  ivrDocumentInfo: undefined,
  status: "idle",
};

export const uploadIVRDocument = createAsyncThunk(
  "uploadIVRDocument",
  async (data: any) => {
    const response = await uploadIVRDocumentData(data);
    return response.data;
  }
);

export const uploadIVRNotes = createAsyncThunk(
  "uploadIVRNotes",
  async (data: any) => {
    const response = await uploadIVRNotesData(data);
    return response.data;
  }
);

export const removeIVRDocument = createAsyncThunk(
  "removeIVRDocument",
  async (documentId: string) => {
    const response = await removeIVRDocumentById(documentId);
    return response.data;
  }
);

export const fetchIVRNotesById = createAsyncThunk("fetchIVRNotesById", async (xrefTable: number) => {
  const response = await getIVRNoteById(xrefTable, 'IVR');
  const v3Response = response.data;
  return v3Response;
});

export const fetchIVRDocuments = createAsyncThunk("fetchIVRDocuments", async (data: any) => {
  const response = await getIVRDocuments(data.ivrId, data.patientId);
  const v3Response = response.data;
  return v3Response;
});

export const fetchDocumentById = createAsyncThunk('fetchDocumentById',async( documentId: number ) => {
  const response = await getIVRDocumentByDocumentId(documentId);
  const v3Response = response.data;
  return v3Response.data;
});

const ivrDocumentSlice = createSlice({
  name: "ivrDocuments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadIVRDocument.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        uploadIVRDocument.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.status = "success";
          state.documentUploadInfo = action.payload.data;
        }
      )
      .addCase(uploadIVRDocument.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(uploadIVRNotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        uploadIVRNotes.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.status = "success";
        }
      )
      .addCase(uploadIVRNotes.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(removeIVRDocument.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        removeIVRDocument.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.status = "success";
          state.documentRemoveInfo = action.payload.data;
        }
      )
      .addCase(removeIVRDocument.rejected, (state, action) => {
        state.status = "error";
      }).addCase(fetchIVRNotesById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchIVRNotesById.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.status = "success";
          state.IVRNotesList = action.payload.data;
        }
      )
      .addCase(fetchIVRNotesById.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(fetchIVRDocuments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchIVRDocuments.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.status = "success";
          state.ivrDocumentInfoList = action.payload.data;
        }
      )
      .addCase(fetchIVRDocuments.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(fetchDocumentById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDocumentById.fulfilled,(state, action: PayloadAction<IVRDocumentInfo>) => {
          state.status = "success";
          state.ivrDocumentInfo = action.payload;
        }
      )
      .addCase(fetchDocumentById.rejected, (state, action) => {
        state.status = "error";
      })
      ;
  },
});

export const useIVRDocumentSlice = () =>
  useAppSelector((state) => state.ivrDocumentSlice);
export default ivrDocumentSlice.reducer;
