import * as React from "react";
import { InputAdornment, TextField, TextFieldProps, useTheme } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { Control, Controller } from "react-hook-form";
import { tokens } from "../../theme";

export type InputFieldProps = {
    color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
    size?: "small" | "medium";
    label?: any | "";
    variant?: "filled" | "outlined" | "standard" | undefined;
    name?: string,
    control?: Control<any, any>
    rules?: Object
    isDisabled?: boolean
    maxLength?: number
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, values?: any) => void;
    onBlur?: (event: React.FocusEventHandler<HTMLInputElement>) => void;
    controllerName?: string;
    isEmpty?: boolean;
    externalValue?: number | string;
    displayType?: "input" | "text" | undefined;
    helpText?: string;
    showErrorMinLength?: number;
} & TextFieldProps;

const CustomCurrencyField: React.FC<InputFieldProps> = ({
    size,
    color,
    label,
    variant,
    name,
    control,
    rules,
    isDisabled,
    maxLength,
    onChange,
    onBlur,
    controllerName,
    isEmpty,
    externalValue,
    displayType,
    helpText,
    showErrorMinLength,
    ...props
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isRequired = () => {
      if (rules && typeof rules === "object" && "required" in rules && label) {
        return true;
      }
      return false;
    };
  
    const getLabel = () => {
      if (isRequired()) {
        return (
          <span>
            {label}{" "}
            <span style={{ color: colors.success[200], fontSize: "18px" }}>
              *
            </span>
          </span>
        );
      }
      return label;
    };
    if (control) {
        return (
            <Controller
                name={controllerName || ""}
                control={control}
                rules={rules ? rules : {}}
                render={({ field: { ref, ...field }, fieldState }) => (
                    // @ts-ignore
                    <NumericFormat
                        customInput={TextField}
                        id={field.name}
                        {...field}
                        value={isEmpty ? '' : externalValue ? externalValue : field.value}
                        name={name ? name : controllerName}
                        label={getLabel() || ""}
                        displayType={displayType || "input"}
                        fullWidth
                        allowNegative={false}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        decimalScale={2}
                        onValueChange={(vals: any, sourceInfo: any) => { field.onChange(vals.value ? vals.floatValue : ""); onChange && onChange(sourceInfo.event, vals); }}
                        onChange={(event: any) => { }}
                        onBlur={(e: any) => { field.onBlur(); onBlur && onBlur(e); }}
                        size={size || "medium"}
                        color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
                        variant={variant || "outlined"}
                        InputLabelProps={{
                            shrink: isDisabled ? true : Boolean("" + field.value),
                            style: { marginLeft: isDisabled ? 0 : Boolean("" + field.value) ? 0 : 16 },
                        }}
                        inputProps={{
                            ...(maxLength ? { maxLength } : {})
                        }}
                        error={!!fieldState.error}
                        helperText={
                            fieldState.error ? ((showErrorMinLength && showErrorMinLength <= (fieldState.error.message ? fieldState.error.message.length : 0)) ? null : fieldState.error.message) : null
                        }
                        disabled={isDisabled}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        prefix={displayType === "text" ? "$ " : ""}
                        {...props}
                    />
                )}
            />
        );
    } else {
        return (
            // @ts-ignore
            <NumericFormat
                customInput={TextField}
                id={name}
                // @ts-ignore
                value={isEmpty ? '' : externalValue ? externalValue : ''}
                name={name ? name : controllerName}
                label={label || ""}
                displayType={displayType || "input"}
                fullWidth
                allowNegative={false}
                fixedDecimalScale={true}
                thousandSeparator={true}
                decimalScale={2}
                onValueChange={(vals: any, sourceInfo: any) => { onChange && onChange(sourceInfo.event, vals); }}
                onChange={(event: any) => { }}
                onBlur={(e: any) => { onBlur && onBlur(e); }}
                size={size || "medium"}
                color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
                variant={variant || "outlined"}
                InputLabelProps={{
                    shrink: isDisabled ? true : Boolean(externalValue),
                    style: { marginLeft: isDisabled ? 0 : Boolean(externalValue) ? 0 : 16 },
                }}
                inputProps={{
                    ...(maxLength ? { maxLength } : {})
                }}
                helperText={
                    helpText ? helpText : null
                }
                disabled={isDisabled}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                prefix={displayType === "text" ? "$" : ""}
                {...props}
            />
        );
    }
};

export default CustomCurrencyField;