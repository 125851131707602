import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CustomTextField from "../common/CustomTextField";
import Button from "../common/Button";
import { LockOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { tokens } from "../../theme";
import {
  LoginInfo,
  loginInfoInitialValues,
  logout,
  relogin,
  resetAuthState,
  resetReloginFetchStatus,
  useAuthSlice,
} from "../../redux/slice/authSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { useNavigate } from "react-router-dom";
import { GridCloseIcon } from "@mui/x-data-grid";

interface ReSignInModalProps {
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ReSignIn: React.FC<ReSignInModalProps> = ({ open, handleClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<LoginInfo>({
    defaultValues: loginInfoInitialValues,
  });

  const [countdown, setCountdown] = useState(30);
  const { reLoginStatus } = useAuthSlice();

  let interval: any;

  useEffect(() => {
    if (open && reLoginStatus === "success") {
      hidePopup();
      dispatch(resetReloginFetchStatus());
    }
  }, [open, reLoginStatus]);

  useEffect(() => {
    setCountdown(30);
    if (open) {
      interval = setInterval(() => {
        setCountdown((prevCounter) => {
          if (prevCounter <= 1) {
            cancelPopup();
            dispatch(resetAuthState());
            navigate("/signIn");
            clearInterval(interval);
            return prevCounter;
          }
          return prevCounter - 1;
        });
      }, 1000);
    }
  }, [open]);

  const hidePopup  = useCallback(() => {
    handleClose();
    clearInterval(interval);
  }, [open, interval]);

  const cancelPopup = useCallback(() => {
    handleClose();
    dispatch(resetAuthState());
    clearInterval(interval);
    navigate("/signIn");
  }, [open, interval]);

  const onSubmit = useCallback(
    async (data: LoginInfo) => {
      await dispatch(relogin(data)).unwrap();
    },
    [open]
  );

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={cancelPopup}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
        maxWidth="xs"
      >
        <IconButton
          aria-label="close"
          onClick={cancelPopup}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <GridCloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1 }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Resign in
              </Typography>
              <Grid container spacing={2} mt={2}>
                <Grid item md={12} xs={12}>
                  <CustomTextField
                    label="Username"
                    controllerName="userName"
                    control={control}
                    rules={{ required: "Username is required." }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CustomTextField
                    label="Password"
                    controllerName="password"
                    type="password"
                    control={control}
                    rules={{ required: "Password is required." }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    Auto close in {countdown} seconds
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="success"
              fullWidth
              size="large"
              style={{
                borderRadius: 50,
                padding: "15px",
                margin: "20px",
              }}
            >
              ReLogin
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ReSignIn;
