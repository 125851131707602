import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import CustomTable from "../../common/CustomTable";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import { Cancel, CloudDownloadOutlined } from "@mui/icons-material";
import { AgyPayout, invoiceInfo, RecordPayoutOverrideInfo } from "../../../redux/slice/invoice/InvoiceSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { priceFormatter } from "../../../utils/Utility";
import CustomDatePicker from "../../common/CustomDatePicker";
import dayjs from "dayjs";
import { RecordPayoutInfo, savePayoutInfo } from "../../../redux/slice/payment/paymentSlice";
import { setSingleAlertObj, SingleAlertInfo } from "../../../redux/slice/commonSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomCurrencyField from "../../common/CustomCurrencyField";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface Row {
  invoice: string;
  date: string;
  invAmount: string;
  paid: string;
  v3Comm: string;
  ovrAgencyComm: string;
  // remainingAmount: string;
}

const columns: Column[] = [
  { id: "invoice", label: "Invoice #", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 100 },
  { id: "invAmount", label: "Invoice Amount", minWidth: 100 },
  { id: "paid", label: "Invoice Paid Amount", minWidth: 100 },
  { id: "v3Comm", label: "V3 Commission", minWidth: 100 },
  { id: "ovrAgencyComm", label: "Override Agency Comm", minWidth: 100 },
  { id: "remainingAmount", label: "Remaining Amt", minWidth: 100 },
];

const RecordPayoutOverride = () => {
  const { control, handleSubmit, setValue, getValues } = useForm<RecordPayoutInfo>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ navigatedInvList, setNavigateInvList ] = useState<invoiceInfo[]>();
  const [agencyName, setAgencyName] = useState<string>("");
  const [overrideAgencyId, setOverrideAgencyId] = useState<number>(0);
  const [fullCommAmount, setFullCommAmount] = useState(0);

 
  useEffect(() => {
    const invList = location.state.selectedInvoices;
    setNavigateInvList(invList);

    if (invList && invList.length > 0) {
      setAgencyName(invList[0].agencyName);
      setOverrideAgencyId(invList[0].overrideAgencyId);
    }
  }, [location.state]);

  const rows: Row[] = useMemo(() => {
    const invList: Row[] = [];
    let totalCommAmount = 0;
    navigatedInvList?.forEach(invoice => {
      totalCommAmount = totalCommAmount + Number(invoice.ovrAgencyCommissionAmount);
      invList.push({
        invoice: invoice.mfgInvoiceNumber,
        date: invoice.mfgInvoiceDate,
        invAmount: priceFormatter(Number(invoice.invoiceAmount)),
        paid: priceFormatter(Number(invoice.v3InvoicePaidAmount)),
        v3Comm: priceFormatter(Number(invoice.v3CommissionAmount)),
        ovrAgencyComm: priceFormatter(Number(invoice.ovrAgencyCommissionAmount)),
      })
    });
    setFullCommAmount(totalCommAmount);
    return invList;
  }, [navigatedInvList]);

  const setInvoiceIds = (): number[] => {
    if (navigatedInvList) {
      return navigatedInvList.map(invoice => invoice.invoiceId);
    }
    return [];
  };

  const onSubmit = async (data: RecordPayoutInfo) => {
    data.invoiceIds = setInvoiceIds();
    data.commissionAgencyType = "OVERRIDE";
    data.agencyId = overrideAgencyId;
    const response = await dispatch(savePayoutInfo(data));
    if (
      response.payload &&
      response.payload.message === "SUCCESS"
    ) {
      const alertObj: SingleAlertInfo = {
        message: "Override agency payments recorded successfully!",
        alertType: "success",
      };
      dispatch(setSingleAlertObj(alertObj));
      navigate(-1)
    }
    console.log(data)
  };
  const handlePayoutDate = (date: Date | null) => {
    setValue("payoutDate", dayjs(date).format("MM/DD/YYYY"));
  };

  return (
    <Box m="20px">
      <Header title="Record Payout" subtitle="Record Override Agency Payout Management" />
      <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h4">
              <strong>Agency:</strong> {agencyName}
            </Typography>
          </Box>

          <Box>
            <CustomTable
              columns={columns}
              rows={rows}
              disablePagination={true}
              borderBox={true}
            />
          </Box>

          <Box sx={{ textAlign: "right", marginTop: 2 }}>
            <Typography variant="body1" fontWeight="bold">
              {priceFormatter(fullCommAmount)}
            </Typography>
          </Box>

          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            pt={1}
          >
            <Grid item xs={12} md={4} textAlign={{ xs: "start", md: "end" }}>
              <Typography variant="body1">
                <strong>Payment:</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomCurrencyField
                control={control}
                controllerName="payment"
                label="Payment Amount"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            pt={1}
          >
            <Grid item xs={12} md={4} textAlign={{ xs: "start", md: "end" }}>
              <Typography variant="body1">
                <strong>Payout Date:</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
            <CustomDatePicker
                  control={control}
                  onChange={handlePayoutDate}
                  controllerName="payoutDate"
                  label="Payout Date"
                />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            pt={1}
          >
            <Grid item xs={12} md={4} textAlign={{ xs: "start", md: "end" }}>
              <Typography variant="body1">
                <strong>Payout Comment:</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomTextField
                control={control}
                controllerName="payoutComment"
                label="Payout Comment"
                aria-readonly
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
            <Grid item xs={12} md="auto">
              <Button
                fullWidth
                color="warning"
                type="submit"
                startIcon={<CloudDownloadOutlined />}
              >
                Record and Download Payout
              </Button>
            </Grid>
            <Grid item xs={12} md="auto" ml={1}>
            <Button
                color="error"
                startIcon={<Cancel />}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Card>
        </form>
      </Box>
    </Box>
  );
};

export default RecordPayoutOverride;
