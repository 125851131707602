import React, { useEffect } from "react";
import {
  Box,
  Avatar,
  Typography,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import {
  Person as PersonIcon,
  Description as DescriptionIcon,
  AccountBox as AccountBoxIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import Header from "../common/Header";
import Card from "../common/Card";
import coverImage from "../../assets/cover.jpg";
import avatarMaleImage from "../../assets/avatar-male.png";
import avatarFemaleImage from "../../assets/avatar-female.png";
import { tokens } from "../../theme";
import { useForm } from "react-hook-form";
import ChangePassword from "../operation/user/ChangePassword";
import Profile from "./Profile";
import MyAccount from "./MyAccount";
import SettingProfile from "./SettingProfile";
import { USER_ROLES } from "../../constants/applicationConstants";
import { useAuthSlice } from "../../redux/slice/authSlice";
import { fullName } from "../../utils/Utility";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabValue: number;
}

export type accountProfile = {
  account: string | undefined;
};

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccountProfile: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const { control, setValue } = useForm<accountProfile>();
  const { userInfo } = useAuthSlice();
  const [tabValue, setTabValue] = React.useState(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    return tab ? parseInt(tab) : 0;
  });
  const [gender] = React.useState("Male");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box m="20px">
      <Header title="Account Profile" subtitle="Account Management" />
      <Card>
        <Box
          sx={{
            position: "relative",
            backgroundColor: colors.grey[800],
            borderRadius: "10px",
            overflow: "hidden",
            border: 1,
            borderColor: colors.grey[700],
          }}
        >
          <Box
          //  sx={{ position: "relative" }}
          >
            {/* <Box
              component="img"
              src={coverImage}
              alt="Background"
              sx={{ width: "100%", height: "200px", objectFit: "cover" }}
            /> */}
            <Box
              sx={{
              //   position: "absolute",
              //   top: 0,
              //   left: 0,
              //   width: "100%",
              //   height: "200px",
              //   background:
              //     theme.palette.mode === "dark"
              //       ? "rgba(0, 0, 0, 0.8)"
              //       : "rgba(0, 0, 0, 0.2)",
              //   borderRadius: "10px 10px 0 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding : 4
              }}
            >
              {/* <Avatar
                src={gender === "Male" ? avatarMaleImage : avatarFemaleImage}
                sx={{ width: 100, height: 100, mb: 2, boxShadow: 2 }}
              /> */}
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                { fullName("", userInfo.firstName, userInfo.middleName, userInfo.lastName) }
              </Typography>
              <Typography variant="subtitle1">
                {userInfo.userRole === USER_ROLES.ROLE_ADMIN ? "ADMIN" :
                  userInfo.userRole === USER_ROLES.ROLE_AGENCYADMIN ? "AGENCY ADMIN" :
                    userInfo.userRole === USER_ROLES.ROLE_AGENCYSTAFF ? "AGENCY STAFF" :
                      userInfo.userRole === USER_ROLES.ROLE_BILLER ? "BILLER" :
                        userInfo.userRole === USER_ROLES.ROLE_MANUFACTURERADMIN ? "MANUFACTURE AGREEMENT" :
                          userInfo.userRole === USER_ROLES.ROLE_MANUFACTURERSTAFF ? "MANUFACTURE STAFF" :
                            userInfo.userRole === USER_ROLES.ROLE_PRACTICEADMIN ? "PRACTICE ADMIN" :
                              userInfo.userRole === USER_ROLES.ROLE_PRACTICESTAFF ? "PRACTICE STAFF" :
                                ""}</Typography>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                centered
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: colors.success[300],
                  },
                }}
              >
                <Tab
                  icon={<PersonIcon />}
                  label="Profile"
                  sx={{ "&.Mui-selected": { color: colors.success[300] } }}
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<DescriptionIcon />}
                  label="2FA"
                  sx={{ "&.Mui-selected": { color: colors.success[300] } }}
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<AccountBoxIcon />}
                  label="Change Password"
                  sx={{ "&.Mui-selected": { color: colors.success[300] } }}
                  {...a11yProps(2)}
                />
                {/* <Tab
                  icon={<SettingsIcon />}
                  label="Settings"
                  sx={{ "&.Mui-selected": { color: colors.success[300] } }}
                  {...a11yProps(3)}
                /> */}
              </Tabs>
            </Box>
            <TabPanel tabValue={tabValue} index={0}>
              <Profile />
            </TabPanel>
            <TabPanel tabValue={tabValue} index={1}>
              <MyAccount />
            </TabPanel>
            <TabPanel tabValue={tabValue} index={2}>
              <ChangePassword />
            </TabPanel>
            <TabPanel tabValue={tabValue} index={3}>
              <SettingProfile />
            </TabPanel>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default AccountProfile;
