import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { FormHelperText, useTheme } from '@mui/material';
import { Grid } from "@mui/material";

type optionProps = {
  value: any,
  label: string,
}

interface CheckboxGroupProps {
  options: { value: string; label: string; checked?: boolean; disabled?: boolean }[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  controllerName: string;
  control: Control<any, any>;
  rules?: object;
  xs? : number;
  isDisabled?: boolean;
}

const CustomCheckboxGroup: React.FC<CheckboxGroupProps> = ({ options, onChange, controllerName, control, rules, xs, isDisabled}) => {

  const theme = useTheme();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<any, string>, option: optionProps) =>{
    const checked = event.target.checked;
    const valueToAdd = option.value;
    let updatedValue;
    if (checked) {
      updatedValue = Array.isArray(field.value) ? [...field.value, valueToAdd] : [valueToAdd];
    } else {
      updatedValue = Array.isArray(field.value) ? field.value.filter((val) => val !== valueToAdd) : [];
    }
    field.onChange(updatedValue);
    onChange && onChange(event)
  }

  return (
    <Controller
      name={controllerName}
      control ={control}
      rules={rules? rules: {}}
      render={({ field, fieldState}) => (
        <FormGroup>
          <Grid container spacing={1}>
        {options.map((option) => (
          <Grid item xs={xs}>
          <FormControlLabel
            key={option.value}
            control={
            <Checkbox
             color={theme.palette.mode === "dark" ? "secondary" : "primary"}
             checked={Array.isArray(field.value) && field.value.includes(option.value)}
             onChange={(e) => handleValueChange(e, field, option)}
             sx={{
              color: fieldState.error && !field.value
                ? theme.palette.error.main
                : '',
              '&.Mui-checked': {
                color: fieldState.error && !field.value
                  ? theme.palette.error.dark
                  : '',
              },
            }}
            disabled={isDisabled}
             />}
            label={option.label}
            sx={{ color: fieldState.error ? theme.palette.error.main : 'inherit' }}
            
          />
          </Grid>
        ))}
        {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
        </Grid>
      </FormGroup>
      )}
    />
  );
};

export default CustomCheckboxGroup;




