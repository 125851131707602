import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Alert,
  Grid,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Button from "../common/Button";
import CustomTextField from "../common/CustomTextField";
import OTPVerification from "../2FA/OTPVerification";
import {
  Generate2faRequest,
  TwoFactorSaveRequest,
  fetchAllUserTwoFactorList,
  generate2faOTP,
  reset2FAFetchStatus,
  save2faCommunicationData,
  useTwoFactorReducer,
  validate2faOTP,
} from "../../redux/slice/2FA/twoFactorSlice";
import { useAuthSlice } from "../../redux/slice/authSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import {
  SingleAlertInfo,
  hideSingleAlert,
  setSingleAlertObj,
} from "../../redux/slice/commonSlice";
import { TWO_FACTOR_VERIFICATION_CODE_LENGTH } from "../../constants/applicationConstants";

export type verificationForm = {
  account: string | undefined;
};

interface VerificationDialogProps {
  open: boolean;
  onClose: () => void;
  type: "email" | "phone";
}

const VerificationDialog: React.FC<VerificationDialogProps> = ({
  open,
  onClose,
  type,
}) => {
  const { control, handleSubmit } = useForm<verificationForm>();
  const [value, setValue] = useState("");
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(false);

  const { userInfo } = useAuthSlice();
  const { generate2faStatus, validate2faStatus, save2faOptionStatus } =
    useTwoFactorReducer();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      if (generate2faStatus === "success") {
        const alertObj: SingleAlertInfo = {
          message: "successfully sent the OTP code.",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
      } else if (generate2faStatus === "error") {
        const alertObj: SingleAlertInfo = {
          message: "error while sending OTP code",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));
      }
      setTimeout(() => {
        dispatch(hideSingleAlert());
      }, 5000);
    }
  }, [generate2faStatus]);

  useEffect(() => {
    if (open) {
      if (validate2faStatus === "success") {
        const alertObj: SingleAlertInfo = {
          message: "successfully verified your account.",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
        let obj: Generate2faRequest = {
          methodValue: value,
          methodType: type === "email" ? "EMAIL" : "MOBILE",
          isPrimary: false,
        };
        dispatch(save2faCommunicationData(obj));
      } else if (validate2faStatus === "error") {
        const alertObj: SingleAlertInfo = {
          message: "error while validate the OTP",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));
      }
      setTimeout(() => {
        dispatch(hideSingleAlert());
      }, 5000);
    }
  }, [validate2faStatus]);

  useEffect(() => {
    if (open) {
      if (save2faOptionStatus === "success") {
        const alertObj: SingleAlertInfo = {
          message: "successfully added two factor option",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
        closePopup();
        dispatch(fetchAllUserTwoFactorList(true));
      } else if (save2faOptionStatus === "error") {
        const alertObj: SingleAlertInfo = {
          message: "error while saving two factor option",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));
      }
      setTimeout(() => {
        dispatch(hideSingleAlert());
      }, 5000);
      setValue("");
      setDisable(false);
    }
  }, [save2faOptionStatus]);

  useEffect(() => {
    console.log(disable);
  }, [disable]);

  const closePopup = () => {
    onClose();
    dispatch(reset2FAFetchStatus());
  };

  const handleSend = () => {
    let obj: TwoFactorSaveRequest = {
      communicationValue: value,
      communicationType: type === "email" ? "EMAIL" : "MOBILE",
      userId: userInfo.userId,
    };
    setDisable(true);
    dispatch(generate2faOTP({ request: obj }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleVerify = () => {
    dispatch(validate2faOTP({ otp: otp }));
  };

  const onSubmit = (data: any) => {
    handleSend();
  };

  return (
    <Dialog open={open} onClose={closePopup}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {type === "email" ? "Email Verification" : "Phone Verification"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Please enter the{" "}
            {type === "email" ? "email address" : "phone number"} you want to
            sign up as a 2FA method and click Send to receive the verification
            code.
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md>
              <CustomTextField
                control={control}
                controllerName={type === "email" ? "Email" : "Phone"}
                onChange={handleChange}
                value={value}
                label={type === "email" ? "Email" : "Phone"}
                variant="outlined"
                fullWidth
                disabled={disable}
                rules={
                  type === "email"
                    ? {
                        required: "Email is required.",
                        pattern: {
                          value:
                            /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|n\/a|N\/A)$/i,
                          message: "Invalid email.",
                        },
                      }
                    : {}
                }
              />
            </Grid>
            <Grid item xs={12} md="auto">
              <Button
                variant="contained"
                type="submit"
                disabled={value.trim().length === 0 || disable}
                color="info"
                fullWidth
                style={{
                  padding: "15px",
                }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
          <Box mt={3}>
            <Grid container justifyContent="center" alignItems="center">
              <OTPVerification
                control={control}
                sendOtp={handleSend}
                submitCode={(value) => setOtp(value)}
                disable={!disable}
              />
            </Grid>
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleVerify}
              disabled={
                otp.trim().length !== TWO_FACTOR_VERIFICATION_CODE_LENGTH
              }
            >
              Verify
            </Button>
            <Button onClick={onClose} color="error">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default VerificationDialog;
