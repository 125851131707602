import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "./Button";
import { Cancel, VpnKey } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import {
  MainErrorInfo,
  SingleAlertInfo,
  setMainErrorObj,
  setSingleAlertObj,
} from "../../redux/slice/commonSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { Box, Grid } from "@mui/material";
import CustomPasswordTextField from "./CustomPasswordTextField";
import CustomTextField from "./CustomTextField";
import OptionsPopup from "./OptionsPopup";
import { ResetPassword, clearResetPasswordObject, resetPassword, useUserReducer } from "../../redux/slice/userSlice";
import { useNavigate } from "react-router-dom";

interface PasswordResetProps {
  open: boolean;
  onClose: () => void;
  email: string;
  userId: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PasswordResetPopup: React.FC<PasswordResetProps> = ({
  open,
  onClose,
  email,
  userId,
}) => {
  const { control, handleSubmit, reset, setValue, watch, setError } = useForm({});
  const dispatch = useAppDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { resetPasswordObject } = useUserReducer();
  const navigate = useNavigate();

  const defaultValues = useCallback(() => {
    if (resetPasswordObject) {
      reset(resetPasswordObject);
    } else {
      reset({
        userId,
        email,
        password: '',
        confirmPassword: '',
      });
    }
  }, [resetPasswordObject, reset, email, userId]);

  // const onSubmit = (data: any) => {
  //   let errorObj: SingleAlertInfo = {
  //     message: "Feature currently under development. Stay tuned for updates!",
  //     alertType: "info",
  //   };
  //   dispatch(setSingleAlertObj(errorObj));
  //   onClose();
  //   setConfirmOpen(true);
  // };

  const onSubmit = async (data: any) => {
    try {
        await handleChangePassword(data, "Password Reset Successful.", "Password Reset Unsuccessful.");
    } catch (error) {
    }
    defaultValues();
    onClose();
};

const handleChangePassword = async (data: ResetPassword, successMessage: string, errorMessage: string) => {
  const resetPWData = {
      userId: userId,
      password: data.password,
      email: '',
      confirmPassword: '',
      oldPassword: '',
  };

  try {
      const response = await dispatch(resetPassword(resetPWData));

      if (response.payload && response.payload.message !== "SUCCESS") {
          const alertObj: SingleAlertInfo = {
              message: successMessage,
              alertType: "success",
          };
          dispatch(setSingleAlertObj(alertObj));
      } else {
          const alertObj: SingleAlertInfo = {
              message: errorMessage,
              alertType: "error",
          };
          dispatch(setSingleAlertObj(alertObj));
      }
      return response;
  } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
  }
};

  const handleClose = () => {
    onClose();
    defaultValues();
  };

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  useEffect(() => {
    return () => {
      dispatch(clearResetPasswordObject());
    };
  }, [dispatch]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <CustomTextField
                    control={control}
                    controllerName="email"
                    label="Email"
                    isDisabled={true}
                    value={email}
                    // rules={{
                    //   required: "Email is required.",
                    //   pattern: {
                    //     value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                    //     message: "Invalid email.",
                    //   },
                    // }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <CustomPasswordTextField
                    control={control}
                    controllerName="password"
                    label="Password"
                    autoComplete="new-password"
                    rules={{
                      required: `Password is required.`,
                      minLength: {
                        value: 12,
                        message: "Password should be at least 12 characters.",
                      },
                      pattern: {
                        value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
                        message: "Password must contain at least one uppercase letter, one number, and one special character"
                      },
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <CustomPasswordTextField
                    control={control}
                    controllerName="confirmPassword"
                    label="Confirm Password"
                    autoComplete="new-password"
                    rules={{
                      required: `Confirm Password is required.`,
                      validate: (value: string) =>
                        value === watch("password") || "Passwords do not match",
                      minLength: {
                        value: 12,
                        message: "Password should be at least 12 characters.",
                      },
                      pattern: {
                        value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
                        message: "Password must contain at least one uppercase letter, one number, and one special character"
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2} pt={2}>
              <Grid item xs={12} sm="auto">
                <Button type="submit" startIcon={<VpnKey />} fullWidth>
                  Reset Password
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="error" startIcon={<Cancel />} onClick={ () => handleClose()} fullWidth>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <OptionsPopup
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onSubmit={onSubmit}
        variant="confirm"
        message="Are you sure you want to reset the password?"
        buttons={[
          { name: "Confirm", color: "primary", onClick: () => onSubmit },
          { name: "Cancel", color: "secondary", onClick: () => setConfirmOpen(false) }
        ]}
      />
    </>
  );
};

export default PasswordResetPopup;
