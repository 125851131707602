// import { SearchIVRListRequest } from "../redux/slice/ivr/createIVRSlice";
import { IVRObjectInfo, SearchPatientInfo, UpdateIVRObj } from "../redux/slice/ivr/createIVRSlice";
import { IVRDocumentInfo, IVRNotes } from "../redux/slice/ivr/ivrDocumentSlice";
import { ADD_IVR_NOTE_URL, GET_ALL_IVR_URL, REMOVE_IVR_DOCUMENT_URL, SUBMIT_IVR_URL, UPDATE_IVR_RESPOND, LIST_IVR_NOTES_URL, UPLOAD_IVR_DOCUMENT_URL, FETCH_IVR_BY_ID, SAVE_IVR_URL, GET_IVR_DOCUMENTS_LIST_URL, DELETE_IVR_BY_ID, SEARCH_IVR_BY_PATIENT_NAME, GET_DOCUMENT_URL, SEARCH_IVR_BY_PATIENT_ID } from "./URL";
import { http } from "./http";

export const getIVRList = async (data: any) => {
  try {
    const response = await http.post(GET_ALL_IVR_URL, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getIVRById = async (id: string) => {
  try {
    const response = await http.get(`${FETCH_IVR_BY_ID}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveIVR = async (submitIVRRequest: IVRObjectInfo) => {
  try {
    const response = await http.post(SAVE_IVR_URL, submitIVRRequest);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadIVRDocumentData = async (documentInfo: IVRDocumentInfo) => {
  try {
    const response = await http.post(UPLOAD_IVR_DOCUMENT_URL, documentInfo);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadIVRNotesData = async (ivrNotesObjecct: IVRNotes) => {
  try {
    const response = await http.post(ADD_IVR_NOTE_URL, ivrNotesObjecct);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getIVRNoteById = async (xrefTableId: number, xrefTable: any) => {
  try {
    const response = await http.post(LIST_IVR_NOTES_URL, null, {
      params: {
        xrefTableId,
        xrefTable,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getIVRDocuments = async (ivrId: number, patientId: number) => {
  try {
    const response = await http.get(GET_IVR_DOCUMENTS_LIST_URL, {
      params: {
        ivrId,
        patientId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getIVRDocumentByDocumentId = async (ivrDocumentId: number) => {
  try {
    const response = await http.get(`${GET_DOCUMENT_URL}/${ivrDocumentId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const removeIVRDocumentById = async (documentId: string) => {
  try {
    const response = await http.delete(`${REMOVE_IVR_DOCUMENT_URL}/${documentId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateIVR = async (data: UpdateIVRObj | null) => {
  try {
      const response = await http.post(UPDATE_IVR_RESPOND, data);
      return response
  } catch (error) {
      throw error;
  }
}

export const submitIVRData = async (submitIVRRequest: IVRObjectInfo) => {
  try {
    const response = await http.post(SUBMIT_IVR_URL, submitIVRRequest);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteIVR = async (id: string) => {
  try {
    const response = await http.post(`${DELETE_IVR_BY_ID}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchIvrByPatientInfo = async (patientInfo: string) => {
  try {
    const response = await http.post(`${SEARCH_IVR_BY_PATIENT_NAME}`, patientInfo);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchIvrByPatientId = async (patientId: number) => {
  try {
    const response = await http.post(`${SEARCH_IVR_BY_PATIENT_ID}`, patientId);
    return response;
  } catch (error) {
    throw error;
  }
};
