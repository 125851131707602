import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import Card from "../../common/Card";
import {
  AttachmentBarcodeStickerInfo,
  OrderItemInfo,
  OrderInfo,
  TissueTrackingInfo,
  addTissueTracking,
  getOrderItemsByOrderId,
  updateElementInOrderSlice,
  useOrderSlice,
} from "../../../redux/slice/order/OrderSlice";
import { useForm } from "react-hook-form";
import {
  Cancel,
  ClassOutlined,
} from "@mui/icons-material";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import CustomCheckbox from "../../common/CustomCheckBox";
import CustomDatePicker from "../../common/CustomDatePicker";
import { tokens } from "../../../theme";
import { useEffect, useMemo, useState } from "react";
import { convertBase64 } from "../../../utils/Utility";
import dayjs from "dayjs";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface TissueTrackingState extends TissueTrackingInfo {
  checkBoxes: string[],
  selectAll?: boolean
}

export interface OrderItemRow {
  select: JSX.Element;
  orderItemId: number;
  serialNumber: string;
  size: string;
  tissueTrackingId: number
  productReturnId: number;
}
 

const TissueTracking = () => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<TissueTrackingState>();

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useAppDispatch();
  const [ order, setOrder ] = useState<OrderInfo>();
  const { orderItemsListInfo, addTissueTrackingStatus } = useOrderSlice();
  const [ checkedList, setCheckedList ] = useState<number[]>([]);
  const [ showOptionPopup, setShowOptionsPopup ] = useState<OptionPopupObjectProps>({message:'', show:false});
  const [ isAllAppliedOrReturned, setIsAllAppliedOrReturned ] = useState(true);

  useEffect(() => {
    const orderDetails : OrderInfo = location.state.selectedOrder;
    setOrder(orderDetails);
    if(orderDetails) {
      setInitialValues(orderDetails);
      dispatch(getOrderItemsByOrderId(orderDetails.orderId));
    };
  }, []);

  const setInitialValues = (orderDetails: OrderInfo) => {
    setValue("orderId", orderDetails.orderId);
    if(orderDetails.patientId){
      setValue('patientId', orderDetails.patientId);
    };
    setValue('productId', orderDetails.productId);
    if(dayjs(orderDetails.dateOfService).isValid()){
      setValue("dateOfService", dayjs(orderDetails.dateOfService).format("MM/DD/YYYY"));
    };
  };

  const handleDropZone = async (uploadedFiles: ExtendedFile[], fieldName: string) => {
    const attachmentBarcodeStickerArray: AttachmentBarcodeStickerInfo[] = [];
    if (uploadedFiles && uploadedFiles.length > 0) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const base64File = String(await convertBase64(uploadedFiles[i]));
        const fileName = uploadedFiles[i].name;
        const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
        const base64Substring = base64File.substring(
          fileExtension === "pdf" ? "data:application/pdf;base64,".length : "data:image/png;base64,".length, 
          base64File.length
        );
        const attachmentBarcodeStickerObj: AttachmentBarcodeStickerInfo = {
          documentName: fileName,
          content: base64Substring,
          fileType: fileExtension
        };
        attachmentBarcodeStickerArray.push(attachmentBarcodeStickerObj);
      }
    }
    setValue("attachmentBarcodeSticker", attachmentBarcodeStickerArray);
    trigger("attachmentBarcodeSticker");
  };
  

  const handleDateOfService = (date : Date | null) => {
    if(dayjs(date).isValid()){
      setValue("dateOfService", dayjs(date).format("MM/DD/YYYY"));
    };
  };

  const onSubmit = () => {
    if(!isAllAppliedOrReturned) {
      if (checkedList?.length > 0) {
        setShowOptionsPopup({
          message: "Do you really want to submit the the Tissue Tracking? ",
          show: true,
          variant: "confirm",
          buttons: [{ name: "Confirm", color: 'primary', onClick: () => submitTissueTracking() }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
        });
      } else {
        let errorObj: SingleAlertInfo = {
          message: "Please select atleast one Order item.",
          alertType: "error"
        };
        dispatch(setSingleAlertObj(errorObj));
        cancelOptionPopup();
      }
    } else {
          let warningObj: SingleAlertInfo = {
            message: "Order items aren't available to Tissue Track.",
            alertType: "warning"
          };
          dispatch(setSingleAlertObj(warningObj));
    };
};

  const submitTissueTracking = async () => {
    const {serialNumbers, checkBoxes, selectAll, ...submitObject} = getValues();
    const orderItemList : OrderItemInfo[] = [];
    checkedList?.map(itemId => {
      orderItemList.push({
        orderItemId: itemId,
        //@ts-ignore
        serialNumber : serialNumbers[`serialNumber${itemId}`]
      })
    });
    if(orderItemList?.length > 0) {
      submitObject.orderItems = orderItemList;
    };
    const response = await dispatch(addTissueTracking(submitObject));
    cancelOptionPopup();
  };

  useEffect(() => {
    if(addTissueTrackingStatus === 'success') {
      let successObj: SingleAlertInfo = {
        message: "Tissue Tracking has been submitted successfully.",
        alertType: "success"
      };
      dispatch(setSingleAlertObj(successObj));
      dispatch(updateElementInOrderSlice(
       { elementName : "addTissueTrackingStatus",
        value: ""}
      ));
      cancelOptionPopup();
      navigate(-1);
    }
  }, [addTissueTrackingStatus])

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  useEffect(() => {
    if(orderItemsListInfo) {
        const isAllItemAppliedOrReturned = orderItemsListInfo.every(item => item.productReturnId || item.tissueTrackingId);
        setIsAllAppliedOrReturned(!!isAllItemAppliedOrReturned);
    };
}, [orderItemsListInfo]);

  const orderItemRow: OrderItemRow[] = useMemo(() => {
    let orderItems: OrderItemRow[] = [];
    if(orderItemsListInfo) {
      orderItems = orderItemsListInfo.map(item => {
        //@ts-ignore
        setValue(`serialNumbers.serialNumber${item.orderItemId}`, item.serialNumber);
        return {
          select: <CustomCheckbox control={control} name="select" />,
          orderItemId: item.orderItemId,
          serialNumber: item.serialNumber,
          size: item.size,
          tissueTrackingId: item.tissueTrackingId,
          productReturnId: item.productReturnId
        }
      });
    }
    return orderItems;
  }, [orderItemsListInfo]);

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>, row: OrderItemRow) => {
    const { checked } = e.target;
    if (checked) {
        row && setCheckedList(prevValues => {
            const newValues : number[] = [...prevValues];
            newValues.push(row.orderItemId);
            return newValues;
        });
    } else {
        setCheckedList(checkedList.filter(item => item !== row.orderItemId));
        setValue("selectAll", false);
    }
};

const checkSerialNumberPresent = (row: OrderItemRow) => {
  if(checkedList.includes(row.orderItemId)) {
    return false;
  } 
  return true;
};

const isCheckBoxDisable = (row: OrderItemRow) => {
  if(row?.tissueTrackingId || row?.productReturnId){
    return true;
  };
  return false;
};

const orderItemStatus = (row: OrderItemRow) => {
  if(row?.tissueTrackingId){
    return "Applied";
  } else if(row?.productReturnId) {
    return "Returned";
  }
  return "Available";
};


const handleSelectAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { checked } = e.target;
  if (checked) {
    const selectList: number[] = [];
    orderItemRow?.forEach(row => {
      if ((row?.tissueTrackingId === null && row?.productReturnId === null)) {
        selectList.push(row.orderItemId);
        //@ts-ignore
        setValue(`checkBoxes.checkbox${row.orderItemId}`, true);
      };
    });
    setCheckedList(selectList)
  } else {
    setCheckedList([]);
    orderItemRow?.forEach(row => {
      if ((row?.tissueTrackingId === null && row?.productReturnId === null)) {
        //@ts-ignore
        setValue(`checkBoxes.checkbox${row.orderItemId}`, false);
      };
    });
  };
};

  return (
    <Box m="20px">
      {showOptionPopup.show &&
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      }
      <Header title="Tissue Tracking" subtitle="Order Management" />
      <Box>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <Grid container spacing={2} mb={2}>
              <Grid item md={12} mb={-2}>
                Please enter the IDs in the provided space or take a picture of
                the barcode stickers and attach to the tracking form.
              </Grid>
              <Grid item md={12}>
                Select the Grafts in which tissue tracking info is provided.
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} mb={2} mt={2}>
              <Grid item md={6}>
                <Typography component="label" fontWeight="bold">
                  Patient :{" "}
                </Typography>
                <Typography style={{ paddingLeft: "10px" }} component="label">{order?.patientName}</Typography>
              </Grid>
            </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Product :{" "}
                  </Typography>
                  <Typography sx={{ paddingLeft: "10px" }} component="label">{order?.productName}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Order # :{" "}
                  </Typography>
                  <Typography sx={{ paddingLeft: "10px" }} component="label">{order?.orderNumber}</Typography>
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item md={3}>
                  <Typography component="label" fontWeight="bold">
                    PO # :{" "}
                  </Typography>
                  <Typography sx={{ paddingLeft: "10px" }} component="label">{order?.poNumber}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
              <Grid item md={4}>
                <CustomDatePicker
                  control={control}
                  onChange={handleDateOfService}
                  controllerName="dateOfService"
                  label="Date Of Service"
                  rules={ !isAllAppliedOrReturned ? { required: "Date of Service is Required. " } : {}}
                />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
              />
            </Grid>
            <Grid container spacing={2} mb={2} mt={1}>
              <Grid item md={8} mb={2}>
                <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                  <ListItem>
                    <ListItemButton
                      onClick={() => { }}
                      sx={{ padding: 0, borderRadius: 2, fontSize: 14 }}
                    >
                        <ListItemIcon sx={{ width: "15%", textAlign: 'left' }}>
                          <CustomCheckbox
                            control={control}
                            controllerName="selectAll"
                            onChange={handleSelectAllCheckbox}
                            tooltipMessage="Select All"
                            tooltipPlacement="top" />
                        </ListItemIcon>
                      <ListItemText sx={{ width: "30%" }}>
                        <b>Ordered Grafts</b>
                      </ListItemText>
                      <ListItemText sx={{ width: "40%" }}>
                        <b>Serial Number</b>
                      </ListItemText>
                      <ListItemText sx={{ width: "10%" }}>
                      </ListItemText>
                      <ListItemText sx={{ width: "40%" }}>
                        <b>Status</b>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  {orderItemRow.map((row) => (
                    <ListItem key={row.orderItemId}>
                      <ListItemButton
                        onClick={() => { }}
                        sx={{ padding: 0, borderRadius: 2 }}
                      >
                        <ListItemIcon sx={{ width: "15%" }}>
                        <CustomCheckbox isDisabled={isCheckBoxDisable(row)} 
                        onChange={(e) => handleCheckBox(e, row)} 
                        control={control} 
                        controllerName={`checkBoxes.checkbox${row.orderItemId}`}
                        />
                        </ListItemIcon>
                        <ListItemText sx={{ width: "30%" }}>
                          {row.size}
                        </ListItemText>
                        <CustomTextField
                          control={control}
                          //@ts-ignore
                          isDisabled={checkSerialNumberPresent(row)}
                          controllerName={`serialNumbers.serialNumber${row.orderItemId}`}
                          label="Serial Number"
                          sx={{ width: "40%" }}
                        />
                        <ListItemText sx={{ width: "10%" }}>
                        </ListItemText>
                        <ListItemText sx={{ width: "40%" }}>
                        {orderItemStatus(row)}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item md={4} xs={12}>
                <Dropzone
                  onFileChangeTest={handleDropZone}
                  name="attachmentBarcodeSticker"
                  onFileChange={() => { }}
                  control={control}
                  label="Attachment Barcode Sticker"
                  rules= {{required: "Barcode Sticker Attachment is required."}}
                  holdOnChange={true}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2} mt={2}>
              <Grid item md={8} xs={12}>
                <CustomTextField
                  controllerName="comment"
                  control={control}
                  label="Comment"
                  multiline
                  rows={4}
                  maxRows={4}
                  // rules={{ required: "Comment is required." }}
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="success"
                startIcon={<ClassOutlined />}
                sx={{ mr: 1 }}
              >
                Submit
              </Button>
              <Button
                color="error"
                startIcon={<Cancel />}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Box>
          </Box>

          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default TissueTracking;
