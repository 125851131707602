import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    APPROVE_PRACTICE_REQUEST_URL, FETCH_ACTIVE_AGENCY_EXECUTIVES_URL, FETCH_MACS_URL, REJECT_PRACTICE_REQUEST_URL,
    
} from "../../../service/URL";
import { http } from "../../../service/http";
import { useAppSelector } from "../redux-hooks";
import { LoadingType } from "../../../types/CommonTypes";
import { findAllBillers } from "../../../service/CommonService";


export type PracticeExecutive = {
    userId: number;
    agencyId: number;
}

export type MacInfo = {
    macId: number,
    macName: string
}

export type practiceMac = {
    macId: number,
    description: string
}


type ApprovePractice = {
    practiceId: string | null;
    practiceExecutive: PracticeExecutive | null;
    notificationEmails: string;
    approvalStatus: string;
    approvalComments: string;
    billerId: number | null,

};

export type AgencyExecutives = {
    firstName: string;
    userId: number;
    agencyId: number;
    agencyName: string
    lastName: string;
    middleName: string;
}

export type ProductPrice = {
    allowableUnitPrice: any,
    macId: number,
    priceType: string,
    productPriceId: string,
    effectiveDate: string,
    inactiveDate: string,
}

export type ProductInfo = {
    productId: string;
    productName: string
    productPrices: ProductPrice[]
}

export type BillerInfo = {
    billerId: string,
    billerName: string,
    phone: string,
    email: string,
    streetAddress: string,
    unitNumber: string,
    city: string,
    state: string,
    zipCode: string
}

export type PracticeLocationInfo = {
    locationId: number;
    locationType: string;
    streetAddress: string;
    unitNumber: string;
    city: string;
    state: string;
    zipCode: string;
  }
  
  export type PracticeProviderInfo = {
    providerId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    npi: string;
    ptan: string;
    createdDate: Date;
    inactiveDate: Date;
    title: string;
  }

  export type RejectPractice = {
    practiceId: string | undefined;
    approvalComments: string;
  };

type PracticeApproveState = {
    approvePractice: ApprovePractice;
    macInfo?: MacInfo[] | null;
    agencyExecutives?: AgencyExecutives[] | null;
    productList?: ProductInfo[] | null;
    billerList?: BillerInfo[] | null;
    errorList?: [] | null;
    status: string;
    fetchActiveBillerStatus: LoadingType;
    error: string | null;
    isValid: boolean;
}

let initialState: PracticeApproveState = {
    approvePractice: {
        practiceId: '',
        practiceExecutive: null,
        notificationEmails: '',
        approvalStatus: 'Approved',
        approvalComments: '',
        billerId: null
    },
    agencyExecutives: undefined,
    macInfo: undefined,
    productList: undefined,
    billerList: undefined,
    errorList: null,
    status: "idle",
    fetchActiveBillerStatus: "idle",
    error: null,
    isValid: false,
};


export const approvePracticeRequest = createAsyncThunk("ApprovePracticeRequest", async (data: any) => {
    const response = await http.post(APPROVE_PRACTICE_REQUEST_URL, data);
     return response.data;
});

export const fetchMac = createAsyncThunk("MAC", async () => {
    const response = await http.get(FETCH_MACS_URL);
    const v3Response = response.data;
    return v3Response.data;
});

export const fetchActiveAgencyExecutives = createAsyncThunk("activeAgencyExecutives", async (getAgencyAdmins: boolean) => {
     const response = await http.get(`${FETCH_ACTIVE_AGENCY_EXECUTIVES_URL}?getAgencyAdmins=${getAgencyAdmins}`);
    const v3Response = response.data;
    return v3Response.data;
});

export const fetchActiveBillers = createAsyncThunk("billerList", async () => {
    const response = await findAllBillers(true);
    const v3Response = response.data;
    return v3Response.data;
});

export const rejectPractice = createAsyncThunk("rejectPractice", async (data: RejectPractice) => {
    const response = await http.post(REJECT_PRACTICE_REQUEST_URL, data);
    return response.data;
});



const practiceApproveSlice = createSlice({
    name: "approcePractice",
    initialState,
    reducers: {
        updateApprocePracticeObject : (state: PracticeApproveState, action) => {
            state.approvePractice = action.payload;
        },
        updatePracticeApproveErrorList : (state: PracticeApproveState, action) => {
            state.errorList = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(approvePracticeRequest.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(approvePracticeRequest.fulfilled, (state, action) => {
                state.status = "success"
                state.isValid = true;
                state.status = action.payload;
            })
            .addCase(approvePracticeRequest.rejected, (state, action) => {
                state.status = "failed";
                state.isValid = false;
                state.error = action.error.message || "Approve failed";
            })
            .addCase(fetchMac.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchMac.fulfilled, (state, action) => {
                state.status = "success";
                state.isValid = true;
                state.macInfo = action.payload;
            })
            .addCase(fetchMac.rejected, (state, action) => {
                state.status = "failed";
                state.isValid = false;
                state.error = action.error.message || "Failed to fetch MAC";
            })
            .addCase(fetchActiveAgencyExecutives.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchActiveAgencyExecutives.fulfilled, (state, action) => {
                state.status = "success";
                state.isValid = true;
                state.agencyExecutives = action.payload;
            })
            .addCase(fetchActiveAgencyExecutives.rejected, (state, action) => {
                state.status = "failed";
                state.isValid = false;
                state.error = action.error.message || "Failed to fetch Active Account Representatives";
            })
            .addCase(fetchActiveBillers.pending, (state) => {
                state.fetchActiveBillerStatus = "loading";
                state.error = null;
            })
            .addCase(fetchActiveBillers.fulfilled, (state, action) => {
                state.fetchActiveBillerStatus = "success";
                state.billerList = action.payload;
                state.isValid = true;
            })
            .addCase(fetchActiveBillers.rejected, (state, action) => {
                state.fetchActiveBillerStatus = "error";
                state.isValid = false;
                state.error = action.error.message || "Failed to fetch Billers"
            });
    }
});

export const usePracticeApproveSlice = () => useAppSelector((state) => state.practiceApproveSlice);
export const { updatePracticeApproveErrorList } = practiceApproveSlice.actions;
export default practiceApproveSlice.reducer;

