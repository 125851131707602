import { ColorModeContext, useMode } from "./theme";
import { Backdrop, CircularProgress, CssBaseline, ThemeProvider } from "@mui/material";
import Dashboard from "./components/dashboard/Dashboard";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Order from "./components/operation/orders/Order";
import Form from "./components/administrator/Form";
import SignIn from "./components/signin/SignIn";
import {
  checkAuthentication,
  reNewAccessToken,
  resetAuthState,
  useAuthSlice,
} from "./redux/slice/authSlice";
import { MyProSidebarProvider } from "./components/common/sidebar/sidebarContext";
import PracticeRegistration from "./components/operation/practice/PracticeRegistration";
import PracticeList from "./components/operation/practice/PracticeList";
import CreatePractice from "./components/operation/practice/CreatePractice";
import Page404 from "./components/common/Page404";
import PracticeRequest from "./components/operation/practice/PracticeRequest";
import PracticeEditView from "./components/operation/practice/PracticeEditView";
import ProductList from "./components/operation/product/ProductList";
import Product from "./components/operation/product/Product";
import Success from "./components/common/Success";
import PatientsList from "./components/operation/patients/PatientsList";
import CreatePatient from "./components/operation/patients/CreatePatient";
import ProductAgreement from "./components/operation/ProductAgreement/ProductAgreement";
import CreateProductAgreement from "./components/operation/ProductAgreement/CreateProductAgreement";
import { useEffect, useState } from "react";
import { fetchAllCodeTablesValues } from "./redux/slice/codetableSlice";
import { useAppDispatch } from "./redux/slice/redux-hooks";
import PurchaseAgreement from "./components/operation/ProductAgreement/PurchaseAgreement";
import CreateNewAgreement from "./components/operation/ProductAgreement/CreateNewAgreement";
import { showHideLoadingDialog, useCommonReducer } from "./redux/slice/commonSlice";
import CustomErrorAlert from "./components/common/CustomErrorAlert";
import CustomSingleAlert from "./components/common/CustomSingleAlert";
import UserList from "./components/operation/user/UserList";
import CreateUser from "./components/operation/user/CreateUser";
import IVRList from "./components/operation/ivr/IVRList";
import { setDispatch, setNavigate } from "./service/http";
import LoadingDialog from "./components/common/LoadingDialog";
import PublicRoute from "./components/navigate/PublicRoute";
import ProtectedRoute from "./components/navigate/ProtectedRoute";
import IVRForm from "./components/operation/ivr/IVRForm";
import OrderList from "./components/operation/orders/OrderList";
import BenefitSummary from "./components/operation/ivr/BenefitSummary";
import TissueTracking from "./components/operation/orders/TissueTracking";
import Returns from "./components/operation/orders/Returns";
import Details from "./components/operation/orders/Details";
import {
  getDataFromLocalStorage,
  removeItemFromLocalStorage,
  setDataToLocalStorage,
} from "./utils/StorageUtils";
import ProductAgreementSign from "./components/operation/ProductAgreement/ProductAgreementSign";
import IVRDetails from "./components/operation/ivr/IVRDetails";
import ConsignmentOrder from "./components/operation/orders/ConsignmentOrder";
import IVRNote from "./components/operation/ivr/IVRNote";
import OrderFulfillment from "./components/operation/orders/OrderFulfillment";
import ReSignIn from "./components/signin/ReSignIn";
import { useUserReducer } from "./redux/slice/userSlice";
import Footer from "./components/common/Footer";
import AgencyList from "./components/operation/agency/AgencyList";
import AgencyForm from "./components/operation/agency/AgencyForm";
import InvoiceList from "./components/operation/invoice/InvoiceList";
import Invoice from "./components/operation/invoice/Invoice";
import SignUp2FA from "./components/2FA/SignUp2FA";
import Retrieve2FA from "./components/2FA/Retrieve2FA";
import Billing from "./components/operation/orders/Billing";
import ForgotPassword from "./components/signin/ForgotPassword";
import ResetPassword from "./components/signin/ResetPassword";
import ChangePassword from "./components/operation/user/ChangePassword";
import useToken from "./hooks/useToken";
import AccountProfile from "./components/dashboard/AccountProfile";
import { fetchAllUserTwoFactorList } from "./redux/slice/2FA/twoFactorSlice";
import { incrementInstanceCount, setupWindowCloseHandler } from "./utils/WindowUtils";
import CacheBuster from "react-cache-buster";
import packageJson from '../package.json';
import CommPayment from "./components/operation/invoice/CommPayement";
import RecordPayout from "./components/operation/invoice/RecordPayout";
import BillingDetail from "./components/operation/invoice/BillingDetail";
import RecordPayoutOverride from "./components/operation/invoice/RecordPayoutOverride";

function App() {
  const [theme, colorMode] = useMode();

  let location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isTokenValueAvailable = useToken();

  const [reLoginPopupShow, setReLoginPopupShow] = useState(false);

  let checkAuthen: boolean = false;

  let goto: string | null = getDataFromLocalStorage("GoTo");

  const {
    showLoadingDialog,
    showMainErrorPopup,
    mainErrorObj,
    showSingleAlert,
    singleAlertObj,
  } = useCommonReducer();

  const { userInfo, isValid } = useAuthSlice();

  setDispatch(dispatch);
  setNavigate(navigate);

  useEffect(() => {
    checkAuthen = false;
    removeItemFromLocalStorage("GoTo");
    checkAuth();
    dispatch(fetchAllCodeTablesValues({skipErrorPopup: true}));
    setupWindowCloseHandler();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "logout" || event.key === "login") {
        dispatch(resetAuthState());
        if (window.location.pathname !== "/signIn") {
          navigate("/signIn");
        }
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    
  }, []);

  useEffect(() => {
    if (userInfo.refreshTokenExpired) setReLoginPopupShow(true);
  }, [userInfo.refreshTokenExpired]);

  useEffect(() => {
    if (
      isValid &&
      userInfo.refreshToken !== null &&
      userInfo.refreshToken !== "" &&
      userInfo.accessTokenRenewTime &&
      userInfo.accessTokenRenewTime > 0
    ) {
      const interval: any = setInterval(
        () => dispatch(reNewAccessToken(userInfo.refreshToken)),
        (userInfo.accessTokenRenewTime * 1000 * 60) / 2
      );
      return () => clearInterval(interval);
    }
  }, [userInfo.refreshToken, isValid]);

  const checkAuth = async () => {
    try {
      let response = await dispatch(checkAuthentication({skipErrorPopup: true}));
      if (
        response.payload &&
        response.payload.code === "V3OK" &&
        response.payload.message === "User get successfully" &&
        (!response.payload.errors || response.payload.errors.length === 0)
      ) {
        checkAuthen = true;
        removeItemFromLocalStorage("GoTo");
        dispatch(fetchAllUserTwoFactorList(false));
      } else {
        checkAuthen = false;
        setGotoPageAfterLogin();
      }
    } catch (error) {
      setGotoPageAfterLogin();
      checkAuthen = false;
    }
  };

  const setGotoPageAfterLogin = async () => {
    let externalPathsToAllow: string[] = ["/sign_product_agreement"];
    let externalSearch = location.search;
    let pathname = location.pathname;
    if (
      externalSearch &&
      externalSearch.trim().length > 0 &&
      pathname &&
      pathname.trim().length > 0 &&
      externalPathsToAllow.includes(pathname)
    ) {
      setDataToLocalStorage("GoTo", pathname + externalSearch);
    }
  };

  return (
    <CacheBuster
        currentVersion={packageJson.version}
        isEnabled={true} 
        isVerboseMode={false} 
        loadingComponent={<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} >
                            <CircularProgress size={60} variant="indeterminate" />
                          </Backdrop>}
        metaFileDirectory={'.'}
      >
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute fallback="/">
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ivr"
              element={
                <ProtectedRoute>
                  <IVRForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/order"
              element={
                <ProtectedRoute>
                  <Order />
                </ProtectedRoute>
              }
            />
            <Route
              path="/fulfill_order"
              element={
                <ProtectedRoute>
                  <OrderFulfillment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/order_list"
              element={
                <ProtectedRoute>
                  <OrderList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/form"
              element={
                <ProtectedRoute>
                  <Form />
                </ProtectedRoute>
              }
            />
            <Route
              path="/setting"
              element={
                <ProtectedRoute>
                  <AccountProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/practice_list"
              element={
                <ProtectedRoute>
                  <PracticeList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create_practice"
              element={
                <ProtectedRoute>
                  <CreatePractice />
                </ProtectedRoute>
              }
            />
            <Route
              path="/practice_request"
              element={
                <ProtectedRoute>
                  <PracticeRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/practice_edit_view"
              element={
                <ProtectedRoute>
                  <PracticeEditView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/product_list"
              element={
                <ProtectedRoute>
                  <ProductList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/product"
              element={
                <ProtectedRoute>
                  <Product />
                </ProtectedRoute>
              }
            />
            <Route
              path="/patients"
              element={
                <ProtectedRoute>
                  <PatientsList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create_patient"
              element={
                <ProtectedRoute>
                  <CreatePatient />
                </ProtectedRoute>
              }
            />
            <Route
              path="/editPatient"
              element={
                <ProtectedRoute>
                  <CreatePatient />
                </ProtectedRoute>
              }
            />
            <Route
              path="/practice_product_agreement"
              element={
                <ProtectedRoute>
                  <ProductAgreement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create_practice_product_agreement"
              element={
                <ProtectedRoute>
                  <CreateProductAgreement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sign_product_agreement"
              element={
                <ProtectedRoute>
                  <ProductAgreementSign />
                </ProtectedRoute>
              }
            />
            <Route
              path="/purchase_agreement"
              element={
                <ProtectedRoute>
                  <PurchaseAgreement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create_new_agreement"
              element={
                <ProtectedRoute>
                  <CreateNewAgreement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user_list"
              element={
                <ProtectedRoute>
                  <UserList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user_create"
              element={
                <ProtectedRoute>
                  <CreateUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/change_password"
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ivr_list"
              element={
                <ProtectedRoute>
                  <IVRList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/benefit_summary"
              element={
                <ProtectedRoute>
                  <BenefitSummary />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tissue_tracking"
              element={
                <ProtectedRoute>
                  <TissueTracking />
                </ProtectedRoute>
              }
            />
            <Route
              path="/returns"
              element={
                <ProtectedRoute>
                  <Returns />
                </ProtectedRoute>
              }
            />
            <Route
              path="/details/:id"
              element={
                <ProtectedRoute>
                  <Details />
                </ProtectedRoute>
              }
            />
            <Route
              path="/billing"
              element={
                <ProtectedRoute>
                  <Billing />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ivr_details"
              element={
                <ProtectedRoute>
                  <IVRDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ivr_note"
              element={
                <ProtectedRoute>
                  <IVRNote />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create_consignment_order"
              element={
                <ProtectedRoute>
                  <ConsignmentOrder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/agency_list"
              element={
                <ProtectedRoute>
                  <AgencyList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/agency_form"
              element={
                <ProtectedRoute>
                  <AgencyForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/invoice_list"
              element={
                <ProtectedRoute>
                  <InvoiceList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/invoice"
              element={
                <ProtectedRoute>
                  <Invoice />
                </ProtectedRoute>
              }
            />
            <Route 
              path="/comm_payment"
              element={
                <ProtectedRoute>
                  <CommPayment />
                </ProtectedRoute>
              }
            />
            <Route 
              path="/recordPayout"
              element={
                <ProtectedRoute>
                  <RecordPayout />
                </ProtectedRoute>
              }
            />
            <Route 
              path="/overrideRecordPayout"
              element={
                <ProtectedRoute>
                  <RecordPayoutOverride />
                </ProtectedRoute>
              }
            />
            <Route
            path="/billing_detail"
            element={
              <ProtectedRoute>
                <BillingDetail />
              </ProtectedRoute>
            } 
            />
            <Route
              path="/accountProfile"
              element={
                <ProtectedRoute>
                  <AccountProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/signup2fa"
              element={
                <ProtectedRoute>
                  <SignUp2FA />
                </ProtectedRoute>
              }
            />
            <Route
              path="/retrive2fa"
              element={
                <PublicRoute fallback={"/retrive2fa"}>
                  <Retrieve2FA />
                </PublicRoute>
                }
            />
            <Route
              path="/signIn"
              element={
                <PublicRoute>
                  <SignIn />
                </PublicRoute>
              }
            />
            <Route
              path="*"
              element={
                <PublicRoute>
                  <Page404 />
                </PublicRoute>
              }
            />
            <Route
              path="/success"
              element={
                <PublicRoute>
                  <Success />
                </PublicRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />
            <Route
            path="/account/reset/:code"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
            <Route
              path="/reset-password"
              element={
                <PublicRoute>
                  <ResetPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/practice"
              element={
                <PublicRoute fallback={"/practice"}>
                  <PracticeRegistration />
                </PublicRoute>
              }
            />
          </Routes>
          <Footer />

          {showMainErrorPopup && (
            <CustomErrorAlert
              open={showMainErrorPopup}
              mainErrorObj={mainErrorObj}
              severity="error"
            />
          )}

          {showSingleAlert && (
            <CustomSingleAlert
              open={showSingleAlert}
              singleAlertObj={singleAlertObj}
            />
          )}
          <ReSignIn
            open={reLoginPopupShow}
            handleClose={() => setReLoginPopupShow(false)}
          />
        </ThemeProvider>

        {showLoadingDialog && <LoadingDialog open={showLoadingDialog} />}
      </ColorModeContext.Provider>
    </CacheBuster>
  );
}

export default App;
