import axios, { AxiosRequestConfig } from "axios";
import CryptoJS from "crypto-js";
import { clearLocalStorage, getDataFromCookies } from "../utils/StorageUtils";
import { MainErrorInfo, hideMainErrorPopup, setMainErrorObj, showHideLoadingDialog } from "../redux/slice/commonSlice";
import { InterceptorOption } from "../types/CommonTypes";
import { FAILED_CODE_701, FAILED_CODE_702 } from "./ServiceConstants";
import { logout } from "../redux/slice/authSlice";
import { getTokenFromCookie } from "../utils/Utility";

let dispatch: any;
let navigate: any;

type HttpModule = {
  post: (url: string, data?: any, config?:AxiosRequestConfig<null>, interceptorOption?: InterceptorOption) => Promise<any>;
  get: (url: string, config?:AxiosRequestConfig<null>, interceptorOption?: InterceptorOption) => Promise<any>;
  delete: (url: string, config?:AxiosRequestConfig<null>, interceptorOption?: InterceptorOption) => Promise<any>;
  put: (url: string, data: any, config?:AxiosRequestConfig<null>, interceptorOption?: InterceptorOption) => Promise<any>;
}

export const interceptor = axios.create({
  baseURL: process.env.REACT_APP_REST_SERVICE_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json"
  } as Record<string, string>,
});

let axiosOption: InterceptorOption | undefined;

export const http: HttpModule = {
  post: (url, data, config, interceptorOption) => {
    axiosOption = interceptorOption;
    return interceptor.post(url, data, config);
  },
  get: (url, config, interceptorOption) => {
    axiosOption = interceptorOption;
    return interceptor.get(url, config);
  },
  delete: (url, config, interceptorOption) => {
    axiosOption = interceptorOption;
    return interceptor.delete(url, config);
  },
  put: (url, data, config, interceptorOption) => {
    axiosOption = interceptorOption;
    return interceptor.put(url, data, config);
  }
}

export const setDispatch = (dispatchFunction: any) => {
  dispatch = dispatchFunction;
}

export const setNavigate = (navigateFunction: any) => {
  navigate = navigateFunction;
}

interceptor.interceptors.request.use(function (config) {
    const token = getTokenFromCookie();
    if (config.url) {
      config.url = token ? appendToken(config.url, token) : config.url;
    }

     try {
      if (
        config.data &&
        config.headers["Content-Type"] === "application/json" &&
        process.env.REACT_APP_ALLOW_ENCRYPTION === "true"
      ) {
        const iv = CryptoJS.lib.WordArray.random(128 / 8).toString(
          CryptoJS.enc.Hex
        );
        const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(
          CryptoJS.enc.Hex
        );
        const key = CryptoJS.PBKDF2(
          "v3biomed",
          CryptoJS.enc.Hex.parse(salt),
          {
            iterations: 1000,
            keySize: 128 / 32,
          }
        );
  
        const encrypedData = CryptoJS.AES.encrypt(
          JSON.stringify(config.data),
          key,
          {
            iv: CryptoJS.enc.Hex.parse(iv),
          }
        );
        config.data = {
          v3: salt,
          v3lite: iv,
          //@ts-ignore
          v3liteRequest: encrypedData.ciphertext.toString(CryptoJS.enc.Base64),
        };
      }
    } catch (ex) {
      console.log("ex", ex);
    }
    if (!axiosOption?.hideLoadingPopup) {
      dispatch(showHideLoadingDialog(true));
    }
    return config;
  }, function (error) {
    // Do something with request error
    if (!axiosOption?.hideLoadingPopup) {
      dispatch(showHideLoadingDialog(false));
    }
    return Promise.reject(error);
  });

  interceptor.interceptors.response.use(function (response) {
     // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (!axiosOption?.hideLoadingPopup) {
      dispatch(showHideLoadingDialog(false));
    }
    
    const responseData = response.data?.responseObj || response.data;
    if (responseData?.v3) {
      try {
        const key = CryptoJS.PBKDF2(
          "v3biomed",
          CryptoJS.enc.Hex.parse(responseData.v3),
          {
            iterations: 1000,
            keySize: 128 / 32,
          }
        );
        // @ts-ignore
        const cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Base64.parse(responseData.v3liteResponse),
        });
  
        const data = CryptoJS.AES.decrypt(cipherParams, key, {
          iv: CryptoJS.enc.Hex.parse(responseData.v3lite),
        }).toString(CryptoJS.enc.Utf8);
  
        try {
          response.data = JSON.parse(data.replaceAll("â€“", "-"));
        } catch (jsonParseError) {
          console.error('JSON parse error:', jsonParseError);
          response.data = data;
        }
      } catch (decryptionError) {
        console.error('Decryption error:', decryptionError);
        // Optionally, you can handle the error or rethrow it
        throw decryptionError;
      }
    }
    if (response.data?.code !== 'V3OK'){
      setErrorToUI(response, undefined);
      throw(response);
    }
    return response;
  }, function (error) {
    if (!axiosOption?.hideLoadingPopup) {
      dispatch(showHideLoadingDialog(false));
    }
    if (
      (error?.response?.status === 401 || error?.response?.status === 403 || error?.response?.status === 400) &&
      window.location.hash !== ""
    ) {
      clearLocalStorage();
      window.location.assign("/");
      return;
    }

    if (error?.response?.data?.v3) {
      const key = CryptoJS.PBKDF2(
        "v3biomed",
        CryptoJS.enc.Hex.parse(error.response.data.v3),
        {
          iterations: 1000,
          keySize: 128 / 32,
        }
      );

      //@ts-ignore
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(
          error.response.data.v3liteResponse
        ),
      });

      const data = CryptoJS.AES.decrypt(cipherParams, key, {
        iv: CryptoJS.enc.Hex.parse(error.response.data.v3lite),
      }).toString(CryptoJS.enc.Utf8);

      try {
        error.response.data = JSON.parse(data);
      } catch {
        error.response.data = data;
      }
    
    }
    setErrorToUI(undefined, error);
    throw error;

  });

const setErrorToUI = async (response: any, error: any) => {
  if (axiosOption?.skipErrorPopup) {
    return;
  }

  let errorObj: MainErrorInfo = {
    title: "An error is occurred while communicating with the server."
  };
  let errorSentence = '';
  if (response) {
    if (!response.data) {
      errorSentence = "An unknown error is occurred. Please contact a person who responsible for this.";
    } else if (response.data.code && response.data.code === FAILED_CODE_701) {
      errorObj.title = "Session is invalidated.";
      errorSentence = response.data.message && response.data.message.trim() !== "" ? response.data.message
        : "The same user has logged in from another device/browser. Only one browser session is allowed at a time."
          + "\nPlease switch to the other browser/device or log in again on this browser to continue.";
      dispatch(logout());
      navigate("/");
    } else if (response.data.code && response.data.code === FAILED_CODE_702) {
      errorObj.title = "Session is invalidated.";
      errorSentence = response.data.message && response.data.message.trim() !== "" ? response.data.message
        : "A valid user session is not found.\n You can log in again.";
      dispatch(logout());
      navigate("/");
    } else if (!response.data.errors || response.data.errors.length === 0) {
      errorSentence = response.data.message && response.data.message.trim() !== "" ? response.data.message
        : "An unknown error is occurred. Please contact a person who responsible for this.";
    } else {
      response.data.errors.forEach((error: any, index: any) => {
        if (error.field && error.errorMessage) {
          errorSentence += (!errorSentence ? `*` : `&nbsp;`) + `&nbsp;&nbsp;` + `${error.field}: ${error.errorMessage}\n`;
        } else if (error.errorMessage) {
          errorSentence += (!errorSentence ? `*` : `&nbsp;`) + `&nbsp;&nbsp;` + `${error.errorMessage}\n`;
        }
        if (error.reason) {
          errorSentence += (!errorSentence ? `*` : `&nbsp;`) + `&nbsp;&nbsp;` + `${error.reason}\n`;
        }
      });
      errorObj.title = "Please consider below issues.";
    }
  } else if (error) {
    errorSentence = `*` + error.message;
  }
  
  errorObj.errorMessageStream = errorSentence;
  dispatch(setMainErrorObj(errorObj));
  setTimeout(() => {
    dispatch(hideMainErrorPopup());
  }, 30000);
}

const appendToken = (url: string, token: string) => {
  if (url) {
    if (url.includes("?")) {
      if (url.endsWith("&")) {
        return `${url}jwtToken=Bearer ${token}`;
      } else {
        return `${url}&jwtToken=Bearer ${token}`;
      }
    } else {
      return `${url}?jwtToken=Bearer ${token}`;
    }
  }
  return url;
};
