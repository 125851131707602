import React from "react";
import { Box, CardContent, CardActions, Typography } from "@mui/material";
import { CategoryOutlined, QrCodeOutlined } from "@mui/icons-material";
import Button from "../../common/Button";
import Card from "../../common/Card";

interface ProfileProps {
  title?: string;
  subTitle?: string;
  logo?: string | null;
  onClick?: () => void | undefined;
}

const ProductCard: React.FC<ProfileProps> = ({
  title,
  subTitle,
  logo,
  onClick,
}) => {
  return (
    <Card sx={{ minHeight: 200, maxHeight: 200, padding: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Box mb={2}>
          {logo ? (
            <img src={logo} alt="Logo" style={{ maxWidth: "100%", maxHeight: "40px", width: "auto", height: "auto" }} />
          ) : (
            <CategoryOutlined fontSize="large" />
          )}
        </Box>
        <Typography gutterBottom variant="h5" component="div" fontWeight="bold" textAlign="center">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" textAlign="center">
          {subTitle}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClick} color="success" startIcon={<QrCodeOutlined />}>
          View
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
