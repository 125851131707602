import React from "react";
import { AlertTitle, Snackbar, SnackbarProps, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import { SingleAlertInfo, hideSingleAlert } from "../../redux/slice/commonSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";

interface CustomSnackbarOrigin {
    vertical: "top" | "bottom";
    horizontal: "left" | "right" | "center";
}

interface CustomAlertProps {
    open: boolean;
    onClose?: () => void;
    singleAlertObj: SingleAlertInfo | null;
    variant?: "filled" | "outlined";
    SnackbarProps?: SnackbarProps;
    autoHideDuration?: number;
    anchorOrigin?: CustomSnackbarOrigin;
}

const CustomSingleAlert: React.FC<CustomAlertProps> = ({
    open,
    onClose,
    singleAlertObj,
    SnackbarProps,
    variant,
    autoHideDuration,
    anchorOrigin,
}) => {

    const dispatch = useAppDispatch();

    const onCloseErrorPopup = () => {
        dispatch(hideSingleAlert());
    }

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration || 5000}
            onClose={onClose ? onClose : onCloseErrorPopup}
            anchorOrigin={anchorOrigin || { vertical: "top", horizontal: "center" }}
            {...SnackbarProps}
        >
            <Alert
                onClose={onClose ? onClose : onCloseErrorPopup}
                severity={singleAlertObj?.alertType}
                sx={{ width: "100%" }}
                variant={variant || "filled"}
            >
                {singleAlertObj?.title &&
                    <AlertTitle>
                        <Typography variant="subtitle1" fontWeight="bold">
                            {singleAlertObj?.title}
                        </Typography>
                    </AlertTitle>}
                {singleAlertObj?.message}
            </Alert>
        </Snackbar>
    );
};

export default CustomSingleAlert;