import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { useForm } from "react-hook-form";
import { DeleteOutline } from "@mui/icons-material";
import VerificationDialog from "./VerificationDialog";
import Button from "../common/Button";
import {
  User2faDTO,
  fetchAllUserTwoFactorList,
  inactivate2FAoption,
  useTwoFactorReducer,
} from "../../redux/slice/2FA/twoFactorSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";

export type accountProfile = {
  account: string | undefined;
};

const MyAccount = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { control } = useForm<accountProfile>();

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const { User2FaListInfo } = useTwoFactorReducer();
  const { inactivateOptionStatus } = useTwoFactorReducer();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllUserTwoFactorList(true));
  }, []);

  const events = [
    {
      color: "blue",
      title: "kanishka.liyanage@gmail.com",
      date: "18 Jun 2024 2:03 pm",
      isActive: true,
      type: "email",
    },
    {
      color: "cyan",
      title: "kanishka.liyanage2@gmail.com",
      date: "16 Jun 2024 12:03 pm",
      isActive: false,
      type: "email",
    },
    {
      color: "orange",
      title: "kanishka.liyanage3@gmail.com",
      date: "15 Jun 2024 11:03 am",
      isActive: false,
      type: "email",
    },
    {
      color: "green",
      title: "011-123123123",
      date: "15 Jun 2024 11:03 am",
      isActive: false,
      type: "phone",
    },
    {
      color: "blue",
      title: "011-111111111",
      date: "18 Jun 2024 2:03 pm",
      isActive: false,
      type: "phone",
    },
  ];

  const emailEvents =
    User2FaListInfo &&
    User2FaListInfo.filter((event) => event.methodType === "EMAIL");
  const phoneEvents =
    User2FaListInfo &&
    User2FaListInfo.filter((event) => event.methodType === "MOBILE");

  const disableOption = (option: User2faDTO) => {
    dispatch(inactivate2FAoption(option.user2faId));
  };

  useEffect(() => {
    if (inactivateOptionStatus === "success")
      dispatch(fetchAllUserTwoFactorList(true));
  }, [inactivateOptionStatus]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold" }}
          color={colors.info[300]}
        >
          2-Factor Authentication
        </Typography>
      </Grid>
      <Grid item md={3} xs={0} spacing={2}></Grid>
      <Grid item md={6} xs={12} spacing={2}>
        <Box
          sx={{
            padding: 2,
            borderRadius: "10px",
            backgroundColor: colors.grey[900],
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", color: colors.info[200] }}
          >
            Emails
          </Typography>
          <Box sx={{ position: "relative" }}>
            {emailEvents &&
              emailEvents.map((event, index) => (
                <>
                  <Box mt={1}>
                    <Typography
                      component="span"
                      sx={{ fontWeight: "bold", mr: 2 }}
                      color={colors.info[300]}
                    >
                      {event.methodValue}
                      {!event.isPrimary && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => disableOption(event)}
                        >
                          <DeleteOutline color="error" />
                        </IconButton>
                      )}
                    </Typography>
                    {event.isPrimary && (
                      <Chip
                        label="Primary"
                        sx={{
                          backgroundColor: "#004d40",
                          color: "#00e676",
                          fontWeight: "bold",
                        }}
                      />
                    )}
                  </Box>
                </>
              ))}
          </Box>
          <Grid
            container
            justifyContent="flex-end"
            spacing={1}
            sx={{ flexWrap: "wrap" }}
          >
            <Grid item xs={12} sm="auto">
              <Button
                variant="contained"
                fullWidth
                onClick={() => setEmailDialogOpen(true)}
              >
                Add Email
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {false && (
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              padding: 2,
              borderRadius: "16px",
              backgroundColor: colors.grey[900],
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: colors.info[200] }}
            >
              Phone Numbers
            </Typography>
            <Box>
              {phoneEvents &&
                phoneEvents?.map((event, index) => (
                  <>
                    <Typography
                      component="span"
                      sx={{ fontWeight: "bold" }}
                      color={colors.info[300]}
                    >
                      {event.methodValue}
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => disableOption(event)}
                      >
                        <DeleteOutline color="error" />
                      </IconButton>
                    </Typography>
                    {event.isPrimary && (
                      <Chip
                        label="Active"
                        sx={{
                          backgroundColor: "#004d40",
                          color: "#00e676",
                          fontWeight: "bold",
                          marginLeft: 1,
                        }}
                      />
                    )}
                  </>
                ))}
            </Box>
            <Grid
              container
              justifyContent="flex-end"
              spacing={1}
              sx={{ flexWrap: "wrap" }}
            >
              <Grid item xs={12} sm="auto">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setPhoneDialogOpen(true)}
                >
                  Add Phone
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}

      <VerificationDialog
        open={emailDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
        type="email"
      />
      <VerificationDialog
        open={phoneDialogOpen}
        onClose={() => setPhoneDialogOpen(false)}
        type="phone"
      />
    </Grid>
  );
};

export default MyAccount;
