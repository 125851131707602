import { Box, Grid, Collapse, Popover, useTheme, useMediaQuery } from "@mui/material";
import Header from "../../common/Header";
import CustomTextField from "../../common/CustomTextField";
import {
  AddCircleOutlineOutlined,
  BrowserUpdated,
  CloudUpload,
  EditNote,
  ListAltOutlined,
  ModeOutlined,
  SearchOutlined,
  TableViewOutlined,
  ViewWeekOutlined,
} from "@mui/icons-material";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import LegacyToolBar from "../../common/LegacyToolBar";
import {
  SearchInvoiceInfo,
  fetchInvoiceList,
  invoiceInfo,
  useInvoiceSlice,
} from "../../../redux/slice/invoice/InvoiceSlice";
import CustomDatePicker from "../../common/CustomDatePicker";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AgencyInfo,
  fetchAgenciesList,
  fetchManufacturersList,
  setSingleAlertObj,
  SingleAlertInfo,
  useCommonReducer,
} from "../../../redux/slice/commonSlice";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  getPracticesByUserRole,
  usePracticeSlice,
} from "../../../redux/slice/practiceSlice";
import {
  selectIsTokenAvailable,
  selectIsValueFoundInToken,
  selectTokenValues,
  useAuthSlice,
} from "../../../redux/slice/authSlice";
import { CAN_ACCESS_APPLICATION, USER_ROLES, INVOICE_STATUS } from "../../../constants/applicationConstants";
import { UserRole, fetchAllUserRoles } from "../../../redux/slice/userSlice";
import ColumnToggle from "../../common/ColumnToggle";
import dayjs from "dayjs";
import CustomCheckbox from "../../common/CustomCheckBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import UploadPaymentsPopup from "./UploadPaymentsPopup";
import DateRangePicker from "../../common/CustomDateRangePicker";
import { priceFormatter, sortNames } from "../../../utils/Utility";
import CustomIconButton from "../../common/CustomIconButton";
import CustomDateRange from "../../common/CustomDateRange";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";
import { DateObject } from "react-multi-date-picker";

interface Column {
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface invoiceTableRow extends invoiceInfo {
  checkBox: JSX.Element;
}

const InvoiceList = () => {
  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<SearchInvoiceInfo>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const legacyItems = [{ icon: <ModeOutlined color="info" />, text: "Edit" }];
  const { manufacturerInfoList, agencyInfoList } = useCommonReducer();
  const { practiceByUserRole } = usePracticeSlice();
  const { userInfo } = useAuthSlice();
  const { invoiceList } = useInvoiceSlice();
  const [showCard, setShowCard] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [showUploadPaymentsPopup, setShowUploadsPaymentsPopup] =
    useState(false);
  const [ selectedAgencyId, setSelectedAgencyId ] = useState<number | null>(null);

  const ITEMS_PER_PAGE = 10;

  const canAccessUploadPayments = useSelector((state: RootState) => selectIsValueFoundInToken("canUploadPayments", INVOICE_STATUS.INVOICE)(state));
  const canAccessUploadCommisions = useSelector((state: RootState) => selectIsValueFoundInToken("canUploadPayments", INVOICE_STATUS.COMMISSION)(state));
  const canAccessCreateAGYPayout = useSelector((state: RootState) => selectIsValueFoundInToken("canCreatePayOut", INVOICE_STATUS.AGENCY)(state));
  const canAccessCreateOVRAGYPayout = useSelector((state: RootState) => selectIsValueFoundInToken("canCreatePayOut", INVOICE_STATUS.OVERRIDE)(state));
  const canAccessImportInvoices = useSelector((state: RootState) => selectIsTokenAvailable("canImportInvoices")(state));
  
  const restrictedInvoiceColumns = useSelector((state: RootState) => selectTokenValues("restrictedInvoiceColumns")(state));

  useEffect(() => {
    dispatch(fetchManufacturersList());
    fetchPracticeListByUserRole();
    dispatch(fetchAgenciesList());
  }, []);
  
  useEffect(() => {
      let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.INVOICE_MANAGEMENT + "SearchCriteria" + userInfo.userId);
      if ((location.state && location.state.invoiceListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
        if (location.state && location.state.withSearchHistory) {
          navigate("/invoice_list", {state: {invoiceListSearchCriteria : criteria}});
          return;
        }
        let searchCriteria: SearchInvoiceInfo = {
          fromDate: "",
          toDate: "",
          mfgInvoiceNumber: "",
          mfgIds: [],
          agencyIds: [],
          practiceIds: [],
          invoiceStatus: [],
          currentPage: 1,
          itemsPerPage: ITEMS_PER_PAGE,
          agyPayStatus: [],
          v3PayStatus: [],
          ovrAgyPayStatus: [],
        };
        if (location.state && location.state.invoiceListSearchCriteria) {
          searchCriteria = location.state.invoiceListSearchCriteria;
        } else {
          searchCriteria = criteria ? criteria : searchCriteria;
        }
        setValuesToCriteriaFields(searchCriteria);
        
        searchInvoiceList(searchCriteria.currentPage, searchCriteria);
      } else {
        searchInvoiceList(1);
      }
  }, [location.state, practiceByUserRole, manufacturerInfoList]);


  const setValuesToCriteriaFields = (searchCriteria: SearchInvoiceInfo) => {
    setValue("mfgInvoiceNumber", searchCriteria.mfgInvoiceNumber);
    setValue("mfgIds", searchCriteria.mfgIds);
    setValue("practiceIds", searchCriteria.practiceIds);
    setValue("invoiceStatus", searchCriteria.invoiceStatus);
    setValue("currentPage", searchCriteria.currentPage);
    setValue("itemsPerPage", searchCriteria.itemsPerPage);
    setValue("agyPayStatus", searchCriteria.agyPayStatus);
    setValue("agencyIds", searchCriteria.agencyIds);
    setValue("v3PayStatus", searchCriteria.v3PayStatus);
    setValue("ovrAgyPayStatus", searchCriteria.ovrAgyPayStatus);
  }

  const invoiceStatusOptions = [
    { label: "PAID", value: "PAID" },
    { label: "UNPAID", value: "UNPAID" }
  ];

  const invoicesStatusOptions = [
    { label: "PARTIAL", value: "PARTIAL" },
    { label: "PAID", value: "PAID" },
    { label: "UNPAID", value: "UNPAID" }
  ];

  const manufacturesList = useMemo(() => {
    let options: { label: string; value: any }[] = [];
    manufacturerInfoList.map((item) => {
      options.push({
        label: item.manufacturerName,
        value: item.manufacturerId,
      });
    });
    return options;
  }, [manufacturerInfoList]);

  const agenciesList = useMemo(() => {
    let options: { label: string; value: any }[] = [];
    if (agencyInfoList) {
      const sortedAgencies: AgencyInfo[] = sortNames(agencyInfoList, 'agencyName');
      sortedAgencies.map((item) => {
        options.push({
          label: item.agencyName,
          value: item.agencyId,
        });
      });
    }
    return options;
  }, [agencyInfoList]);

  const practiceList = useMemo(() => {
    if (userInfo.userRole === USER_ROLES.ROLE_PRACTICEADMIN) {
      let options: { label: string; value: any }[] = [];
      if (practiceByUserRole) {
        setValue("practiceIds", [Number(practiceByUserRole[0]?.practiceId)]);
        practiceByUserRole.map((item) => {
          options.push({ label: item.practiceName, value: item.practiceId });
        });
      }
      return options;
    } else {
      let options: { label: string; value: any }[] = [
        { label: "ALL", value: "" },
      ];
      if (practiceByUserRole) {
        practiceByUserRole.map((item) => {
          options.push({ label: item.practiceName, value: item.practiceId });
        });
      }
      return options;
    }
  }, [practiceByUserRole]);

  const fetchPracticeListByUserRole = async () => {
    const response = await dispatch(fetchAllUserRoles());
    if (response?.meta?.requestStatus === "fulfilled") {
      if (response?.payload) {
        const useRoles = response.payload;
        const role = useRoles?.find(
          (role: UserRole) => role.roleName === userInfo.userRole
        );
        if (role) {
          dispatch(getPracticesByUserRole(false));
        }
      }
    }
  };

  const onSubmit = (data: SearchInvoiceInfo) => {
    const {checkBox, selectAllCheckBox, ...submitObj} = data;
    submitObj.currentPage = 1;
      navigate("/invoice_list", {state: {invoiceListSearchCriteria : submitObj}});
  };

  const handleColumnToggleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColumnToggleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const pagination = (pageNumber: number) => {
    let obj = getValues();
    obj.currentPage = pageNumber + 1;
    navigate("/invoice_list", {state: {invoiceListSearchCriteria : obj}});
    setValue('selectAllCheckBox', false);
    formattedInvoiceRows?.map(row => {
      //@ts-ignore
      setValue(`checkBox.invoice_${row.invoiceId}`, false);
    });
    setSelectedAgencyId(null);
  };

  const checkboxComponent = (row: invoiceInfo): JSX.Element => {
    return (
      <>
        <CustomCheckbox
          control={control}
          color="primary"
          controllerName={`checkBox.invoice_${row.invoiceId}`}
          onChange={(e) => handleCheckbox(e, row.agencyId)}
          isDisabled={selectedAgencyId ? selectedAgencyId !== row.agencyId : false}
        />
      </>
    );
  };

  const handleCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    agencyId: number
  ) => {
    const { checked } = e.target;
    if(!checked) {
      setValue("selectAllCheckBox", false);
      setSelectedAgencyId(null);
    } else {
      setSelectedAgencyId(agencyId);
    }
  };

  const columnsInvoice: Column[] = [
    { id: "checkBox", label: <CustomCheckbox control={control} controllerName="selectAllCheckBox" isDisabled={!selectedAgencyId}  onChange={(e) => handleSelectAllCheckBox(e)}/>, minWidth: 20 },
    { id: "mfgInvoiceNumber", label: "Invoice #", minWidth: 10 },
    { id: "mfgInvoiceDate", label: "Date", minWidth: 10 },
    { id: "manufacturerName", label: "Manufacture", minWidth: 10 },
    { id: "practiceName", label: "Practice", minWidth: 10 },
    { id: "agencyName", label: "Agency/Rep", minWidth: 10 },
    { id: "invoiceStatus", label: "Invoice Status", minWidth: 10, align: "right" },
    { id: "invoiceAmount", label: "Invoice Amt", minWidth: 10, align: "right" },
    {
      id: "v3InvoicePaidAmount",
      label: "Invoice Paid Amt",
      minWidth: 10,
      align: "right",
    },
    {
      id: "v3CommissionAmount",
      label: "V3 Comm",
      minWidth: 10,
      align: "right",
    },
    { id: "v3CommissionPaidAmount", label: "V3 Comm Paid", minWidth: 10, align: "right" },
    {
      id: "agencyCommissionAmount",
      label: "AGY Comm",
      minWidth: 10,
      align: "right",
    },
    { id: "agencyCommissionPaidAmount", label: "Agy Comm Paid", minWidth: 10, align: "right" },
    {
      id: "ovrAgencyCommissionAmount",
      label: "Over AGy Comm",
      minWidth: 10,
      align: "right",
    },
    {
      id: "ovrAgencyCommissionPaidAmount",
      label: "Over AGy Comm Paid",
      minWidth: 10,
      align: "right",
    },
    { id: "v3CommNet", label: "V3 Comm Net", minWidth: 10, align: "right" },
    {
      id: "action",
      label: "Action",
      minWidth: 100,
    },
  ];

  const actionComponent = (row: invoiceTableRow): JSX.Element => {
    return (
      <>
        <CustomIconButton
          color="info"
          tooltipMessage="Details"
          tooltipPlacement="top"
          variant="outlined"
          size="small"
          onClick={() =>
            navigate("/billing_detail", { state: { invoiceId: row.invoiceId } })
          }
        >
          <ListAltOutlined fontSize="small" />
        </CustomIconButton>
      </>
    );
  };

  const formattedInvoiceRows: invoiceTableRow[] | [] = useMemo(() => {
    let formatRows: invoiceTableRow[] = [];
    invoiceList?.content?.map((row) => {
      let formatRow: invoiceTableRow = {
        ...row,
        invoiceAmount: priceFormatter(Number(row.invoiceAmount)),
        v3InvoicePaidAmount: priceFormatter(Number(row.v3InvoicePaidAmount)),
        v3CommissionAmount: priceFormatter(Number(row.v3CommissionAmount)),
        v3CommissionPaidAmount: priceFormatter(Number(row.v3CommissionPaidAmount)),
        agencyCommissionAmount: priceFormatter(
          Number(row.agencyCommissionAmount)
        ),
        agencyCommissionPaidAmount: priceFormatter(Number(row.agencyCommissionPaidAmount)),
        ovrAgencyCommissionAmount: priceFormatter(
          Number(row.ovrAgencyCommissionAmount) 
        ),
        ovrAgencyCommissionPaidAmount: priceFormatter(Number(row.ovrAgencyCommissionPaidAmount)),
        v3CommNet: priceFormatter(Number(row.v3CommNet)),
        checkBox: checkboxComponent(row),
      };
      formatRows.push(formatRow);
    });
    return formatRows;
  }, [invoiceList, getValues()]);

  const formattedInvoiceColumns: Column[] | [] = useMemo(() => {
    let formatColumns: Column[] = [];

    // If user is an admin, return all columns
    if (userInfo?.userRole === USER_ROLES.ROLE_ADMIN) {
      return columnsInvoice;
    }

    if (Array.isArray(columnsInvoice)) {
      columnsInvoice.forEach((invoiceColumn) => {
        if (!restrictedInvoiceColumns.includes(invoiceColumn.id)) {
          formatColumns.push(invoiceColumn);
        }
      });
    }

    return formatColumns;
  }, [restrictedInvoiceColumns, columnsInvoice]);

  const handleSelectAllCheckBox = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
      formattedInvoiceRows?.map(row => {
        if(selectedAgencyId === row.agencyId){
        //@ts-ignore
        setValue(`checkBox.invoice_${row.invoiceId}`, checked ? true : false);
        }
      });
    
      if(!checked) {
        setSelectedAgencyId(null);
      }

  }, [formattedInvoiceRows, getValues('selectAllCheckBox')])

  const practiceIdsList = useMemo(() => {
    return (
      practiceByUserRole?.map((practice) => parseInt(practice.practiceId)) || []
    );
  }, [practiceByUserRole]);

  const mfgIdsList = useMemo(() => {
    return manufacturerInfoList?.map((mfg) => mfg.manufacturerId) || [];
  }, [manufacturerInfoList]);

  const agencyIdsList = useMemo(() => {
    return agencyInfoList?.map((agy) => parseInt(agy.agencyId)) || [];
  }, [agencyInfoList]);

  const searchInvoiceList = useCallback(
    (currentPage: number, obj?: SearchInvoiceInfo) => {
      let data: SearchInvoiceInfo = {
        fromDate: obj?.fromDate,
        toDate: obj?.toDate,
        mfgIds: obj?.mfgIds
          ? obj.mfgIds
          : mfgIdsList?.length === 1
          ? mfgIdsList
          : [],
        agencyIds: obj?.agencyIds
        ? obj.agencyIds
        : agencyIdsList?.length === 1
        ? agencyIdsList
        : [],
        practiceIds: obj?.practiceIds
          ? obj.practiceIds
          : practiceIdsList?.length === 1
          ? practiceIdsList
          : [],
        mfgInvoiceNumber: obj?.mfgInvoiceNumber ? obj.mfgInvoiceNumber : "",
        invoiceStatus: obj?.invoiceStatus ? obj.invoiceStatus : [],
        currentPage: currentPage,
        itemsPerPage: ITEMS_PER_PAGE,
        v3PayStatus: obj?.v3PayStatus
        ? obj.v3PayStatus : [],
        agyPayStatus: obj?.agyPayStatus
        ? obj.agyPayStatus : [],
        ovrAgyPayStatus: obj?.ovrAgyPayStatus
        ? obj.ovrAgyPayStatus : [],
      };
      setValue("currentPage", currentPage);
      setSearchCriteria(data);
      dispatch(fetchInvoiceList(data));
    },
    [practiceByUserRole, manufacturerInfoList]
  );

  const setSearchCriteria =  useCallback((data: SearchInvoiceInfo)=>{
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.INVOICE_MANAGEMENT + "SearchCriteria" + userInfo.userId, data);
  },[]);

  const handleDateRangeChange = (fromDate: DateObject | null, toDate: DateObject | null) => {
    const fromDateString = fromDate ? fromDate.format("MM/DD/YYYY") : null;
    const toDateString = toDate ? toDate.format("MM/DD/YYYY") : null;    
  
    setValue("fromDate",fromDateString?fromDateString:"");
    setValue("toDate", toDateString?toDateString:"");
  };

  const [uploadPopupInfo, setUploadPopupInfo] = useState({
    title: "",
    fileName: "",
    filePath: "",
  });

  const showPaymentPopup = (popupName: string) => {
    if (popupName === "Import Invoices") {
      setUploadPopupInfo({
        title: popupName,
        fileName: "V3Bio_Template_Import_Invoice_.xlsx",
        filePath: "/V3Bio_Template_Import_Invoice_.xlsx",
      });
    } else if (popupName === "Import Invoice Payments"){
      setUploadPopupInfo({
        title: popupName,
        fileName: "V3Bio_Template_Invoice_Payments.xlsx",
        filePath: "/V3Bio_Template_Invoice_Payments.xlsx",
      });
    } else {
      setUploadPopupInfo({
        title: popupName,
        fileName: "V3Bio_Template_Invoice_commission_Payments.xlsx",
        filePath: "/V3Bio_Template_Invoice_commission_Payments.xlsx",
      });
    }
    setShowUploadsPaymentsPopup(true);
  };

  const selectedInvList = () => {
    const checkedList: any= getValues('checkBox');
    const checkedIdList: number[] = Object.entries(
      checkedList
    )
    .filter(([invoiceIdString, isChecked]) => isChecked)
    .map(([invoiceIdString]) => parseInt(invoiceIdString.split("_")[1]));

    const selectedRows: invoiceInfo[]  = []
    checkedIdList.forEach((invId: number) => {
      const invoice = invoiceList?.content?.find(inv => inv.invoiceId === invId);
      if(invoice) {
        selectedRows.push(invoice)
      }
    });
    return selectedRows;
  }

  const navigateRecordPayout = () => {
    const selectedRows: invoiceInfo[] = selectedInvList();
    if(selectedRows?.length > 0) {
      navigate("/recordPayout", {state: {selectedInvoices : selectedRows}});
    } else {
      let errObj: SingleAlertInfo = {
        message: "Please select at least one invoice.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errObj));
    } 
  };

  const navigateAgyRecordPayout = () => {
    const selectedRows: invoiceInfo[] = selectedInvList();
    const isOvrAgenciesFound = selectedRows.every(row => row.overrideAgencyId);
    if(!selectedRows || selectedRows?.length == 0) {
      let errObj: SingleAlertInfo = {
        message: "Please select at least one invoice.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errObj)); 
    } else if (!isOvrAgenciesFound){
      let errObj: SingleAlertInfo = {
        message: "Please select invoice/s with override agencies.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errObj)); 
    } else {
      navigate("/overrideRecordPayout", {state: {selectedInvoices : selectedRows}});
    };
  };
 
  return (
    <>
      <Box m="20px">
        <Header title="Invoice List" subtitle="Invoice Management" />
        {showUploadPaymentsPopup && (
          <UploadPaymentsPopup
            open={showUploadPaymentsPopup}
            title={uploadPopupInfo.title}
            downloadFileName={uploadPopupInfo.fileName}
            downloadFilePath={uploadPopupInfo.filePath}
            onClose={() => {
              setShowUploadsPaymentsPopup(false);
            }}
          />
        )}
        <Box>
        <Collapse in={showCard}>
            <Card sx={{ mb: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  <Grid item md={3} xs={12}>
                    <CustomDateRange
                      label="Invoice Date Range"
                      control={control}
                      controllerName="dateRange"
                      onChange={handleDateRangeChange}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown
                      control={control}
                      name="mfgIds"
                      label="Manufacture"
                      options={manufacturesList}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown
                      control={control}
                      name="practiceIds"
                      label="Practice"
                      options={practiceList}
                      disabled={
                        userInfo.userRole === USER_ROLES.ROLE_PRACTICEADMIN
                      }
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown
                      control={control}
                      name="agencyIds"
                      label="Agency"
                      options={agenciesList}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField
                      controllerName="mfgInvoiceNumber"
                      control={control}
                      name="mfgInvoiceNumber"
                      label="Invoice Number"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown
                      control={control}
                      name="v3PayStatus"
                      label="V3 Pay Status"
                      options={invoiceStatusOptions}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown
                      control={control}
                      name="agyPayStatus"
                      label="AGY Pay Status"
                      options={invoiceStatusOptions}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown
                      control={control}
                      name="ovrAgyPayStatus"
                      label="OVR AGY Pay Status"
                      options={invoiceStatusOptions}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomMultipleDropDown
                      control={control}
                      name="invoiceStatus"
                      label="Invoice Status"
                      options={invoicesStatusOptions}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      size="large"
                      color="success"
                      startIcon={<SearchOutlined />}
                      style={{
                        padding: "15px",
                      }}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>
        <Box>
          <Card>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} md>
              {isMobile ?<LegacyToolBar items={legacyItems} /> : ""}
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => setShowCard(!showCard)}
                  startIcon={<SearchOutlined />}
                  fullWidth
                >
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
              {canAccessImportInvoices?
              <Grid item xs={12} md="auto">
                <Button
                  color="info"
                  startIcon={<BrowserUpdated />}
                  onClick={() => showPaymentPopup("Import Invoices")}
                  fullWidth
                >
                  Import Invoices
                </Button>
              </Grid>
              :""}
              {canAccessUploadPayments?
              <Grid item xs={12} md="auto">
                <Button
                  color="warning"
                  // startIcon={<CloudUpload />}
                  startIcon={<BrowserUpdated />}
                  onClick={() => showPaymentPopup("Import Invoice Payments")}
                  fullWidth
                >
                  Import INV Pmts
                </Button>
              </Grid>
              :""}
              {canAccessUploadCommisions?
              <Grid item xs={12} md="auto">
                <Button
                  color="info"
                  // startIcon={<CloudUpload />}
                  startIcon={<BrowserUpdated />}
                  onClick={() => showPaymentPopup("Import Manufacturer Payments")}
                  fullWidth
                >
                  Import MFR Pmts
                </Button>
              </Grid>
              :""}
              {canAccessCreateAGYPayout?
              <Grid item xs={12} md="auto">
                <Button
                  color="error"
                  startIcon={<EditNote />}
                  onClick={() => navigateRecordPayout()}
                  fullWidth
                >
                  Record AGY Pmts
                </Button>
              </Grid>
              :""}
              {canAccessCreateOVRAGYPayout?
              <Grid item xs={12} md="auto">
                <Button
                  color="secondary"
                  startIcon={<EditNote />}
                  onClick={() => navigateAgyRecordPayout()}
                  fullWidth
                >
                  Record OVR Pmts
                </Button>
              </Grid>
              :""}
              <Grid item xs={12} md="auto">
                <CustomIconButton
                  onClick={handleColumnToggleClick}
                  color="warning"
                  variant="contained"
                  tooltipMessage="Toggle Columns"
                  size="small"
                >
                  <TableViewOutlined />
                </CustomIconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleColumnToggleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ColumnToggle
                    columns={formattedInvoiceColumns}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                  />
                </Popover>
              </Grid>
            </Grid>
            <Table
              columns={formattedInvoiceColumns}
              rows={formattedInvoiceRows || []}
              emptyText="No Invoice(s) Found"
              totalPages={invoiceList?.totalPages}
              pageNumber={pagination}
              hiddenColumns={hiddenColumns}
              actionComponent={(row: invoiceTableRow) => actionComponent(row)}
              currentPageNumber={getValues("currentPage")? getValues("currentPage") - 1: 1}
            />
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default InvoiceList;
