import React, { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Button from "./Button";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorSharp } from "@mui/icons-material";

export type OptionPopupObjectProps = {
  show: boolean;
  message: React.ReactNode;
  variant?: "info" | "success" | "danger" | "warning" | "confirm" | "custom";
  buttons?: ButtonComponentProps[];
  title?: string;
  obj?: any;
};

export type ButtonComponentProps = {
  name: string;
  color:
    | "info"
    | "success"
    | "warning"
    | "primary"
    | "secondary"
    | "error"
    | undefined;
  onClick: (obj?: any) => void;
  icon?: React.ReactNode;
};

export type OptionsPopupProps = {
  open: boolean;
  onClose: () => void;
  onSubmit?: (obj?: any) => void;
  variant?: "info" | "success" | "danger" | "warning" | "confirm" | "custom";
  message: React.ReactNode;
  buttons?: ButtonComponentProps[];
  title?: string;
  obj?: any;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const OptionsPopup: React.FC<OptionsPopupProps> = ({
  open,
  onClose,
  onSubmit,
  variant,
  message,
  buttons,
  title,
  obj,
}) => {
  const data = useMemo(() => {
    let data: { popupTitle: string; icon: React.ReactNode } = {
      popupTitle: "",
      icon: <></>,
    };
    if (variant === "confirm")
      data = { popupTitle: "Confirmation", icon: <CheckCircleSharpIcon /> };
    else if (variant === "danger") data = { popupTitle: "Error", icon: <ErrorSharp /> };
    else if (variant === "info")
      data = { popupTitle: "Information", icon: <></> };
    else if (variant === "success")
      data = { popupTitle: "successful", icon: <></> };
    else if (variant === "warning")
      data = { popupTitle: "Warning", icon: <></> };
    else if (variant === "custom")
      data = { popupTitle: title || "", icon: <></> };
    return data;
  }, [open, variant, title]);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth = "md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
            {data.popupTitle}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            sx={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography gutterBottom>{data.icon}</Typography>
            <Typography gutterBottom>{message}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          {buttons ? (
            buttons.map((button) => {
              return (
                <Grid item xs={12} md={6}>
                  <Button
                    color={button.name === "Cancel" ? "error" : button.color}
                    onClick={() => button.onClick(obj)}
                    startIcon={button.icon}
                    size="large"
                    fullWidth
                  >
                    {button.name}
                  </Button>
                </Grid>
              );
            })
          ) : (
            <>
              <Button
                color="success"
                onClick={() => (onSubmit ? onSubmit(obj) : onClose)}
              >
                OK
              </Button>
              <Button color="error" onClick={() => onClose}>
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OptionsPopup;
