import { Box, Collapse, Grid, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../common/Header";
import CustomTextField from "../../common/CustomTextField";
import { AddCircleOutlineOutlined, ModeOutlined, SearchOutlined, } from "@mui/icons-material";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import LegacyToolBar from "../../common/LegacyToolBar";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import { SearchAgency, clearAgencyInfoList, searchAgency, useAgencyReducer, Option } from "../../../redux/slice/agency/AgencySlice";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { formatAddress, hideElement } from "../../../utils/Utility";
import CustomIconButton from "../../common/CustomIconButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectIsValueFoundInToken, useAuthSlice } from "../../../redux/slice/authSlice";
import { AGENCY_STATUS, CAN_ACCESS_APPLICATION } from "../../../constants/applicationConstants";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 200 },
  { id: "phone", label: "Phone", minWidth: 100 },
  { id: "address", label: "Address", minWidth: 250 },
  { id: "commType", label: "Commission Type", minWidth: 40 },
  { id: "action", label: "Action", minWidth: 100, },
];

const commTypeOptions: Option[] = [
  { label: "V3", value: "V3" }, 
  { label: "Agency", value: "Agency"}, 
  { label: "Override", value: "Override"}
]

interface AgencyRow {
  id: number | undefined;
  name: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  address: string | undefined;
  commType: string | undefined;
  action?: JSX.Element;
}

const ITEMS_PER_PAGE = 20;

const AgencyList = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { control, handleSubmit, reset, setValue } = useForm<SearchAgency>();
  const [showCard, setShowCard] = useState(false);

  const [searchCriteria, setSearchCriteria] = useState<SearchAgency>({
    agencyName: undefined,
    phone: undefined,
    email: undefined,
    address: undefined,
    commType: undefined,
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE
  });
  
  const { userInfo } = useAuthSlice();
  const { agencyInfoList } = useAgencyReducer();

  const canEditAgency = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessAgencyActions", AGENCY_STATUS.EDIT)(state));

  //Mount
  useEffect(() => {
  }, []);

  //Unmount
  useEffect(() => () => {
    dispatch(clearAgencyInfoList());
  }, []);

  useEffect(() => {
    //1. When login another user pagination is remain in the history and can redirect that page
    let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.AGENCY_MANAGEMENT + "SearchCriteria" + userInfo.userId);
    if ((location.state && location.state.agencyListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
      if (location.state && location.state.withSearchHistory) {
        //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
        navigate("/agency_list", { state: { agencyListSearchCriteria: criteria } });
        return;
      }
      let searchCriteria: SearchAgency = {
        agencyName: '',
        phone: '',
        email: '',
        address: '',
        commType: '',
        currentPage: 0,
        itemsPerPage: 0
      };
      if (location.state && location.state.agencyListSearchCriteria) {
        searchCriteria = location.state.agencyListSearchCriteria;
      } else {
        searchCriteria = criteria ? criteria : searchCriteria;
      }
      setSearchCriteria(searchCriteria);
      setValuesToCriteriaFields(searchCriteria);

      searchAgenciesList(searchCriteria);
    } else {
      loadInitialAgencyList(1);
    }
  }, [location.state]);

  const setValuesToCriteriaFields = (searchCriteria: SearchAgency) => {
    setValue("agencyName", searchCriteria.agencyName);
    setValue("phone", searchCriteria.phone);
    setValue("email", searchCriteria.email);
    setValue("address", searchCriteria.address);
    setValue("commType", searchCriteria.commType);
  }

  const loadInitialAgencyList = (pNumber: number = 1) => {
    const data: SearchAgency = {
      agencyName: '',
      phone: '',
      email: '',
      address: '',
      commType: '',
      currentPage: pNumber,
      itemsPerPage: ITEMS_PER_PAGE,
    };
    setSearchCriteria(data);
    setValuesToCriteriaFields(data);

    searchAgenciesList(data);
  }

  const searchAgenciesList = (searchCriteria: SearchAgency) => {
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.AGENCY_MANAGEMENT + "SearchCriteria" + userInfo.userId, searchCriteria);
    
    let dataToSearch = {
      ...searchCriteria,
    }

    dispatch(searchAgency(dataToSearch));
  }

  const onSubmit = (data: SearchAgency) => {
    const data2: SearchAgency = {
      agencyName: data.agencyName,
      phone: data.phone,
      email: data.email,
      address: data.address,
      commType: data.commType,
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE,
    }
    setSearchCriteria(data2);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/agency_list", {state: {agencyListSearchCriteria : data2}});
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      ...searchCriteria,
      currentPage: pageNumber + 1
    }
    setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/agency_list", { state: { agencyListSearchCriteria: data } });
  }

  const handleActionEdit = (row: AgencyRow) => {
    navigate("/agency_form", { state: { agency: { agencyId: row.id } } });
  }

  const actionComponent = (row: AgencyRow): JSX.Element => {
    return (
      <>
        <CustomIconButton
          color="info"
          size="small"
          variant="outlined"
          onClick={() => handleActionEdit(row)}
          tooltipMessage="Edit"
          tooltipPlacement="top"
          style={{display: `${hideElement(!canEditAgency) }`}}
        >
          <ModeOutlined fontSize="small"/>
        </CustomIconButton>
      </>
    );
  };

  const agencyList: AgencyRow[] = useMemo(() => {
    if (agencyInfoList?.content) {
      return agencyInfoList.content?.map((agency) => {
        return {
          id: agency.agencyId,
          name: agency.agencyName,
          phone: agency.phone,
          email: agency.email,
          address: formatAddress(
            agency.streetAddress,
            agency.unitNumber,
            agency.city,
            agency.state,
            agency.zipcode
          ),
          commType: agency.commissionType
        };
      });
    }
    return [];
  }, [agencyInfoList]);

  const legacyItems = [{ icon: <ModeOutlined color="info" />, text: "Edit" }];

  return (
    <>
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Agency List" subtitle="Agency Management" />
        <Box>
          <Collapse in={showCard}>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1.5} mt={-3} mb={-1}>
                  <Grid item md={4} xs={12}>
                    <CustomTextField control={control} controllerName="agencyName" label="Name" />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CustomPatternTextField control={control} name="phone" label="Phone" />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CustomTextField control={control} controllerName="email" label="Email" />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CustomTextField control={control} controllerName="address" label="Address" />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <CustomDropdownField control={control} name="commType" label="Commission Type" options={commTypeOptions} />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Button fullWidth size="large" color="success" startIcon={<SearchOutlined />} style={{ padding: "15px" }}  type="submit" >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>
        <Box mt={2}>
          <Card>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
              <Grid item xs = {12} md>
                {isMobile ?<LegacyToolBar items={legacyItems} /> : ""}
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button fullWidth color="success" startIcon={<AddCircleOutlineOutlined />} onClick={() => navigate("/agency_form")} >
                  Create Agency
                </Button>
              </Grid>
              </Grid>
            <Table
              columns={columns}
              rows={agencyList}
              actionComponent={actionComponent}
              totalPages={agencyInfoList?.totalPages}
              pageNumber={handlePagination}
              totalElementText={"Records: " + (agencyInfoList?.totalElements ? agencyInfoList?.totalElements : "0")}
              currentPageNumber={searchCriteria.currentPage - 1}
            />
          </Card>
        </Box>
      </Box>
    </>
  );

}

export default AgencyList;