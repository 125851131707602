// dropzone file extensions - start

// application types
export const pdf = {"application/pdf": [".pdf"]}
export const doc = {"application/msword": [".doc"]}
export const docx = {"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]}
export const xls = {"application/vnd.ms-excel": [".xls"]}
export const xlsx = {"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]}
export const zip = {"application/zip": [".zip"]}
//text types
export const csv = {"text/csv": [".csv"]}
export const txt = {"text/plain": [".txt"]}
export const rtf = {"text/rtf": [".rtf"]}
export const html = {"text/html": [".html", ".htm"]}
//audio types
export const mp3 = {"audio/mpeg": [".mp3"]}
export const wma = {"audio/x-ms-wma": [".wma"]}
//video types
export const mpg = {"video/mpeg": [".mpg"]}
export const flv = {"video/x-flv": [".flv"]}
export const avi = {"video/x-msvideo": [".avi"]}
//image types
export const jpeg = {"image/jpeg": [".jpg", ".jpeg"]}
export const png = {"image/png": [".png"]}
export const gif = {"image/gif": [".gif"]}

// dropzone file extensions - end

export const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const CAN_ACCESS_APPLICATION = {
  DASHBOARD : "DASHBOARD",
  IVR_MANAGEMENT : "IVR_MANAGEMENT",
  ORDER_MANAGEMENT : "ORDER_MANAGEMENT",
  USER_MANAGEMENT : "USER_MANAGEMENT",
  USER_CREATE : "USER_CREATE",
  PRACTICE : "PRACTICE",
  PATIENT: "PATIENT",
  PRODUCTS : "PRODUCTS",
  PRODUCT_AGREEMENT : "PRODUCT_AGREEMENT",
  AGENCY_MANAGEMENT : "AGENCY_MANAGEMENT",
  INVOICE_MANAGEMENT : "INVOICE_MANAGEMENT"
}

export const USER_ROLES = {
  ROLE_ADMIN : "ROLE_ADMIN",
  ROLE_PRACTICEADMIN : "ROLE_PRACTICEADMIN",
  ROLE_MANUFACTURERADMIN : "ROLE_MANUFACTURERADMIN",
  ROLE_MANUFACTURERSTAFF : "ROLE_MANUFACTURERSTAFF",
  ROLE_PRACTICESTAFF : "ROLE_PRACTICESTAFF",
  ROLE_BILLER : "ROLE_BILLER",
  ROLE_AGENCYADMIN : "ROLE_AGENCYADMIN",
  ROLE_AGENCYSTAFF : "ROLE_AGENCYSTAFF",
  ROLE_ACCOUNTEXECUTIVE : "ROLE_ACCOUNTEXECUTIVE",
};

export const PRACTICE_USER = "Practice Users";
export const AGENCY_USER = "Agency Users";
export const MANUFACTURE_USER = "Manufacturer Users";

export const AGREEMENT_STATUS = {
  INITIATED : "INITIATED",
  PRACTICE_SIGNED : "PRACTICE SIGNED",
  EXECUTED : "EXECUTED",
  INACTIVE : "INACTIVE",
  DELETED : "DELETED",
  HELD : "HELD"
};

export const AGREEMENT_ACTIONS = {
  DELETE : "DELETE",
  PREVIEW : "PREVIEW",
  INITIATE : "INITIATE",
  INACTIVATE : "INACTIVATE",
  AGREEMENT: "AGREEMENT"
}

export const PRODUCT_STATUS = {
  PLACED: "PLACED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  CANCELED: "CANCELED",
};

export const ORDER_INVOICE_STATUS = {
  PENDING: "PENDING",
  PARTIAL: "PARTIAL",
  INVOICED: "INVOICED",
};

export const ORDERTYPE = {
  DIRECT_ORDER : "Direct Purchase Order",
  CONSIGNMENT_ORDER : "Consignment Order"
};

export const BENEFIT_SUMMARY_STATUS = {
  DENIED : "DENIED",
  NEED_MORE_INFO: "NEEDS_MORE_INFO",
  VERIFIED: "VERIFIED"
}

export const PRODUCTS_DOCUMENT_TYPE = {
  PRODUCT_DOCUMENT : "Document",
	PRODUCT_TEMPLATE : "Template",
};

export const IVR_STATUS = {
  NEEDS_MORE_INFO : "NEEDS MORE INFO",
  SUBMITTED : "SUBMITTED",
  DENIED : "DENIED",
  VERIFIED : "VERIFIED",
  DRAFTED : "DRAFTED",
  RESPOND: "RESPOND",
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
  VIEW: "VIEW",
  NOTE: "NOTE"
};

export const ORDER_ACTIONS = {
  RETURN: "RETURN",
  TRACKING: "TRACKING",
  DETAILS: "DETAILS",
  FULFILL: "FULFILL",
  BILLING: "BILLING"
};

export const ORDER_STATUS = {
  PLACED : "PLACED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  CANCELED: "CANCELED"
};

export const AGENCY_STATUS = {
  EDIT: "EDIT",
};

export const PRACTICE_STATUS = {
   VIEW: "VIEW",
   EDIT: "EDIT",
   PREVIEW: "PREVIEW",
   APPROVE: "APPROVE"
}

export const COMMISSION_STATUS = {
  CREATE: "CREATE",
  EDIT: "EDIT",
}

export const INVOICE_STATUS = {
  INVOICE: "INVOICE",
  COMMISSION: "COMMISSION",
  AGENCY: "AGENCY",
  OVERRIDE: "OVERRIDE",
}

 export const adminRoleId = 1;
 export const practiceAdminRoleId = 2;
 export const practiceStaffRoleId = 3;
 export const billerRoleId = 4;
 export const agencyAdminRoleId = 5;
 export const accountExecutiveRoleId = 6;
 export const manufacturerAdminRoleId = 7;
 export const manufacturerStaffRoleId = 8;

 export const TWO_FACTOR_METHOD_TYPE_EMAIL = "EMAIL";
export const TWO_FACTOR_METHOD_TYPE_MOBILE = "MOBILE";
export const TWO_FACTOR_METHOD_TYPE_GOOGLE_AUTH = "GOOGLE_AUTH";
export const TWO_FACTOR_METHOD_TYPE_MICROSOFT_AUTH = "MICROSOFT_AUTH";
export const TWO_FACTOR_REGISTRATION = "REGISTRATION";
export const TWO_FACTOR_AUTHENTICATION = "AUTHENTICATION";
export const TWO_FACTOR_VERIFICATION_CODE_LENGTH = 6;
export const TWO_FACTOR_SIGNIN = "REGISTRATION";
export const TWO_FACTOR_VERIFICATION = "VERIFICATION";


export const IVR_STATUS_LIST = "IVRStatus";
export const PRODUCT_AGREEMENT_STATUS_LIST = "AgreementStatus";

export const FETCH_TYPE_SUCCESS = "success";

export const RESPONSE_CODES = {
  SUCCESS_CODE : "V3OK",
  FAILED_CODE : "V3ER001"
};

export const SHIFT_KEY = 9;
export const SHIFT_TEXT = "V3BioMed";

export const MSG_ACTIVE_PRODUCT_AGREEMENT_FOUND = "There is an active agreement already exists for the given inputs.";

export const MSG_NO_AGENCY_FOUND = "No Agency Found";
export const MSG_NO_USER_FOUND = "No User Found";
export const MSG_NOT_AN_ACTIVE_EXECUTIVE = "Given Practice Executive is not active.";
export const MSG_PRACTICE_EXECUTIVE_NOT_FOUND = "No Practice Executive Found";

export const MSG_PRACTICE_BILLER_NOT_FOUND = "No Practice Biller Found";
export const MSG_NOT_AN_ACTIVE_BILLER = "Given Practice Billler is not active.";
export const MSG_BILLER_NOT_FOUND = "No Biller Found";