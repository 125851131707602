import React, { useCallback, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Divider, Grid } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import Button from "./Button";
import CustomDatePicker from "./CustomDatePicker";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";

export type ValidationProps = {
    type : 'isBefore' | 'isAfter' | 'isSame';
    date : string;
}

interface DatePickerPopupProps {
    title: string;
    label: string;
    open: boolean;
    validationArray?: ValidationProps[];
    submitDate: (date: string)=> void;
    isDisablePast?: boolean;
    isDisableFuture?: boolean;
    onClose: () => void;
    maxDate?: Date;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DatePickerPopup: React.FC<DatePickerPopupProps> = ({
    title,
    label,
    open,
    submitDate,
    validationArray,
    isDisablePast,
    isDisableFuture,
    maxDate,
    onClose
}) => {

    const { control, handleSubmit, reset} = useForm<{date: string}>({ defaultValues: {date: ""} });

    useEffect(()=>{
        if (!open)
            reset()
    },[open]);

    const validateDate = useCallback((value:  string | number | Date | dayjs.Dayjs | null | undefined)=>{
        let date = value? dayjs(value): null;
        if (!date?.isValid())
            return "Invalid Date";
        let errors =  validationArray?.map((el: ValidationProps)=>{
            if (el.type === 'isAfter' && date?.isAfter(dayjs(el.date)))
                return `${label} date should not be a future date to ${el.date}`;
            if (el.type === 'isBefore' && date?.isBefore(dayjs(el.date)))
                return `${label} date should be a future date to ${el.date}`;
            if (el.type === 'isAfter' && date?.isSame(dayjs(el.date)))
                return `${label} date should not be a same date to ${el.date}`;
        })

        return (errors && errors.length > 0)? errors[0]: true;
        

    },[open])

    const onSubmit = useCallback((data: {date: string}) => {
        submitDate(dayjs(data.date).format("MM/DD/YYYY"));
      }, [open]);

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                maxWidth={"lg"}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{title}</DialogTitle>
                <Divider orientation="horizontal" variant="fullWidth" flexItem />
                <DialogContent >
                    <Box p={1}>
                        <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                        <CustomDatePicker
                    label={label}
                    openTo="day"
                    controllerName="date"
                    control={control}
                    format="MM/DD/YYYY"
                    disableFuture={isDisableFuture}
                    disablePast={isDisablePast}
                    maxDate={maxDate}
                    rules={{
                      validate: {
                      isValidDate: (
                        value:
                        | string
                        | number
                        | Date
                        | dayjs.Dayjs
                        | null
                        | undefined
                      ) =>
                      validateDate(value),
                      },
                    }}
                  />
                </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" >Submit</Button>
                    <Button color="error" onClick={onClose} startIcon={<Cancel />}>
                        Cancel
                    </Button>
                </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default DatePickerPopup;
