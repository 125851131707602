import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import Card from "../../common/Card";
import {
  OrderInfo,
  OrderReturn,
  addProductReturn,
  getOrderItemsByOrderId,
  useOrderSlice,
} from "../../../redux/slice/order/OrderSlice";
import { useForm, useWatch } from "react-hook-form";
import { Cancel, ClassOutlined } from "@mui/icons-material";
import Dropzone from "../../common/Dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDatePicker from "../../common/CustomDatePicker";
import { tokens } from "../../../theme";
import React, { useEffect, useMemo, useState } from "react";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import moment from "moment";
import SignaturePad, {
  SignatureData,
} from "../../common/signature/SignaturePad";
import {
  SingleAlertInfo,
  setSingleAlertObj,
} from "../../../redux/slice/commonSlice";
import { OrderItemRow } from "./TissueTracking";
import CustomCheckbox from "../../common/CustomCheckBox";
import dayjs, { Dayjs } from "dayjs";
import {
  CodeTable,
  fetchAllCodeTablesValues,
  getCodeTableValuesByTableName,
  useCodetablesSlice,
} from "../../../redux/slice/codetableSlice";
import OptionsPopup, {
  OptionPopupObjectProps,
} from "../../common/OptionsPopup";
import { useAuthSlice } from "../../../redux/slice/authSlice";
import { fullName, sortNames } from "../../../utils/Utility";
import { boolean } from "yup";

interface ExtendedFile extends File {
  documentId?: string;
}

interface Attachment {
  documentName: string;
  content: string;
  fileType: string;
}

interface ReturnOrderInfo {
  orderId: number;
  productId: number;
  returnReason: string;
  otherReturnReason: string;
  returnDate: string;
  signature: string;
  name: string;
  orderItems: {}[];
  productNotDamaged: boolean;
  productNotTransfer: boolean;
  productStoredInRecommendedEnv: boolean;
  returnTrackingInfo: {
    returnCarrier: string;
    returnTracking: string;
  };

  attachmentBarcodeSticker: {
    documentName: string;
    content: string;
    fileType: string;
  }[];

  attachmentProductBoxPictures: {
    documentName: string;
    content: string;
    fileType: string;
  }[];
}

interface FormattedOrderItem {
  orderItemId: number;
  serialNumber?: any;
}

interface ExtendedReturnOrder extends ReturnOrderInfo {
  checkbox: string[];
  selectAll: boolean;
}

const Returns = () => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<ExtendedReturnOrder>({
    defaultValues: {
      orderId: 0,
      productId: 0,
      returnReason: "",
      otherReturnReason: "",
      returnDate: dayjs(new Date()).format("MM/DD/YYYY"),
      signature: "",
      name: "",
      orderItems: [],
      productNotDamaged: false,
      productNotTransfer: false,
      productStoredInRecommendedEnv: false,
      returnTrackingInfo: {
        returnCarrier: "",
        returnTracking: "",
      },
      attachmentBarcodeSticker: [],
      attachmentProductBoxPictures: [],
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userInfo } = useAuthSlice();
  const [orderInfo, setOrderInfo] = useState<OrderInfo>();
  const { orderItemsListInfo } = useOrderSlice();
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [ checkedList, setCheckedList ] = useState<number[]>([]);
  const [formattedOrderItems, setFormattedOrderItems] = useState<
    FormattedOrderItem[]
  >([]);
  const { allCodeTablesValuesMap } = useCodetablesSlice();
  const [isAllAppliedOrReturned, setIsAllAppliedOrReturned] = useState(true);
  const [showOptionPopup, setShowOptionsPopup] =
    useState<OptionPopupObjectProps>({ message: "", show: false });
  const today = dayjs().toDate(); // Initialize today's date using dayjs
  const [showOtherReturnOption, setShowOtherReturnOption] = useState(false);
  const [formInteracted, setFormInteracted] = useState(false);
  // Mount
  useEffect(() => {
    const orderDetails: OrderInfo = location?.state?.orderDetails;
    setOrderInfo(orderDetails);
    dispatch(getOrderItemsByOrderId(orderDetails.orderId));
    setValue("orderId", orderDetails.orderId);
    setValue("productId", orderDetails.productId);
  }, []);

  const handleSignatureChange = (data: SignatureData) => {
    setValue("signature", data.signature);
    setValue("name", data.typedText);
  };

  const onSubmit = async (data: ReturnOrderInfo) => {
    if (isAllAppliedOrReturned) {
      let warningObj: SingleAlertInfo = {
        message: "Order items aren't available to Return.",
        alertType: "warning",
      };
      dispatch(setSingleAlertObj(warningObj));
    } else if (
      !(
        data.productNotDamaged ||
        data.productNotTransfer ||
        data.productStoredInRecommendedEnv
      )
    ) {
      let warningObj: SingleAlertInfo = {
        message:
          "Please select atleast one statement of the return product status",
        alertType: "warning",
      };
      dispatch(setSingleAlertObj(warningObj));
    } else {
      if (formattedOrderItems.length === 0) {
        let errObj: SingleAlertInfo = {
          message: "Please select atleast one Order item.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errObj));
      } else {
        setShowOptionsPopup({
          message: "Do you really want to Return the order? ",
          show: true,
          variant: "confirm",
          buttons: [
            {
              name: "Confirm",
              color: "primary",
              onClick: () => returnOrder(data),
            },
            { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
          ],
        });
      }
    }
  };

  const returnOrder = async (data: any) => {
    const returnOrderObject: OrderReturn = {
      orderId: parseInt(data.orderId),
      productId: parseInt(data.productId),
      returnReason: data.returnReason,
      otherReturnReason: data.otherReturnReason,
      returnTrackingInfo: data.returnTrackingInfo,
      returnDate: data.returnDate
        ? moment(data.returnDate?.toString())?.format("MM/DD/YYYY")
        : "",
      productNotDamaged: data.productNotDamaged,
      productNotTransfer: data.productNotTransfer,
      productStoredInRecommendedEnv: data.productStoredInRecommendedEnv,
      signature: data.signature,
      name: data.name,
      orderItems: formattedOrderItems,
      attachmentBarcodeSticker: data.attachmentBarcodeSticker,
      attachmentProductBoxPictures: data.attachmentProductBoxPictures,
      additionalContext: data.additionalContext
    };
    const response = await dispatch(addProductReturn(returnOrderObject));
    if (response.payload.message === "SUCCESS") {
      let successObj: SingleAlertInfo = {
        message: "Order has Returned successfully.",
        alertType: "success",
      };
      dispatch(setSingleAlertObj(successObj));
      navigate(-1);
    }
  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const orderItemRow: OrderItemRow[] = useMemo(() => {
    let orderItems: OrderItemRow[] = [];

    if (orderItemsListInfo) {
      orderItems = orderItemsListInfo.map((item) => {
        //@ts-ignore
        setValue(`serialNumbers.serialNumber${item.orderItemId}`,item.serialNumber);
        return {
          select: <CustomCheckbox control={control} name="select" />,
          orderItemId: item.orderItemId,
          serialNumber: item.serialNumber,
          size: item.size,
          tissueTrackingId: item.tissueTrackingId,
          productReturnId: item.productReturnId,
        };
      });
    }

    return orderItems;
  }, [orderItemsListInfo]);

  const orderItemStatus = (row: OrderItemRow) => {
    if (row?.tissueTrackingId) {
      return "Applied";
    } else if (row?.productReturnId) {
      return "Returned";
    }
    return "Available";
  };

  const checkSerialNumberPresent = (row: OrderItemRow) => {
    if(checkedList.includes(row.orderItemId)) {
      return false;
    } 
    return true;
  };

  const handleFaceSheetChange = async (uploadedFiles: ExtendedFile[]) => {
    const readFileAsBase64 = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    const attachments: Attachment[] = await Promise.all(
      uploadedFiles.map(async (file) => {
        const base64Content = await readFileAsBase64(file);
        return {
          documentName: file.name,
          content: base64Content.split(",")[1],
          fileType: file.type,
        };
      })
    );

    setValue("attachmentBarcodeSticker", attachments);
  };

  const handleProductBoxPicturesChange = async (uploadedFiles: ExtendedFile[]) => {
    const readFileAsBase64 = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    const attachments: Attachment[] = await Promise.all(
      uploadedFiles.map(async (file) => {
        const base64Content = await readFileAsBase64(file);
        return {
          documentName: file.name,
          content: base64Content.split(",")[1],
          fileType: file.type,
        };
      })
    );

    setValue("attachmentProductBoxPictures", attachments);
  };

  const handleCheckboxChange = (e:React.ChangeEvent<HTMLInputElement> ,row: OrderItemRow) => {
    const { checked } = e.target;
    if (checked) {
        row && setCheckedList(prevValues => {
            const newValues : number[] = [...prevValues];
            newValues.push(row.orderItemId);
            return newValues;
        });
    } else {
        setValue('selectAll', false);
        setCheckedList(checkedList.filter(item => item !== row.orderItemId));
    }
  };

  const isCheckBoxDisable = (row: OrderItemRow) => {
    if (row?.tissueTrackingId || row?.productReturnId) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const newFormattedOrderItems: FormattedOrderItem[] = checkedList.map(orderItemId => {
      return {
        orderItemId: orderItemId,
        //@ts-ignore
        serialNumber: getValues(`serialNumbers.serialNumber${orderItemId}`)
      }
    });
    setFormattedOrderItems(newFormattedOrderItems);
  }, [checkedList, getValues()]);

  useEffect(() => {
    if (orderItemsListInfo) {
      const isAllItemAppliedOrReturned = orderItemsListInfo.every(
        (item) => item.productReturnId || item.tissueTrackingId
      );
      setIsAllAppliedOrReturned(!!isAllItemAppliedOrReturned);
    }
  }, [orderItemsListInfo]);

  const returnReasonList = useMemo(() => {
    let codeTableList: CodeTable[] = [];

    if (allCodeTablesValuesMap) {
      codeTableList = getCodeTableValuesByTableName(
        allCodeTablesValuesMap,
        "OrderReturn"
      );
    }
    const sortedProductList: CodeTable[] = sortNames(
      codeTableList,
      "displayValue"
    );

    if (sortedProductList) {
      let options = sortedProductList.map((option) => ({
        label: option.displayValue,
        value: option.displayValue,
      }));
      // used to set other option at the bottom of the list
      const otherOptionIndex = options.findIndex(
        (option) => option.value.toLowerCase() === "other"
      );
      const [otherOption] = options.splice(otherOptionIndex, 1);
      options.push(otherOption);
      return options;
    }
    return [];
  }, [allCodeTablesValuesMap]);

  const onChangeReturnReasonList = (event: any) => {
    if (event) {
      if (event && event.label === "Other") {
        setShowOtherReturnOption(true);
        setValue("returnReason", event.value);
      } else {
        setShowOtherReturnOption(false);
        setValue("returnReason", event && event.value);
      }
    }
  };

  const carrierOptions = useMemo(() => {
    let carriers: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      carriers = getCodeTableValuesByTableName(
        allCodeTablesValuesMap,
        "Carrier"
      );
    }
    if (!carriers || carriers.length === 0) {
      dispatch(fetchAllCodeTablesValues());
    }
    let options: { label: string; value: any }[] = [];
    carriers?.map((option) => {
      options.push({ label: option.displayValue, value: option.displayValue });
    });
    return options;
  }, [allCodeTablesValuesMap]);

  const onError = (_errors: any, e: any) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const signaturePadValidations = {
    name: {
      required: "Name is required.",
    },
    signature: {
      required: "Signature is required.",
    },
  };

  const handleSelectAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      const selectList: number[] = [];
      orderItemRow?.forEach(row => {
        if ((row?.tissueTrackingId === null && row?.productReturnId === null)) {
          selectList.push(row.orderItemId);
          //@ts-ignore
          setValue(`checkbox.order${row.orderItemId}`, true);
        };
      });
      setCheckedList(selectList)
    } else {
      setCheckedList([]);
      orderItemRow?.forEach(row => {
        if ((row?.tissueTrackingId === null && row?.productReturnId === null)) {
          //@ts-ignore
          setValue(`checkbox.order${row.orderItemId}`, false);
        };
      });
    };
  };


  return (
    <Box m="20px">
      {showOptionPopup.show && (
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      )}
      <Header title="Returns" subtitle="Order Management" />
      <Box>
        <Card>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Box p={1}>
              <Grid container spacing={2} mb={2}>
                <Grid item md={12}>
                  Please enter the IDs in the provided space or take a picture
                  of the barcode stickers and attach to the return form.
                </Grid>
                <Grid item md={12}>
                  Select the Grafts that are being returned.
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2} mb={2} mt={2}>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Patient :{" "}
                  </Typography>
                  <Typography style={{ paddingLeft: "85px" }} component="label">
                    {orderInfo?.patientName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Product :{" "}
                  </Typography>
                  <Typography style={{ paddingLeft: "80px" }} component="label">
                    {orderInfo?.productName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={3}>
                  <Typography component="label" fontWeight="bold">
                    Order # :{" "}
                  </Typography>
                  <Typography style={{ paddingLeft: "82px" }} component="label">
                    {orderInfo?.orderId}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography component="label" fontWeight="bold">
                    PO # :{" "}{orderInfo?.poNumber}
                  </Typography>
                  <Typography style={{ paddingLeft: "85px" }} component="label">
                    {orderInfo?.orderType}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2} mb={2} mt={1}>
                <Grid item md={4} xs={12}>
                  <CustomDropdownField
                    name="returnReason"
                    control={control}
                    options={returnReasonList}
                    onChangeSelect={onChangeReturnReasonList}
                    label="Return Reason"
                    rules={{
                      required: "Return Reason is required.",
                      validate: (value: string) =>
                        value.trim() !== "" || "Return Reason cannot be empty.",
                    }}
                  />
                </Grid>
                <Grid item md={8}></Grid>
                <Grid item md={6}>
                    <CustomTextField 
                    controllerName="additionalContext"
                    control={control}
                    label="Please provide any additional context or request regarding this return here"
                    multiline
                    rows={6}
                    />
                </Grid>
                {showOtherReturnOption && (
                  <Grid item xs={4}>
                    <Box display="flex" alignItems="center">
                      <CustomTextField
                        controllerName="otherReturnReason"
                        label="Enter the return reason"
                        onChange={(e) =>
                          setValue("otherReturnReason", e.target.value)
                        }
                        control={control}
                        rules={{ required: "Return Reason is required." }}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} mb={2} mt={1}>
                <Grid item md={8} mb={2}>
                  <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                    <ListItem>
                      <ListItemButton
                        onClick={() => {}}
                        sx={{ padding: 0, borderRadius: 2, fontSize: 14 }}
                      >
                        <ListItemIcon sx={{ width: "15%", textAlign: 'left' }}>
                          <CustomCheckbox
                            control={control}
                            controllerName="selectAll"
                            onChange={handleSelectAllCheckbox}
                            tooltipMessage="Select All"
                            tooltipPlacement="top"
                          />
                        </ListItemIcon>
                        <ListItemText sx={{ width: "30%" }}>
                          <b>Ordered Graphs</b>
                        </ListItemText>
                        <Typography sx={{ width: "30%" }}>
                          <b>Serial Number</b>
                        </Typography>
                        <ListItemText sx={{ width: "10%" }}></ListItemText>
                        <ListItemText sx={{ width: "10%" }}>
                          <b>Status</b>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    {orderItemRow?.map((row) => (
                      <ListItem key={row.orderItemId}>
                        <ListItemButton sx={{ padding: 0, borderRadius: 2 }}>
                          <ListItemIcon sx={{ width: "15%" }}>
                            <CustomCheckbox
                              onChange={(e) => handleCheckboxChange(e, row)}
                              isDisabled={isCheckBoxDisable(row)}
                              control={control}
                              controllerName={`checkbox.order${row.orderItemId}`}
                            />
                          </ListItemIcon>
                          <ListItemText sx={{ width: "30%" }}>
                            {row.size}
                          </ListItemText>
                          <CustomTextField
                            controllerName={`serialNumbers.serialNumber${row.orderItemId}`}
                            control={control}
                            label="Serial Number"
                            sx={{ width: "30%" }}
                            //@ts-ignore
                            isDisabled={checkSerialNumberPresent(row)}
                          />
                          <ListItemText sx={{ width: "10%" }}></ListItemText>
                          <ListItemText sx={{ width: "10%" }}>
                            {orderItemStatus(row)}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={4} xs={12}>
                  <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                    <Box display="flex" alignItems="center" mb={2} flex="1">
                      <Typography component="span">
                        Take clear pictures of the box that contains the product(s) you'd like to return. Upload the pictures below to verify that the box has not been opened, tampered with, damaged, or contaminated.
                      </Typography>
                    </Box>
                    <Dropzone
                      name="attachmentProductBoxPictures"
                      onFileChange={handleProductBoxPicturesChange}
                      control={control}
                      label="Pictures of the box that contains the product(s)"
                    />
                  </Box>
                </Grid>
                <Grid item md={1} xs={12}></Grid>
                <Grid item md={4} xs={12}>
                  <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                    <Box display="flex" alignItems="center" mb={4} flex="1">
                      <Typography component="span">
                        Take picture(s) of the Tissue ID barcode sticker(s) associated with the product(s) you'd like to return.
                      </Typography>
                    </Box>
                    <Dropzone
                      name="attachmentBarcodeSticker"
                      onFileChange={handleFaceSheetChange}
                      control={control}
                      label="Attachment Barcode Sticker"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={9} xs={12}>
                  Signature
                  <SignaturePad
                    onSignatureChange={handleSignatureChange}
                    name="signature"
                    textName="name"
                    control={control}
                    validationRules={
                      !isAllAppliedOrReturned
                        ? signaturePadValidations
                        : undefined
                    }
                    setSignOutside={
                      userInfo &&
                      fullName(
                        null,
                        userInfo.firstName,
                        userInfo.middleName,
                        userInfo.lastName
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}></Grid>
              <Grid container spacing={2} mb={2} mt={2} alignItems="center">
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center">
                    <Typography component="span" fontWeight="bold">
                      Return Date:
                    </Typography>
                    <Box ml={2}>
                      <CustomDatePicker
                        control={control}
                        controllerName="returnDate"
                        label=""
                        rules={
                          !isAllAppliedOrReturned
                            ? { required: "Return Date is required." }
                            : {}
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2} mt={2} alignItems="center">
                <Grid item xs={4}>
                  <CustomDropdownField
                    name={`returnTrackingInfo.${"returnCarrier"}`}
                    control={control}
                    options={carrierOptions}
                    label="Carrier"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center">
                    <CustomTextField
                      controllerName={`returnTrackingInfo.${"returnTracking"}`}
                      label="Shipping Tracking #"
                      control={control}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={0} mt={3}>
                <Grid item xs={12}>
                  <Typography>I confirm, to the best of my knowledge, that the following statements are true:</Typography>
                </Grid>
                <Grid item md={12}>
                  <CustomCheckbox
                    id={"12"}
                    control={control}
                    controllerName={`productNotDamaged`}
                    label="Product Returned has not been opened, tampered with, damaged or contaminated."
                    name="productNotDamaged"
                    rules={{
                      required:
                        "Please read and acknowledge the consent clauses to complete the return",
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <CustomCheckbox
                    id={"12"}
                    control={control}
                    controllerName={`productNotTransfer`}
                    label="The product did not transfer ownership or location from the original recipient entity."
                    name="productNotTransfer"
                    rules={{
                      required:
                        "Please read and acknowledge the consent clauses to complete the return",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomCheckbox
                    id={"12"}
                    control={control}
                    controllerName={`productStoredInRecommendedEnv`}
                    label="The product was stored on the recommended environment by the product manufacturer [59-86F/15-30C}"
                    name="productStoredInRecommendedEnv"
                    rules={{
                      required:
                        "Please read and acknowledge the consent clauses to complete the return",
                    }}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center" mt={4}>
                <Grid container>
                  <Grid item md={9} xs={12}>
                    <Header title="What happens next?" subtitle={undefined}></Header>
                    <p>Once you hit submit, this data will be go straight to the respective manufacturer.
                      Depending on the Manufacturer, you will either be emailed a return label to ship this back
                      out or you will already have a prepared return label that came inside the original shipment
                      packaging to return to the manufacturer.</p>
                  </Grid>
                </Grid>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="success"
                  startIcon={<ClassOutlined />}
                  sx={{ mr: 1 }}
                >
                  Submit
                </Button>
                <Button
                  color="error"
                  startIcon={<Cancel />}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default Returns;
