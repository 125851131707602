import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  Link,
  Divider,
  useMediaQuery,
} from "@mui/material";
import Header from "../common/Header";
import Card from "../common/Card";
import { tokens } from "../../theme";
import {
  ArrowBack,
  ArrowCircleRightOutlined,
  ArrowForward,
  EmailOutlined,
  LogoutOutlined,
  Padding,
  PhoneAndroid,
} from "@mui/icons-material";
import FormStepper from "../common/FormStepper";
import { useForm } from "react-hook-form";
import {
  TwoFactorSaveRequest,
  User2faDTO,
  fetchAllUserTwoFactorList,
  generate2faOTP,
  twoFaSignUp,
  useTwoFactorReducer,
  validate2faOTP,
} from "../../redux/slice/2FA/twoFactorSlice";
import CustomCheckbox from "../common/CustomCheckBox";
import Button from "../common/Button";
import OTPVerification from "./OTPVerification";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { logout, useAuthSlice } from "../../redux/slice/authSlice";
import {
  removeItemFromLocalStorage,
  setDataToCookies,
  setDataToLocalStorage,
} from "../../utils/StorageUtils";
import { useNavigate } from "react-router-dom";
import {
  SHIFT_KEY,
  SHIFT_TEXT,
  TWO_FACTOR_VERIFICATION_CODE_LENGTH,
} from "../../constants/applicationConstants";
import CustomSingleAlert from "../common/CustomSingleAlert";
import {
  SingleAlertInfo,
  hideSingleAlert,
  setSingleAlertObj,
  useCommonReducer,
} from "../../redux/slice/commonSlice";
import { reset2FAFetchStatus } from "../../redux/slice/2FA/twoFactorSlice";
import CustomRadioButton from "../common/CustomRadioButton";
import { authenticationString } from "../../types/CommonTypes";
import { encrypt } from "../../utils/EncryptorUtils";

const Retrieve2FA: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<User2faDTO | null>(null);
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [version, setVersion] = useState("private");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { User2FaListInfo, validate2faStatus, generate2faStatus } =
    useTwoFactorReducer();
  const { userInfo } = useAuthSlice();
  const { showSingleAlert, singleAlertObj } = useCommonReducer();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const steps = [
    "Introduction",
    "Get Verification Code",
    "Enter Verification Code",
  ];

  useEffect(() => {
    if (generate2faStatus === "success") {
      const alertObj: SingleAlertInfo = {
        message: "successfully sent the OTP code.",
        alertType: "success",
      };
      dispatch(setSingleAlertObj(alertObj));
    } else if (generate2faStatus === "error") {
      const alertObj: SingleAlertInfo = {
        message: "error while sending OTP code",
        alertType: "error",
      };
      dispatch(setSingleAlertObj(alertObj));
    }
    setTimeout(() => {
      dispatch(hideSingleAlert());
    }, 5000);
  }, [generate2faStatus]);

  useEffect(() => {
    if (validate2faStatus === "success") {
      setDataToCookies(
        encrypt(`2FA${userInfo?.userId}CHECK`, SHIFT_KEY),
        encrypt(userInfo.userId.toString() + SHIFT_TEXT, SHIFT_KEY),
        { expires: 90 }
      );
      setDataToCookies(`version`, encrypt(version, SHIFT_KEY), { expires: 90 });
      const alertObj: SingleAlertInfo = {
        message: "successfully verified your account.",
        alertType: "success",
      };
      dispatch(setSingleAlertObj(alertObj));
      navigate("/");
    } else if (validate2faStatus === "error") {
      const alertObj: SingleAlertInfo = {
        message: "error while validate the OTP",
        alertType: "error",
      };
      dispatch(setSingleAlertObj(alertObj));
    }
    setTimeout(() => {
      dispatch(hideSingleAlert());
    }, 5000);
  }, [validate2faStatus]);

  useEffect(() => {
    if (step === 1) dispatch(fetchAllUserTwoFactorList(true));
    if (step === 2) generateOTP();
  }, [step]);

  useEffect(
    () => () => {
      dispatch(reset2FAFetchStatus());
    },
    []
  );

  const handleOptionSelect = (option: User2faDTO) => {
    setSelectedOption(option);
  };

  const { control } = useForm<twoFaSignUp>();

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const generateOTP = () => {
    let obj: TwoFactorSaveRequest = {
      communicationValue: selectedOption?.methodValue || "",
      communicationType: selectedOption?.methodType || null,
      userId: selectedOption?.user2faId || null,
    };
    dispatch(generate2faOTP({ request: obj }));
  };

  const handleSubmitClick = () => {
    dispatch(validate2faOTP({ otp: otp }));
  };

  const radioHandleChange = (e: any) => {
    setVersion(e.target.value);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8}>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Two-factor authentication
            </Typography>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              Retrieve Two-factor authentication Management
            </Typography>
            <Card sx={{ padding: isMobile ? 2 : 5, marginBottom: 5 }}>
              <FormStepper
                activeStep={step}
                setActiveStep={setStep}
                steps={steps}
              >
                <Box pt={2}>
                  {step === 0 && (
                    <>
                      <Box sx={{ marginTop: 4 }}>
                        <Box display="flex" alignItems="center" mb={2}>
                          <ArrowCircleRightOutlined />
                          <Typography variant="h6" ml={1}>
                            Why have I reached this page?
                          </Typography>
                        </Box>
                        <Typography>
                          Your account is set up with a second layer security
                          and we would like to ensure your identity by sending a
                          temporary code to provide access to your account.
                        </Typography>
                      </Box>
                      <Box sx={{ marginTop: 4 }}>
                        <Box display="flex" alignItems="center" mb={2}>
                          <ArrowCircleRightOutlined />
                          <Typography variant="h6" ml={1}>
                            What do I need to do?
                          </Typography>
                        </Box>
                        <Typography>
                          We will send you a temporary code that needs to be
                          provided to the system before you can access the
                          remaining portion of the site.
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={4}>
                        <Box display="flex" justifyContent="flex-start">
                          <Button
                            onClick={handleLogout}
                            endIcon={<LogoutOutlined fontSize="small" />}
                          >
                            Logout
                          </Button>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            onClick={handleNext}
                            startIcon={<ArrowForward />}
                          >
                            Next
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {step === 1 && (
                    <>
                      <Box sx={{ marginTop: 4 }}>
                        <Box display="flex" alignItems="center" mb={2}>
                          <ArrowCircleRightOutlined />
                          <Typography variant="h6" ml={1}>
                            Get Your Verification Code
                          </Typography>
                        </Box>
                        <Typography>
                          Select one of the emails/ phone numbers from below to
                          receive your temporary verification code. you will
                          need this code on the next page.
                        </Typography>
                      </Box>
                      <Box sx={{ marginTop: 4 }}>
                        <Box display="flex" alignItems="center" mb={2}>
                          <ArrowCircleRightOutlined />
                          <Typography variant="h6" ml={1}>
                            Send identification code to one of the following
                            Email Address or Cell phone number(if updated):
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          {User2FaListInfo &&
                            User2FaListInfo.map((info) => (
                              <Grid item xs={12} key={info.user2faId}>
                                <Box
                                  display={isMobile ? "block" : "flex"}
                                  alignItems="center"
                                  borderRadius={2}
                                  bgcolor={
                                    selectedOption?.user2faId === info.user2faId
                                      ? colors.grey[600]
                                      : colors.grey[700]
                                  }
                                  onClick={() => handleOptionSelect(info)}
                                  sx={{ cursor: "pointer", padding: "15px" }}
                                >
                                  <CustomCheckbox
                                    checked={
                                      selectedOption?.user2faId ===
                                      info.user2faId
                                    }
                                    control={control}
                                  />
                                  {!isMobile &&
                                    (info.methodType === "EMAIL" ? (
                                      <EmailOutlined
                                        sx={{
                                          width: 20,
                                          height: 20,
                                          marginRight: 1,
                                        }}
                                      />
                                    ) : (
                                      <PhoneAndroid
                                        sx={{
                                          width: 20,
                                          height: 20,
                                          marginRight: 1,
                                        }}
                                      />
                                    ))}
                                  <Box>
                                    <Typography variant="body1">
                                      {info.methodValue}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                      <Box sx={{ marginTop: 4 }}>
                        <Box display="flex" alignItems="center" mb={2}>
                          <ArrowCircleRightOutlined />
                          <Typography variant="h6" ml={1}>
                            In order to receive proper email notifications,
                            kindly add email{" "}
                            <strong>noreply@v3biomed.com</strong> in your
                            address book.
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={4}>
                        <Button onClick={handleBack} startIcon={<ArrowBack />}>
                          Back
                        </Button>
                        <Button
                          onClick={handleNext}
                          disabled={!selectedOption}
                          startIcon={<ArrowForward />}
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  )}
                  {step === 2 && (
                    <Box>
                      <Box sx={{ marginTop: 4 }}>
                        <Box display="flex" alignItems="center" mb={2}>
                          <ArrowCircleRightOutlined />
                          <Typography variant="h6" ml={1}>
                            Enter Your Verification Code
                          </Typography>
                        </Box>
                        <Typography>
                          Please enter your verification code that you received
                          in your email or on your cell phone, the one you chose
                          in the previous screen, and press NEXT to proceed
                        </Typography>
                      </Box>
                      <Box sx={{ marginTop: 4 }}>
                        <Box display="flex" alignItems="center" mb={2}>
                          <ArrowCircleRightOutlined />
                          <Typography variant="h6" ml={1}>
                            Enter Verification code
                          </Typography>
                        </Box>
                        <Box>
                          <OTPVerification
                            control={control}
                            sendOtp={generateOTP}
                            submitCode={(value) => setOtp(value)}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ marginTop: 4 }}>
                        <Typography variant="h5" gutterBottom>
                          <strong>My Verification Code did not arrive</strong>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          Please allow extra time to receive your Verification
                          Code by Email. We will send the code immediately, but
                          many factors (software settings, etc.) may influence
                          how fast it appears in your inbox. If you have a Spam
                          folder, please check there once.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>
                            Please make sure you have added
                            software@v3biomedical.com in your address book.
                          </strong>
                        </Typography>
                        <Link href="#" variant="body1">
                          Request a new identification code
                        </Link>
                      </Box>
                      <Divider />
                      <Box sx={{ marginTop: 4 }} flexDirection="column">
                        <CustomRadioButton
                          control={control}
                          options={[
                            {
                              value: "private",
                              label:
                                "Private Computer (Select this option if this your work or home computer you use to access the system frequently. We will not ask you for verification code again for this computer for next 90 days.)",
                            },
                            {
                              value: "public",
                              label:
                                "Public Computer (Select this option if this is NOT your work or home computer. Be sure to sign out when you have finished and close all windows to end your session.)",
                            },
                          ]}
                          controllerName="version"
                          md={12}
                          xs={12}
                          onChange={radioHandleChange}
                          value={version}
                        />
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={4}>
                        <Button onClick={handleBack} startIcon={<ArrowBack />}>
                          Back
                        </Button>
                        <Button
                          onClick={handleSubmitClick}
                          disabled={
                            otp.length !==
                              TWO_FACTOR_VERIFICATION_CODE_LENGTH ||
                            version.length === 0
                          }
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </FormStepper>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <CustomSingleAlert
        open={showSingleAlert}
        singleAlertObj={singleAlertObj}
      />
    </>
  );
};

export default Retrieve2FA;
