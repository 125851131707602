import { Generate2faRequest, TwoFactorSaveRequest } from "../redux/slice/2FA/twoFactorSlice";
import { GET_ALL_USER2FA_LIST, INACTIVATE_TWO_FACTOR_OPTION, SAVE_TWO_FACTOR_COMMUNICATION_DETAIL, SEND_OTP, TWO_FACTOR_COMMUNICATION_DETAILS, VALIDATE_OTP } from "./URL";
import { http } from "./http";
 
export const getAll2faOptions = async() => {
    const response = await http.get(`${TWO_FACTOR_COMMUNICATION_DETAILS}`);
    const v3Response = response.data;
    const resData = v3Response.data;
    return resData;
};
 
export const inactivate2FAoptionById = async(user2faId: number) => {
    console.log(user2faId)
    const response = await http.get(`${INACTIVATE_TWO_FACTOR_OPTION}/${user2faId}`);
    const v3Response = response.data;
    const resData = v3Response.data;
    return resData;
};
 
export const generate2faOTPs = async(requestBody: TwoFactorSaveRequest) => {
    let obj = {
        methodType: requestBody.communicationType,
        methodValue: requestBody.communicationValue,
    }
    const response = await http.post(`${SEND_OTP}${requestBody.userId}`, obj, undefined, {skipErrorPopup: true});
    const v3Response = response.data;
    const resData = v3Response.data;
    return resData;
};
 
export const validate2faOTPs = async(otp: string) => {
    const response = await http.post(`${VALIDATE_OTP}`,  otp, undefined, {skipErrorPopup: true}
    );
    const v3Response = response.data;
    const resData = v3Response.data;
    return resData;
};
 
export const save2faCommunications = async(obj: Generate2faRequest) => {
    const response = await http.post(`${SAVE_TWO_FACTOR_COMMUNICATION_DETAIL}`, obj);
    const v3Response = response.data;
    const resData = v3Response.data;
    return resData;
};

export const getUser2FList = async (activeUser2faOnly : boolean) => {
    const response = await http.get(`${GET_ALL_USER2FA_LIST}?activeUser2faOnly=${activeUser2faOnly}`);
    const v3Response = response.data;
    const resData = v3Response.data;
    return resData;
  };