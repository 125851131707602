import React, { useEffect, useMemo } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Button from "../Button";
import type {
  ToolbarProps,
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { getFilePlugin } from '@react-pdf-viewer/get-file';


interface PDFViewerProps {
  base64String: string;
  fileName? : string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  defaultScale? : number
}

const PDFViewer: React.FC<PDFViewerProps> = ({
  base64String,
  fileName,
  isOpen,
  onClose,
  title,
  defaultScale
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getFilePluginInstance = getFilePlugin({
      fileNameGenerator: () => {
          return fileName || title || "Sample";
      },
  });

  const { DownloadButton } = getFilePluginInstance;

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Download: () => <DownloadButton />,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
  });

  const renderToolbar = (
    Toolbar: (props: ToolbarProps) => React.ReactElement
  ) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;


  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      defaultTabs[0], // Bookmarks tab
      // defaultTabs[1], // Thumbnails tab
    ],
  });
  const { renderDefaultToolbar } =
    defaultLayoutPluginInstance.toolbarPluginInstance;

  const blobUrl = useMemo(() => {
    try {
      const raw = atob(base64String);
      const uint8Array = new Uint8Array(raw.length);
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Failed to create PDF Blob URL:", error);
      return null;
    }
  }, [base64String]);

  useEffect(() => {
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [blobUrl]);

  if (!blobUrl) {
    return (
      <Typography color="error">
        Failed to load PDF. Please check the file format.
      </Typography>
    );
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="lg"
      PaperProps={{ sx: { borderRadius: 3 } }}
      onClose={onClose}
    >
      <DialogTitle sx={{ m: 0, p: 0 }}>
        <Box sx={{ m: 0 }} display="flex" justifyContent="space-between">
          <Box></Box>
          <Box sx={{ mt: 1, ml: 1 }}>{title}</Box>
          <IconButton>
            <CloseOutlined onClick={onClose} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <div style={{ height: "100vh" }}>
            <Viewer
              fileUrl={blobUrl}
              plugins={[defaultLayoutPluginInstance,getFilePluginInstance]}
              theme={theme.palette.mode}
              defaultScale={isMobile? 0.4 : defaultScale || 1}
            />
          </div>
        </Worker>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PDFViewer;
