import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slice/authSlice'
import practiceSlice from './slice/practiceSlice'
import woundCareInfoSlice from './slice/woundCareInfoSlice'
import patientSlice from './slice/patient/patientSlice'
import practiceRegistrationSlice from './slice/practice/practiceRegistrationSlice';
import codetableSlice from './slice/codetableSlice';
import productSlice from './slice/product/createProductSlice';
import practiceApproveSlice from './slice/practice/practiceApproveSlice';
import practiceEditSlice from './slice/practice/practiceEditSlice'
import productListSlice from './slice/product/productListSlice'
import practiceLocationSlice from './slice/practice/practiceLocationSlice'
import practiceProvidersSlice from './slice/practice/practiceProvidersSlice'
import practiceProductsSlice from './slice/practice/practiceProductSlice'
import commonSlice from './slice/commonSlice'
import createAgreementSlice from './slice/productAgreement/createAgreementSlice';
import practiceBillerSlice from './slice/practice/practiceBillerSlice'
import practiceExecutiveSlice from './slice/practice/practiceExecutiveSlice'
import userSlice from './slice/userSlice'
import createIVRSlice from './slice/ivr/createIVRSlice'
import ivrDocumentSlice from './slice/ivr/ivrDocumentSlice'
import OrderSlice from './slice/order/OrderSlice'
import agencySlice from './slice/agency/AgencySlice'
import twoFactorSlice from './slice/2FA/twoFactorSlice'
import InvoiceSlice from './slice/invoice/InvoiceSlice'
import PaymentSlice from './slice/payment/paymentSlice'
import woundCodesSlice from './slice/ivr/woundCodeSlice'

export const store = configureStore({
  reducer: {
    authSlice : authSlice,
    practiceSlice : practiceSlice,
    woundCareInfoSlice : woundCareInfoSlice,
    patientSlice : patientSlice,
    practiceRegistrationSlice: practiceRegistrationSlice,
    codetableSlice: codetableSlice,
    productSlice : productSlice,
    productListSlice : productListSlice,
    practiceApproveSlice: practiceApproveSlice,
    practiceEditSlice: practiceEditSlice,
    practiceLocationSlice: practiceLocationSlice,
    practiceProvidersSlice: practiceProvidersSlice,
    practiceProductsSlice: practiceProductsSlice,
    commonSlice: commonSlice,
    createAgreementSlice: createAgreementSlice,
    practiceBillerSlice: practiceBillerSlice,
    practiceExecutiveSlice: practiceExecutiveSlice,
    userSlice: userSlice,
    createIVRSlice: createIVRSlice,
    ivrDocumentSlice: ivrDocumentSlice,
    orderSlice: OrderSlice,
    agencySlice: agencySlice,
    twoFactorSlice: twoFactorSlice,
    invoiceSlice: InvoiceSlice,
    paymentSlice: PaymentSlice,
    woundCodesSlice: woundCodesSlice
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch