import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../common/Button";
import { Box, FormControl, Grid } from "@mui/material";
import CustomAddressTextField from "../../common/CustomAddressTextField";
import { useForm } from "react-hook-form";
import { PracticeLocationInfo } from "../../../redux/slice/practice/practiceLocationSlice";
import CustomRadioButton from "../../common/CustomRadioButton";
import { AddCircleOutlineOutlined, Cancel } from "@mui/icons-material";
import CustomAlert from "../../common/CustomAlert";

interface PracticeLocationProps {
  open: boolean;
  onClose: () => void;
  addPractice: (practiceLocation: PracticeLocationInfo) => void;
  practiceLocations?: PracticeLocationInfo[];
  billingAddressPanel: boolean;
  practiceLocationObject: PracticeLocationInfo;
}

export const initialState: any = {
  locationId: undefined,
  practiceId: undefined,
  streetAddress: '',
  unitNumber: '',
  city: '',
  state: '',
  zipCode: '',
  isBillingAddress: '',
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PracticeLocationPopup: React.FC<PracticeLocationProps> = ({
  open,
  onClose,
  addPractice,
  practiceLocations,
  billingAddressPanel,
  practiceLocationObject
}) => {
  const { control, handleSubmit, reset, setError, clearErrors, formState: { errors } } = useForm<PracticeLocationInfo>();
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");

  useEffect(() => {
    if (open) {
      reset(practiceLocationObject)
    }
    if (!open) {
      setDefaultValues();
    }
  }, [open]);

  const setDefaultValues = useCallback(() => {
    reset();
  }, []);

  const onSubmit = (data: PracticeLocationInfo) => {
    clearErrors();
    data.locationType = data.isBillingAddress === "yes" ? "BILLING" : "OTHER";

    if (data.isBillingAddress === "yes") {
      const alreadyHasBilling = practiceLocations?.some(loc => loc.isBillingAddress === "yes");
      const isLocationTypeBilling = practiceLocations?.some(loc => loc.locationType === "BILLING" && loc.inactiveDate === null);
      console.log(isLocationTypeBilling);

      if (alreadyHasBilling && practiceLocationObject.isBillingAddress !== "yes") {
        setCustomAlertMessage("A billing address already exists. Please update the existing billing address instead.");
        setShowCustomAlert(true);
        return;
      } 
      else if (isLocationTypeBilling && data.locationType === "BILLING") {
        setCustomAlertMessage("A billing address already exists. Please inactivate the existing billing address and add a new address.");
        setShowCustomAlert(true);
        return;
      }
    }
    addPractice(data)
    onClose();
  }

const closeCustomAlert = () => {
  setShowCustomAlert(false);
}

  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }} 
      >
        <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Manage Practice Location</DialogTitle>
        <DialogContent>
          <Box p={1}>
            <CustomAddressTextField control={control} isRequired={true}/>
            <br/>
              <Grid item>
                <FormControl fullWidth error={!!errors.isBillingAddress}>
                  <label>Is this also your billing address? *</label>
                  <CustomRadioButton control={control} controllerName="isBillingAddress"
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                    rules={{ required: "Billing Address is required." }}
                  />
                </FormControl>
              </Grid>
              {showCustomAlert && (
                <CustomAlert open={showCustomAlert} onClose={closeCustomAlert} severity="error"
                  message={customAlertMessage} alertTitle="Billing Address Alert" />
              )}
          </Box>
        </DialogContent>
        <DialogActions>
        <Grid container justifyContent="flex-end" sx={{ mt: 1, flexWrap: 'wrap' }} spacing={1}>
          <Grid item xs={12} sm="auto">
            <Button type="submit" startIcon={<AddCircleOutlineOutlined />} fullWidth >
                Add Practice Location
            </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button color="error" onClick={onClose}  startIcon={<Cancel />} fullWidth>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default PracticeLocationPopup;