import { createAsyncThunk,  createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import {  FETCH_WOUNDCAREINFO_URL } from "../../service/URL";
import { useAppSelector } from "./redux-hooks";

type WoundCareInfo = {
    woundCareFocus: string;
    avgWoundsTreatedPerMonth: string;
    placesOfServices: [],
    productOfInterest: [],
}

   type WoundCareState = {
    woundCareInfo: WoundCareInfo[] | null;
    woundCareFocus?: string[];
    placesOfServices?: string[];
    productOfInterest?: string[];
    WoundCareSaveInfo?: WoundCareInfo;
    status: string;
    error: string | null;
    isValid: true | false;
   }

   const initialState: WoundCareState = {
       woundCareInfo: null,
       status: "",
       error: null,
       isValid: false,
       placesOfServices: [],
       productOfInterest: [],
   };

   export const fetchWoundCareInfo = createAsyncThunk("woundCareFocus", async() => {
    const response = await http.get(FETCH_WOUNDCAREINFO_URL);
    const resData = response.data.map((option: any) => ({
      value: option,
      label: option,
    }));
    return resData;
  })

const woundCareInfoSlice = createSlice({
    name: "woundCareInfo",
    initialState,
    reducers: {
      updateWoundCareInfoObject: (state: WoundCareState, action) => {
        state.WoundCareSaveInfo = action.payload;
      },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchWoundCareInfo.pending, (state) => {
            state.status = "loading";
            state.error = null;
          })
        .addCase(fetchWoundCareInfo.fulfilled, (state, action) => {
            state.status = "success";
            state.isValid = true;
            state.woundCareFocus = action.payload;
          })
          .addCase(fetchWoundCareInfo.rejected, (state, action) => {
            state.status = "failed";
            state.isValid = false;
            state.error = action.error.message || "Failed to fetch wound care focus";
          });
    }
})

export const {updateWoundCareInfoObject} = woundCareInfoSlice.actions;
export const useWoundCareInfoSlice = () => useAppSelector((state) => state.woundCareInfoSlice);
export default woundCareInfoSlice.reducer;