import { Alert, Box, Collapse, Grid, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../common/Header";
import { AddCircleOutlineOutlined, DeleteOutlineOutlined, ModeOutlined, SearchOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Card from "../../common/Card";
import TextField from "../../common/TextField";
import Button from "../../common/Button";
import { useEffect, useState} from "react";
import { Controller, useForm } from "react-hook-form";
import Table from "../../common/CustomTable";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { usePatientReducer, deletePatient, getPatientList, SearchInfo } from "../../../redux/slice/patient/patientSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomAlert from "../../common/CustomAlert";
import LegacyToolBar from "../../common/LegacyToolBar";
import CustomIconButton from "../../common/CustomIconButton";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const PatientsList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  

  const {practiceList, patientList} = usePatientReducer();
  const { control, handleSubmit, reset } = useForm<SearchInfo>();
  const [showCard, setShowCard] = useState(false);

  useEffect(() => {
    //dispatch(getPatientList());
  }, []);

  const handleEditClick = (row: any) => {
    navigate("/editPatient?id=" + row?.id)
  };

  const handleDeleteClick = async (row: any) => {
    dispatch(deletePatient({patientId:row?.id}));
  };

  const columns: Column[] = [
    { id: "name", label: "Patient Name", minWidth: 100 },
    {
      id: "streetAddress",
      label: "Address",
      minWidth: 100,
      align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "action",
      label: "Action", // Rename the column to "Action"
      minWidth: 170,
      align: "center", // Align the button to the right
    },
  ];

  const legacyItems = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
  ];

  const actionComponent = (row:any): JSX.Element => {
    return (
      <>
        <CustomIconButton color="info" size="small" variant="outlined" tooltipMessage="Edit" tooltipPlacement="top" onClick={()=>handleEditClick(row)}>
          <ModeOutlined fontSize="small" />
          </CustomIconButton>
        <CustomIconButton color="error" size="small" variant="outlined" tooltipMessage="Delete" tooltipPlacement="top" onClick={()=>handleDeleteClick(row)}>
          <DeleteOutlineOutlined fontSize="small" />
        </CustomIconButton>
      </>
    );
  };

  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const handleSearchClick = () => {
    setShowErrorAlert(true);
  };

  const handleAddPatientClick = () => {
    setShowErrorAlert(true);
  };

  return (
    <>
      <Box m="20px">
        <Header title="Patients List" subtitle="Patients Management" />
        <Box mb="20px">
          <Collapse in={showCard}>
            <Card>
              <form>
                <Grid container spacing={3}>
                  <Grid item md={3} xs={12}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          id={field.name}
                          {...field}
                          placeholder="Patient Name"
                          label="Patient Name"
                          InputLabelProps={{
                            shrink: Boolean(field.value),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          id={field.name}
                          {...field}
                          placeholder="City"
                          label="City"
                          InputLabelProps={{
                            shrink: Boolean(field.value),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Controller
                      name="primaryInsurance"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          id={field.name}
                          {...field}
                          placeholder="Primary Insurance"
                          label="Primary Insurance"
                          InputLabelProps={{
                            shrink: Boolean(field.value),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Controller
                      name="secondaryInsurance"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          id={field.name}
                          {...field}
                          placeholder="Secondary Insurance"
                          label="Secondary Insurance"
                          InputLabelProps={{
                            shrink: Boolean(field.value),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomDropdownField
                      name="practiceName"
                      control={control}
                      options={[{ value: "a", label: "a" }, { value: "b", label: "b" }]}
                      label="Practice Name"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      size="large"
                      startIcon={<SearchOutlined />}
                      style={{
                        padding: "15px",
                      }}
                      fullWidth
                      onClick={handleSearchClick}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>

        <Box>
          <Card>
          <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs = {12} md>
              {isMobile ?<LegacyToolBar items={legacyItems} /> : ""}
              </Grid>
              <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  color="secondary"
                  startIcon={<AddCircleOutlineOutlined />}
                  fullWidth
                  onClick={handleAddPatientClick}
                >
                  Add Patient
                </Button>
              </Grid>
            </Grid>
          
            <Table
              columns={columns}
              rows={[]}
              actionComponent={actionComponent}
              emptyText="No Patient(s) Found"
            />
          </Card>
        </Box>
      </Box>
      <CustomAlert
        open={showErrorAlert}
        onClose={() => setShowErrorAlert(false)}
        severity="info"
        message="Feature currently under development. Stay tuned for updates!"
      />
    </>
  );
}

export default PatientsList;