import React, { ChangeEvent } from 'react';
import { Checkbox, FormControlLabel, FormHelperText, Tooltip, useTheme } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface CheckboxProps {
    color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
    id?: string;
    name?: string;
    label?: any;
    control: Control<any, any>
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    isDisabled?: boolean;
    controllerName?: string;
    rules?: object;
    tooltipMessage?:string;
    tooltipPlacement?:
    | "right"
    | "left"
    | "top"
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;

}

const CustomCheckbox: React.FC<CheckboxProps> = (props) => {

    const { color, id, name, label, control, onChange, checked, isDisabled, controllerName, rules, tooltipMessage, tooltipPlacement } = props;
    const theme = useTheme();

    
    const a = (checked: any , field: any) => {
        return checked || !!field.value
    }

    return (
        <Controller
            name={controllerName || ""}
            control={control}
            rules={rules? rules: {}}
            render={({ field, fieldState }) => (
                <>
                    <Tooltip
                        title={tooltipMessage}
                        placement={tooltipPlacement || "bottom"}
                        arrow
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={id}
                                    {...field}
                                    name={name ? name : controllerName}
                                    color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
                                    size="medium"
                                    onChange={(e) => {
                                        field.onChange(e);
                                        onChange && onChange(e);
                                    }}
                                    checked={checked || !!field.value}
                                    disabled={isDisabled}
                                    sx={{
                                        color: fieldState.error && !field.value
                                            ? theme.palette.error.main
                                            : '',
                                        '&.Mui-checked': {
                                            color: fieldState.error && !field.value
                                                ? theme.palette.error.dark
                                                : '',
                                        },
                                    }}
                                />
                            }
                            label={label}
                            sx={{ color: fieldState.error ? theme.palette.error.main : 'inherit' }}
                        />
                    </Tooltip>
                    {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                </>
            )}
        />
    );
}

export default CustomCheckbox;
