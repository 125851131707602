import * as React from "react";
import {
  DatePicker as DatePickerField,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

export type DatePickerFieldProps = {
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium";
  label?: string | "";
  variant?: "filled" | "outlined" | "standard" | undefined;
  fullWidth?: true | false;
  openTo? : "year" |"month" |"day";
  rules?: Object;
} & DatePickerProps<Date>;

const DatePicker: React.FC<DatePickerFieldProps> = ({
  size,
  color,
  label,
  variant,
  fullWidth,
  openTo,
  rules,
  ...props
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isRequired = () => {
    if (rules && typeof rules === "object" && "required" in rules && label) {
      return true;
    }
    return false;
  };

  const getLabel = () => {
    if (isRequired()) {
      return (
        <span>
          {label}{" "}
          <span style={{ color: colors.success[200], fontSize: "18px" }}>
            *
          </span>
        </span>
      );
    }
    return label;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerField
        label={getLabel() || ""}
        openTo={openTo || "day"}
        views={["year", "month", "day"]}
        slotProps={{
          textField: {
            size: size || "medium",
            color:
              color || theme.palette.mode === "dark" ? "secondary" : "primary",
            fullWidth: fullWidth || true,
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
