import { GET_PATIENT_LIST_BY_USER_ROLE } from "./URL";
import { http } from "./http";

export const fetchPatientsByUserRole = async () => {
    try {
        const response = await http.get(GET_PATIENT_LIST_BY_USER_ROLE);
        return response;
    } catch (error) {
        throw error;
    }
};