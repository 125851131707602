import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { fetchPracticeBillerById, updatePracticeBiller } from "../../../service/PracticeService";
import { useAppSelector } from "../redux-hooks";
import { SUCCESS_CODE } from "../../../service/ServiceConstants";

export type PracticeBillerInfo = {
  practiceBillerId: number;
  biller: {
    billerId: number;
    billerName: string;
    phone: string;
    email: string;
    streetAddress: string;
    unitNumber: string;
    city: string;
    state: string;
    zipcode: string;
  };
};

type PracticeBillerState = {
  practiceBillerInfo?: PracticeBillerInfo | null;
  status?: LoadingType;
  error?: string;
};

const initialState: PracticeBillerState = {
  practiceBillerInfo: undefined,
  status: "idle",
};

export const fetchPracticeBillerData = createAsyncThunk(
  "fetchPracticeBillerData",
  async (practiceId: string) => {
    const response = await fetchPracticeBillerById(practiceId);
    return response.data;
  }
);

export const updatePracticeBillerById = createAsyncThunk(
  "updatePracticeBiller",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updatePracticeBiller(data.practiceBillerId, data.billerId);
      if (response.data.code !== SUCCESS_CODE) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.data);
    }
  }
);

const practiceBillerSlice = createSlice({
  name: "practiceBiller",
  initialState,
  reducers: {
    updatePracticeBillerInfoObject: (state: PracticeBillerState, action) => {
      state.practiceBillerInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPracticeBillerData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchPracticeBillerData.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.status = "success";
          state.practiceBillerInfo = action.payload.data;
        }
      )
      .addCase(fetchPracticeBillerData.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(updatePracticeBillerById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        updatePracticeBillerById.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          if (action.payload.code === SUCCESS_CODE) {
            state.status = "success";
            state.practiceBillerInfo = action.payload.data;
          } else {
            state.status = "error";
            state.error = action.payload.message;
          }
        }
      )
      .addCase(updatePracticeBillerById.rejected, (state, action) => {
        state.status = "error";
        if (action.error.message) {
          state.error = action.error.message;
        } else {
          state.error = "An unknown error occurred.";
        }
      });
  },
});

export const { updatePracticeBillerInfoObject } = practiceBillerSlice.actions;
export const usePracticeBillerSlice = () =>
  useAppSelector((state) => state.practiceBillerSlice);
export default practiceBillerSlice.reducer;
