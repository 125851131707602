import React from 'react';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

interface CustomTooltipProps extends TooltipProps {
  color?: string;
}

const CustomTooltip = styled(({ className, color, ...props }: CustomTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: color || theme.palette.common.black,
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding : 5,
    paddingLeft : 10,
    paddingRight : 10,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: color || theme.palette.common.black,
    
  },
}));

export default CustomTooltip;
