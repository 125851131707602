import React, { useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Divider, Grid } from "@mui/material";
import Button from "../../common/Button";
import { Cancel, CheckCircleOutline } from "@mui/icons-material";
import CustomTable from "../../common/CustomTable";
import { Column, SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import { ProductItemInfo, activateProductItem, getProductItemHistory, useProductReducer } from "../../../redux/slice/product/createProductSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomIconButton from "../../common/CustomIconButton";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";

interface ProductItemHistoryProps {
    open: boolean;
    onClose: () => void;
}

interface Row {
    sku: string;
    inactiveDate: string;
    size: string;
    units: string;
    action: JSX.Element;
  }

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ProductItemHistoryPopup: React.FC<ProductItemHistoryProps> = ({
    open,
    onClose
}) => {
    const dispatch = useAppDispatch();
    const { productItemsHistory, productObject } = useProductReducer();
    const [showOptionPopup, setShowOptionsPopup] = useState<OptionPopupObjectProps>({ message: "", show: false });

    const columnsPriceItem: Column[] = useMemo(() => {
        return [
            { id: "sku", label: "SKU", minWidth: 100 },
            { id: "inactiveDate", label: "Inactive Date", minWidth: 100 },
            { id: "size", label: "Size", minWidth: 100 },
            { id: "units", label: "# of Units", minWidth: 100 },
            { id: "action", label: "Action", minWidth: 100 },
        ]
    }, [open]);

    useEffect(() => {
        if (open) {
            dispatch(getProductItemHistory({productId: productObject.productId}))
        }
    }, [open])

    const handleAlert = (message: string, type: "success" | "error" | "info") => {
        const alertObj: SingleAlertInfo = {
            message,
            alertType: type,
        };
        dispatch(setSingleAlertObj(alertObj));
    };

    const activateProductItemData = (row: ProductItemInfo) => {
        setShowOptionsPopup({
            message: "Do you really want to activate this product item ? ",
            show: true,
            variant: "confirm",
            buttons: [
                {
                    name: "Confirm",
                    color: "primary",
                    onClick: () => handleActionActiveProductItem(row),
                },
                { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
            ],
        });
    }

    const handleActionActiveProductItem = async (row: ProductItemInfo) => {

        const data = { productItemId: row.productItemId }
        setShowOptionsPopup({ message: "", show: false });

        try {
            const response = await dispatch(activateProductItem(data));

            if (response.payload.message === "SUCCESS") {
                dispatch(getProductItemHistory({ productId: productObject.productId }))
                handleAlert(`Product Item activated successfully.`, "success");
                return response;
            } else {
                throw new Error("Failed to activate product item.");
            }
        } catch (error) {
            handleAlert("Failed to activate product item.", "error");
            throw error;
        }
    };

    const actionComponent = (row: ProductItemInfo): JSX.Element => {
        return (
          <>
              <CustomIconButton
                color="success"
                size="small"
                variant="outlined"
                tooltipMessage="Activate Product Item"
                tooltipPlacement="top"
                onClick={() => activateProductItemData(row)}
              >
                <CheckCircleOutline fontSize="small" />
              </CustomIconButton>
          </>
        );
      };
    
    const productItemsHistoryData: Row[] = productItemsHistory ? productItemsHistory.map((item: ProductItemInfo) => ({
        sku: item.sku,
        inactiveDate: item.inactiveDate,
        size: item.size,
        units: item.units,
        action: actionComponent(item),
    })) : [];

    const cancelOptionPopup = () => {
        setShowOptionsPopup({ message: "", show: false });
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                maxWidth={"lg"}
            >
                {showOptionPopup.show && (
                    <OptionsPopup
                        open={showOptionPopup.show}
                        onClose={() => setShowOptionsPopup({ message: "", show: false })}
                        variant={showOptionPopup.variant}
                        message={<>{showOptionPopup.message}</>}
                        buttons={showOptionPopup.buttons}
                        title={showOptionPopup.title}
                        obj={showOptionPopup.obj}
                    />
                )}
                <DialogTitle>Item History</DialogTitle>
                <Divider orientation="horizontal" variant="fullWidth" flexItem />
                <DialogContent >
                    <Box p={1}>
                        <Grid container spacing={2}>
                            <CustomTable
                                columns={columnsPriceItem}
                                rows={productItemsHistoryData}
                                disablePagination={true}
                                borderBox={true}
                            />
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={onClose} startIcon={<Cancel />}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProductItemHistoryPopup;
