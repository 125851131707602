import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "./Button";
import {
  Cancel,
  CancelOutlined,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

interface AlertDialogProps {
  open: boolean;
  content: string;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ErrorDialog: React.FC<AlertDialogProps> = ({
  open,
  content,
  onClose,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Dialog
        sx={{
          backdropFilter: "blur(3px)",
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                  color: "white",
                }}
              >
                <CancelOutlined sx={{ fontSize: 48,color: colors.error[300] }} />
              </Box>
              <Typography sx={{ color: colors.error[300], textAlign: "center" }}>
                <b>{content}</b>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button color="error" startIcon={<Cancel />} onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ErrorDialog;
