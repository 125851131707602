import { Box, Grid, Collapse, useTheme, useMediaQuery } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import Button from "../../common/Button";
import { Checklist, ModeOutlined, NoteAddOutlined, SearchOutlined, VisibilityOutlined} from "@mui/icons-material";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { usePracticeSlice, searchPracticeList, SearchPracticeRequest, fetchDocumentById, resetPracticeInfo } from "../../../redux/slice/practiceSlice";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useForm } from "react-hook-form";
import CustomTextField from "../../common/CustomTextField";
import { fetchPracticeByIdData } from "../../../redux/slice/practice/practiceEditSlice"
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { fullName, formatDate, hideElement } from "../../../utils/Utility";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import PDFViewer from "../../common/pdf/PDFViewer";
import LegacyToolBar from "../../common/LegacyToolBar";
import CustomIconButton from "../../common/CustomIconButton";
import PracticeApprovePopup from "./PracticeApprovePopup";
import { selectIsValueFoundInToken, useAuthSlice } from "../../../redux/slice/authSlice";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { CAN_ACCESS_APPLICATION, PRACTICE_STATUS } from "../../../constants/applicationConstants";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left"; // Adjust the align property type
  format?: (value: number) => string;
}

interface Row {
  contactName: string;
  practiceName: string;
  approvalStatus: string;
  contactEmail: string;
  createdDate : String
  action: JSX.Element;
  practiceId: string;
}

// Define columns and rows data
const columns: Column[] = [
  { id: "practiceName", label: "Practice Name", minWidth: 100 },
  { id: "contactName", label: "Contact Name", minWidth: 100 },
  { id: "contactEmail", label: "Contact Email", minWidth: 100 },
  { id: "contactPhone", label: "Contact Phone Number", minWidth: 100 },
  { id: "approvalStatus", label: "Approval Status", minWidth: 100 },
  { id: "createdDate", label: "Created Date", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 170, align: "center", },
];

const ITEMS_PER_PAGE = 20;

const PracticeList = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { control, handleSubmit, reset, setValue } = useForm<SearchPracticeRequest>();

  const [showCard, setShowCard] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [showPracticesPopup, setShowPracticesPopup] = useState(false);

  const [searchCriteria, setSearchCriteria] = useState<SearchPracticeRequest>({
    practiceName: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    approvalStatus: '',
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const { practiceInfo, document } = usePracticeSlice(); // Use the practiceInfo from the Redux store
  const { userInfo } = useAuthSlice();

  const canAccessPracticeActionsView = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.VIEW)(state));
  const canAccessPracticeActionsEdit = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.EDIT)(state));
  const canAccessPracticeActionsPreview = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.PREVIEW)(state));
  const canAccessPracticeActionsApprove = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessPracticeActions", PRACTICE_STATUS.APPROVE)(state));

  const statusOptions = useMemo(() => {
    let options: { label: string, value: any }[] = [
      { value: "ALL", label: "ALL" },
      { value: "SUBMITTED", label: "SUBMITTED" },
      { value: "APPROVED", label: "APPROVED" },
      { value: "REJECTED", label: "REJECTED" },
    ];
    return options;
  }, []);

  //Mount
  useEffect(() => {
  }, []);

  //Unmount
  useEffect(() => () => {
    dispatch(resetPracticeInfo());
  }, []);

  useEffect(() => {
    //1. When login another user pagination is remain in the history and can redirect that page
    let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.PRACTICE + "SearchCriteria" + userInfo.userId);
    if ((location.state && location.state.practiceListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
      if (location.state && location.state.withSearchHistory) {
        //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
        navigate("/practice_list", { state: { practiceListSearchCriteria: criteria } });
        return;
      }
      let searchCriteria: SearchPracticeRequest = {
        practiceName: '',
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
        approvalStatus: '',
        currentPage: 0,
        itemsPerPage: 0
      };
      if (location.state && location.state.practiceListSearchCriteria) {
        searchCriteria = location.state.practiceListSearchCriteria;
      } else {
        searchCriteria = criteria ? criteria : searchCriteria;
      }
      setSearchCriteria(searchCriteria);
      setValuesToCriteriaFields(searchCriteria);

      searchPracticesList(searchCriteria);
    } else {
      loadInitialPracticeList(1);
    }
  }, [location.state]);

  const setValuesToCriteriaFields = (searchCriteria: SearchPracticeRequest) => {
    setValue("practiceName", searchCriteria.practiceName);
    setValue("contactFirstName", searchCriteria.contactFirstName);
    setValue("contactLastName", searchCriteria.contactLastName);
    setValue("contactEmail", searchCriteria.contactEmail);
    setValue("contactPhone", searchCriteria.contactPhone);
    setValue("approvalStatus", searchCriteria.approvalStatus);
  }

  const loadInitialPracticeList = (pNumber: number = 1) => {
    const data : SearchPracticeRequest = {
      practiceName: '',
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      approvalStatus: "",
      currentPage: pNumber,
      itemsPerPage: ITEMS_PER_PAGE,
    };
    setSearchCriteria(data);
    setValuesToCriteriaFields(data);
    
    searchPracticesList(data);
  }

  const searchPracticesList = (searchCriteria: SearchPracticeRequest) => {
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.PRACTICE + "SearchCriteria" + userInfo.userId, searchCriteria);
    
    let dataToSearch = {
      ...searchCriteria,
      approvalStatus: searchCriteria.approvalStatus === "ALL" ? "" : searchCriteria.approvalStatus,
    }

    dispatch(searchPracticeList(dataToSearch));
  }

  const onSubmit = (data: SearchPracticeRequest) => {
    const data2: SearchPracticeRequest = {
      practiceName: data.practiceName,
      contactFirstName: data.contactFirstName,
      contactLastName: data.contactLastName,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      approvalStatus: data.approvalStatus,
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE,
    }
    setSearchCriteria(data2);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/practice_list", {state: {practiceListSearchCriteria : data2}});
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      ...searchCriteria,
      currentPage: pageNumber + 1
    }
    setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state] and search
    navigate("/practice_list", { state: { practiceListSearchCriteria: data } });
  }
  
  const handleActionEdit = (row: any) => {
    const { approvalStatus } = row;
    navigate("/practice_edit_view", { state: { practiceEditInfo: { approvalStatus }, practiceId: row.practiceId } });
  }

  const handleActionPreview = async (row: any) => {
    if (row.associateAgreementDocumentId !== null) {
      const actionResult = await dispatch(fetchDocumentById(row.associateAgreementDocumentId));
      const documentData = actionResult.payload;
      if (documentData && documentData.content) {
        setShowPDF(true);
      }
    } else {
      let errorObj: SingleAlertInfo = {
        message: "Feature currently under development. Stay tuned for updates!",
        alertType: "info"
      }
      dispatch(setSingleAlertObj(errorObj));
    }
  }

  const handleApproveAction = async (row: any) => {
    const response = await dispatch(fetchPracticeByIdData(row.practiceId));
    if (response.payload) {
      navigate('/practice_request', { state: { rowValue: response.payload?.data } })
    }
  }

  const handlePracticeInfoPreview = async (row: any) => {
    const response = await dispatch(fetchPracticeByIdData(row.practiceId));
    if (response.payload) {
      clickShowPracticesPopup();
    }
  }

  const clickShowPracticesPopup = () => {
    setShowPracticesPopup(true);
  }

  const clickClosePracticesPopup = () => {
    setShowPracticesPopup(false);
  }

  const handleClose = () => {
    setShowPDF(false);
  }

  const actionComponent = (row: any): JSX.Element => {
    const { approvalStatus } = row;
    return (
      <>
        {approvalStatus === "APPROVED" || approvalStatus === "REJECTED" ? (
          <>
            <CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleActionEdit(row)} tooltipMessage="Edit" tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsEdit)}` }}>
              <ModeOutlined fontSize='small' />
            </CustomIconButton>
            {approvalStatus === "APPROVED" &&
              (<CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleActionPreview(row)}
                tooltipMessage="Preview" tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsPreview)}` }}>
                <VisibilityOutlined fontSize='small' />
              </CustomIconButton>)
            }
          </>
        ) : (
          <>
            <CustomIconButton color="success" size="small" variant="outlined" onClick={() => handleApproveAction(row)} tooltipMessage="Approve" tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsApprove)}` }}>
              <Checklist fontSize="small" />
            </CustomIconButton>
          </>
        )}
        <CustomIconButton color="info" size="small" variant="outlined" onClick={() => handlePracticeInfoPreview(row)} tooltipMessage="View Practice Request"
          tooltipPlacement='top' style={{ display: `${hideElement(!canAccessPracticeActionsView)}` }}>
          <NoteAddOutlined fontSize="small" />
        </CustomIconButton>
      </>
    );
  }

  const formattedPracticeInfo: Row[] = useMemo(() => {
    let practices: Row[] = [];
    if (practiceInfo) {
      practices = practiceInfo.content.map((practice: any) => ({
        practiceName: practice.practiceName,
        contactName: fullName(practice.title, practice.contactFirstName, practice.contactMiddleName, practice.contactLastName),
        contactEmail: practice.contactEmail,
        contactPhone: practice.contactPhone,
        approvalStatus: practice.approvalStatus,
        createdDate: formatDate(practice.requestTimestamp),
        action: actionComponent(practice),
        practiceId: practice.practiceId,
        title: practice.title,
        associateAgreementDocumentId: practice.associateAgreementDocumentId,
      }));
    };
    return practices;
  }, [practiceInfo]);

  const legacyItems = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <VisibilityOutlined color="info" />, text: "Preview" },
    { icon: <Checklist color="success" />, text: "Approve" },
    { icon: <NoteAddOutlined color="info" />, text: "View Practice Request" }
  ];

  return (
    <>
      { showPDF &&
        <PDFViewer
          isOpen={showPDF}
          onClose={handleClose}
          fileName={document?.documentName + ""}
          title={document?.documentName + ""}
          base64String={document?.content || ""}
        />
      }
      { showPracticesPopup &&
      <PracticeApprovePopup onClose={clickClosePracticesPopup} open={showPracticesPopup} />
      }
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Practice List" subtitle="Practice Management" />
        <Box>
          <Collapse in={showCard}>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1.5} mt={-3} mb={-1}>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="practiceName" label="Practice Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="contactFirstName" label="Contact First Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="contactLastName" label="Contact Last Name" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomTextField control={control} controllerName="contactEMail" label="Contact Email" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomPatternTextField control={control} label="Contact Phone Number" name="contactPhone" />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <CustomDropdownField name="approvalStatus" control={control} label="Practice Status" options={statusOptions} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button fullWidth size="large" startIcon={<SearchOutlined />} style={{ padding: "15px", }} type="submit" >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>
        <Box mt={2}>
          <Card>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
              <Grid item xs={12} md>
              {isMobile ?<LegacyToolBar items={legacyItems} /> : ""}
              </Grid>
              <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
            </Grid>
            <Table
              columns={columns}
              rows={formattedPracticeInfo || []}
              actionComponent={(row: any) => actionComponent(row)}
              emptyText="No Practice(s) Found"
              pageNumber={handlePagination}
              totalPages={practiceInfo?.totalPages}
              totalElementText={"Records: " + (practiceInfo?.totalElements ? practiceInfo?.totalElements : "0")}
              currentPageNumber={searchCriteria.currentPage - 1}
            />
          </Card>
        </Box>
      </Box>
    </>
  );

}

export default PracticeList;