import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Typography,
  useTheme,
  Box,
  Pagination,
  useMediaQuery,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { tokens } from "../../theme";
import { IVR_STATUS } from "../../constants/applicationConstants";

interface Column {
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface Row {
  [key: string]: any;
  uniqueId?: any;
}

interface Props {
  columns: Column[];
  rows: Row[];
  hiddenColumns?: string[];
  onRowClick?: (row: Row) => void;
  actionComponent?: (row: any, rowIndex: number) => JSX.Element;
  disablePagination?: boolean;
  borderBox?: boolean;
  emptyText?: string;
  pageNumber?: (newPage: number) => void;
  totalPages?: number;
  totalElementText?: string;
  resetPage?: boolean;
  currentPageNumber?: number;
  uniqueValue?: string;
  cardContentComponent?: (row: Row) => JSX.Element;
  disabled?: boolean;
}

const CustomTable: React.FC<Props> = ({
  columns,
  rows,
  hiddenColumns = [],
  onRowClick,
  actionComponent,
  disablePagination,
  borderBox,
  emptyText,
  pageNumber,
  totalPages,
  totalElementText,
  resetPage,
  currentPageNumber,
  uniqueValue,
  cardContentComponent,
  disabled,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState<Row | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuRow, setMenuRow] = useState<Row | null>(null);

  useEffect(() => {
    setPage(0);
  }, [resetPage]);

  useEffect(() => {
    if (currentPageNumber !== null && currentPageNumber !== undefined) {
      setPage(currentPageNumber);
    }
  }, [currentPageNumber]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    pageNumber && pageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row: Row) => {
    setSelectedRow(row);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: Row) => {
    setAnchorEl(event.currentTarget);
    setMenuRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRow(null);
  };
  
  const filteredColumns = columns.filter(
    (column) => !hiddenColumns.includes(column.id)
  );

  const renderTable = () => (
    <Box sx={{ width: "100%", overflowX: isMobile ? "auto" : "hidden", mt:1}}>
      {!isMobile && (
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ background: disabled ? theme.palette.mode === "dark" ? "#383838" : "#FFFFFF" : colors.grey[800] }}
          >
            <TableHead>
              <TableRow>
                {filteredColumns.map(
                  (column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, background: disabled ? theme.palette.mode === "dark" ? "#383838" : "#FFFFFF" : colors.grey[800]  }}
                      >
                        <Typography
                          fontWeight="bold"
                          color={
                            disabled? "#898989" :
                            theme.palette.mode === "dark"
                              ? colors.info[300]
                              : colors.grey[500]
                          }
                        >
                          {column.label}
                        </Typography>
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, rowIndex) => renderTableRow(row, rowIndex))
              ) : (
                <TableRow>
                  <TableCell colSpan={filteredColumns.length + 1} align="center">
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {emptyText || "No Data Found"}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isMobile && (
        <Box>
          {rows.length > 0 ? (
            rows.map((row, rowIndex) => renderCardView(row, rowIndex))
          ) : (
            <Box sx={{ textAlign: "center", padding: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {emptyText || "No Data Found"}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {!disablePagination && renderPagination()}
    </Box>
  );

  const renderTableRow = (row: Row, rowIndex: number) => {
    const isDrafted = row.status === IVR_STATUS.DRAFTED;
    const isNeedMoreInfo = row.status === IVR_STATUS.NEEDS_MORE_INFO;
    return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.uniqueId || rowIndex}
      onClick={() => {
        if (onRowClick) {
          handleRowClick(row);
          onRowClick(row);
        }
      }}
      style={{
        cursor: onRowClick ? "pointer" : "",
        backgroundColor:
          selectedRow &&
          uniqueValue &&
          selectedRow[uniqueValue] === row[uniqueValue]
            ? colors.grey[600]
            : "inherit",
      }}
    >
        {filteredColumns.map(
          (column) => (
              <TableCell key={column.id} align={column.align}>
                {column.id === "status"  ? (
                  // <Chip
                  //   label={row[column.id]}
                  //   sx={{
                  //     backgroundColor: theme.palette.mode === "dark" ? "#450D25" : "#D9D9D9",
                  //     color: { color: isDrafted ? colors.error[300] : colors.success[200] },
                  //     fontWeight: 'bold',
                  //     marginLeft: 1,
                  //   }}
                  // />
                  <span style={{ color: isDrafted ? "red" : isNeedMoreInfo ? "orange" : "inherit" }}>
                    {row[column.id]}
                  </span>
                ) :
                  column.id === "practice" && row[column.id].length > 20 ? (
                    <span title={row[column.id]}>{`${row[column.id].substring(
                      0,
                      20
                    )}...`}</span>
                  ) : column.format && typeof row[column.id] === "number" ? (
                    column.format(row[column.id])
                  ) : column.id === "action" && row[column.id] ? (
                    row[column.id]
                  ) : column.id === "action" && actionComponent ? (
                    actionComponent(row, rowIndex)
                  ) : (
                    row[column.id]
                  )}
              </TableCell>
            )
        )}
      </TableRow>
    );
  };

  const renderCardView = (row: Row, rowIndex: number) => (
    <Box key={rowIndex} sx={{ mb: 2 }}>
      <Box
        onClick={() => {
          if (onRowClick) {
            handleRowClick(row);
            onRowClick(row);
          }
        }}
        sx={{
          cursor: onRowClick ? "pointer" : "default",
          backgroundColor: colors.grey[900],
          borderRadius : 3,
          border :  1,
          borderColor : colors.primary[500],
        }}
      >
        <CardContent>
          {cardContentComponent ? (
            cardContentComponent(row)
          ) : (
            <Grid container spacing={2} alignItems="center">
              {filteredColumns.map(
                (column, index) =>
                  column.id !== "action" &&
                  (index === 0 ? (
                    <>
                      <Grid
                        item
                        xs={10}
                        key={column.id}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          sx={{ marginRight: 1 }}
                        >
                          {column.label} :
                        </Typography>
                        <Typography variant="body2">
                          {row[column.id]}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "right" }}>
                        <IconButton
                          onClick={(event) => handleMenuClick(event, row)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      key={column.id}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ marginRight: 1 }}
                      >
                        {column.label}:
                      </Typography>
                      <Typography variant="body2">{row[column.id]}</Typography>
                    </Grid>
                  ))
              )}
            </Grid>
          )}
        </CardContent>
      </Box>
    </Box>
  );

  const renderPagination = () => (
    <Box
      sx={{
        padding: 1,
        bottom: 0,
        background: colors.grey[900],
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs = {12} md>
          <Box display="flex" justifyContent="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={totalPages || Math.ceil(rows.length / rowsPerPage)}
              page={page + 1}
              onChange={(event, value) => handleChangePage(event, value - 1)}
              color={theme.palette.mode === "dark" ? "secondary" : "primary"}
              showFirstButton
              showLastButton
            />
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <label style={{textWrap: "nowrap", fontSize: "13px", alignSelf: "center"}}>{totalElementText ? totalElementText : ""}</label>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderMenuItems = () => {
    if (menuRow && actionComponent) {
      const actions = actionComponent(menuRow, 0);
      return React.Children.map(actions, (action, index) => (
        <MenuItem key={index}>
          {React.cloneElement(action as React.ReactElement)}
        </MenuItem>
      ));
    }
    return null;
  };

  return (
    <Box sx={borderBox ? tableBorderStyle : {}}>
      {renderTable()}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {renderMenuItems()}
      </Menu>
    </Box>
  );
};

const tableBorderStyle = {
  backgroundColor: (theme: any) =>
    theme.palette.mode === "dark"
      ? tokens(theme.palette.mode).primary[500]
      : tokens(theme.palette.mode).grey[700],
  borderRadius: 2,
  p: 1,
};

export default CustomTable;
