import React from "react";
import {
  IconButtonProps,
  useTheme,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { tokens } from "../../theme";

export type CustomIconButtonProps = {
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium" | "large";
  variant?: "contained" | "outlined" | "text";
  tooltipPlacement?:
    | "right"
    | "left"
    | "top"
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  tooltipMessage?: string;
} & IconButtonProps;

const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  size,
  color,
  variant,
  tooltipPlacement,
  tooltipMessage,
  ...props
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Tooltip
      title={tooltipMessage}
      placement={tooltipPlacement || "bottom"}
      arrow
    >
      <IconButton
        size={size || "large"}
        color={color || "success"}
        {...props}
        sx = {{borderRadius : 1, border : 1,m : '2px'}}
      >
        {props.children}    
      </IconButton>
    </Tooltip>
  );
};

export default CustomIconButton;
