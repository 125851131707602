import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { PropsWithChildren } from "react";
import { setFirstTimeLogin, setSelectedModule, useAuthSlice } from "../../redux/slice/authSlice";
import { MyProSidebarProvider } from "../common/sidebar/sidebarContext";
import CustomToolbar from "../common/CustomToolbar";
import { getDataFromCookies, getDataFromLocalStorage, removeItemFromLocalStorage } from "../../utils/StorageUtils";
import { decrypt, encrypt } from "../../utils/EncryptorUtils";
import { SHIFT_KEY, SHIFT_TEXT } from "../../constants/applicationConstants";
import useToken from "../../hooks/useToken";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { sideBarItems } from "../common/sidebar/MyProSidebar";

type Props = {
  fallback?: string;
};

const ProtectedRoute = ({ children, fallback }: PropsWithChildren<Props>) => {
  const { status, isValid, userInfo } = useAuthSlice();
  const location = useLocation();
  const isTokenValueAvailable = useToken();
  const dispatch = useAppDispatch();
  
  const authCookieValue = getDataFromCookies(encrypt(`2FA${userInfo?.userId}CHECK`, SHIFT_KEY))
  const version = getDataFromCookies(`version`);

  if (status === "idle" && fallback) {
    return <Navigate to={fallback} replace state={{ from: location }} />;
  } else if ((status === "success" && !isValid) || (status === "failed" && isValid) || (status === "failed" && !isValid) ) {
    return <Navigate to="/signIn" replace state={{ from: location }} />;
  }

  if ((status === "success" ) && isValid && userInfo?.securityTokens?.hasOwnProperty('enable2FA') && (!authCookieValue || parseInt(decrypt(authCookieValue, SHIFT_KEY).replace(SHIFT_TEXT, "")) !== userInfo.userId)) {
    return <Navigate to="/retrive2fa" replace state={{ from: location }} />;
  }

  if (status === "success" && isValid && fallback === "/") {
    let goto: string | null = getDataFromLocalStorage("GoTo");
    if (goto && goto.trim() !== "") {
      removeItemFromLocalStorage("GoTo");
      return <Navigate to={goto} replace state={{ from: location }} />;
    } else {
      const firstValidItem = sideBarItems.find((item) =>
        isTokenValueAvailable(item.token)
      );
      if (firstValidItem) {
        dispatch(setSelectedModule(firstValidItem.title));
        return <Navigate to={firstValidItem.to} replace state={{ from: location }} />;
      }
    }
  }

  if (status === "success" && isValid) {
    return (
      <MyProSidebarProvider>
        <div style={{ height: "100%", width: "100%" }}>
          <main>
            <CustomToolbar />
            {children}
          </main>
        </div>
      </MyProSidebarProvider>
    );
  }
  
  if (status === "idle" && !isValid){
    return <Navigate to="/signIn" replace />;
  }

  // Return null or another fallback if none of the conditions are met
  return null;
};

export default ProtectedRoute;
