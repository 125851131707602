import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  OrderInfo,
  OrderItemDTO,
  addClaim,
  addReimbursedClaim,
  claimInfo,
  getOrderItemsByOrderId,
  orderItemClaim,
  orderItemInvoice,
  reimburseClaimInfo,
  useOrderSlice,
} from "../../../redux/slice/order/OrderSlice";
import OptionsPopup, {
  OptionPopupObjectProps,
} from "../../common/OptionsPopup";
import Header from "../../common/Header";
import Card from "../../common/Card";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomTextField from "../../common/CustomTextField";
import Button from "../../common/Button";
import { Cancel, CheckCircleOutline, CurrencyExchangeOutlined, PaidOutlined } from "@mui/icons-material";
import CustomCheckbox from "../../common/CustomCheckBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectIsTokenAvailable } from "../../../redux/slice/authSlice";
import dayjs from "dayjs";
import { getProductItemHistory } from "../../../redux/slice/product/createProductSlice";
import { getProductItem } from "../../../service/ProductService";
import { getPracticeProductById, getPracticeProductByPracticeProductId } from "../../../redux/slice/practiceSlice";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { convertBase64, priceFormatter } from "../../../utils/Utility";
import { addInvoice, Attachment, SaveInvoiceInfo } from "../../../redux/slice/invoice/InvoiceSlice";
import { RESPONSE_CODES } from "../../../constants/applicationConstants";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

export interface OrderItemRow {
  select: JSX.Element;
  orderItemId: number;
  serialNumber: string;
  size: string;
  tissueTrackingId: number
  productReturnId: number;
  claim: orderItemClaim;
  invoice: orderItemInvoice;
  extendedPrice: number;
  invoiceId: number;
}

interface billingForm {
  insuranceClaim: string;
  mfgInvoice: string;
  invoiceAmt: number;
  invoicedDate: string;
  calculatedAmt: number;
  attachment: Attachment[];
}

interface FormattedOrderItem {
  orderItemId: number;
}

const Billing = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<billingForm>({
    defaultValues : {insuranceClaim: "",
    mfgInvoice: "",
    invoicedDate: dayjs(new Date()).format("MM/DD/YYYY"),
  }
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<OrderInfo>();
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showOptionPopup, setShowOptionsPopup] = useState<OptionPopupObjectProps>({ message: "", show: false });
  const [orderInfo, setOrderInfo] = useState<OrderInfo>();
  const {orderItemsListInfo} = useOrderSlice();
  const [submitAction, setSubmitAction] = useState<string>();
  const [productExtendedPrice, setProductExtendedPrice] = useState<number>(0);
  const [orderItemRow, setOrderItemRow] = useState<OrderItemRow[] | []>([]);
  const [ totalExtendedPrice, setTotalExtendedPrice ] = useState(0);
  const [ statusCount, setStatusCount ] = useState<{orderItemsCount: number, countToCheck: number}>( {
    orderItemsCount: 0,
    countToCheck: 0
  } );
  const location = useLocation();
  
  useEffect(() => {
    const orderDetails: OrderInfo = location?.state?.selectedOrderBilling;
    setOrderInfo(orderDetails);
    dispatch(getProductItemHistory({ productId: orderDetails.productId }))
    dispatch(getOrderItemsByOrderId(orderDetails.orderId));
    dispatch(getPracticeProductById(orderDetails.productId.toString())).then((res) => {
     
      if (res.payload) {
        const productAgreementObject = res.payload;
        setProductExtendedPrice(productAgreementObject.extendedPrice);
      }
    });
    }, [])

  const parseSize = (size: string): number => {
     const sizeWithoutCm = size.replace('cm', ''); 
    const [length, width] = sizeWithoutCm.split('x').map(Number);
    return length * width;
  }


  const modifyClaimObject = (claim: orderItemClaim, size: string): orderItemClaim => {
    return {
      ...claim,
      claimAmount: parseSize(size) * 122,
    };
  };

  const selectedOrderItems: {orderItemId: number}[] = useMemo(() => {
    const newSelectedOrderItems: {orderItemId:number}[] = checkedList?.map((id) => ({
       orderItemId : id
    }));
    return newSelectedOrderItems;
  }, [checkedList]);

  const onSubmit = async (data: billingForm) => {
    if (submitAction === "markClaim") {
      if (orderInfo) {
        const claimDtoObject: claimInfo = {
          orderId: orderInfo?.orderId,
          productId: orderInfo?.productId,
          claimNumber: data.insuranceClaim,
          claimStatus: 'SUBMITTED',
          submittedDate: dayjs(new Date()).format("MM/DD/YYYY"),
          orderItems: []
        }
        const response = await dispatch(addClaim(claimDtoObject));
  
        if (response.payload && response.payload.data.message === 'SUCCESS') {
          let alertMessage =  "Mark Claim Submitted!"; 
          const alertObj: SingleAlertInfo = {
            message: alertMessage,
            alertType: "success",
          };
          dispatch(setSingleAlertObj(alertObj));   
        }
      }
    } else if (submitAction === "markInvoiced") {
      if(checkedList?.length > 0) {
          setShowOptionsPopup({message: data.calculatedAmt == data.invoiceAmt ?  "Do you really want to record the the invoice?" 
            : "Calculated Amount and Invoice Amount are not equal. Do you still want to record the invoice?", 
            show: true, 
            variant: "confirm", 
            buttons: [{name: "Confirm", color: 'primary', onClick:() => submitMarkInvoiced(data) },{name: "Cancel", color: 'secondary', onClick:cancelOptionPopup }]});
        
      } else {
        let alertMessage =  "Please select atleast one order item"; 
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));  
      }
    }
  };

  const submitMarkInvoiced = async (data: billingForm) => {
    if (orderInfo) {
      const product = await getProductItem(orderInfo.productId);
      const manufacturerId = product && product.data.data && product.data.data.manufacturerId;
      const invoiceDtoObject: SaveInvoiceInfo = {
        orderId: orderInfo?.orderId,
        manufacturerId: manufacturerId,
        mfgInvoiceNumber: data.mfgInvoice,
        mfgInvoiceDate: data.invoicedDate,
        calculatedAmount: data.calculatedAmt,
        invoiceAmount: Number(data.invoiceAmt),
        orderItems: selectedOrderItems,
        attachInvoice : data.attachment,
        orderInvoiceStatus: statusCount.countToCheck === checkedList?.length ? "INVOICED" : "PARTIAL"
      };
      const response = await dispatch(addInvoice({invoiceData: invoiceDtoObject, interceptorOption: { skipErrorPopup: true } }));
      if (response?.payload?.code === RESPONSE_CODES.SUCCESS_CODE) {
        let alertMessage =  "Invoice was recorded successfully"; 
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));  
        navigate(-1);
      } else if (response?.payload?.code === RESPONSE_CODES.FAILED_CODE) {
        let alertMessage =  response.payload?.message; 
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));  
      }
      cancelOptionPopup();
    }
  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const claimDtoObject: orderItemClaim = {
    claimId: 2,
    claimNumber: "C3156456",
    claimAmount: 22.36,
    claimStatus: "REIMBURSED",
    submittedDate: "05/22/2024",
    reimbursedDate: "06/13/2024"
  }

  useEffect(() => {
    const fetchOrderItems = async () => {
      if (orderItemsListInfo) {
        let count = 0;
        const orderItemsPromises = orderItemsListInfo.map(async (orderItem) => {
          const response = await dispatch(getPracticeProductByPracticeProductId(orderItem.practiceProductId));

          if(orderItem.invoiceId === null && orderItem.productReturnId === null) {
            count += 1;
            setStatusCount(prev => {
              return ({...prev, countToCheck:count })
            });
          };
          return {
            select: <CustomCheckbox control={control} name="select" />,
            orderItemId: orderItem.orderItemId,
            serialNumber: orderItem.serialNumber,
            size: orderItem.size,
            tissueTrackingId: orderItem.tissueTrackingId,
            productReturnId: orderItem.productReturnId,
            claim: modifyClaimObject(claimDtoObject, orderItem.size),
            invoice: orderItem.invoice,
            extendedPrice: response?.payload?.extendedPrice ? response.payload.extendedPrice : "",
            productItemId: orderItem.productItemId,
            invoiceId: orderItem.invoiceId
          };
        });
        const orderItems = await Promise.all(orderItemsPromises);
        setOrderItemRow(orderItems);
        setStatusCount(prev => {
          return ({...prev, orderItemsCount: orderItemsListInfo.length})
        });
      }
    };

    fetchOrderItems();
  }, [orderItemsListInfo]);

  const handleCheckboxChange = (e: any, orderItemId: number, extendedPrice: number) => {

    const { checked } = e.target;

    setTotalExtendedPrice(prev => {
      const newVal =  checked ? prev + extendedPrice : prev - extendedPrice;
      setValue('calculatedAmt', newVal);
      return newVal;
    });

    setCheckedList(prev => {
      if(!prev.includes(orderItemId)){
        return [...prev, orderItemId]
      };
      return prev.filter(id => id !== orderItemId)
    })
  };

  const handleMarkedClaimReimbursed = async (claim : orderItemClaim) => {
      const addReimbursedClaimObj : reimburseClaimInfo = {
        claimId: claim.claimId,
        claimAmount: claim.claimAmount ? claim.claimAmount : 0,
        claimStatus: 'REIMBURSED',
        reimbursedDate:  dayjs(new Date()).format("MM/DD/YYYY"),
      }
    const response = await dispatch(addReimbursedClaim(addReimbursedClaimObj));
  };

  const handleMarkedInvoicePaid = (orderItemId: number) => {
    // setIsInvoicePaid((prevStatuses) => ({
    //   ...prevStatuses,
    //   [orderItemId]: !prevStatuses[orderItemId],
    // }));
  };

  const orderItemStatus = useCallback((row: OrderItemRow) => {
    if (row?.tissueTrackingId) {
      if (row?.invoiceId) {
        return "Applied (Invoiced)";
      }
      return "Applied";
    } else if (row?.productReturnId) {
      if (row?.invoiceId) {
        return "Returned (Invoiced)";
      }
      return "Returned";
    } else if (row?.invoiceId) {
      return "Invoiced";
    }
    return "Available";
  }, []);

  const columns = [
    { id: 'size', label: 'Ordered Grafts' },
    { id: 'serialNumber', label: 'Serial Number' },
    { id: 'status', label: 'Status' },
    { id: 'claim', label: 'Claim #' },
    { id: 'invoice', label: 'Invoice #' },
  ];

  const rows = orderItemRow;

  const caUpdateOrderClaim = useSelector((state: RootState) => selectIsTokenAvailable("caUpdateOrderClaim")(state));
  const caUpdateOrderInvoice = useSelector((state: RootState) => selectIsTokenAvailable("caUpdateOrderInvoice")(state));

  const handleCheckboxEnableDisable = (row: any) => {
    if (row.claim && caUpdateOrderClaim) {
      if (row.claim && row.claim.claimStatus) {
        return true;
      } else {
        return false;
      }
    } else if (row.invoice && caUpdateOrderInvoice) {
      if (row.invoice && row.invoice.mfgInvoiceStatus) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const disableCheckBox = (row: OrderItemRow) => {
    if(row?.invoiceId !== null || row?.productReturnId !== null) {
      return true;
    }
    return false;
  };

  const handleDropzoneChange = async (uploadedFiles: ExtendedFile[], fieldName: any) => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      const base64File = String(await convertBase64(uploadedFiles[0]));
      const fileName = uploadedFiles[0].name;
      const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
      const base64Substring = base64File.substring(
        fileExtension === "pdf" ? "data:application/pdf;base64,".length : "data:image/png;base64,".length,
        base64File.length);
      const attachmentObj: Attachment[] = [{
        content: base64Substring,
        documentName: fileName,
        fileType: fileExtension,
        type:  'INVOICE'
      }]
      setValue('attachment', attachmentObj);
    }
  };

  const handleDeleteFile = () => {
    setValue('attachment', []);
  };

  const handleSelectAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      const selectList: number[] = [];
      let invAmt : number = 0;
      orderItemRow?.forEach(row => {
        if ((row?.invoiceId === null && row?.productReturnId === null)) {
          selectList.push(row.orderItemId);
          invAmt += row.extendedPrice;
        };
      });
      setTotalExtendedPrice(invAmt);
      setValue('calculatedAmt', invAmt);
      setCheckedList(selectList)
    } else {
      setCheckedList([]);
      setTotalExtendedPrice(0);
      setValue('calculatedAmt', 0);
    };
  };

  return (
    <Box m="20px">
      {showOptionPopup.show && (
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      )}
      <Header title="Billing" subtitle="Biller Management" />
      <Box>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={1}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                  <Typography>Please select items to Record Invoice.</Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2} mb={2} mt={2}>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Patient : {orderInfo?.patientName}
                  </Typography>
                  <Typography component="span" style={{ paddingLeft: "10px" }}>
                    {order?.patientName}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Product :{orderInfo?.productName}
                  </Typography>
                  <Typography component="span" sx={{ paddingLeft: "10px" }}>
                    {order?.productName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Order # :{orderInfo?.orderNumber}
                  </Typography>
                  <Typography component="span" sx={{ paddingLeft: "10px" }}>
                    {order?.orderNumber}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography component="label" fontWeight="bold">
                    PO # :{orderInfo?.poNumber}
                  </Typography>
                  <Typography component="span" sx={{ paddingLeft: "10px" }}>
                    {order?.poNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }} />
              <Grid container md={8} spacing={2} mb={2} mt={1}>
                <Grid item xs={12}>
                  <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                    <ListItem>
                      <ListItemButton
                        onClick={() => {}}
                        sx={{ padding: 0, borderRadius: 2, fontSize: 14 }}
                      >
                        <ListItemIcon sx={{ width: "15%", textAlign:'left' }}>
                          <CustomCheckbox
                            control={control}
                            controllerName="selectAll"
                            onChange={handleSelectAllCheckbox}
                            tooltipMessage="Select All"
                            tooltipPlacement="top"
                          />
                        </ListItemIcon>
                        <ListItemText sx={{ width: "15%" }}>
                          <b>Ordered Grafts</b>
                        </ListItemText>
                        <ListItemText sx={{ width: "15%" }}>
                          <b>Serial Number</b>
                        </ListItemText>
                        <ListItemText sx={{ width: "15%" }}>
                          <b>Status</b>
                        </ListItemText>
                        {caUpdateOrderClaim ?
                        <ListItemText sx={{ width: "15%" }}>
                          <b>Claim #</b>
                        </ListItemText>
                        :""}
                        {caUpdateOrderInvoice ?
                        <ListItemText sx={{ width: "15%" }}>
                          <b>Invoice #</b>
                        </ListItemText>
                        : ""}
                        <ListItemText sx={{ width: "15%", textAlign:'right' }}>
                          <b>Extended Price</b>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    {orderItemRow.map((row) => (
                      <ListItem key={row.orderItemId}>
                        <ListItemButton
                          onClick={() => {}}
                          sx={{ padding: 0, borderRadius: 2 }}
                        >
                          <ListItemIcon sx={{ width: "15%" }}>
                            <CustomCheckbox
                              onChange={(e) => handleCheckboxChange(e, row.orderItemId, row.extendedPrice)}
                              control={control}
                              name={`select-${row.orderItemId}`}
                              checked={checkedList.includes(row.orderItemId)}
                              isDisabled={disableCheckBox(row)}
                            />
                          </ListItemIcon>
                          <ListItemText sx={{ width: "15%" }}>
                            {row.size}
                          </ListItemText>
                          <ListItemText sx={{ width: "15%" }}>
                            {row.serialNumber}
                          </ListItemText>
                          <ListItemText sx={{ width: "15%" }}>
                          {orderItemStatus(row)}
                          </ListItemText>
                          {caUpdateOrderClaim ?
                          <ListItemText sx={{ width: "15%" }}>
                            {row.claim.claimNumber}
                            {row.claim.claimStatus === 'SUBMITTED' ?
                            <>
                            <IconButton 
                                color="info" 
                                onClick={() => handleMarkedClaimReimbursed(row.claim)}
                            >
                              <CurrencyExchangeOutlined />
                            </IconButton>
                            <Grid item xs={6} sx={{ textAlign: "center" }}>
                              <Typography variant="body1" component="span">
                                {row.claim.claimStatus}
                              </Typography>
                            </Grid>
                            </>
                            :""}
                          </ListItemText>
                          : ""}
                          {caUpdateOrderInvoice?
                          <ListItemText sx={{ width: "15%" }}>
                            {row.invoice.mfgInvoiceNumber}
                            {row.invoice.mfgInvoiceStatus === 'UNPAID' ?
                            <>
                            <IconButton
                              color="warning"
                              onClick={() => handleMarkedInvoicePaid(row.orderItemId)}
                            >
                              <PaidOutlined />
                            </IconButton>
                            <Grid item xs={6} sx={{ textAlign: "center" }}>
                              <Typography variant="body1" component="span">
                                {row.invoice.mfgInvoiceStatus}
                              </Typography>
                            </Grid>
                            </>
                            :""}
                          </ListItemText>
                        : ""}
                          <ListItemText sx={{ width: "15%", textAlign:"right" }}>
                            {priceFormatter(row.extendedPrice)}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} mb={2}>
                <Grid item xs={12} md={3}>
                  <CustomTextField
                    controllerName="insuranceClaim"
                    control={control}
                    label="Insurance Claim #"
                    rules={submitAction === "markClaim" ? {required:"Insurance Claim Number is required"} : {}}
                  />
                </Grid>
                <Grid item md={6} xs={12}></Grid>
                <Grid item md={3} xs={12}>
                  <Button
                    fullWidth
                    size="large"
                    startIcon={<CheckCircleOutline />}
                    style={{ padding: "15px" }}
                    type="submit"
                    onClick={() => {setSubmitAction("markClaim")}}
                  >
                    Mark Claim Submitted
                  </Button>
                </Grid>
              </Grid> */}
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} md={3}>
                  <CustomTextField
                    controllerName="mfgInvoice"
                    control={control}
                    label="MFG Invoice #"
                    rules={submitAction === "markInvoiced" ? {required:"Manufacture Invoice Number is required"}:{}}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                <CustomCurrencyField
                      label="Calculated Amt"
                      controllerName="calculatedAmt"
                      control={control}
                      isDisabled
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomCurrencyField
                    controllerName="invoiceAmt"
                    control={control}
                    label="Invoice Amt"
                    rules={submitAction === "markInvoiced" ? {required:"Invoice amount is required"}:{}}
                   />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomDatePicker
                    controllerName="invoicedDate"
                    control={control}
                    label="Invoiced Date"
                    rules={submitAction === "markInvoiced" ? {required:"Invoice date is required"}:{}}
                   />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Dropzone
                    name="invoiceAttachment"
                    onFileChange={() => { }}
                    onFileChangeTest={handleDropzoneChange}
                    onDeleteFile={handleDeleteFile}
                    control={control}
                    maxFileCount={1}
                    label="Attach Invoice"
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="end" mt="20px">
              <Button
                    size="large"
                    startIcon={<CheckCircleOutline />}
                    sx={{ mr: 1 }}
                    type="submit"
                    onClick={() => {setSubmitAction("markInvoiced")}}
                  >
                    Record Invoice
                  </Button>
                <Button
                  color="error"
                  startIcon={<Cancel />}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default Billing;
