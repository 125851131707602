import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

interface IconDetailToolBarProps {
    items: Array<{
        icon: React.ReactElement;
        text: string;
    }>;
}

const IconDetailToolBar: React.FC<IconDetailToolBarProps> = ({ items }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Stack
            direction="row" alignItems="center" spacing={2} >
            {items.map((item, index) => (
                <Box sx={{ display: "flex", padding: "5px 10px" }}>
                    {item.icon}
                    <Typography variant="caption" sx={{ ml: 1, fontWeight: "bold" }}>
                        {item.text}
                    </Typography>
                </Box>
            ))}
        </Stack>
    );
};

export default IconDetailToolBar;