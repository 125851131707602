import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../redux-hooks";
import { LoadingType } from "../../../types/CommonTypes";
import { addAgencyProductCommissionData, editAgencyData, editAgencyProductCommissionData, getAgencyByUser, getAgencyDataById, getAssociateAgencyList, getIsV3AgencyExists, getProductCommissionsByAgencyId, saveAgencyData, searchAgencyData } from "../../../service/AgencyService";

export type SearchAgency = {
  agencyName: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  address: string | undefined;
  commType: string | undefined;
  currentPage: number;
  itemsPerPage: number;
};

export type Option = {
  value: string,
  label: string
};

export type AgencyOption = {
  value: number,
  label: string
};

interface PaginationAgencyList {
  content: CreateSearchAgency[];
  totalElements: number;
  totalPages: number;
  size: number;
}

interface PaginationProductCommissionList {
  content: ProductCommissionAgency[];
  totalElements: number;
  totalPages: number;
  size: number;
}

export type CreateSearchAgency = {
  agencyId: number | undefined
  agencyName: string | undefined;
  commissionType: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  address: string | undefined;
  streetAddress: string | undefined;
  unitNumber: string | undefined;
  zipcode: string | undefined;
  city: string | undefined;
  state: string | undefined;
  agencyProductCommisions?: ProductCommissionAgency[]
};

export type ProductCommissionAgency = {
  rowIndex: any;
  productCommissionId: number | undefined;
  productId: number | undefined;
  productName: string | undefined;
  agencyId: number | undefined;
  associateAgencyId: number | undefined;
  commissionRate: number | string | undefined;
  commissionPayTerms: string | undefined;
  effectiveDate: string | undefined;
  inactiveDate: string | undefined;
}

type AgencyState = {
  agency?: CreateSearchAgency | null;
  agencyInfoList?: PaginationAgencyList | null;
  agencyProductCommission?: ProductCommissionAgency | null;
  agencyProductCommissionList?: PaginationProductCommissionList | null;
  associateAgencyList?: CreateSearchAgency[] | null;
  isV3AgencyExists?: boolean;
  status: string;
  error: string | null;
}

const initialState: AgencyState = {
  agency: {
    agencyId: 0,
    agencyName: "",
    commissionType: "",
    email: "",
    phone: "",
    address: "",
    streetAddress: "",
    unitNumber: "",
    zipcode: "",
    city: "",
    state: "",
    agencyProductCommisions: []
  },
  agencyInfoList: undefined,
  agencyProductCommission: undefined,
  agencyProductCommissionList: undefined,
  associateAgencyList: undefined,
  isV3AgencyExists: false,
  status: "idle",
  error: null
};

export const saveAgency = createAsyncThunk("saveAgency", async (data: CreateSearchAgency, { rejectWithValue }) => {
  try{
  const response = await saveAgencyData(data);
  return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});

export const editAgency = createAsyncThunk("editAgency", async (data: CreateSearchAgency, { rejectWithValue }) => {
  try{
  const response = await editAgencyData(data);
  return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});

export const searchAgency = createAsyncThunk("searchAgency", async (data: SearchAgency, { rejectWithValue }) => {
  try{
  const response = await searchAgencyData(data);
  return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchAgencyById = createAsyncThunk("fetchAgencyById", async (data: number, { rejectWithValue }) => {
  try{
  const response = await getAgencyDataById(data);
  return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchAssociateAgencyList = createAsyncThunk("fetchAssociateAgencyList", async () => {
  try{
  const response = await getAssociateAgencyList();
  return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchAgencyByUser = createAsyncThunk("fetchAgencyByUser", async () => {
  try{
  const response = await getAgencyByUser();
  return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const addAgencyProductCommission = createAsyncThunk("addAgencyProductCommission", async (data: ProductCommissionAgency,  { rejectWithValue }) => {

  try {
    const response = await addAgencyProductCommissionData(data);
    const v3Response = response.data;
    return v3Response;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }

});

export const editAgencyProductCommission = createAsyncThunk("editAgencyProductCommission", async (data: ProductCommissionAgency, { rejectWithValue }) => {

  try {
    const response = await editAgencyProductCommissionData(data);
    const v3Response = response.data;
    return v3Response;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }

});

export const fetchProductCommissionsByAgencyId = createAsyncThunk("fetchProductCommissionsByAgencyId", async (data: any, { rejectWithValue }) => {
  try{
  const response = await getProductCommissionsByAgencyId(data.agencyId, data.currentPage, data.itemsPerPage);
  return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchIsV3AgencyExist = createAsyncThunk("fetchIsV3AgencyExist", async () => {
  try{
  const response = await getIsV3AgencyExists();
  return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

const agencySlice = createSlice({
  name: "agency",
  initialState,
  reducers: {
    updateAgencyInfoObject: (state: AgencyState, action) => {
      state.agency = action.payload;
    },
    clearAgencyObject: (state: AgencyState) => {
      state.agency = null;
    },
    clearAgencyProductCommissionList: (state: AgencyState) => {
      state.agencyProductCommissionList = null;
    },
    clearAgencyInfoList : (state) =>{
      state.agencyInfoList = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveAgency.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveAgency.fulfilled, (state, action) => {
        state.status = "success";
        state.agency = action.payload.data;
      })
      .addCase(saveAgency.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message ?? null;
      })
      .addCase(editAgency.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editAgency.fulfilled, (state, action) => {
        state.status = "success";
        state.agency = action.payload.data;
      })
      .addCase(editAgency.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message ?? null;
      })
      .addCase(searchAgency.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchAgency.fulfilled, (state, action) => {
        state.status = "success";
        state.agencyInfoList = action.payload.data;
      })
      .addCase(searchAgency.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch agencies";
      })
      .addCase(addAgencyProductCommission.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addAgencyProductCommission.fulfilled, (state, action) => {
        state.status = "success"
        state.agencyProductCommission = action.payload.data
      })
      .addCase(addAgencyProductCommission.rejected, (state, action) => { 
        state.status = "error"
      })
      .addCase(editAgencyProductCommission.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(editAgencyProductCommission.fulfilled, (state, action) => {
        state.status = "success"
        state.agencyProductCommission = action.payload.data
      })
      .addCase(editAgencyProductCommission.rejected, (state, action) => { 
        state.status = "error"
      })
      .addCase(fetchProductCommissionsByAgencyId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductCommissionsByAgencyId.fulfilled, (state, action) => {
        state.status = "success";
        state.agencyProductCommissionList = action.payload.data;
      })
      .addCase(fetchProductCommissionsByAgencyId.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch agency product commission data";
      })
      .addCase(fetchAgencyById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAgencyById.fulfilled, (state, action) => {
        state.status = "success";
        state.agency = action.payload.data;
      })
      .addCase(fetchAgencyById.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch agency data";
      })
      .addCase(fetchAssociateAgencyList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAssociateAgencyList.fulfilled, (state, action) => {
        state.status = "success";
        state.associateAgencyList = action.payload.data;
      })
      .addCase(fetchAssociateAgencyList.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch associate agencies";
      })
      .addCase(fetchIsV3AgencyExist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchIsV3AgencyExist.fulfilled, (state, action) => {
        state.status = "success";
        state.isV3AgencyExists = action.payload.data;
      })
      .addCase(fetchIsV3AgencyExist.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch V3 Agency Existence";
      })
      .addCase(fetchAgencyByUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAgencyByUser.fulfilled, (state, action) => {
        state.status = "success";
        state.agency = action.payload.data;
      })
      .addCase(fetchAgencyByUser.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch  Agency";
      })
  },
});

export const { updateAgencyInfoObject, clearAgencyObject, clearAgencyProductCommissionList, clearAgencyInfoList} = agencySlice.actions;
export const useAgencyReducer = () => useAppSelector((state) => state.agencySlice);
export default agencySlice.reducer;