import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/slice/redux-hooks';
import { Box, Grid, } from '@mui/material';
import Header from '../../common/Header';
import Card from '../../common/Card';
import Dropzone from '../../common/Dropzone';
import CustomTextField from '../../common/CustomTextField';
import Button from '../../common/Button';
import { Cancel, SaveOutlined } from '@mui/icons-material';
import { IVRNotes, uploadIVRNotes } from '../../../redux/slice/ivr/ivrDocumentSlice';
import { SingleAlertInfo, setSingleAlertObj } from '../../../redux/slice/commonSlice';
interface ExtendedFile extends File {
  documentId?: string;
}

const IVRNote = () => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<IVRNotes>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  useEffect(() => {
    let iverId = location?.state?.ivrInfo?.ivrId;
    setValue('xrefId', iverId);
    setValue('xrefTable', "IVR");
    setValue('noteType', "NOTE");
  }, [])

  const onSubmit = async (data: IVRNotes) => {
    try {
      const response = await dispatch(uploadIVRNotes(data));
  
      if (response.payload && response.payload.message === 'Note is added successfully') {
        navigate(-1);
        let alertMessage =  "Note saved Successfully!";
  
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));   
      }
      
    }catch (error) {
      console.log("Error saving note:", error);
    } 
  }

  const handleNotDocumentFileChange = async (uploadedFiles: ExtendedFile[]) => {
    let base64File = "";
    const fileData = uploadedFiles[uploadedFiles.length - 1];
    base64File = removeDataUriPrefix(String(await convertBase64(fileData)));

    setValue('attachment', base64File);
    setValue('documentName', fileData.name);
    setValue('fileType', fileData.type);
    setValue('noteType', 'NOTE');
    setValue('xrefTable', 'IVR');

  }

  const convertBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  };

  const removeDataUriPrefix = (dataUri: string): string => {
    const base64String = dataUri.split(',')[1];
    return base64String || '';
  };

      return (
        <Box m="20px">
          <Header title="Note" subtitle="" />
          <Box>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
    
              <Box p={1}>
                <Grid container spacing={2} mb={2}>
                  <Grid item md={12} xs={12}>
                    <CustomTextField
                      controllerName="content"
                      control={control}
                      label="Notes"
                      multiline
                      rows={4}
                      maxRows={4}
                      rules={{ required: "Notes are required." }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={2} mt={1}>
                  <Grid item md={4} xs={12}>
                    <Dropzone
                      name="attachment"
                      onFileChange={handleNotDocumentFileChange}
                      control={control}
                      label="Attachment"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} md="auto">
                    <Button type="submit" startIcon={<SaveOutlined />} sx={{ mr: 1 }} onClick={() => handleSubmit(onSubmit)}
                      fullWidth>
                        Save
                    </Button>
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <Button color="error" startIcon={<Cancel />} onClick={() => navigate(-1)}
                    fullWidth>
                        Close
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              </form>
            </Card>
          </Box>
        </Box>
      );
    };

export default IVRNote