import * as React from 'react';
import { TextField, MenuItem, Select, InputLabel, FormControl, useTheme, Autocomplete } from '@mui/material';
import { tokens } from '../../theme';

export type DropdownFieldProps = {
  options: { value: string | number; label: string; style?: React.CSSProperties }[];
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium";
  label?: string;
  variant?: "filled" | "outlined" | "standard";
  disableSearch?: boolean;
  name?: string;
  onChangeSelect?: (e: any) => any;
  value?: string | number;
  rules?: Object
}; 

const DropdownField: React.FC<DropdownFieldProps> = ({
  options,
  size = "medium",
  color,
  label,
  variant = "outlined",
  disableSearch = false, 
  name,
  onChangeSelect,
  value,
  rules,
  ...props
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isRequired = () => {
    if (rules && typeof rules === "object" && "required" in rules && label) {
      return true;
    }
    return false;
  };

  const getLabel = () => {
    if (isRequired()) {
      return (
        <span>
          {label}{" "}
          <span style={{ color: colors.success[200], fontSize: "18px" }}>
            *
          </span>
        </span>
      );
    }
    return label;
  };

  if (disableSearch) {
    return (
      <FormControl fullWidth size={size || "medium"} variant={variant || "outlined"}>
        {label && <InputLabel color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}>{label}</InputLabel>}
        <Select
          label={getLabel() || ""}
          color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
          style={{ maxHeight: 200 }}
          name={name}
          onChange={onChangeSelect}
          value={value}
          {...props}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} style={option.style}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <Autocomplete
        {...props}
        options={options.map(option => option.label)}
        getOptionLabel={(option: string) => {
          const originalOption = options.find(o => o.label === option);
          return originalOption ? originalOption.label : "";
        }}
        renderInput={(params) => (
          <TextField 
            {...params} 
            label={getLabel() || ""}
            variant={variant}
            size={size}
            name={name}
            color={color || (theme.palette.mode === "dark" ? "secondary" : "primary")}
            onChange={onChangeSelect}
          />
        )}
      />
    );
  }
};

export default DropdownField;
