import { InterceptorOption } from "../types/CommonTypes";
import { FETCH_ALL_CODE_TABLES_VALUES_URL, FETCH_CODE_TABLE_VALUES_BY_TABLE_NAME_URL } from "./URL";
import { http } from "./http";

export const getAllCodeTableValue = async (interceptorOption?: InterceptorOption) => {
    const response = await http.get(FETCH_ALL_CODE_TABLES_VALUES_URL, undefined, interceptorOption);
    return response;
};

export const getAllCodeTableValueByTableName = async (tableName: string) => {
    const response = await http.get(FETCH_CODE_TABLE_VALUES_BY_TABLE_NAME_URL,
        {
            params: {
                tableName: tableName
            }
        }
    );
    return response;
};